function SyncDisableIcon() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="436.05px"
      height="436.05px"
      viewBox="0 0 436.05 436.05"
    >
      <path
        d="M181.05,58.65v-51c-20.4,5.1-38.25,12.75-56.1,25.5L163.2,71.4C168.3,66.3,173.4,61.2,181.05,58.65z M0,35.7l61.2,61.2
			C40.8,127.5,28.05,163.2,28.05,204c0,56.1,22.95,107.1,61.2,142.8L28.05,408h153V255l-56.1,56.1
			c-28.05-25.5-45.9-63.75-45.9-107.1c0-25.5,5.1-48.45,17.85-71.4l206.55,206.55c-5.101,2.55-12.75,5.1-20.4,7.649v53.55
			c20.4-5.1,38.25-12.75,56.1-25.5l61.2,61.2L433.5,402.9L30.6,2.55L0,35.7z M436.05,0h-153v153l56.1-56.1
			c28.051,25.5,45.9,63.75,45.9,107.1c0,25.5-5.1,48.45-17.85,71.4l38.25,38.25c17.85-33.15,30.6-68.851,30.6-109.65
			c0-56.1-22.95-107.1-61.2-142.8L436.05,0z"
      />
    </svg>
  );
}

export default SyncDisableIcon;
