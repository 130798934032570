function SyncType({ type }) {
  switch (type) {
    case "ORDER":
      return <span>Order</span>;
    case "FULFILLMENT":
      return <span>Fulfillment</span>;
    case "PAYMENT":
      return <span>Payment</span>;
    case "REFUND":
      return <span>Refund</span>;
    default:
      return <span></span>;
  }
}

export default SyncType;
