import {
  getWoocommerceAccounts,
  getWoocommerceConfig,
  getWoocommerceCustomers,
  getWoocommerceProducts,
  getWoocommerceOrders,
  getWoocommerceJobStatus,
  getWoocomPaymentMethodConfig,
  getWoocomProductAttributes,
  getWoocomTaxMappings,
  getDeskeraSyncedProductsForWoocommerce,
  getDeskeraDeskeraInventoryTransactionForWoocommerce
} from "src/api";
import {
  WOOCOMMERCE_FAILED_CONFIG_LOAD,
  WOOCOMMERCE_SET_CONFIG,
  WOOCOMMERCE_FAILED_ACCOUNT_LOAD,
  WOOCOMMERCE_SET_ACCOUNTS,
  WOOCOMMERCE_FAILED_CUSTOMERS_LOAD,
  WOOCOMMERCE_SET_CUSTOMERS,
  WOOCOMMERCE_FAILED_PRODUCTS_LOAD,
  WOOCOMMERCE_SET_PRODUCTS,
  WOOCOMMERCE_FAILED_ORDERS_LOAD,
  WOOCOMMERCE_SET_ORDERS,
  WOOCOMMERCE_SET_JOB,
  WOOCOMMERCE_FAILED_JOB_LOAD,
  WOOCOMMERCE_SET_PAYMENT_CONFIG,
  WOOCOMMERCE_FAILED_PAYMENT_LOAD,
  WOOCOMMERCE_SET_PRODUCT_ATTRIBUTES,
  WOOCOMMERCE_FAILED_PRODUCT_ATTRIBUTES_LOAD,
  WOOCOMMERCE_SET_DESKERA_PRODUCTS,
  WOOCOMMERCE_SET_DESKERA_INVENTORY_TRANSACTION,
  WOOCOMMERCE_SET_TAX_MAPPING,
  WOOCOMMERCE_FAILED_TAX_MAPPING_LOAD
} from "../types";

export function loadConfig() {
  return async function (dispatch, getState) {
    try {
      const config = await getWoocommerceConfig();
      dispatch(setConfig(config));
    } catch (err) {
      dispatch(failedConfigLoad());
    }
  };
}

export function setConfig(config) {
  return {
    type: WOOCOMMERCE_SET_CONFIG,
    config: config,
  };
}

export function failedConfigLoad() {
  return {
    type: WOOCOMMERCE_FAILED_CONFIG_LOAD,
  };
}

export function loadAccounts() {
  return async function (dispatch, getState) {
    const accountLoading = getState().woocommerce.accounts.loading;

    try {
      const accounts = await getWoocommerceAccounts();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(failedAccountLoad());
    }
  };
}

export function setAccounts(accounts) {
  return {
    type: WOOCOMMERCE_SET_ACCOUNTS,
    accounts: accounts,
  };
}

export function failedAccountLoad() {
  return {
    type: WOOCOMMERCE_FAILED_ACCOUNT_LOAD,
  };
}

export function loadCustomers(limit, page, status, sortBy) {
  return async function (dispatch, getState) {
    try {
      const customers = await getWoocommerceCustomers(limit, page, status, sortBy);
      dispatch(setCustomers(customers));
    } catch (err) {
      console.log(err);
      dispatch(failedCustomersLoad());
    }
  };
}

export function setCustomers(customers) {
  return {
    type: WOOCOMMERCE_SET_CUSTOMERS,
    customers: customers,
  };
}

export function failedCustomersLoad() {
  return {
    type: WOOCOMMERCE_FAILED_CUSTOMERS_LOAD,
  };
}

export function loadOrders(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    const ordersLoading = getState().woocommerce.orders.loading;

    try {
      const orders = await getWoocommerceOrders(limit, page, status, sortBy, search);
      dispatch(setOrders(orders));
    } catch (err) {
      dispatch(failedOrdersLoad());
    }
  };
}

export function setOrders(orders) {
  return {
    type: WOOCOMMERCE_SET_ORDERS,
    orders: orders,
  };
}

export function failedOrdersLoad() {
  return {
    type: WOOCOMMERCE_FAILED_ORDERS_LOAD,
  };
}

export function loadProducts(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const products = await getWoocommerceProducts(limit, page, status, sortBy, search ?? "");
      dispatch(setProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setProducts(products) {
  return {
    type: WOOCOMMERCE_SET_PRODUCTS,
    products: products,
  };
}

export function failedProductsLoad() {
  return {
    type: WOOCOMMERCE_FAILED_PRODUCTS_LOAD,
  };
}

export function setJob(job) {
  return {
    type: WOOCOMMERCE_SET_JOB,
    job: job,
  };
}

export function loadJob() {
  return async function (dispatch) {
    try {
      const job = await getWoocommerceJobStatus();
      dispatch(setJob(job));
    } catch (err) {
      dispatch(failedJobLoad());
    }
  };
}

export function failedJobLoad() {
  return {
    type: WOOCOMMERCE_FAILED_JOB_LOAD,
  };
}

export function setPaymentMethodConfig(paymentConfig) {
  return {
    type: WOOCOMMERCE_SET_PAYMENT_CONFIG,
    paymentConfig: paymentConfig
  };
}
export function loadPaymentAccountConfig() {
  return async function(dispatch) {
    try {
      const resp = await getWoocomPaymentMethodConfig();
      if (resp) {
        dispatch(setPaymentMethodConfig(resp));
      } else {
        dispatch(setPaymentMethodConfig({}))
      }
    } catch (err) {
      dispatch(failedPaymentAccountConfigLoad());
    }
  }
}

export function failedPaymentAccountConfigLoad() {
  return {
    type: WOOCOMMERCE_FAILED_PAYMENT_LOAD
  };
}
export function setProductAttributes(productAttributes) {
  return {
    type: WOOCOMMERCE_SET_PRODUCT_ATTRIBUTES,
    productAttributes: productAttributes
  };
}
export function loadProuductAttributes() {
  return async function(dispatch) {
    try {
      const resp = await getWoocomProductAttributes();
      if (resp) {
        dispatch(setProductAttributes(resp));
      } else {
        dispatch(setProductAttributes({}))
      }
    } catch (err) {
      dispatch(failedProductAttributesLoad());
    }
  }
}

export function failedProductAttributesLoad() {
  return {
    type: WOOCOMMERCE_FAILED_PRODUCT_ATTRIBUTES_LOAD
  };
}


export function loadDeskeraSyncedProducts(limit, page, status, sortBy) {
  return async function (dispatch, getState) {
    try {
      const products = await getDeskeraSyncedProductsForWoocommerce(limit, page, status, sortBy);
      dispatch(setDeskeraProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setDeskeraProducts(products) {
  return {
    type: WOOCOMMERCE_SET_DESKERA_PRODUCTS,
    products: products,
  };
}


export function loadDeskeraInventoryTransaction(limit, page, status, sortBy) {
  return async function (dispatch, getState) {
    try {
      const inventoryTransaction = await getDeskeraDeskeraInventoryTransactionForWoocommerce(limit, page, status, sortBy);
      dispatch(setDeskeraInventoryTransaction(inventoryTransaction));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setDeskeraInventoryTransaction(inventoryTransaction) {
  return {
    type: WOOCOMMERCE_SET_DESKERA_INVENTORY_TRANSACTION,
    deskeraInventoryTransaction: inventoryTransaction,
  };
}

export function setTaxMapping(taxMapping) {
  return {
    type: WOOCOMMERCE_SET_TAX_MAPPING,
    taxMapping: taxMapping
  };
}
export function loadTaxMapping() {
  return async function(dispatch) {
    try {
      const resp = await getWoocomTaxMappings();
      if (resp) {
        dispatch(setTaxMapping(resp));
      } else {
        dispatch(setTaxMapping({}))
      }
    } catch (err) {
      dispatch(failedTaxMappingLoad());
    }
  }
}

export function failedTaxMappingLoad() {
  return {
    type: WOOCOMMERCE_FAILED_TAX_MAPPING_LOAD
  };
}