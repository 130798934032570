import React, { useEffect, useState } from "react";
import SearchLogo from "src/assets/Search";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination, useRowSelect } from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";
import EmptyTray from "src/assets/EmptyTray";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "src/redux/actions/toasts";
import ArrowClockwise from "src/assets/ArrowClockwise";
import { getDeskeraTenantDetails } from "src/api";
import Moment, { suppressDeprecationWarnings } from "moment";
import { startDateFormat } from "src/utils/Constants";
import { DKStatus } from "deskera-ui-library";
import { Checkbox } from "./Checkbox";
import ShopifyStoreFilter from "./Shopify/ShopifyStoreFilter";

function RowSelectionTable({ columns, data, migrate, cart, refresh, pageCount: controlledPageCount, loading}) {
  const [value, setValue] = useState();
  const [status, setStatus] = useState([]);
  const [hover, setHover] = useState(false);
  const job = useSelector((state) => state?.[cart]?.job?.data);
  const jobRunning =
    job && (job.jobStatus === "QUEUED" || job.jobStatus === "IN_PROGRESS") ? true : false;
  const [triggered, setTriggered] = useState(false);
  const dispatch = useDispatch();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
    prepareRow,
    setGlobalFilter,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()}
               disabled={row.original.syncStatus === "SUCCESSFUL"}
               />
              
            </div>
          ),
        },
        ...columns,
      ])
    }
  )
  const colummsLength = columns.length;
  const dataLength = data.length;
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  function handleChange(e) {
    e.preventDefault();
    setValue(e.target.value);
    onChange(e.target.value);
  }
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: "230px",
    }),
  };

  const statusOptions = [
    {
      value: "SUCCESSFUL",
      label: "Successful",
    },
    {
      value: "FAILED",
      label: "Failed",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
  ];

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];
  const [initialRefreshDataState] = useState({ pageIndex, pageSize, status });
  const [query, setQuery] = useState({});
  const accounts = useSelector((state) => state.shopify.accounts);

  useEffect(() => {
    if (initialRefreshDataState.pageIndex !== pageIndex || initialRefreshDataState.pageSize !== pageSize || initialRefreshDataState.status !== status || Object.keys(query)?.length) {
      refresh({ pageIndex, pageSize, status, query });
    }
  }, [pageIndex, pageSize, status, query])

  async function handleSyncSubmit() {
    setTriggered(true);
    var bbd; 
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      bbd = tenantDetails.bookBeginningStartDate; 
    } catch (err) {
      setTriggered(false);
      return;
    }

    try {
      const body = selectedFlatRows.map(row => row.original);
      const resp = await migrate(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job started",
        })
      );
    } catch (err) {
      if (cart !== "amazon") {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: err.response.data.message,
          })
        );
      }
    }
    refresh({ pageIndex, pageSize, status, query });
    setTriggered(false);
  }

  function Warning() {
    return <div className="bg-chip-blue border-radius-l p-h-s ml-s p-v-xs">Syncing...</div>;
  }

  const updateSelectedStatus = (options) => {
    var selected = [];
    options.forEach((element) => {
      selected.push(element.value);
    });
    setStatus(selected);
  };

  return (
    <>
      <div className="d-flex flex-row mb-3 space-between">
        <div className="d-flex" style={{
          maxHeight: '40px'
        }}>
          <button style={{marginRight: "10px"}}className="btn border-radius-m p-v-s text-white bg-success" onClick={() => refresh({pageIndex, pageSize, status, query })}>
            <span className="svg-icon">
              <ArrowClockwise />
            </span>
          </button>
          <button
            className= "btn border-radius-m p-v-s text-white bg-success"
            onClick={handleSyncSubmit}
            disabled={triggered}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              {triggered ? (
                <div class="spinner-border text-white spinner-border-sm" role="status"></div>
              ) : (
                <></>
              )}
            </span>
            {Object.entries(selectedRowIds).length > 0 ? (
            
              <span>Sync Selected to {cart.charAt(0).toUpperCase() + cart.slice(1)}</span>
        
            ) : (<span>
              {cart == "square" ? (
                <span>Sync All to {cart.charAt(0).toUpperCase() + cart.slice(1)}</span>
              ) : (
                <span> Sync to {cart.charAt(0).toUpperCase() + cart.slice(1)}</span>
              )}
            </span>

            )}
            
          </button>
        </div>
        <div style={{ verticalAlign: "bottom", marginTop: "5px" }}>{triggered && hover && Warning()}</div>
        <div style={{ marginLeft: "auto" }}>
          <form className="form-inline">
            {/* <span className="mr-2">Show by status:</span> */}
            {cart === "shopify" && 
                <ShopifyStoreFilter
                accounts={accounts}
                customSelectTheme={customSelectTheme}
                customStyles={customStyles}
                query={query}
                setQuery={setQuery}
                />
              }
            <Select
              placeholder="Filter By Sync Status"
              className="mr-2"
              styles={customStyles}
              options={statusOptions}
              // value={statusOptions.filter((option) => option.value === status)}
              menuPlacement="auto"
              onChange={(e) => {
                updateSelectedStatus(e);
                console.log("status value  : ", status);
                console.log("select value  : ", e);
              }}
              theme={customSelectTheme}
              components={{
                IndicatorSeparator: () => null,
              }}
              isSearchable={false}
              isMulti={true}
            />
            {/* <div className="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light">
                  <span className="svg-icon svg-disabled">
                    <SearchLogo />
                  </span>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={handleChange}
                value={value}
              />
            </div> */}
          </form>
        </div>
      </div>
      <div className="card" style={{ overflowX: "auto" }}>
      <table className="table m-0 dk-table-hover dk-table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th 
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                    style={{verticalAlign: "top"}}
                    // style={{ whiteSpace: "nowrap" }}
                  >
                    <span className="fs-r text-align-left fw-m fs-m text-gray cursor-hand ">
                      {column.render("Header")}
                    </span>
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading && dataLength === 0 && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="mt-3 svg-disabled" style={{ width: "50px", height: "50px" }}>
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">No records were found.</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([{ className: cell.column.className }])}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
          {loading && (
            <tr>
              <td colSpan={colummsLength}>
                <div className="d-flex flex-column align-items-center">
                  <span className="mt-2 text-muted">Loading...</span>
                </div>
              </td>
            </tr>
          )}
          {/* {loading && <label className="d-flex justify-content-center">Loading...</label>} */}
        </table>
      </div>
      <div className="d-flex flex-row-reverse align-items-center mt-3">
        <div className="d-flex flex-row align-items-center">
          <span className="mr-2">Max rows per page:</span>
          <Select
            placeholder="Page"
            className="page-selector"
            options={pageSizeOptions}
            value={pageSizeOptions.filter((option) => option.value === pageSize)}
            menuPlacement="auto"
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-row align-items-center mr-2">
          <button className="btn mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button className="btn mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className="mr-2">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button className="btn mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ArrowRight />
            </span>
          </button>
          <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default RowSelectionTable;
