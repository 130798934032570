import {
  getAmazonAccounts,
  getAmazonConfig,
  getAmazonCustomers,
  getAmazonProducts,
  getAmazonOrders,
  getAmazonMappingProducts,
} from "src/api";
import {
  FAILED_AMAZON_CONFIG_LOAD,
  SET_AMAZON_CONFIG,
  FAILED_ACCOUNT_LOAD,
  SET_ACCOUNTS,
  FAILED_CUSTOMERS_LOAD,
  SET_CUSTOMERS,
  FAILED_PRODUCTS_LOAD,
  SET_PRODUCTS,
  FAILED_ORDERS_LOAD,
  SET_ORDERS,
  SET_MATCHING_PRODUCTS,
} from "../types";

export function loadConfig() {
  return async function (dispatch, getState) {
    try {
      const config = await getAmazonConfig();
      dispatch(setConfig(config));
    } catch (err) {
      dispatch(failedConfigLoad());
    }
  };
}

export function setConfig(config) {
  return {
    type: SET_AMAZON_CONFIG,
    config: config,
  };
}

export function failedConfigLoad() {
  return {
    type: FAILED_AMAZON_CONFIG_LOAD,
  };
}

export function loadAccounts() {
  return async function (dispatch, getState) {
    const accountLoading = getState().amazon.accounts.loading;
    if (!accountLoading) {
      return;
    }

    try {
      const accounts = await getAmazonAccounts();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(failedAccountLoad());
    }
  };
}

export function setAccounts(accounts) {
  return {
    type: SET_ACCOUNTS,
    accounts: accounts,
  };
}

export function failedAccountLoad() {
  return {
    type: FAILED_ACCOUNT_LOAD,
  };
}

export function loadCustomers(limit,page, sortBy) {
  return async function (dispatch, getState) {
    try {
      const customers = await getAmazonCustomers(limit,page,sortBy);
      dispatch(setCustomers(customers));
    } catch (err) {
      dispatch(failedCustomersLoad());
    }
  };
}

export function setCustomers(customers) {
  return {
    type: SET_CUSTOMERS,
    customers: customers,
  };
}

export function failedCustomersLoad() {
  return {
    type: FAILED_CUSTOMERS_LOAD,
  };
}

export function loadOrders(limit, page, sortBy, query) {
  return async function (dispatch, getState) {
    const ordersLoading = getState().amazon.orders.loading;

    try {
      const orders = await getAmazonOrders(limit, page, sortBy, query ?? "");
      dispatch(setOrders(orders));
    } catch (err) {
      dispatch(failedOrdersLoad());
    }
  };
}

export function setOrders(orders) {
  return {
    type: SET_ORDERS,
    orders: orders,
  };
}

export function failedOrdersLoad() {
  return {
    type: FAILED_ORDERS_LOAD,
  };
}

export function loadProducts(limit, page, sortBy, search = "") {
  return async function (dispatch, getState) {
    try {
      const products = await getAmazonProducts(limit, page, sortBy, search);
      dispatch(setProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setProducts(products) {
  return {
    type: SET_PRODUCTS,
    products: products,
  };
}

export function failedProductsLoad() {
  return {
    type: FAILED_PRODUCTS_LOAD,
  };
}
export function setMachingProduct(matchingProducts) {
  return {
    type: SET_MATCHING_PRODUCTS,
    matchingProducts: matchingProducts,
  };
}

export function loadMatchingProducts(body) {
  return async function (dispatch, getState) {
    try {
      const matchingProduct = await getAmazonMappingProducts(body);
      dispatch(setMachingProduct(matchingProduct));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  }
}