import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { setAmazonConnection } from "src/api";
import Alert from "src/components/Alert";

function AmazonOAuth() {
  const [status, setStatus] = useState("connecting");
  const history = useHistory();
  const [accountName, setAccountName] = useState("");
  const [formSubmitting, setFormSubmitting] = useState(false);

  async function updateConnection() {
    if (!accountName || accountName === "") {
      return;
    }

    setFormSubmitting(true);
    let windowUrl = new URL(window.location);
    let oauthParams = windowUrl.searchParams;

    const connectionInfo = {
      code: oauthParams.get("spapi_oauth_code"),
      state: oauthParams.get("state"),
      sellingPartnerId: oauthParams.get("selling_partner_id"),
      accountName: accountName,
    };

    try {
      await setAmazonConnection(connectionInfo);
      setStatus("connected");
    } catch {
      setStatus("failed");
    } finally {
      setTimeout(() => history.push("/app/amazon"), 1000);
    }
  }

  return status === "connecting" ? (
    <div className="w-100 h-100">
      <label for="account-name">Select an Account Name</label>
      <div className="input-group">
        <input
          id="account-name"
          type="text"
          class="form-control"
          placeholder="Eg. My Amazon Store"
          aria-label="Eg. My Amazon Store"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
        />
      </div>
      <button
        className="btn dk-btn border-primary text-primary font-weight-bold mt-3"
        onClick={updateConnection}
        style={{ width: 100 }}
      >
        {formSubmitting ? (
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Connecting...</span>
          </div>
        ) : (
          "Connect"
        )}
      </button>
    </div>
  ) : status === "connected" ? (
    <Alert type="success">
      Amazon account successfully connected. Redirecting you to Deskera ECom.
    </Alert>
  ) : (
    <Alert type="danger">
      Failed to connect your Amazon account. Please try authorizing again.
    </Alert>
  );
}

export default AmazonOAuth;
