import React, { useEffect, useMemo, useState } from "react";
import SyncStatus from "src/components/SyncStatus";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SearchLogo from "src/assets/Search";
import AsyncSelect from "react-select/async";
import {
  getDeskeraProductBreifSearch,
  getDeskeraProductBrif,
  saveMagentoMatchingProducts,
} from "src/api";
import { useAsyncDebounce } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { DKLabel, DKButton, Toggle } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import { addToast } from "src/redux/actions/toasts";
import { loadMatchingProducts, loadProducts } from "src/redux/actions/magento";
import { Utility } from "src/utils/Utility";
function MatchingSummaryTable(props) {
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(true);
  const [toggleMapProductStatus, setToggleMapProductStatus] = useState(false);
  const [input, setInput] = useState("");
  const data1 = useSelector((state) => state.magento.matchingProduct);
  const [data, setData] = useState(data1.data);
  const [productData, setProductData] = useState(props.productData);
  const [error, setError] = useState("");
  const [columns, setColumns] = useState([]);

  const refreshData = ({ pageSize, pageIndex, sortBy }) => {
    if (!loading) {
      setLoading(true);
      let payload = {
        magentoDeskeraMappingField: props.deskeraMappingField.value,
        magentoMappingField: props.magentoMappingField,
        isCustomField: props.deskeraMappingField.isCustomField,
        showSucceedProducts: toggleStatus,
        showMappedProducts: toggleMapProductStatus,
        limit: !toggleStatus && showAll ? 1000 : pageSize,
        page: !toggleStatus && showAll ? 0 : pageIndex,
        sortDir: "ASC",
        search: input,
      };
      setPageSize(pageSize);
      setPageIndex(pageIndex);
      setStatus(status);
      setSortBy(sortBy);
      dispatch(loadMatchingProducts(payload));
    }
  };

  async function getBooksProducts() {
    // try {
    //   const products = await getDeskeraProductBrif();
    //   products.forEach((product) => {
    //     productData.push(product);
    //   });
    // } catch {
    //   setError("Failed to load Deskera Books Products");
    // }
  }

  useEffect(() => {
    getBooksProducts();
  }, []);

  useEffect(() => {}, [columns]);

  useEffect(() => {
    setLoading(false);
    setColumns([
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productName}{" "}
            {row.original.productVariantName &&
              row.original.productVariantName !== "Default Title" &&
              "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Mapped Deskera Product",
        accessor: "deskeraDocumentCode",
        width:'350px',
        Cell: ({ cell: { row } }) => {
          return (
            <SelectProduct
              row={row}
              loadDeskeraOptions={loadDeskeraOptions}
              deskeraHandleChange={deskeraHandleChange}
              productData={productData}
              deskeraMappingField={props.deskeraMappingField}
              setProductData={setProductData}
            />
          );
        },
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
        disableGlobalFilter: true,
      },
    ]);
  }, [data]);

  useMemo(() => setData(data1.data), [data1]);
  useMemo(() => setPageCount(data.totalPages), [data]);

  const handleCancel = () => {
    props.setShowPopup(false);
    props.setSyncConfigOpen(false);
    props.refreshData();
  };

  useEffect(() => {
    refreshData({ pageSize, pageIndex, sortBy });
    if (toggleStatus || toggleMapProductStatus) {
      setShowAll(false);
    }
  }, [toggleStatus, input, showAll, toggleMapProductStatus]);

  const handleToggle = () => {
    if (!loading) {
      setToggleStatus((toggleStatus) => !toggleStatus);
    }
  };

  const handleMappedToggle = () => {
    if (!loading) {
      setToggleMapProductStatus((toggleMapProductStatus) => !toggleMapProductStatus);
    }
  };

  const loadDeskeraOptions = async (inputValue) => {
    const config = {
      search: inputValue,
      limit: 25,
    };
    const response = await getDeskeraProductBreifSearch(config);
    // return response
    if (inputValue === undefined || inputValue == "") {
      return response;
    } else {
      return response.filter((data) => {
        if (String(data.name)?.toLowerCase().includes(String(inputValue)?.toLowerCase())) {
          return true;
        } else if (props.deskeraMappingField.isCustomField && data.customField?.length) {
          let customObj = data.customField.find((field) => {
            return props.deskeraMappingField.value === field.code;
          });
          if (
            customObj &&
            String(customObj.value)?.toLowerCase().includes(String(inputValue)?.toLowerCase())
          ) {
            return true;
          }
        } else if (String(data.barcode).includes(String(inputValue)?.toLowerCase())) {
          return true;
        }
        return false;
      });
    }
  };

  const deskeraHandleChange = (e, index) => {
    let newData = Utility.deepClone(data);
    if (Utility.isNotEmpty(newData.content)) {
      newData.content[index] = {
        ...newData.content[index],
        deskeraDocumentCode: e.documentSequenceCode,
        deskeraId: e.pid,
        deskeraProductName: e.name,
      };
      setData(newData);
    }
  };

  async function handleSyncSubmit() {
    try {
      const resp = await saveMagentoMatchingProducts(data.content);

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Product Mapping Saved successfully ",
        })
      );
      props.refreshData();
      return;
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
    }
  }

  async function handleSyncSubmitAndClosePopup() {
    await handleSyncSubmit();
    props.setShowPopup(false);
    props.setSyncConfigOpen(false);
  }

  const onTextChange = useAsyncDebounce((value) => {
    setInput(value);
  }, 50);

  const handleSearchChange = (value) => {
    onTextChange(value || "");
  };

  return (
    <div
      className="w-100 h-100  d-flex justify-content-center  overflow-auto"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card dk-card-shadow d-flex border border-radius-l"
        style={{ minWidth: "100%", minHeight: "70%" }}
      >
        <h3 className="pt-4 ml-4">Map Products</h3>
        <div className="card-body d-flex flex-column">
          <form className="form-inline" style={{ color: "gray", fontSize: 12 }}>
            <div class="d-flex justify-content-between" style={{ width: "100%" }}>
              <div class="">
                {
                  <>
                    <div className="form-group mr-5">
                      <div class="form-check form-check-inline">
                        <DKLabel
                          text="Show All Products"
                          style={{ color: "gray", fontSize: 12, marginLeft: "0px" }}
                        />
                        <Toggle
                          disabled={loading}
                          className="ml-s"
                          isOn={toggleStatus}
                          onChange={handleToggle}
                        />
                        {/* <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          defaultChecked={showAll}
                          checked={showAll}
                          disabled={loading || toggleStatus}
                          onChange={() => {
                            setShowAll(!showAll);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox2">
                          Show all
                        </label> */}
                      </div>
                    </div>
                  </>
                }
              </div>
              <div class="ml-xxl" style={{ width: "30%" }}>
                <div className=" input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text bg-light">
                      <span className="svg-icon svg-disabled">
                        <SearchLogo />
                      </span>
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => handleSearchChange(e.target.value)}
                    value={input}
                  />
                </div>
              </div>

              <div class="d-flex flex-row">
                <DKLabel
                  text="Show Pending to map"
                  style={{ color: "gray", fontSize: 12, marginLeft: "300px" }}
                />
                <Toggle
                  disabled={loading}
                  className="ml-s"
                  isOn={toggleMapProductStatus}
                  onChange={handleMappedToggle}
                />
              </div>
            </div>
            <div class="d-flex justify-content-end" style={{ width: "100%" }}>
              <div class="d-flex flex-row"></div>
            </div>
          </form>

          <ServerPaginationTable
            data={data.content ? data.content : []}
            columns={columns}
            migrate={""}
            minHeight={Utility.isEmpty(data.content) ? 'auto' : 450}
            cart="shopify"
            refresh={refreshData}
            tab="MatchingSummaryTable"
            pageCount={pageCount}
            loading={loading}
            rowId="id"
          />
          <div className="d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmitAndClosePopup}
              title={"SAVE AND CLOSE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmit}
              title={"SAVE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
              onClick={handleCancel}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MatchingSummaryTable;

function SelectProduct({
  row,
  loadDeskeraOptions,
  deskeraHandleChange,
  productData,
  deskeraMappingField,
  setProductData,
}) {
  // const [product, setProduct] = useState(
  //   productData.filter((obj) => obj.pid === row.original.deskeraId)
  // );

  const [product] = useState(
    row.original.deskeraId
      ? { pid: row.original.deskeraId, name: row.original.deskeraProductName }
      : null
  );

  const loadProductsOptions = Utility.debounce((inputValue, callback) => {
    loadDeskeraOptions(inputValue).then((response) => {
      callback(response);
    });
  }, 250);

  return (
    <AsyncSelect
      placeholder={"Select Product"}
      className="filter-selector ml-3"
      cacheOptions
      defaultOptions={productData}
      maxMenuHeight={250}
      value={product}
      getOptionLabel={(e) => (
        <div class="d-flex  align-items-center " style={{ minWidth: "230px" }}>
          <div className="ellipsis"> {e.name} </div>
          {deskeraMappingField?.isCustomField &&
            e.customField.find(({ code }) => code === deskeraMappingField.value)?.value && (
              <div className="ml-xs fs-s align-items-end">
                {" "}
                ({" "}
                {deskeraMappingField?.isCustomField &&
                  deskeraMappingField.value &&
                  e.customField &&
                  e.customField.find(({ code }) => code === deskeraMappingField.value)?.value}
                )
              </div>
            )}
          {deskeraMappingField.value === "BARCODE" && (
            <div className="fs-s align-items-right">
              {" "}
              {deskeraMappingField.value === "BARCODE" && e.barcode}
            </div>
          )}
        </div>
      )}
      getOptionValue={(option) => option.pid}
      loadOptions={loadProductsOptions}
      onChange={(option) => {
        //   setProduct(option);
        //   setProductData((prev) => {
        //     const doesOptionsExsits = prev.find((obj) => obj.pid === option.pid);
        //     if (!doesOptionsExsits) {
        //       prev.push(option);
        //     }
        //     return prev;
        //   })
        // }}
        deskeraHandleChange(option, row.index);
      }}
    />
  );
}
