function SyncIcon() {
  return (
    <svg
      id="Capa_1"
      enable-background="new 0 0 515.554 515.554"
      height="512"
      viewBox="0 0 515.554 515.554"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m96.666 257.778c0-77.635 55.647-141.267 128.889-156.322v27.433l96.667-64.444-96.667-64.445v34.968c-109.108 15.716-193.334 109.432-193.334 222.81 0 68.268 30.612 129.428 78.693 170.827l56.424-37.619c-42.608-29.023-70.672-77.885-70.672-133.208z" />
      <path d="m404.639 86.951-56.424 37.619c42.608 29.022 70.673 77.885 70.673 133.208 0 77.731-55.535 142.223-128.891 157.26v-28.373l-96.667 64.444 96.667 64.444v-34.838c109.12-15.72 193.335-109.55 193.335-222.938.001-68.267-30.611-129.427-78.693-170.826z" />
    </svg>
  );
}

export default SyncIcon;
