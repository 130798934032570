import {
  BIGCOMMERCE_SET_ACCOUNTS,
  BIGCOMMERCE_FAILED_ACCOUNT_LOAD,
  BIGCOMMERCE_CUSTOM_FIELD,
  BIGCOMMERCE_FAILED_PRODUCTS_LOAD,
  BIGCOMMERCE_SET_CONFIG,
  BIGCOMMERCE_FAILED_CONFIG_LOAD,
  BIGCOMMERCE_SET_JOB,
  BIGCOMMERCE_FAILED_JOB_LOAD,
  BIGCOMMERCE_SET_PRODUCTS,
  BIGCOMMERCE_FAILED_ORDERS_LOAD,
  BIGCOMMERCE_SET_ORDERS,
  BIGCOMMERCE_SET_CUSTOMERS,
  BIGCOMMERCE_FAILED_CUSTOMERS_LOAD
} from "../types";
import { 
  getBigcommerceAccounts, 
  getBigcommerceConfig,
  getBigcommerceJobStatus,
  getBigcommerceProducts,
  getBigcommerceOrders,
  getBigcommerceCustomers
} from "src/api";

export function setAccounts(accounts) {
  return {
    type: BIGCOMMERCE_SET_ACCOUNTS,
    accounts: accounts,
  };
}

export function loadAccounts() {
  return async function (dispatch, getState) {
    try {
      const accounts = await getBigcommerceAccounts();
      console.log("bigcommerce accounts loaded successfully:", accounts);
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(failedAccountLoad());
    }
  };
}

export function failedAccountLoad() {
  return {
    type: BIGCOMMERCE_FAILED_ACCOUNT_LOAD,
  };
}

export function failedProductsLoad() {
  return {
    type: BIGCOMMERCE_FAILED_PRODUCTS_LOAD,
  };
}

export function loadConfig() {
  return async function (dispatch, getState) {
    try {
      let config = await getBigcommerceConfig();
      let bigcommerceStoreId = 0;
      if (config && config.bigcommerceStore && config.bigcommerceStore.length > 0) {
        bigcommerceStoreId = config.bigcommerceStore[0].id;
        config = { ...config, bigcommerceId: bigcommerceStoreId };
      } else if (config) {
        config = { ...config, bigcommerceId: bigcommerceStoreId };
      }
      dispatch(setConfig(config));
    } catch (err) {
      dispatch(failedConfigLoad());
    }
  };
}

export function setConfig(config) {
  return {
    type: BIGCOMMERCE_SET_CONFIG,
    config: config,
  };
}

export function failedConfigLoad() {
  return {
    type: BIGCOMMERCE_FAILED_CONFIG_LOAD,
  };
}

export function loadJob() {
  return async function (dispatch) {
    try {
      const job = await getBigcommerceJobStatus();
      dispatch(setJob(job));
    } catch (err) {
      dispatch(failedJobLoad());
    }
  };
}

export function setJob(job) {
  return {
    type: BIGCOMMERCE_SET_JOB,
    job: job,
  };
}

export function failedJobLoad() {
  return {
    type: BIGCOMMERCE_FAILED_JOB_LOAD,
  };
}

export function loadProducts(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const products = await getBigcommerceProducts(limit, page, status, sortBy, search ?? "");
      dispatch(setProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setProducts(products) {
  return {
    type: BIGCOMMERCE_SET_PRODUCTS,
    products: products,
  };
}

export function loadOrders(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    const ordersLoading = getState().bigcommerce.orders.loading;

    try {
      const orders = await getBigcommerceOrders(limit, page, status, sortBy, search ?? "");
      dispatch(setOrders(orders));
    } catch (err) {
      dispatch(failedOrdersLoad());
    }
  };
}

export function failedOrdersLoad() {
  return {
    type: BIGCOMMERCE_FAILED_ORDERS_LOAD,
  };
}

export function setOrders(orders) {
  return {
    type: BIGCOMMERCE_SET_ORDERS,
    orders: orders,
  };
}

  export function loadCustomers(limit, page, status, sortBy) {
    return async function (dispatch, getState) {
      try {
        const customers = await getBigcommerceCustomers(limit, page, status, sortBy);
        dispatch(setCustomers(customers));
      } catch (err) {
        dispatch(failedCustomersLoad());
      }
    };
  }

    export function setCustomers(customers) {
    return {
      type: BIGCOMMERCE_SET_CUSTOMERS,
      customers: customers,
    };
  }

    export function failedCustomersLoad() {
    return {
      type: BIGCOMMERCE_FAILED_CUSTOMERS_LOAD,
    };
  }