import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Transition } from "react-transition-group";
import { setAuthToken, getLoginStatus, getTenantInfo, getDeskeraUserInfo, getDeskeraLoginUserInfo } from "src/api";
import { setCurrentUser, setDeskeraInfo, setUserInfo } from "src/redux/actions/auth";
import SplashScreen from "./SplashScreen";

const fadeInStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function LoginCheck({ children }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(function () {
    async function checkStatus() {
      let tenantInfo;
      try {
        const userTokens = await getLoginStatus();
        setAuthToken(userTokens.accessToken);
        localStorage.setItem("deskera-refresh-token", userTokens.refreshToken);
      } catch (err) {
        window.location = process.env.REACT_APP_AUTH_URL;
      }

      try {
        const userInfo = await getDeskeraUserInfo();
        dispatch(setDeskeraInfo(userInfo));
      } catch (err) {
        setError("Failed to get user info");
      }

      try {
        tenantInfo = await getTenantInfo();
        dispatch(setCurrentUser(tenantInfo));
      } catch (err) {
        setError("Failed to get tenant info");
      }
      if (tenantInfo && tenantInfo.userId) {
        try {
          const loginUserInfo = await getDeskeraLoginUserInfo(tenantInfo.userId);
          dispatch(setUserInfo(loginUserInfo));
        } catch (err) {
          setError("Failed to get user info");
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
    checkStatus();
  }, []);


  return (
    <>
      <Transition in={loading} appear={true} timeout={300} unmountOnExit>
        {(state) => {
          return (
            <SplashScreen style={{ transition: "opacity 0.3s ease-out", ...fadeInStyles[state] }} />
          );
        }}
      </Transition>
      {!loading && children}
    </>
  );
}

export default LoginCheck;
