import InvoicesChart from "src/components/charts/InvoicesChart";
import SyncChart from "src/components/charts/SyncChart";
import CustomersChart from "src/components/charts/CustomersChart";
import { useHistory } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCustomers as amazonLoadCustomers,
  loadOrders as amazonLoadOrders,
  loadProducts as amazonLoadProducts,
} from "src/redux/actions/amazon";
import {
  loadCustomers as shopifyLoadCustomers,
  loadOrders as shopifyLoadOrders,
  loadProducts as shopifyLoadProducts,
} from "src/redux/actions/shopify";
import {
  loadCustomers as loadWooCustomers,
  loadOrders as loadWooLoadOrders,
  loadProducts as loadWooProducts,
} from "src/redux/actions/woocommerce";

import {
  loadOrders as loadShipStationLoadOrders,
} from "src/redux/actions/shipStation";

import {
  loadOrders as loadMagentoOrders,
  loadProducts as loadMagentoProducts,
  loadCustomers as loadMagentoCustomers,
} from "src/redux/actions/magento";

import { DKButton } from "deskera-ui-library";

function DashboardHome() {
  const history = useHistory();
  const dispatch = useDispatch();

  const amazonCustomers = useSelector((state) => state.amazon.customers);
  const amazonOrders = useSelector((state) => state.amazon.orders);
  const amazonProducts = useSelector((state) => state.amazon.products);

  const shopifyCustomers = useSelector((state) => state.shopify.customers);
  const shopifyOrders = useSelector((state) => state.shopify.orders);
  const shopifyProducts = useSelector((state) => state.shopify.products);

  const woocommerceCustomers = useSelector((state) => state.woocommerce.customers);
  const woocommerceOrders = useSelector((state) => state.woocommerce.orders);
  const woocommerceProducts = useSelector((state) => state.woocommerce.products);

  const shipStationOrders = useSelector((state) => state.shipStation.orders);

  const magentoCustomers = useSelector((state) => state.magento.customers);
  const magentoOrders = useSelector((state) => state.magento.orders);
  const magetnoProducts = useSelector((state) => state.magento.products);

  const sections = [
    {
      title: "Amazon",
      href: "/app/amazon",
      plots: [
        {
          title: "Customers",
          chart: <CustomersChart customers={amazonCustomers} />,
        },
        {
          title: "Orders",
          chart: <InvoicesChart orders={amazonOrders} />,
        },
        {
          title: "Synced Data",
          chart: (
            <SyncChart
              customers={amazonCustomers}
              orders={amazonOrders}
              products={amazonProducts}
            />
          ),
        },
      ],
    },
    {
      title: "Shopify",
      href: "/app/shopify",
      plots: [
        {
          title: "Customers",
          chart: <CustomersChart customers={shopifyCustomers} />,
        },
        {
          title: "Orders",
          chart: <InvoicesChart orders={shopifyOrders} />,
        },
        {
          title: "Synced Data",
          chart: (
            <SyncChart
              customers={shopifyCustomers}
              orders={shopifyOrders}
              products={shopifyProducts}
            />
          ),
        },
      ],
    },
    {
      title: "WooCommerce",
      href: "/app/woocommerce",
      plots: [
        {
          title: "Customers",
          chart: <CustomersChart customers={woocommerceCustomers} />,
        },
        {
          title: "Orders",
          chart: <InvoicesChart orders={woocommerceOrders} />,
        },
        {
          title: "Synced Data",
          chart: (
            <SyncChart
              customers={woocommerceCustomers}
              orders={woocommerceOrders}
              products={woocommerceProducts}
            />
          ),
        },
      ],
    },
    {
      title: "ShipStation",
      href: "/app/shipStation",
      plots: [
        {
          title: "Deskera Orders",
          chart: <InvoicesChart orders={shipStationOrders} />,
        },
        {
          title: "Synced Data",
          chart: (
            <SyncChart
              orders={shipStationOrders}
            />
          ),
        },
      ],
    },
    {
      title: "Magento",
      href: "/app/magento",
      plots: [
        {
          title: "Customers",
          chart: <CustomersChart customers={magentoCustomers} />,
        },
        {
          title: "Orders",
          chart: <InvoicesChart orders={magentoOrders} />,
        },
        {
          title: "Synced Data",
          chart: (
            <SyncChart
              customers={magentoCustomers}
              orders={magentoOrders}
              products={magetnoProducts}
            />
          ),
        },
      ],
    },
    // {
    //   title: "Foodics",
    //   href: "/app/foodics",
    //   plots: [
    //     {
    //       title: "Purchases",
    //       chart: <CustomersChart customers={woocommerceCustomers} />,
    //     },
    //     {
    //       title: "Orders",
    //       chart: <InvoicesChart orders={woocommerceOrders} />,
    //     },
    //     {
    //       title: "Synced Data",
    //       chart: (
    //         <SyncChart
    //           customers={woocommerceCustomers}
    //           orders={woocommerceOrders}
    //           products={woocommerceProducts}
    //         />
    //       ),
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    dispatch(amazonLoadCustomers());
    dispatch(amazonLoadOrders());
    dispatch(amazonLoadProducts());
    dispatch(shopifyLoadCustomers());
    dispatch(shopifyLoadOrders());
    dispatch(shopifyLoadProducts());
    dispatch(loadWooCustomers());
    dispatch(loadWooLoadOrders());
    dispatch(loadWooProducts());
    dispatch(loadShipStationLoadOrders());
    dispatch(loadMagentoCustomers());
    dispatch(loadMagentoOrders());
    dispatch(loadMagentoProducts());
  }, []);

  return (
    <div className="w-100 h-100 p-3 overflow-auto" style={{ backgroundColor: "#f6f6f6" }}>
      {sections.map((section) => (
        <>
          <div className="border-bottom mb-2">
            <div className="d-flex align-items-center mb-2">
              <h3 className="mr-4 m-0">{section.title}</h3>
              <DKButton
                style={{ color: "white", backgroundColor: "#dc3545", borderRadius: "8px"}}
                onClick={(e) => history.push(section.href)}
                title="Open"
>
              </DKButton>
            </div>
          </div>
          <div className="row mb-4">
            {section.plots.map((plot) => (
              <div className="col-sm-4">
                <div className="card dk-card dk-card-shadow" style={{ height: "100%" }}>
                  <div className="card-header dk-card-header">
                    <h5 className="m-0">{plot.title}</h5>
                  </div>
                  <div
                    className="card-body d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                  >
                    {plot.chart}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ))}
    </div>
  );
}

export default DashboardHome;
