import { useState, useEffect } from "react";
import { Transition } from "react-transition-group";
import { useHistory, useLocation } from "react-router";
import { connectFoodicsStore } from "src/api";
import SplashScreen from "./SplashScreen";

function FoodicsOAuth({children}) {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    redirectCall();
  }, []);

  const fadeInStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  async function redirectCall() {
    setLoading(true);
    const path = location.pathname;
    const params = new URLSearchParams(location.search);
    const url = "http://localhost:8080" + path;
    const body = {};
    body["code"] = params.get("code");
    body["oauth_state"] = params.get("state");
    console.log("Making call to : " + url);
    try {
      const resp = await connectFoodicsStore(url, body);      
      history.push("/app/foodics");
    } catch (err) {
      history.push("/404")
      throw err;
    } finally {
      setLoading(false);
      // setTimeout(() => {
      //   history.push("/app/foodics");
      // }, 1000)
      
    }
  }

  return (
    <>
      <Transition in={loading} appear={true} timeout={300} unmountOnExit>
        {(state) => {
          return (
            <SplashScreen style={{ transition: "opacity 0.3s ease-out", ...fadeInStyles[state] }} />
          );
        }}
      </Transition>
      {!loading && children}
    </>
  );
}

export default FoodicsOAuth;
