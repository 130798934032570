import React, { useEffect, useMemo, useState } from "react";
import SyncStatus from "src/components/SyncStatus";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SearchLogo from "src/assets/Search";
import AsyncSelect from "react-select/async";
import {
  getDeskeraProductBreifSearch,
  saveBigCommerceMatchingProducts,
  getDeskeraDimensions,
  getDeskeraProductBrif,
  migrateBigCommerceMatchingProducts,
} from "src/api";
import { useAsyncDebounce } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { DKLabel, DKButton, Toggle } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import { addToast } from "src/redux/actions/toasts";
import { loadAccounts } from "src/redux/actions/bigcommerce";
import Select from "react-select";
import { Utility } from "src/utils/Utility";
import customSelectTheme from "src/utils/selectTheme";

function MapBigCommerceProducts(props) {
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [toggleMapProductStatus, setToggleMapProductStatus] = useState(false);
  const [input, setInput] = useState("");
  const [data, setData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [error, setError] = useState("");
  const [bigcommerceMappingField, setBigcommerceMappingField] = useState("PRODUCT_NAME");
  const [deskeraMappingField, setDeskeraMappingField] = useState({
    value: "PRODUCT_NAME",
    isCustomField: false,
  });
  const [bigcommerceStore, setBigcommerceStore] = useState(null);
  const [deskeraStatusOptions, setDeskeraStatusOptions] = useState([
    {
      value: "PRODUCT_NAME",
      label: "Product Name",
      isCustomField: false,
    },
    {
      value: "DOCUMENT_SEQ_CODE",
      label: "Product Seq Code",
      isCustomField: false,
    },
    {
      value: "BARCODE",
      label: "Barcode",
      isCustomField: false,
    },
  ]);

  const accounts = useSelector((state) => state.bigcommerce.accounts);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: "13rem",
    }),
  };

  const bigcommerceStatusOption = [
    {
      value: "PRODUCT_NAME",
      label: "Product Name",
    },
    {
      value: "SKU",
      label: "SKU",
    },
  ];

  useEffect(() => {
    if (!accounts?.data?.length) {
      dispatch(loadAccounts());
    }
    getDeskeraDimensionsData();
    getBooksProducts();
  }, []);

  useEffect(() => {
    if (!isMapProductsDisabled()) {
      refreshData({ pageSize, pageIndex, sortBy });
    }

    if (toggleStatus || toggleMapProductStatus) {
      setShowAll(false);
    }
  }, [toggleStatus, input, showAll, toggleMapProductStatus]);

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productName}{" "}
            {row.original.productVariantName &&
              row.original.productVariantName !== "Default Title" &&
              "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Mapped Deskera Product",
        accessor: "deskeraDocumentCode",
        width: "350px",
        Cell: ({ cell: { row } }) => {
          return (
            <SelectProduct
              row={row}
              loadDeskeraOptions={loadDeskeraOptions}
              deskeraHandleChange={deskeraHandleChange}
              productData={productData}
              deskeraMappingField={deskeraMappingField}
              setProductData={setProductData}
            />
          );
        },
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
        disableGlobalFilter: true,
      },
    ],
    [data]
  );

  const refreshData = async ({ pageSize, pageIndex, sortBy }) => {
    if (!loading) {
      setLoading(true);
      let payload = {
        bigcommerceDeskeraMappingField: deskeraMappingField?.value,
        bigcommerceMappingField: bigcommerceMappingField,
        cartAccountId: bigcommerceStore?.id || accounts?.data?.id,
        isCustomField: deskeraMappingField?.isCustomField,
        showSucceedProducts: toggleStatus,
        showMappedProducts: toggleMapProductStatus,
        limit: !toggleStatus && showAll ? 1000 : pageSize,
        page: !toggleStatus && showAll ? 0 : pageIndex,
        sortDir: "ASC",
        search: input,
      };
      setPageSize(pageSize);
      setPageIndex(pageIndex);
      setStatus(status);
      setSortBy(sortBy);

      await migrateBigCommerceMatchingProducts(payload)
        .then((response) => {
          if (response?.content?.length) {
            setData([...response.content]);
            setPageCount(response.totalPages);
          } else {
            setData([]);
            setPageCount(0);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  async function getBooksProducts() {
    try {
      await getDeskeraProductBrif().then((response) => {
        if (response?.length) {
          setProductData(response);
        }
      });
    } catch {
      setError("Failed to load Deskera Books Products");
    }
  }

  const handleCancel = () => {
    props.setSyncConfigOpen(false);
    props.refreshData();
  };

  async function getDeskeraDimensionsData() {
    try {
      const accounts = await getDeskeraDimensions();
      const dimensionOptions = [];
      accounts.forEach((account) => {
        if (account.modules.includes("PRODUCT")) {
          dimensionOptions.push({ value: account.code, label: account.label, isCustomField: true });
        }
      });
      let tempDeskeraStatusOptions = deskeraStatusOptions;
      tempDeskeraStatusOptions =
        dimensionOptions !== null
          ? tempDeskeraStatusOptions.concat(dimensionOptions)
          : dimensionOptions;
      setDeskeraStatusOptions(tempDeskeraStatusOptions);
    } catch {
      console.log("Failed to load Deskera Dimensions");
    }
  }

  const handleToggle = () => {
    if (!loading) {
      setToggleStatus((toggleStatus) => !toggleStatus);
    }
  };

  const handleMappedToggle = () => {
    if (!loading) {
      setToggleMapProductStatus((toggleMapProductStatus) => !toggleMapProductStatus);
    }
  };

  const loadDeskeraOptions = async (inputValue) => {
    const config = {
      search: inputValue,
      limit: 25,
    };
    const response = await getDeskeraProductBreifSearch(config);
    if (inputValue === undefined || inputValue == "") {
      return response;
    }
    return response;
  };

  const deskeraHandleChange = (e, index) => {
    let newData = Utility.deepClone(data);
    if (Utility.isNotEmpty(newData)) {
      newData[index] = {
        ...newData[index],
        deskeraDocumentCode: e.documentSequenceCode,
        deskeraId: e.pid,
        deskeraProductName: e.name,
        cartAccountId: bigcommerceStore?.id || accounts?.data?.id,
      };
      setData(newData);
    }
  };

  async function handleSyncSubmit() {
    try {
      const resp = await saveBigCommerceMatchingProducts(data);

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Product Mapping Saved successfully ",
        })
      );
      props.refreshData();
      return;
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
    }
  }

  async function handleSyncSubmitAndClosePopup() {
    await handleSyncSubmit();
    props.setSyncConfigOpen(false);
  }

  const handleSearchChange = useAsyncDebounce((value) => {
    setInput(value);
  }, 500);

  const isMapProductsDisabled = () => {
    if (
      (accounts.data.length > 1 ? !bigcommerceStore?.id : accounts?.data?.id) ||
      !bigcommerceMappingField ||
      !deskeraMappingField
    ) {
      return true;
    }

    return false;
  };

  return (
    <div
      className="w-100 h-100 d-flex justify-content-center overflow-auto position-absolute"
      style={{
        padding: "4rem 4rem 1rem",
        zIndex: 99,
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card d-flex border-radius-l"
        style={{ minWidth: "100%", minHeight: "70%" }}
      >
        <div className="card-body d-flex flex-column">
          <h3 className="mt-2 mb-xxl">Map Products</h3>
          <form className="form-inline align-items-end">
            {accounts?.data?.length > 1 && (
              <div className="form-group flex-column align-items-start">
                <span className="font-weight-bold text-muted">BigCommerce Store</span>
                <Select
                  placeholder="BigCommerce Store"
                  styles={customStyles}
                  options={accounts?.data || []}
                  menuPlacement="auto"
                  onChange={(e) => {
                    setBigcommerceStore(e);
                    setData([]);
                    setPageCount(0);
                    setPageIndex(0);
                  }}
                  theme={customSelectTheme}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  formatOptionLabel={(data) => data.name}
                  isOptionSelected={(data) => data?.id === bigcommerceStore?.id}
                />
              </div>
            )}
            <div className="form-group flex-column align-items-start ml-m">
              <span className="font-weight-bold text-muted">BigCommerce Mapping Field</span>
              <Select
                placeholder="BigCommerce Mapping Field"
                styles={customStyles}
                options={bigcommerceStatusOption}
                defaultValue={bigcommerceStatusOption[0]}
                menuPlacement="auto"
                onChange={(e) => setBigcommerceMappingField(e.value)}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
              />
            </div>
            <div className="form-group flex-column align-items-start ml-m">
              <span className="font-weight-bold text-muted">Deskera Mapping Field</span>
              <Select
                placeholder="Deskera Mapping Field"
                styles={customStyles}
                options={deskeraStatusOptions}
                defaultValue={deskeraStatusOptions[0]}
                onChange={(e) => setDeskeraMappingField(e)}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
              />
            </div>
            <div className="form-group flex-column align-items-start flex-grow-1">
              <DKButton
                disabled={isMapProductsDisabled()}
                className={
                  "text-white ml-m " + (isMapProductsDisabled() ? "bg-secondary" : "bg-primary")
                }
                onClick={() => refreshData({ pageSize, pageIndex, sortBy })}
                title={"MAP PRODUCTS"}
                style={{ height: 38 }}
              />
            </div>
            <div class="form-group flex-column align-items-start ml-m">
              <div class="form-check form-check-inline mb-2">
                <DKLabel text="Show All Products" className="font-weight-bold text-muted" />
                <Toggle
                  disabled={loading}
                  className="ml-s"
                  isOn={toggleStatus}
                  onChange={handleToggle}
                />
              </div>
            </div>
            <div class="form-group flex-column align-items-start ml-m">
              <div class="form-check form-check-inline mb-2">
                <DKLabel text="Show Pending to Map" className="font-weight-bold text-muted" />
                <Toggle
                  disabled={loading}
                  className="ml-s"
                  isOn={toggleMapProductStatus}
                  onChange={handleMappedToggle}
                />
              </div>
            </div>
            <div class="form-group flex-column align-items-start ml-m">
              <div className="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-light">
                    <span className="svg-icon svg-disabled">
                      <SearchLogo />
                    </span>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => handleSearchChange(e.target.value)}
                  style={{ height: 38 }}
                />
              </div>
            </div>
          </form>

          <ServerPaginationTable
            data={data || []}
            columns={columns}
            minHeight={Utility.isEmpty(data) ? "auto" : 450}
            migrate={""}
            cart="bigcommerce"
            refresh={refreshData}
            tab="MatchingSummaryTable"
            pageCount={pageCount}
            loading={loading}
            rowId="id"
          />
          <div className="d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmitAndClosePopup}
              title={"SAVE AND CLOSE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmit}
              title={"SAVE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
              onClick={handleCancel}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectProduct({
  row,
  loadDeskeraOptions,
  deskeraHandleChange,
  productData,
  deskeraMappingField,
}) {
  const [product] = useState(
    row.original.deskeraId
      ? { pid: row.original.deskeraId, name: row.original.deskeraProductName }
      : null
  );

  const loadProductsOptions = Utility.debounce((inputValue, callback) => {
    loadDeskeraOptions(inputValue).then((response) => {
      callback(response);
    });
  }, 250);

  return (
    <AsyncSelect
      placeholder={"Select Product"}
      className="filter-selector ml-3"
      cacheOptions
      defaultOptions={productData}
      maxMenuHeight={250}
      value={product}
      getOptionLabel={(e) => (
        <div class="d-flex  align-items-center " style={{ minWidth: "230px" }}>
          <div className="ellipsis">
            {e.name}
            {e.documentSequenceCode && (
              <small className="d-block text-muted">{e.documentSequenceCode}</small>
            )}
          </div>
          {deskeraMappingField?.isCustomField &&
            e.customField.find(({ code }) => code === deskeraMappingField.value)?.value && (
              <div className="ml-xs fs-s align-items-end">
                (
                {deskeraMappingField?.isCustomField &&
                  deskeraMappingField.value &&
                  e.customField &&
                  e.customField.find(({ code }) => code === deskeraMappingField.value)?.value}
                )
              </div>
            )}
          {deskeraMappingField.value === "BARCODE" && (
            <div className="fs-s align-items-right">
              {deskeraMappingField.value === "BARCODE" && e.barcode}
            </div>
          )}
        </div>
      )}
      getOptionValue={(e) => e.pid}
      loadOptions={loadProductsOptions}
      onChange={(option) => {
        deskeraHandleChange(option, row.index);
      }}
    />
  );
}

export default MapBigCommerceProducts;
