import {
  BIGCOMMERCE_SET_CONFIG,
  BIGCOMMERCE_SET_CUSTOMERS,
  BIGCOMMERCE_SET_ORDERS,
  BIGCOMMERCE_SET_PRODUCTS,
  BIGCOMMERCE_SET_ACCOUNTS,
  BIGCOMMERCE_SET_JOB,
  BIGCOMMERCE_SET_LOCATIONS,
  BIGCOMMERCE_SET_DESKERA_PRODUCTS,
  BIGCOMMERCE_SET_INVENTORY_TRANSACTIONS,
  BIGCOMMERCE_SET_PAYMENT_CONFIG,
  DESKERA_CUSTOM_FIELD,
  BIGCOMMERCE_DISCOUNT_CODE
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  products: {
    loading: true,
    data: [],
  },
  deskeraproducts: {
    loading: true,
    data: [],
  },
  customers: {
    loading: true,
    data: [],
  },
  inventoryTransactions: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  locations: {
    loading: true,
    data: {},
  },
  paymentConfig: {
    loading: true,
    data: {},
  },
  matchingProduct: {
    loading: true,
    data: {},
  },
};

export default function bigcommerceReducer(state = initialState, action = {}) {
  switch (action.type) {
    case BIGCOMMERCE_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case BIGCOMMERCE_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case BIGCOMMERCE_SET_PRODUCTS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.products,
        },
      };
    case BIGCOMMERCE_SET_JOB:
      return {
        ...state,
        job: {
          loading: false,
          data: action.job,
        },
      };
    case BIGCOMMERCE_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case BIGCOMMERCE_SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          loading: false,
          data: action.customers,
        },
      };
    default:
      return state;
  }
}