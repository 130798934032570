import { NavLink, Route, Switch } from "react-router-dom";
import { useState } from "react";
import Customers from "./Customers";
import Orders from "./Orders";
import Products from "./Products";
import Config from "./Config";
import CartonBoxIcon from "src/assets/CartonBox";
import CreditCardIcon from "src/assets/CreditCard";
import Bag from "src/assets/Bag";
import CreditCard from "src/assets/CreditCard";
import UserIcon from "src/assets/User";
import WrenchIcon from "src/assets/Wrench";
import DeskeraProducts from "./DeskeraProducts";
import InventoryTransactions from "./InventoryTransactions";
import { useEffect } from "react";
import CardCredit from "src/assets/CardCredit";
import { loadAccounts } from "src/redux/actions/shopify";
import { useDispatch } from "react-redux";

const menuItems = [
  {
    name: "Setup",
    href: "/app/shopify",
    icon: <WrenchIcon />,
  },
  {
    name: "Orders",
    href: "/app/shopify/orders",
    icon: <CreditCardIcon />,
  },
  {
    name: "Customers",
    href: "/app/shopify/customers",
    icon: <UserIcon />,
  },
  {
    name: "Products",
    href: "/app/shopify/products",
    icon: <CartonBoxIcon />,
  },
  {
    name: "Deskera Products",
    href: "/app/shopify/deskeraproducts",
    icon: <Bag />,
  },
  {
    name: "Inventory Transactions",
    href: "/app/shopify/inventory-transactions",
    icon: <CardCredit />,
  },
];

function Shopify() {
  const [activeVisible, setActiveVisible] = useState(false);
  const [activeTop, setActiveTop] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.setItem("cartType", "shopify");
  });
  useEffect(()=>{
    makeInitialApiCalls();
  },[]);

  function makeInitialApiCalls() {
    dispatch(loadAccounts());
  }
  return (
    <>
      <div
        className="bg-white py-3 dk-shadow-sm"
        style={{
          minWidth: 240,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          zIndex: 9,
          position: "relative",
        }}
      >
        {activeVisible && (
          <div
            className="ease-in-out-back"
            style={{
              width: 8,
              height: "3.25rem",
              position: "absolute",
              right: 0,
              top: activeTop,
              backgroundColor: "#4962df",
            }}
          ></div>
        )}
        {menuItems.map((item, index) => (
          <NavLink
            exact
            to={item.href}
            className="text-decoration-none px-4 py-3 d-block dk-navlink dk-navlink-hover mb-2 ease-in"
            isActive={(match, location) => {
              if (!match) return false;
              setActiveVisible(true);
              setActiveTop(1 + (3.375 + 0.5) * index + "rem");
              return true;
            }}
            style={{
              fontWeight: 500,
              color: "black"
            }}
          >
            <span className="svg-icon-lg svg-baseline mr-3">{item.icon}</span>
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="w-100 h-100 overflow-auto" style={{ background: "#f6f6f6" }}>
        <Switch>
          <Route exact path="/app/shopify">
            <Config />
          </Route>
          <Route path="/app/shopify/orders">
            <Orders />
          </Route>
          <Route path="/app/shopify/customers">
            <Customers />
          </Route>
          <Route path="/app/shopify/products">
            <Products />
          </Route>
          <Route path="/app/shopify/deskeraproducts">
            <DeskeraProducts />
          </Route>
          <Route path="/app/shopify/inventory-transactions">
            <InventoryTransactions />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default Shopify;
