import {
    getMagentoAccounts,
    getMagentoConfig,
    getMagentoCustomers,
    getMagentoInventoryTransactions,
    getMagentoProducts,
    getMagentoOrders,
    getMagentoJobStatus,
    getMagentoLocationMappings,
    getDeskeraSyncedProductsForMagento,
    getMagentoPaymentMethodConfig,
    migrateMagentoMatchingProducts,
    getMagentoCustomFields,
    getMagentoCustomFieldMapping,
    getDeskeraDimensions,
    getMagentoDiscountCode,
    getMagentoCutomerFilter
  } from "src/api";
  import {
    MAGENTO_FAILED_CONFIG_LOAD,
    MAGENTO_SET_CONFIG,
    MAGENTO_FAILED_ACCOUNT_LOAD,
    MAGENTO_SET_ACCOUNTS,
    MAGENTO_FAILED_CUSTOMERS_LOAD,
    MAGENTO_SET_CUSTOMERS,
    MAGENTO_FAILED_INVENTORY_TRANSACTIONS_LOAD,
    MAGENTO_SET_INVENTORY_TRANSACTIONS,
    MAGENTO_FAILED_PRODUCTS_LOAD,
    MAGENTO_SET_PRODUCTS,
    MAGENTO_FAILED_ORDERS_LOAD,
    MAGENTO_SET_ORDERS,
    MAGENTO_SET_JOB,
    MAGENTO_FAILED_JOB_LOAD,
    MAGENTO_FAILED_LOCATIONS_LOAD,
    MAGENTO_SET_LOCATIONS,
    MAGENTO_SET_DESKERA_PRODUCTS,
    MAGENTO_FAILED_PAYMENT_LOAD,
    MAGENTO_SET_PAYMENT_CONFIG,
    MAGENTO_MATCHING_PRODUCT,
    MAGENTO_CUSTOM_FIELD,
    MAGENTO_CUSTOM_FIELD_MAPPING,
    DESKERA_CUSTOM_FIELD,
    MAGENTO_DISCOUNT_CODE,
    MAGENTO_CUSTOMER_FILTER
  } from "../types";
  
  export function loadConfig() {
    return async function (dispatch, getState) {
      try {
        let config = await getMagentoConfig();
        let magentoStoreId = 0;
        if(config && config.magentoStore && config.magentoStore.length > 0){
         
          magentoStoreId =config.magentoStore[0].id 
          config = {...config, magentoId :magentoStoreId}
        }
        else if(config){
          config = {...config, magentoId :magentoStoreId}
        }
        dispatch(setConfig(config));
      } catch (err) {
        dispatch(failedConfigLoad());
      }
    };
  }
  
  export function setConfig(config) {
    return {
      type: MAGENTO_SET_CONFIG,
      config: config,
    };
  }
  
  export function failedConfigLoad() {
    return {
      type: MAGENTO_FAILED_CONFIG_LOAD,
    };
  }
  
  export function loadAccounts() {
    return async function (dispatch, getState) {
      try {
        const accounts = await getMagentoAccounts();
        dispatch(setAccounts(accounts));
      } catch (err) {
        dispatch(failedAccountLoad());
      }
    };
  }
  
  export function setAccounts(accounts) {
    return {
      type: MAGENTO_SET_ACCOUNTS,
      accounts: accounts,
    };
  }
  
  export function failedAccountLoad() {
    return {
      type: MAGENTO_FAILED_ACCOUNT_LOAD,
    };
  }
  
  export function loadCustomers(limit, page, status, sortBy) {
    return async function (dispatch, getState) {
      try {
        const customers = await getMagentoCustomers(limit, page, status, sortBy);
        dispatch(setCustomers(customers));
      } catch (err) {
        dispatch(failedCustomersLoad());
      }
    };
  }

  export function loadInventoryTransactions(limit, page, status) {
    return async function (dispatch, getState) {
      try {
        const inventoryTransactions = await getMagentoInventoryTransactions(limit, page, status);
        dispatch(setInventoryTransactions(inventoryTransactions));
      } catch (err) {
        dispatch(failedInventoryTransactionsLoad());
      }
    };
  }

  export function setCustomers(customers) {
    return {
      type: MAGENTO_SET_CUSTOMERS,
      customers: customers,
    };
  }

  export function setInventoryTransactions(inventoryTransactions) {
    return {
      type: MAGENTO_SET_INVENTORY_TRANSACTIONS,
      inventoryTransactions: inventoryTransactions,
    };
  }

  export function failedCustomersLoad() {
    return {
      type: MAGENTO_FAILED_CUSTOMERS_LOAD,
    };
  }

  export function failedInventoryTransactionsLoad() {
    return {
      type: MAGENTO_FAILED_INVENTORY_TRANSACTIONS_LOAD,
    };
  }

  export function loadOrders(limit, page, status, sortBy, startDate = "", endDate = "", search) {
    return async function (dispatch, getState) {
      const ordersLoading = getState().magento.orders.loading;

      try {
        const orders = await getMagentoOrders(
          limit,
          page,
          status,
          sortBy,
          startDate,
          endDate,
          search ?? ""
        );
        dispatch(setOrders(orders));
      } catch (err) {
        dispatch(failedOrdersLoad());
      }
    };
  }

  export function setOrders(orders) {
    return {
      type: MAGENTO_SET_ORDERS,
      orders: orders,
    };
  }

  export function failedOrdersLoad() {
    return {
      type: MAGENTO_FAILED_ORDERS_LOAD,
    };
  }

  export function loadProducts(limit, page, status, sortBy, search) {
    return async function (dispatch, getState) {
      try {
        const products = await getMagentoProducts(limit, page, status, sortBy, search ?? "");
        dispatch(setProducts(products));
      } catch (err) {
        dispatch(failedProductsLoad());
      }
    };
  }
  
  export function loadDeskeraSyncedProducts(limit, page) {
    return async function (dispatch, getState) {
      try {
        const products = await getDeskeraSyncedProductsForMagento(limit, page);
        dispatch(setDeskeraProducts(products));
      } catch (err) {
        dispatch(failedProductsLoad());
      }
    };
  }
  
  export function setProducts(products) {
    return {
      type: MAGENTO_SET_PRODUCTS,
      products: products,
    };
  }
  
  export function setDeskeraProducts(products) {
    return {
      type: MAGENTO_SET_DESKERA_PRODUCTS,
      products: products,
    };
  }
  
  export function failedProductsLoad() {
    return {
      type: MAGENTO_FAILED_PRODUCTS_LOAD,
    };
  }
  
  export function setJob(job) {
    return {
      type: MAGENTO_SET_JOB,
      job: job,
    };
  }
  
  export function loadJob() {
    return async function (dispatch) {
      try {
        const job = await getMagentoJobStatus();
        dispatch(setJob(job));
      } catch (err) {
        dispatch(failedJobLoad());
      }
    };
  }
  
  export function failedJobLoad() {
    return {
      type: MAGENTO_FAILED_JOB_LOAD,
    };
  }
  
  export function setLocations(locations) {
    return {
      type: MAGENTO_SET_LOCATIONS,
      locations: locations,
    };
  }
  
  export function loadLocations() {
    return async function (dispatch) {
      try {
        const locations = await getMagentoLocationMappings();
        dispatch(setLocations(locations));
      } catch (err) {
        dispatch(failedLocationsLoad());
      }
    };
  }
  
  export function failedLocationsLoad() {
    return {
      type: MAGENTO_FAILED_LOCATIONS_LOAD,
    };
  }
  
  export function setPaymentMethodConfig(paymentConfig) {
    return {
      type: MAGENTO_SET_PAYMENT_CONFIG,
      paymentConfig: paymentConfig
    };
  }
  export function loadPaymentAccountConfig() {
    return async function(dispatch) {
      try {
        const resp = await getMagentoPaymentMethodConfig();
        if (resp) {
          dispatch(setPaymentMethodConfig(resp));
        } else {
          dispatch(setPaymentMethodConfig({}))
        }
      } catch (err) {
        dispatch(failedPaymentAccountConfigLoad());
      }
    }
  }
  
  export function failedPaymentAccountConfigLoad() {
    return {
      type: MAGENTO_FAILED_PAYMENT_LOAD
    };
  }

  export function setMachingProduct(matchingProduct) {
    return { type: MAGENTO_MATCHING_PRODUCT, matchingProduct: matchingProduct };
  }
  export function loadMatchingProducts(body) {
    return async function (dispatch, getState) {
      try {
        const matchingProduct = await migrateMagentoMatchingProducts(body);
        dispatch(setMachingProduct(matchingProduct));
      } catch (err) {
        dispatch(failedProductsLoad());
      }
    };
  }
  export function setMagentoCustomfields(matchingCustomField) {
    return { type: MAGENTO_CUSTOM_FIELD, matchingCustomField: matchingCustomField };
  }
  export function loadMagentoCustomfields(body){

    return async function (dispatch) {
      try {
        const matchingCustomField = await getMagentoCustomFields(body);
        dispatch(setMagentoCustomfields(matchingCustomField));
      } catch (err) {
        dispatch(failedProductsLoad());
      }
    }

  }
  export function setMagentoCustomfieldMapping(matchingCustomFieldMapping) {
    return { type: MAGENTO_CUSTOM_FIELD_MAPPING, matchingCustomFieldMapping: matchingCustomFieldMapping };
  }
  export function loadMagentoCustomfieldMapping(body){

    return async function (dispatch) {
      try {
        const matchingCustomFieldMapping = await  getMagentoCustomFieldMapping(body);
        dispatch(setMagentoCustomfieldMapping(matchingCustomFieldMapping));
      } catch (err) {
        dispatch(failedProductsLoad());
      }
    }
  }

  export function setDeskeraCustomfields(deskeraCustomFields) {
    return { type: DESKERA_CUSTOM_FIELD, deskeraCustomFields: deskeraCustomFields };
  }
  export function loadDeskeraCustomfields(){

      return async function (dispatch) {
        try {
          const deskeraCustomFields = await  getDeskeraDimensions();
          dispatch(setDeskeraCustomfields(deskeraCustomFields));
        } catch (err) {
          dispatch(failedProductsLoad());
        }
      }
      
  }


  export function setMagentoDiscountCodes(magentoDiscountCodes) {
    return { type: MAGENTO_DISCOUNT_CODE, magentoDiscountCodes: magentoDiscountCodes };
  }
  export function loadMagentoDiscountCode(limit = 300, page = 0){

    return async function (dispatch) {
      try {
        const magentoDiscountCodes = await  getMagentoDiscountCode(limit,page);
        dispatch(setMagentoDiscountCodes(magentoDiscountCodes));
      } catch (err) {
        dispatch(failedProductsLoad());
      }
    }
    
}


export function setMagentoCustomerFilterConfig(magentoCusomterFilter) {
  return { type: MAGENTO_CUSTOMER_FILTER, magentoCusomterFilter: magentoCusomterFilter };
}

export function loadCustomerFilterConfig() {
  return async function (dispatch) {
    try {
      const magentoCusomterFilter = await getMagentoCutomerFilter();
      dispatch(setMagentoCustomerFilterConfig(magentoCusomterFilter));
    } catch (err) {
      dispatch(failedCustomersLoad());
    }
  };
}

  

  