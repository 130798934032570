import React, { useMemo } from "react";
import Select from "react-select";

const ShopifyStoreFilter = ({ accounts, setQuery, customStyles, customSelectTheme }) => {
  const options = useMemo(() => {
    return [
      { value: "all", label: "All" },
      ...(accounts?.data?.map?.((item) => ({ value: item.id, label: item.name })) || []),
    ];
  }, [accounts]);

  const handleChange = (selectedOption) => {
    if (selectedOption.value === "all") {
      setQuery((prevState) => ({
        ...prevState,
        shopUrl: '',
      }));
    } else {
      setQuery((prevState) => ({
        ...prevState,
        shopUrl: selectedOption?.value,
      }));
    }
  };

  return (
    <Select
      placeholder="Filter by Shopify Store"
      className="m-1"
      styles={customStyles}
      options={options}
      menuPlacement="auto"
      onChange={handleChange}
      theme={customSelectTheme}
      components={{
        IndicatorSeparator: () => null,
      }}
      isSearchable={true}
      isMulti={false}
    />
  );
};

export default ShopifyStoreFilter;
