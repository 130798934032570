import {
  SHOPIFY_SET_CONFIG,
  SHOPIFY_SET_CUSTOMERS,
  SHOPIFY_SET_ORDERS,
  SHOPIFY_SET_PRODUCTS,
  SHOPIFY_SET_ACCOUNTS,
  SHOPIFY_SET_JOB,
  SHOPIFY_SET_LOCATIONS,
  SHOPIFY_SET_DESKERA_PRODUCTS,
  SHOPIFY_SET_INVENTORY_TRANSACTIONS,
  SHOPIFY_SET_PAYMENT_CONFIG,
  SHOPIFY_SET_DISCOUNT_CONFIG,
  SHOPIFY_CONTACT_CUSTOMER_TYPE,
  SHOPIFY_FAILED_CONTACT_CUSTOMER_LOAD,
  SHOPIFY_SET_REFUNDS,
  SHOPIFY_FAILED_REFUNDS_LOAD
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  products: {
    loading: true,
    data: [],
  },
  contactCustomerType:{
    loading: true, 
    data: []
  },
  deskeraproducts: {
    loading: true,
    data: [],
  },
  customers: {
    loading: true,
    data: [],
  },
  inventoryTransactions: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  locations: {
    loading: true,
    data: {}
  },
  paymentConfig:{
    loading: true,
    data: {}
  },
  discountCodes:{
    loading: true,
    data: {}
  },
  matchingProduct:{
    loading: true,
    data: {}
  },
  refunds:{
    loading: true,
    data: []
  }
};

function shopifyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOPIFY_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case SHOPIFY_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case SHOPIFY_SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          loading: false,
          data: action.customers,
        },
      };
    case SHOPIFY_SET_INVENTORY_TRANSACTIONS:
      return {
        ...state,
        inventoryTransactions: {
          loading: false,
          data: action.inventoryTransactions,
        },
      };
    case SHOPIFY_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case SHOPIFY_SET_PRODUCTS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.products,
        },
      };
    case SHOPIFY_SET_DESKERA_PRODUCTS:
      return {
        ...state,
        deskeraproducts: {
          loading: false,
          data: action.products,
        },
      };
    case SHOPIFY_SET_JOB:
      return {
        ...state,
        job: {
          loading: false,
          data: action.job,
        },
      };
    case SHOPIFY_SET_LOCATIONS:
      return {
        ...state,
        locations: {
          loading: false,
          data: action.locations,
        },
      };
    case SHOPIFY_SET_PAYMENT_CONFIG:
      return {
        ...state,
        paymentConfig: {
          loading: false,
          data: action.paymentConfig,
        },
      };
    case SHOPIFY_SET_DISCOUNT_CONFIG:
      return {
        ...state,
        discountCodes: {
          loading: false,
          data: action.discountCodes,
        },
      };
    case SHOPIFY_CONTACT_CUSTOMER_TYPE:
      return {
        ...state,
        contactCustomerType: {
          loading: false,
          data: action.contactCustomerType,
        },
      };
    case SHOPIFY_FAILED_CONTACT_CUSTOMER_LOAD:
      return {
        ...state,
        contactCustomerType: {
          loading: false,
          data: action.contactCustomerType,
        },
      };
    case SHOPIFY_SET_REFUNDS:
      return {
        ...state,
        refunds: {
          loading: false,
          data: action.refunds,
        },
      };
    case SHOPIFY_FAILED_REFUNDS_LOAD:
      return {
        ...state,
        refunds: {
          loading: false,
          data: [],
        }
      }
    default:
      return state;
  }
}

export default shopifyReducer;
