import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';


const components = {
  DropdownIndicator: null
};

const createOption  = (label) => ({
  label,
  value: label
});

export default function MultiTextInput(props) {
  const [inputValue, setInputValue] = useState("");
  
  const handleChange = (newValue) => { 
  const newValueArray = newValue.map((item) => item.value);
  props.onValueChange(newValueArray, false);
  };
  const handleKeyDown = (event) => {
    if (!inputValue || inputValue.length === 0) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if ((inputValue?.trim() || '') ) {
          props.onValueChange([...props.value, inputValue], false);
          setInputValue('');
        } else {
          props.onValueChange([...props.value], true, )
        setInputValue('');
        }
        if (event.preventDefault) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  }; 

  const dropDownStyle = {
    container: (provided, state) => ({
      ...provided
    }),
    control: (provided, state) => {
      let color = 'var(--books-color)';
      if ((props?.value ==null)
        && props?.isMandatory) {
        color = 'red';
      }
      return {
        ...provided,
        background: '#f4f4f6',
        borderColor: state.isFocused ? color : provided.borderColor,
        '&:hover': { 
          borderColor: color,
          boxShadow: state.isFocused ? `0 0 0 1px ${color}` : provided.boxShadow
        },
        boxShadow: state.isFocused ? `0 0 0 1px ${color}` : provided.boxShadow
      };
    },
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontSize: '14px'
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      fontSize: '13px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--books-color)',
        color: '#FFFFFF'
      },
    })
  };
  return (
    <div>
       <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        styles={dropDownStyle}
        menuIsOpen={false}
        onChange={handleChange}
        onBlur={() => {
          handleKeyDown({ key: 'Tab' });
        }}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        placeholder={props.placeHolder}
        value={props.value !== undefined ? props.value.map((item) => createOption(item)) : []}
      />
    </div>
     

  );
}
