import {
  WOOCOMMERCE_SET_CONFIG,
  WOOCOMMERCE_SET_CUSTOMERS,
  WOOCOMMERCE_SET_ORDERS,
  WOOCOMMERCE_SET_PRODUCTS,
  WOOCOMMERCE_SET_ACCOUNTS,
  WOOCOMMERCE_SET_JOB,
  WOOCOMMERCE_SET_PAYMENT_CONFIG,
  WOOCOMMERCE_SET_PRODUCT_ATTRIBUTES,
  WOOCOMMERCE_SET_DESKERA_PRODUCTS,
  WOOCOMMERCE_SET_DESKERA_INVENTORY_TRANSACTION,
  WOOCOMMERCE_SET_TAX_MAPPING
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  products: {
    loading: true,
    data: [],
  },
  customers: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  paymentConfig:{
    loading: true,
    data: {}
  },
  productAttributes:{
    loading: true,
    data: {}
  },
  deskeraProducts: {
    loading: true,
    data: {}
  },
  deskeraInventoryTransaction:{
    loading: true,
    data: {}
  },
  taxMapping:{
    loading: true,
    data: {}
  }
};

function woocommerceReducer(state = initialState, action = {}) {
  switch (action.type) {
    case WOOCOMMERCE_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case WOOCOMMERCE_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case WOOCOMMERCE_SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          loading: false,
          data: action.customers,
        },
      };
    case WOOCOMMERCE_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case WOOCOMMERCE_SET_PRODUCTS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.products,
        },
      };
    case WOOCOMMERCE_SET_JOB: {
      return {
        ...state,
        job: {
          loading: false,
          job: action.job,
        },
      };
    }
    case WOOCOMMERCE_SET_PAYMENT_CONFIG:
      return {
        ...state,
        paymentConfig: {
          loading: false,
          data: action.paymentConfig,
        },
      };
    case WOOCOMMERCE_SET_PRODUCT_ATTRIBUTES:
      return {
        ...state,
        productAttributes: {
          loading: false,
          data: action.productAttributes,
        },
      };
    case WOOCOMMERCE_SET_DESKERA_PRODUCTS:
      return {
        ...state,
        deskeraProducts: {
          loading: false,
          data: action.products,
        },
      };
    case WOOCOMMERCE_SET_DESKERA_INVENTORY_TRANSACTION:
      return {
        ...state,
        deskeraInventoryTransaction: {
          loading: false,
          data: action.deskeraInventoryTransaction,
        },
      };
    case WOOCOMMERCE_SET_TAX_MAPPING:
      return {
        ...state,
        taxMapping: {
          loading: false,
          data: action.taxMapping,
        },
      };
    default:
      return state;
  }
}

export default woocommerceReducer;
