function CartonBoxIcon() {
  return (
    <svg
      enable-background="new 0 0 24 24"
      height="512"
      viewBox="0 0 24 24"
      width="512"
    >
      <path d="m1.3 11.03v11.77c0 .66.54 1.2 1.2 1.2h19c.67 0 1.2-.54 1.2-1.2v-11.77zm13.619 3.767h-5.838c-.537 0-.973-.436-.973-.973s.436-.973.973-.973h5.838c.537 0 .973.436.973.973s-.436.973-.973.973z" />
      <path d="m23.5 9.032h-23c-.276 0-.5-.224-.5-.5v-4.04h24v4.041c0 .276-.224.499-.5.499z" />
      <path d="m20.432 0h-16.864l-3.568 4.492h24z" />
    </svg>
  );
}

export default CartonBoxIcon;
