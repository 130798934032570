import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchLogo from "src/assets/Search";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import { loadOrders, loadConfig, loadJob, loadAccounts } from "src/redux/actions/shopify";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SyncStatus from "src/components/SyncStatus";
import getSymbolFromCurrency from "currency-symbol-map";
import { migrateShopifyData, migrateShopifyDataForOrders } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import { getQueryParams } from "src/utils/Utility";
import PopUp from "src/components/PopUp";

function Orders() {
  const [syncStatus, setSyncStatus] = useState("enabled");
  const orders = useSelector((state) => state.shopify.orders);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.shopify.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const tenantInfo = useSelector((state) => state.auth.user);
  const accounts = useSelector((state) => state.shopify.accounts);

  useEffect(() => {
    const financialDates=getFinancialStartEndDate();
    const {startDate, endDate } = getDateRange(financialDates)
    dispatch(loadConfig());
    if (accounts?.data?.length === 0) {
      dispatch(loadAccounts());
    }
    dispatch(loadJob());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const data = useMemo(() => orders.data, [orders]);

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "cartInvoiceNo",
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        disableSortBy: true,
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "Customer Email",
        accessor: "customerEmail",
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.totalAmount && getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.totalAmount}
          </span>
        ),
        className: "text-right",
      },
      {
        Header: "Deskera Document Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Shopify Store",
        accessor: "shopifyStore",
        Cell: ({ cell: { row } }) => {
          const account =
            row.original.cartAccountId &&
            accounts?.data?.find((item) => item.id === row.original.cartAccountId);
          return account?.name || "";
        },
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartInvoiceId}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          const text = row?.original?.reason;
          const displayText = text?.length > 50 ? text?.slice(0, 50)  : text;

          return (
            <>
              {(
                <PopUp text={text} triggerText={displayText}/>
              )}
            </>
          );
        },
      },
    ],
    [accounts]
  );

  const config = useSelector((state) => state.shopify.config);

  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  function getFinancialStartEndDate() {
    let bookBeginningStartDate = tenantInfo?.bookBeginningStartDate
      ? new Date(tenantInfo?.bookBeginningStartDate)
      : new Date();
    let startDate = new Date().setDate(new Date().getDate() - 7);
    startDate = startDate >= bookBeginningStartDate ? startDate : bookBeginningStartDate;
    const endDate = new Date();
    startDate = new Date(startDate);
    return { startDate, endDate };
  }

  function getDateRange(financialDates) {
    let endDate = "";
    let startDate = "";
    if (!financialDates?.startDate || !financialDates.endDate) {
      return { startDate, endDate };
    }
    var sd = financialDates.startDate;
    var startMonth = sd.getMonth() + 1;
    var ed = financialDates.endDate ? financialDates.endDate : financialDates.startDate;
    var endMonth = ed.getMonth() + 1;
    endDate =
      ed.getUTCFullYear() +
      "-" +
      String(endMonth).padStart(2, "0") +
      "-" +
      String(ed.getDate()).padStart(2, "0");
    startDate =
      sd.getUTCFullYear() +
      "-" +
      String(startMonth).padStart(2, "0") +
      "-" +
      String(sd.getDate()).padStart(2, "0");
    return { startDate, endDate };
  }

  const refreshData = ({ pageSize, pageIndex, status, sortBy, startDate, endDate, search, query }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setStatus(status);
    setSortBy(sortBy);
    setLoading(true);
    setStartDate(startDate);
    setEndDate(endDate);
    
    const queryParams = getQueryParams(query);
    
    dispatch(loadOrders(pageSize, pageIndex, status, sortBy, startDate, endDate, search ?? "", queryParams));
  };

  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateShopifyData();
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.message,
        })
      );
    }
  }

  function jobStatus(job) {
    if (!job.loading && !Object.keys(job.data).length === 0) {
      return true;
    }
    return false;
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Orders</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced orders will appear here. Please complete{" "}
          <Link to="/app/shopify" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ServerPaginationTable
        data={data.content ? data.content : []}
        columns={columns}
        migrate={migrateShopifyDataForOrders}
        cart="shopify"
        refresh={refreshData}
        tab="Orders"
        pageCount={pageCount}
        loading={loading}
        rowId="cartInvoiceId"
      />
    </div>
  );
}

export default Orders;
