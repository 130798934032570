function ArrowRight() {
  return (
    <svg
      width="318"
      height="318"
      viewBox="0 0 318 318"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M226.515 141.805L131.254 46.5438C122.529 37.8187 108.229 37.8187 99.5038 46.5438L91.5538 54.4938C82.8287 63.209 82.8287 77.5091 91.5538 86.2342L164.237 158.937L91.5438 231.63C82.8187 240.355 82.8187 254.655 91.5438 263.37L99.4938 271.32C108.219 280.055 122.519 280.055 131.244 271.32L226.505 176.049C231.196 171.369 233.282 165.088 232.935 158.927C233.292 152.776 231.205 146.495 226.515 141.805Z" />
    </svg>
  );
}

export default ArrowRight;
