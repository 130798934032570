import React, { useEffect, useMemo, useState } from "react";
import SyncStatus from "src/components/SyncStatus";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SearchLogo from "src/assets/Search";
import AsyncSelect from "react-select/async";
import {
  getDeskeraProductBreifSearch,
  getDeskeraProductBrif,
  saveMatchingProducts,
  migrateMatchingProducts,
} from "src/api";
import { useAsyncDebounce } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { DKButton } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import { addToast } from "src/redux/actions/toasts";
import { Utility } from "src/utils/Utility";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";

const PRODUCT_SYNC_STATUS_FILTER = [
  {
    name: "All",
    value: null,
  },
  {
    name: "Synced & Mapped",
    value: "SUCCESSFUL",
  },
  {
    name: "Synced & Pending to Map",
    value: "PENDING",
  },
  {
    name: "Failed to Map",
    value: "FAILED",
  },
  // {
  //   name: 'Mapping not found',
  //   value: 'RESYNC',
  // }
];

function MapShopifyProducts(props) {
  const [toggleStatus, setToggleStatus] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState("");
  const [productData, setProductData] = useState([]);
  const [error, setError] = useState("");
  const [productSyncStatusFilter, setProductSyncStatusFilter] = useState(
    PRODUCT_SYNC_STATUS_FILTER[0]
  );
  const [shopifyMappingField, setShopifyMappingField] = useState("PRODUCT_NAME");
  const [deskeraMappingField, setDeskeraMappingField] = useState("PRODUCT_NAME");
  const [shopifyStore, setShopifyStore] = useState(null);

  const accounts = useSelector((state) => state.shopify.accounts);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: "13rem",
    }),
  };

  const deskeraStatusOptions = [
    {
      value: "PRODUCT_NAME",
      label: "Product Name",
    },
    {
      value: "DOCUMENT_SEQ_CODE",
      label: "Document Sequence Code",
    },
    {
      value: "BARCODE",
      label: "Barcode",
    },
  ];

  const shopifyStatusOption = [
    {
      value: "PRODUCT_NAME",
      label: "Product Name",
    },
    {
      value: "VARIANT_ID",
      label: "Varient Id",
    },
    {
      value: "SKU",
      label: "SKU",
    },
  ];

  useEffect(() => {
    getBooksProducts();
  }, []);

  useEffect(() => {
    if (!isMapProductsDisabled()) {
      refreshData({ pageSize, pageIndex, sortBy });
    }
  }, [input, showAll, productSyncStatusFilter]);

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Variant ID",
        accessor: "productVariantId",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productName}{" "}
            {row.original.productVariantName &&
              row.original.productVariantName !== "Default Title" &&
              "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Mapped Deskera Product",
        accessor: "deskeraDocumentCode",
        width: "350px",
        Cell: ({ cell: { row } }) => {
          return (
            <SelectProduct
              row={row}
              loadDeskeraOptions={loadDeskeraOptions}
              deskeraHandleChange={deskeraHandleChange}
              productData={productData}
              setProductData={setProductData}
            />
          );
        },
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
        disableGlobalFilter: true,
      },
    ],
    [data]
  );

  const refreshData = async ({ pageSize, pageIndex, sortBy }) => {
    if (!loading) {
      setLoading(true);
      let payload = {
        deskeraMappingField: deskeraMappingField,
        shopifyMappingField: shopifyMappingField,
        cartAccountId: shopifyStore?.id || accounts?.data?.id,
        showSucceedProducts: true,
        limit: showAll ? 1000 : pageSize,
        page: showAll ? 0 : pageIndex,
        sortDir: "ASC",
        search: input,
        syncStatus: productSyncStatusFilter.value,
      };
      setPageSize(pageSize);
      setPageIndex(pageIndex);
      setStatus(status);
      setSortBy(sortBy);

      await migrateMatchingProducts(payload)
        .then((response) => {
          if (response?.content?.length) {
            setData([...response.content]);
            setPageCount(response.totalPages);
          } else {
            setData([]);
            setPageCount(0);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const deskeraHandleChange = (e, index) => {
    let newData = Utility.deepClone(data);
    if (Utility.isNotEmpty(newData)) {
      newData[index] = {
        ...newData[index],
        deskeraDocumentCode: e.documentSequenceCode,
        deskeraId: e.pid,
        deskeraProductName: e.name,
        cartAccountId: shopifyStore.id || accounts?.data?.id,
      };
      setData(newData);
    }
  };

  async function getBooksProducts() {
    try {
      const products = await getDeskeraProductBrif();
      products.forEach((product) => {
        productData.push(product);
      });
    } catch {
      setError("Failed to load Deskera Books Products");
    }
  }

  const handleCancel = () => {
    props.setSyncConfigOpen(false);
    props.refreshData();
  };

  const loadDeskeraOptions = async (inputValue) => {
    const config = {
      search: inputValue,
      limit: 25,
    };
    const products = await getDeskeraProductBreifSearch(config);
    return products;
  };

  async function handleSyncSubmitAndClosePopup() {
    await handleSyncSubmit();
    props.setSyncConfigOpen(false);
  }

  async function handleSyncSubmit() {
    try {
      const resp = await saveMatchingProducts(data);

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Product Mapping Saved successfully ",
        })
      );
      return;
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
    }
  }

  const handleSearchChange = useAsyncDebounce((value) => {
    setInput(value);
  }, 500);

  const isMapProductsDisabled = () => {
    if (
      (accounts.data.length > 1 ? !shopifyStore?.id : accounts?.data?.id) ||
      !shopifyMappingField ||
      !deskeraMappingField
    ) {
      return true;
    }

    return false;
  };

  return (
    <div
      className="w-100 h-100 d-flex justify-content-center overflow-auto position-absolute"
      style={{
        padding: "4rem 4rem 1rem",
        zIndex: 99,
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card d-flex border-radius-l"
        style={{ minWidth: "100%", minHeight: "70%" }}
      >
        <div className="card-body d-flex flex-column">
          <h3 className="mt-2 mb-xxl">Map Products</h3>
          <form className="form-inline align-items-end">
            {accounts?.data?.length > 1 && (
              <div className="form-group flex-column align-items-start">
                <span className="font-weight-bold text-muted">Shopify Store</span>
                <Select
                  placeholder="Shopify Store"
                  styles={customStyles}
                  options={accounts?.data || []}
                  menuPlacement="auto"
                  onChange={(e) => {
                    setShopifyStore(e);
                    setData([]);
                    setPageCount(0);
                    setPageIndex(0);
                  }}
                  theme={customSelectTheme}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                  formatOptionLabel={(data) => data.name}
                  isOptionSelected={(data) => data?.id === shopifyStore?.id}
                />
              </div>
            )}
            <div className="form-group flex-column align-items-start ml-m">
              <span className="font-weight-bold text-muted">Shopify Mapping Field</span>
              <Select
                placeholder="Shopify Mapping Field"
                styles={customStyles}
                options={shopifyStatusOption}
                defaultValue={shopifyStatusOption[0]}
                menuPlacement="auto"
                onChange={(e) => setShopifyMappingField(e.value)}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
              />
            </div>
            <div className="form-group flex-column align-items-start ml-m">
              <span className="font-weight-bold text-muted">Deskera Mapping Field</span>
              <Select
                placeholder="Deskera Mapping Field"
                styles={customStyles}
                options={deskeraStatusOptions}
                defaultValue={deskeraStatusOptions[0]}
                onChange={(e) => setDeskeraMappingField(e.value)}
                theme={customSelectTheme}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isSearchable={false}
              />
            </div>
            <div className="form-group flex-column align-items-start flex-grow-1">
              <DKButton
                disabled={isMapProductsDisabled()}
                className={
                  "text-white ml-m " + (isMapProductsDisabled() ? "bg-secondary" : "bg-primary")
                }
                onClick={() => refreshData({ pageSize, pageIndex, sortBy })}
                title={"MAP PRODUCTS"}
                style={{ height: 38 }}
              />
            </div>
            <div className="form-group flex-column align-items-start ml-m">
              <Select
                placeholder={"Select Status"}
                styles={customStyles}
                options={PRODUCT_SYNC_STATUS_FILTER}
                value={productSyncStatusFilter}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.value}
                onChange={(option) => setProductSyncStatusFilter(option)}
              />
            </div>
            <div className="form-group flex-column align-items-start ml-m">
              <div className="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text bg-light">
                    <span className="svg-icon svg-disabled">
                      <SearchLogo />
                    </span>
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => handleSearchChange(e.target.value)}
                  style={{ height: 38 }}
                />
              </div>
            </div>
          </form>

          <ServerPaginationTable
            data={data}
            minHeight={"auto"}
            columns={columns}
            migrate={""}
            cart="shopify"
            refresh={refreshData}
            tab="MatchingSummaryTable"
            pageCount={pageCount}
            loading={loading}
            rowId="id"
          />
          <div className="d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmitAndClosePopup}
              title={"SAVE AND CLOSE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmit}
              title={"SAVE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
              onClick={handleCancel}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function SelectProduct({ row, loadDeskeraOptions, deskeraHandleChange, productData }) {
  const [product] = useState(
    row.original.deskeraId
      ? { pid: row.original.deskeraId, name: row.original.deskeraProductName }
      : null
  );

  const loadProductsOptions = Utility.debounce((inputValue, callback) => {
    loadDeskeraOptions(inputValue).then((response) => {
      callback(response);
    });
  }, 250);

  return (
    <AsyncSelect
      placeholder={"Select Product"}
      className="filter-selector ml-3"
      cacheOptions
      defaultOptions={productData}
      value={product}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.pid}
      loadOptions={loadProductsOptions}
      onChange={(option) => {
        deskeraHandleChange(option, row.index);
      }}
    />
  );
}

export default MapShopifyProducts;
