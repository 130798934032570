import { useEffect, useState } from "react";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import SyncDisableIcon from "src/assets/SyncDisable";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "src/assets/Close";
import CheckIcon from "src/assets/Check";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import {
  getAmazonRedirectUrl,
  getDeskeraRedirectUrl,
  setDisconnectedDeskeraAccount,
  getDeskeraUserInfo,
  setDisconnectedAmazonAccount,
  getDeskeraBooksAccounts,
  setAmazonConfig,
  getDeskeraProductUom,
} from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import { setDeskeraInfo } from "src/redux/actions/auth";
import { loadConfig, setConfig, loadAccounts } from "src/redux/actions/amazon";
import SyncIcon from "src/assets/Sync";
import { addToast } from "src/redux/actions/toasts";
import { DKButton } from "deskera-ui-library";

function Config() {
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const accounts = useSelector((state) => state.amazon.accounts);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const amazonConfig = useSelector((state) => state.amazon.config);
  const [formErrors, setFormErrors] = useState({});
  const [isAmazonConfigDone, setIsAmazonConfigDone] = useState(false);
  const [isSaveAccountsLoading, setIsSaveAccountsLoading] = useState(false);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const [dataSyncSaveLoading, setDataSyncSaveLoading] = useState(false);
  const [booksDocumentType, setBooksDocumentType] = useState("SALES_INVOICE");
  const userInfo = useSelector((state) => state.auth.user);
  const useDocumentCodeFromAmazonOptions = [
    { value: true, label: "YES" },
    { value: false, label: "NO" },
  ];
  const useBooksTaxRateOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const useProductActionAtDeskeraOptions = [
    { value: "CREATE_NEW", label: "Create New Product" },
    { value: "MAP_MANUALLY", label: "I Will Map Manually" },
  ];
  const documentTypes = [
    {
      value: "SALES_ORDER",
      label: "Sales Order",
    },
    {
      value: "SALES_INVOICE",
      label: "Sales Invoice",
    },
  ];

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      accounts.forEach((account) => {
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadConfig());
    getBooksAccounts();
    getBooksProductUom();
  }, []);

  async function handleAddAmazonAccount(e) {
    e.preventDefault();
    try {
      const amazonConnectUrl = await getAmazonRedirectUrl();
      window.open(amazonConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch redirect url");
    }
  }

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      const disconnectResp = await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectAmazonAccount(e, accountId) {
    e.preventDefault();
    try {
      const disconnectResp = await setDisconnectedAmazonAccount(accountId);
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Amazon account");
    }
  }

  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    const newConfig = { ...amazonConfig.data, syncEnabled: syncStatus };
    try {
      const configResp = await setAmazonConfig(newConfig);
      dispatch(setConfig(configResp));
      setSyncButton(true);
      setSyncWarning(false);
    } catch {
      setError("Failed to set config");
    }
  }

  async function saveAccountConfigs(e) {
    e.preventDefault();
    if (!amazonConfig.data.purchaseAccountCode) {
      setFormErrors({ ...formErrors, purchaseAccountCode: "Select a valid account" });
      return;
    }

    if (!amazonConfig.data.salesReturnAccountCode) {
      setFormErrors({ ...formErrors, salesReturnAccountCode: "Select a valid account" });
      return;
    }

    if (!amazonConfig.data.salesAccountCode) {
      setFormErrors({ ...formErrors, salesAccountCode: "Select a valid account" });
      return;
    }

    if (!amazonConfig.data.purchaseReturnAccountCode) {
      setFormErrors({ ...formErrors, purchaseReturnAccountCode: "Select a valid account" });
      return;
    }

    const newConfig = { ...amazonConfig.data };
    setIsSaveAccountsLoading(true);
    try {
      const configResp = await setAmazonConfig(newConfig);
      dispatch(setConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Accounts config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    } finally {
      setIsSaveAccountsLoading(false);
    }
  }

  async function saveDataSyncSettings() {
    const newConfig = { ...amazonConfig.data, booksDocumentTypeForAmazonOrders: booksDocumentType };
    setDataSyncSaveLoading(true);
    try {
      const configResp = await setAmazonConfig(newConfig);
      dispatch(setConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Data sync settings updated successfully.",
        })
      );
    } catch (error) {
      console.error("Failed to update data sync settings: ", error);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Accounts config updated successfully.",
        })
      );
    } finally {
      setDataSyncSaveLoading(false);
    }
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account with ECom app.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    handleAccountsConfigured();
  }, [amazonConfig]);

  function handleAccountsConfigured() {
    setIsAmazonConfigDone(true);
    if (!amazonConfig.data.purchaseAccountCode) {
      setIsAmazonConfigDone(false);
      return;
    }

    if (!amazonConfig.data.salesAccountCode) {
      setIsAmazonConfigDone(false);
      return;
    }

    if (!amazonConfig.data.purchaseReturnAccountCode) {
      setIsAmazonConfigDone(false);
      return;
    }

    if (!amazonConfig.data.salesReturnAccountCode) {
      setIsAmazonConfigDone(false);
      return;
    }

    if (amazonConfig.data.booksDocumentTypeForAmazonOrders) {
      setBooksDocumentType(amazonConfig.data.booksDocumentTypeForAmazonOrders);
    }
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected.</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title="Disconnect"
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is Enabled for your account. Orders, Products and Customers from your Amazon account
          will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            // <DKButton
            //   className="bg-danger px-3 text-white"
            //   onClick={(e) => setSyncWarning(true)}
            //   title={"Disable Sync"}
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => setSyncWarning(true)}
              // style={{ marginRight: "20px" }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is Disabled for your account. Orders, Products and Customers from your Amazon account
          are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={(e) => handleUpdateSync(e, true)}
            // style={{ marginRight: "20px" }}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  function dataSyncSettingsModal() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <div className="form-group">
            <div className="text-muted mb-2" aria="label">
              <b>Books Document Type For Amazon Orders</b>
            </div>

            <Select
              placeholder="Select an Account"
              theme={customSelectTheme}
              required={true}
              options={
                userInfo?.additionalSettings?.ENABLE_SO ? documentTypes : documentTypes.slice(1)
              }
              isSearchable={true}
              menuPlacement="auto"
              onChange={(e) => setBooksDocumentType(e.value)}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
              value={documentTypes.find(({ value }) => value === booksDocumentType)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              onClick={saveDataSyncSettings}
              className="btn border-radius-m p-v-s text-white bg-success"
              disabled={dataSyncSaveLoading}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                {dataSyncSaveLoading ? (
                  <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                ) : (
                  <FloppyDiskIcon />
                )}
              </span>
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 w-auto">
      <h3 className="mb-4">Account Setup</h3>
      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Amazon Stores</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <p className="text-muted">Link your Amazon stores with the ECom app.</p>
              <div className="d-flex flex-row-reverse mb-2">
                {/* <DKButton
                  className="bg-success px-3 text-white"
                  onClick={}
                  title={"Add Store"}
                  style={{ marginRight: "15px" }}
                ></DKButton> */}
                <button
                  className="btn border-radius-m p-v-s text-white bg-success"
                  onClick={handleAddAmazonAccount}
                  // style={{ marginRight: "20px" }}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    <PlusLogo />
                  </span>
                  <span>Add Store</span>
                </button>
              </div>
              {accounts.data.length > 0 && (
                <table className="table m-0 table-no-padding">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.data.map((account, index) => (
                      <tr>
                        <td className="align-middle" style={{ width: "10%" }}>
                          <p className="m-0">
                            <b>{account.name}</b>
                          </p>
                          <span class="d-inline-block text-truncate">
                            <small>{account.id}</small>
                          </span>
                        </td>
                        <td className="align-middle text-right">
                          <button
                            // style={{ marginRight: "20px" }}
                            onClick={(e) => handleDisconnectAmazonAccount(e, account.id)}
                            className="btn border-radius-m p-v-s text-white bg-danger"
                          >
                            <span className="svg-icon svg-baseline mr-2 svg-white">
                              <DeleteLogo />
                            </span>
                            <span>Disconnect</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </>
      )}
      {accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {amazonConfig.data.syncEnabled ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}
      {accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Books Accounts</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!isAmazonConfigDone && (
                <Alert type="primary">
                  Accounts must be configured before Amazon products can be synced with Deskera
                  Books.
                </Alert>
              )}
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Bank Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...amazonConfig.data, bankAccountCode: e.value }))
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === amazonConfig.data.bankAccountCode
                  )}
                />
                {formErrors.bankAccountCode && (
                  <div className="text-muted">
                    <small>{formErrors.bankAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Purchase Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...amazonConfig.data, purchaseAccountCode: e.value }))
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === amazonConfig.data.purchaseAccountCode
                  )}
                />
                {formErrors.purchaseAccountCode && (
                  <div className="text-muted">
                    <small>{formErrors.purchaseAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Sales Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...amazonConfig.data, salesAccountCode: e.value }))
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === amazonConfig.data.salesAccountCode
                  )}
                />
                {formErrors.salesAccountCode && (
                  <div className="text-muted">
                    <small>{formErrors.salesAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Purchase Return Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({ ...amazonConfig.data, purchaseReturnAccountCode: e.value })
                    )
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === amazonConfig.data.purchaseReturnAccountCode
                  )}
                />
                {formErrors.purchaseReturnAccountCode && (
                  <div className="text-muted">
                    <small>{formErrors.purchaseReturnAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Sales Return Account</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraAccounts}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...amazonConfig.data, salesReturnAccountCode: e.value }))
                  }
                  value={deskeraAccounts.filter(
                    (obj) => obj.value === amazonConfig.data.salesReturnAccountCode
                  )}
                />
                {formErrors.salesReturnAccountCode && (
                  <div className="text-muted">
                    <small>{formErrors.salesReturnAccountCode}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Unit of Measure</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={deskeraProductUom}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...amazonConfig.data, productUom: e.value }))
                  }
                  value={deskeraProductUom.filter(
                    (obj) => obj.value === amazonConfig.data.productUom
                  )}
                />
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Use Order Id From Amazon As Invoice Document Code</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useDocumentCodeFromAmazonOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({ ...amazonConfig.data, useAmazonIdAsDocumentCodeOrders: e.value })
                    )
                  }
                  value={useDocumentCodeFromAmazonOptions.filter(
                    (obj) => obj.value === amazonConfig.data.useAmazonIdAsDocumentCodeOrders
                  )}
                />
                {formErrors.useAmazonIdAsDocumentCodeOrders && (
                  <div className="text-muted">
                    <small>{formErrors.useAmazonIdAsDocumentCodeOrders}</small>
                  </div>
                )}
              </div>

              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Use Seller SKU From Amazon As Product Document Code</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useDocumentCodeFromAmazonOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(
                      setConfig({
                        ...amazonConfig.data,
                        useAmazonIdAsDocumentCodeProducts: e.value,
                      })
                    )
                  }
                  value={useDocumentCodeFromAmazonOptions.filter(
                    (obj) => obj.value === amazonConfig.data.useAmazonIdAsDocumentCodeProducts
                  )}
                />
                {formErrors.useAmazonIdAsDocumentCodeProducts && (
                  <div className="text-muted">
                    <small>{formErrors.useAmazonIdAsDocumentCodeProducts}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Ignore Amazon taxes and use Tax Rates from Books</b>
                </div>
                <Select
                  placeholder="Select an Option"
                  theme={customSelectTheme}
                  options={useBooksTaxRateOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...amazonConfig.data, useBooksTaxRates: e.value }))
                  }
                  value={useBooksTaxRateOptions.filter(
                    (obj) => obj.value === amazonConfig.data.useBooksTaxRates
                  )}
                />
                {formErrors.useBooksTaxRates && (
                  <div className="text-muted">
                    <small>{formErrors.useBooksTaxRates}</small>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>Product Action At Deskera</b>
                </div>
                <Select
                  placeholder="Select Action"
                  theme={customSelectTheme}
                  options={useProductActionAtDeskeraOptions}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) => {
                    console.log(e);
                    return dispatch(
                      setConfig({
                        ...amazonConfig.data,
                        productAction: e.value,
                      })
                    );
                  }}
                  value={useProductActionAtDeskeraOptions.filter(
                    (obj) => obj.value === amazonConfig.data.productAction
                  )}
                />
                {formErrors.useBooksTaxRates && (
                  <div className="text-muted">
                    <small>{formErrors.useBooksTaxRates}</small>
                  </div>
                )}
              </div>
              <div className="d-flex flex-row-reverse">
                {/* <DKButton
                  className="bg-success px-3 text-white"
                  ></DKButton> */}
                <button
                  onClick={saveAccountConfigs}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={isSaveAccountsLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {isSaveAccountsLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync Settings</h5>
          </div>
          {dataSyncSettingsModal()}
        </>
      )}
    </div>
  );
}

export default Config;
