import React, { useState, useEffect, useRef } from "react";
import "./popup.css";
import { DKButton, DKIcons } from "deskera-ui-library";

const PopUp = ({ text, triggerText }) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  return (
    <div style={{ position: "relative" }} ref={popupRef}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <span style={{ flex: 1 }}>{triggerText}</span>
        {text?.length > 50 && (
          <DKButton
            icon={DKIcons.ic_more}
            isReverse
            onClick={togglePopup}
            className="bg-gray2 border-m cursor-hand"
            style={{ marginLeft: "8px" }}
          />
        )}
      </div>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">{text}</div>
        </div>
      )}
    </div>
  );
};

export default PopUp;
