import React, { useEffect, useState } from "react";
import { queryTemplate } from "src/utils/Constants";
import { isEmpty } from "src/utils/Utility";

export function SearchFilter(props) {
  const [query, setQuery] = useState({});
  useEffect(() => {
    if (!isEmpty(query)) {
      if (props?.onFilter) {
        props?.onFilter(getFilterQuery());
      }
      if (props?.onFilterMap) {
        props?.onFilterMap(query);
      }
    }
  }, [query]);

  const onPatchQuery = (key, value = "") => {
    if (!isEmpty(key)) {
      setQuery({ ...query, [key]: value });
    }
  };
  const getFilterQuery = () => {
    let queryStr = "";
    Object.keys(query)
      .filter((key) => !isEmpty(key))
      .forEach((key) => {
        const value = isEmpty(query[key]) ? "" : query[key];
        const condition = queryTemplate.replace("{key}", key).replace("{value}", value);
        if (isEmpty(queryStr)) {
          queryStr += condition;
        } else {
          queryStr += (props?.chainingCondition || "&") + condition;
        }
      });
    return queryStr;
  };
  return (
    <div style={{ ...props?.style }} className={props?.className || "form-inline"}>
      {React.Children.map(props?.children, (child) =>
        React.cloneElement(child ?? <></>, { patchQuery: onPatchQuery })
      )}
    </div>
  );
}

export default SearchFilter;
