import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { useState } from "react";
import Purchases from "./Purchases";
import Orders from "./Orders";
import Inventory from "./Inventory";
import Config from "./Config";
import CartonBoxIcon from "src/assets/CartonBox";
import CreditCardIcon from "src/assets/CreditCard";
import UserIcon from "src/assets/User";
import WrenchIcon from "src/assets/Wrench";

const menuItems = [
  {
    name: "Setup",
    href: "/app/foodics",
    icon: <WrenchIcon />,
  },
  {
    name: "Orders",
    href: "/app/foodics/orders",
    icon: <CreditCardIcon />,
  },
  {
    name: "Purchases",
    href: "/app/foodics/purchases",
    icon: <UserIcon />,
  },
  {
    name: "Inventory",
    href: "/app/foodics/inventory",
    icon: <CartonBoxIcon />,
  },
];

function Foodics() {
  const [activeVisible, setActiveVisible] = useState(false);
  const [activeTop, setActiveTop] = useState(0);

  return (
    <>
      <div
        className="bg-white py-3 dk-shadow-sm"
        style={{
          minWidth: 240,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          zIndex: 9,
          position: "relative",
        }}
      >
        {activeVisible && (
          <div
            className="ease-in-out-back"
            style={{
              width: 8,
              height: "3.25rem",
              position: "absolute",
              right: 0,
              top: activeTop,
              backgroundColor: "#800020",
            }}
          ></div>
        )}

        {menuItems.map((item, index) => (
          
          <NavLink
            exact to={item.href}
            className="text-decoration-none px-4 py-3 d-block dk-navlink dk-navlink-hover mb-2 ease-in"
            isActive={(match, location) => {
              if (!match) return false;
              setActiveVisible(true);
              setActiveTop(1 + (3.375 + 0.5) * index + "rem");
              return true;
            }}
            style={{
              fontWeight: 500,
            }}
          >
            <span className="svg-icon svg-icon-lg svg-baseline mr-3">{item.icon}</span>
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="w-100 h-100 overflow-auto" style={{ background: "#f6f6f6" }}>
        <Switch>
          <Route exact path="/app/foodics">
            {/* <Config /> */}
            <Redirect to="/app"></Redirect>
          </Route>
          {/* <Route path="/app/foodics/orders">
            <Orders />
          </Route>
          <Route path="/app/foodics/purchases">
            <Purchases />
          </Route>
          <Route path="/app/foodics/inventory">
            <Inventory />
          </Route> */}
        </Switch>
      </div>
    </>
  );
}

export default Foodics;
