import { Link } from '@material-ui/core';
import getSymbolFromCurrency from 'currency-symbol-map';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { migrateBrightPearlProductsData } from 'src/api';
import Alert from 'src/components/Alert';
import ServerPaginationTable from 'src/components/ServerPaginationTable';
import SyncStatus from 'src/components/SyncStatus';
import { loadConfig, loadJob, loadProducts } from 'src/redux/actions/brightpearl';

const Product = () => {
    const products = useSelector((state) => state.brightpearl.products);
    const accounts = useSelector((state) => state.brightpearl.accounts);
    const dispatch = useDispatch();
    const job = useSelector((state) => state.brightpearl.job);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [status, setStatus] = useState([]);
    const [sortBy, setSortBy] = useState();
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      dispatch(loadProducts());
      dispatch(loadConfig());
    }, []);
  
    useEffect(() => {
      dispatch(loadJob());
    }, job);
  
    const data = useMemo(() => products.data, [products]);
    useEffect(() => {
      setLoading(false);
      if (data && data.totalPages) {
        setPageCount(data.totalPages);
      }
    }, [data]);
  
    const columns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "productName",
          Cell: ({ cell: { row } }) => (
            <span>
              {row.original.productName}{" "}
              {row.original.productVariantName &&
                row.original.productVariantName !== "Default Title" &&
                "(" + row.original.productVariantName + ")"}
            </span>
          ),
        },
        {
          Header: "SKU",
          accessor: "sku",
        },
        // {
        //   Header: "Unit Price",
        //   accessor: "productUnitPrice",
        //   Cell: ({ cell: { row } }) => (
        //     <span>
        //       {row.original.productUnitPrice && getSymbolFromCurrency(row.original.currency)}{" "}
        //       {row.original.productUnitPrice}
        //     </span>
        //   ),
        //   className: "text-right",
        // },
        {
          Header: "Deskera Product Code",
          accessor: "deskeraDocumentCode",
        },
        {
          Header: "Sync Status",
          accessor: "syncStatus",
          Cell: ({ cell: { row } }) => {
            return (
              <SyncStatus
                status={row.original.syncStatus}
                reason={row.original.reason}
                id={row.original.cartProductId}
              />
            );
          },
          disableGlobalFilter: true,
        },
        {
          Header: "Sync Remarks",
          accessor: "reason",
          disableSortBy: true,
          Cell: ({ cell: { row } }) => {
            return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.reason}</>;
          }
        }
      ],
      []
    );
  
    const config = useSelector((state) => state.brightpearl.config);
  
    const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
      setPageSize(pageSize);
      setPageIndex(pageIndex);
      setStatus(status);
      setSortBy(sortBy);
      setLoading(true);
      dispatch(loadProducts(pageSize, pageIndex, status, sortBy, search ?? ""));
    };
  
    return (
      <div className="p-4">
        <h3 className="mb-3">Products</h3>
        {(!config.data || !config.data.syncEnabled) && (
          <Alert type="warning">
            Your synced products will appear here. Please complete{" "}
            <Link to="/app/brightpearl" className="text-alert-warning">
              <u>Setup</u>
            </Link>{" "}
            before continuing.
          </Alert>
        )}
        <ServerPaginationTable
          data={data.content ? data.content : []}
          columns={columns}
          migrate={migrateBrightPearlProductsData}
          cart="brightpearl"
          refresh={refreshData}
          tab="Products"
          pageCount={pageCount}
          loading={loading}
          rowId="cartProductId"
        />
      </div>
    );
}

export default Product
