import {
  getShipStationAccounts,
  getShipStationConfig,
  getShipStationConnectedStores,
  getShipStationOrders,
  getShipStationInvoices,
  getShipStationJobStatus,
} from "src/api";
import {
  SHIP_STATION_FAILED_CONFIG_LOAD,
  SHIP_STATION_FAILED_STORE_LOAD,
  SHIP_STATION_SET_CONFIG,
  SHIP_STATION_SET_STORES,
  SHIP_STATION_FAILED_ACCOUNT_LOAD,
  SHIP_STATION_SET_ACCOUNTS,
  SHIP_STATION_FAILED_ORDERS_LOAD,
  SHIP_STATION_SET_ORDERS,
  SHIP_STATION_SET_JOB,
  SHIP_STATION_FAILED_JOB_LOAD,
  SHIP_STATION_SET_INVOICES,
  SHIP_STATION_FAILED_INVOICES_LOAD
} from "../types";

export function loadConfig() {
  return async function (dispatch, getState) {
    try {
      const config = await getShipStationConfig();
      dispatch(setConfig(config));
    } catch (err) {
      dispatch(failedConfigLoad());
    }
  };
}

export function loadConnectedStores() {
  return async function (dispatch, getState) {
    try {
      const stores = await getShipStationConnectedStores();
      dispatch(setStores(stores));
    } catch (err) {
      dispatch(failedStoresLoad());
    }
  };
}

export function setConfig(config) {
  return {
    type: SHIP_STATION_SET_CONFIG,
    config: config,
  };
}

export function setStores(stores) {
  return {
    type: SHIP_STATION_SET_STORES,
    stores: stores,
  };
}

export function failedConfigLoad() {
  return {
    type: SHIP_STATION_FAILED_CONFIG_LOAD,
  };
}

export function failedStoresLoad() {
  return {
    type: SHIP_STATION_FAILED_STORE_LOAD,
  };
}

export function loadAccounts() {
  return async function (dispatch, getState) {
    try {
      const accounts = await getShipStationAccounts();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(failedAccountLoad());
    }
  };
}

export function setAccounts(accounts) {
  return {
    type: SHIP_STATION_SET_ACCOUNTS,
    accounts: accounts,
  };
}

export function failedAccountLoad() {
  return {
    type: SHIP_STATION_FAILED_ACCOUNT_LOAD,
  };
}

export function loadOrders(limit, page, status, sortBy, startDate = "", endDate = "", search) {
  return async function (dispatch, getState) {
    const ordersLoading = getState().shipStation.orders.loading;

    try {
      const orders = await getShipStationOrders(
        limit,
        page,
        status,
        sortBy,
        startDate,
        endDate,
        search ?? ""
      );
      dispatch(setOrders(orders));
    } catch (err) {
      dispatch(failedOrdersLoad());
    }
  };
}

export function setOrders(orders) {
  return {
    type: SHIP_STATION_SET_ORDERS,
    orders: orders,
  };
}

export function failedOrdersLoad() {
  return {
    type: SHIP_STATION_FAILED_ORDERS_LOAD,
  };
}

export function setJob(job) {
  return {
    type: SHIP_STATION_SET_JOB,
    job: job,
  };
}

export function loadJob() {
  return async function (dispatch) {
    try {
      const job = await getShipStationJobStatus();
      dispatch(setJob(job));
    } catch (err) {
      dispatch(failedJobLoad());
    }
  };
}

export function failedJobLoad() {
  return {
    type: SHIP_STATION_FAILED_JOB_LOAD,
  };
}

export function loadInvoices(limit, page, status, sortBy, startDate = '', endDate = '') {
  return async function (dispatch, getState) {
    const invoicesLoading = getState().shipStation.invoices.loading;

    try {
      const orders = await getShipStationInvoices(limit, page, status, sortBy, startDate, endDate);
      dispatch(setInvoices(orders));
    } catch (err) {
      dispatch(failedInvoicesLoad());
    }
  };
}

export function setInvoices(invoices) {
  return {
    type: SHIP_STATION_SET_INVOICES,
    invoices: invoices,
  };
}

export function failedInvoicesLoad() {
  return {
    type: SHIP_STATION_FAILED_INVOICES_LOAD,
  };
}