import getSymbolFromCurrency from "currency-symbol-map";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { migrateBigcommerceData, migrateBigcommerceDataForOrders } from "src/api";
import Alert from "src/components/Alert";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SyncStatus from "src/components/SyncStatus";
import { loadConfig, loadJob, loadOrders } from "src/redux/actions/bigcommerce";
import { addToast } from "src/redux/actions/toasts";

export default function Orders() {
  const orders = useSelector((state) => state.bigcommerce.orders);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.bigcommerce.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate ,setEndDate] = useState();
  const config = useSelector((state) => state.bigcommerce.config);
  const userInfo = useSelector((state) => state.auth.user);
  useEffect(() => {
   const tempStartDate =  config?.data.orderSyncStartDate === undefined ||
   config?.data.orderSyncStartDate === null
     ? userInfo.bookBeginningStartDate
     : config.data.orderSyncStartDate.substring(0, 10)
    const tempEndDate = new Date().toISOString().substring(0,10);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    dispatch(loadOrders( 10 , 0 , '',  [{ id: "orderDate", desc: "DESC" }]));
    dispatch(loadConfig());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const data = useMemo(() => orders.data, [orders]);

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "cartInvoiceId",
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        disableSortBy: true,
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "Customer Email",
        accessor: "customerEmail",
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.totalAmount && getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.totalAmount}
          </span>
        ),
        className: "text-right",
      },
      {
        Header: "Deskera Invoice Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartInvoiceId}
            />
          );
        },
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.reason}</>;
        },
      },
    ],
    []
  );


  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setStatus(status);
    setSortBy(sortBy);
    setLoading(true);
    dispatch(
      loadOrders(pageSize, pageIndex, status, [{ id: "orderDate", desc: "DESC" }], search ?? "")
    );
  };

  async function handleSyncDataSubmit() {
    try {
      // const resp = await migrateWoocommerceData();
      const resp = await migrateBigcommerceData();
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.message,
        })
      );
    }
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Orders</h3>
      {(!config?.data || !config?.data?.syncEnabled) && (
        <Alert type="warning">
          Your synced orders will appear here. Please complete{" "}
          <Link to="/app/bigcommerce" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ServerPaginationTable
        data={data?.content ? data?.content : []}
        columns={columns}
        migrate={migrateBigcommerceDataForOrders}
        cart="bigcommerce"
        refresh={refreshData}
        tab="Orders"
        pageCount={pageCount}
        loading={loading}
        rowId="id"
      />
    </div>
  );
}
