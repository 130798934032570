import {
  MAGENTO_SET_CONFIG,
  MAGENTO_SET_CUSTOMERS,
  MAGENTO_SET_ORDERS,
  MAGENTO_SET_PRODUCTS,
  MAGENTO_SET_ACCOUNTS,
  MAGENTO_SET_JOB,
  MAGENTO_SET_LOCATIONS,
  MAGENTO_SET_DESKERA_PRODUCTS,
  MAGENTO_SET_INVENTORY_TRANSACTIONS,
  MAGENTO_SET_PAYMENT_CONFIG,
  MAGENTO_MATCHING_PRODUCT,
  MAGENTO_CUSTOM_FIELD,
  MAGENTO_CUSTOM_FIELD_MAPPING,
  DESKERA_CUSTOM_FIELD,
  MAGENTO_DISCOUNT_CODE,
  MAGENTO_CUSTOMER_FILTER
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  products: {
    loading: true,
    data: [],
  },
  deskeraproducts: {
    loading: true,
    data: [],
  },
  customers: {
    loading: true,
    data: [],
  },
  inventoryTransactions: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  locations: {
    loading: true,
    data: {},
  },
  paymentConfig: {
    loading: true,
    data: {},
  },
  matchingProduct:{
    loading: true,
    data: {}
  }
};

export default function magentoReducer(state = initialState, action = {}) {
  switch (action.type) {
    case MAGENTO_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case MAGENTO_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case MAGENTO_SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          loading: false,
          data: action.customers,
        },
      };
    case MAGENTO_SET_INVENTORY_TRANSACTIONS:
      return {
        ...state,
        inventoryTransactions: {
          loading: false,
          data: action.inventoryTransactions,
        },
      };
    case MAGENTO_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case MAGENTO_SET_PRODUCTS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.products,
        },
      };
    case MAGENTO_SET_DESKERA_PRODUCTS:
      return {
        ...state,
        deskeraproducts: {
          loading: false,
          data: action.products,
        },
      };
    case MAGENTO_SET_JOB:
      return {
        ...state,
        job: {
          loading: false,
          data: action.job,
        },
      };
    case MAGENTO_SET_LOCATIONS:
      return {
        ...state,
        locations: {
          loading: false,
          data: action.locations,
        },
      };
    case MAGENTO_SET_PAYMENT_CONFIG:
      return {
        ...state,
        paymentConfig: {
          loading: false,
          data: action.paymentConfig,
        },
      };
      case MAGENTO_MATCHING_PRODUCT:
        return {
          ...state,
          matchingProduct: {
            loading: false,
            data: action.matchingProduct,
          },
        };
      case MAGENTO_CUSTOM_FIELD:
      return {
        ...state,
        matchingCustomField: {
          loading: false,
          data: action.matchingCustomField,
        },
      };
      case MAGENTO_CUSTOM_FIELD_MAPPING:
        return {
          ...state,
          matchingCustomFieldMapping: {
            loading: false,
            data: action.matchingCustomFieldMapping,
          },
        };
        case DESKERA_CUSTOM_FIELD:
        return {
          ...state,
          deskeraCustomFields: {
            loading: false,
            data: action.deskeraCustomFields,
          },
        };
        case MAGENTO_DISCOUNT_CODE:
        return {
          ...state,
          magentoDiscountCodes: {
            loading: false,
            data: action.magentoDiscountCodes,
          },
        };
        case MAGENTO_CUSTOMER_FILTER:
        return {
          ...state,
          magentoCusomterFilter: {
            loading: false,
            data: action.magentoCusomterFilter,
          },
        };
    default:
      return state;
  }
}
