import { Redirect, Route, Switch } from "react-router";
import EComLogo from "src/assets/EComLogo";
import AmazonOAuth from "./Amazon";
import DeskeraOAuth from "./Deskera";
import FoodicsOAuth from "./Foodics";

function OAuth2() {
  return (
    <div
      className="d-flex flex-column overflow-hidden align-items-center justify-content-center vw-100 vh-100"
      style={{ backgroundColor: "#f6f6f6" }}
    >
      <div className="card dk-card dk-shadow-sm" style={{ width: 400 }}>
        <div className="card-header dk-card-header d-flex align-items-center justify-content-center">
          <h2 className="m-0 mr-2 svg-icon">
            <EComLogo />
          </h2>
          <span className="text-wine" style={{ fontSize: 20, fontWeight: 500 }}>
            Deskera ECom
          </span>
        </div>
        <div className="card-body text-center">
          <Switch>
            <Route exact path="/oauth">
              <Redirect to="/404" />
            </Route>
            <Route path="/oauth/amazon">
              <AmazonOAuth />
            </Route>
            <Route path="/oauth/deskera">
              <DeskeraOAuth />
            </Route>
            <Route path="/oauth/foodics">
              <FoodicsOAuth />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default OAuth2;
