import ReactTooltip from "react-tooltip";

function ActiveInactive({ status }) {
  switch (status) {
    case "INACTIVE":
      return <span className="badge badge-pill badge-warning">Inactive</span>;
    case "ACTIVE":
      return <span className="badge badge-pill badge-success">Active</span>;
    case "DELETED":
      return <span className="badge badge-pill badge-danger">Deleted</span>;      
  }
}

export default ActiveInactive;