import {
    FOODICS_SET_CONFIG,
    FOODICS_SET_PURCHASES,
    FOODICS_SET_ORDERS,
    FOODICS_SET_INVENTORY,
    FOODICS_SET_ACCOUNTS,
    FOODICS_SET_JOB,
    FOODICS_SET_PRODUCT_CONFIG
  } from "../types";
  
  const initialState = {
    config: {
      loading: true,
      data: {},
    },
    orders: {
      loading: true,
      data: [],
    },
    accounts: {
      loading: true,
      data: [],
    },
    inventory: {
      loading: true,
      data: [],
    },
    purchases: {
      loading: true,
      data: [],
    },
    job: {
      loading: true,
      data: {},
    },
    productConfig: {
      loading: true,
      data: {}
    }
  };
  
  function FoodicsReducer(state = initialState, action = {}) {
    switch (action.type) {
      case FOODICS_SET_CONFIG:
        return {
          ...state,
          config: {
            loading: false,
            data: action.config,
          },
        };
      case FOODICS_SET_ACCOUNTS:
        return {
          ...state,
          accounts: {
            loading: false,
            data: action.accounts,
          },
        };
      case FOODICS_SET_PURCHASES:
        return {
          ...state,
          purchases: {
            loading: false,
            data: action.purchases,
          },
        };
      case FOODICS_SET_ORDERS:
        return {
          ...state,
          orders: {
            loading: false,
            data: action.orders,
          },
        };
      case FOODICS_SET_INVENTORY:
        return {
          ...state,
          inventory: {
            loading: false,
            data: action.inventory,
          },
        };
      case FOODICS_SET_JOB:
        return {
          ...state,
          job: {
            loading: false,
            data: action.job,
          },
        };
        case FOODICS_SET_PRODUCT_CONFIG:
        return {
          ...state,
          productConfig: {
            loading: false,
            data: action.productConfig,
          },
        };
      default:
        return state;
    }
  }
  
  export default FoodicsReducer;
  
