import getSymbolFromCurrency from "currency-symbol-map";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { migrateBigcommerceData, migrateBigcommerceDataForProducts } from "src/api";
import Alert from "src/components/Alert";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SyncStatus from "src/components/SyncStatus";
import { loadAccounts, loadConfig, loadJob, loadProducts } from "src/redux/actions/bigcommerce";
import { addToast } from "src/redux/actions/toasts";

function Products() {
  const products = useSelector((state) => state.bigcommerce.products);
  const accounts = useSelector((state) => state.bigcommerce.accounts);
  const config = useSelector((state) => state.bigcommerce.config);

  const dispatch = useDispatch();
  const job = useSelector((state) => state.bigcommerce.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadProducts());
    dispatch(loadConfig());
    if(!accounts?.data?.length) {
      dispatch(loadAccounts());
    }
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const data = useMemo(() => products.data, [products]);
  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productName}{" "}
            {row.original.productVariantName &&
              row.original.productVariantName !== "Default Title" &&
              "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "Unit Price",
        accessor: "productUnitPrice",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productUnitPrice && getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.productUnitPrice}
          </span>
        ),
        className: "text-right",
      },
      {
        Header: "Initial Quantity",
        accessor: "openingQuantity",
        className: "text-right",
      },
      {
        Header: "BigCommerce Store",
        accessor: "bigcommerceStore",
        Cell: ({ cell: { row } }) => {
          const account =
            row.original.cartAccountId &&
            accounts?.data?.find((item) => item.id === row.original.cartAccountId);
          return account?.name || "";
        },
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
      },
      {
        Header: "Deskera Product Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.reason}</>;
        },
      },
    ],
    [accounts]
  );

  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setStatus(status);
    setSortBy(sortBy);
    setLoading(true);
    dispatch(loadProducts(pageSize, pageIndex, status, sortBy, search ?? ""));
  };

  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateBigcommerceData();
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.message,
        })
      );
    }
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Products</h3>
      {(!config?.data || !config?.data?.syncEnabled) && (
        <Alert type="warning">
          Your synced products will appear here. Please complete{" "}
          <Link to="/app/bigcommerce" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ServerPaginationTable
        data={data?.content ? data?.content : []}
        columns={columns}
        migrate={migrateBigcommerceDataForProducts}
        cart="bigcommerce"
        refresh={refreshData}
        tab="Products"
        pageCount={pageCount}
        loading={loading}
        rowId="id"
      />
    </div>
  );
}

export default Products;