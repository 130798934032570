import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import ArrowRight from "src/assets/ArrowRight";
import ForwardRight from "src/assets/ForwardRight";
import SearchLogo from "src/assets/Search";
import UpArrowIcon from "src/assets/UpArrow";
import Alert from "src/components/Alert";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import { loadCustomers, loadConfig, loadAccounts, loadjob, loadJob } from "src/redux/actions/shopify";
import customSelectTheme from "src/utils/selectTheme";
import SyncStatus from "src/components/SyncStatus";
import { migrateShopifyData, migrateShopifyDataForCustomers } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import { getQueryParams } from "src/utils/Utility";

function Customers() {
  const customers = useSelector((state) => state.shopify.customers);
  const data = useMemo(() => customers.data, [customers]);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.shopify.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);
  const accounts = useSelector((state) => state.shopify.accounts);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customerName",
      },
      {
        Header: "Email",
        accessor: "customerEmail",
      },
      {
        Header: "Shopify Store",
        accessor: "shopifyStore",
        Cell: ({ cell: { row } }) => {
          const account =
            row.original.cartAccountId &&
            accounts?.data?.find((item) => item.id === row.original.cartAccountId);
          return account?.name || "";
        },
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return <SyncStatus status={row.original.syncStatus} reason={row.original.reason} id={row.original.cartCustomerId}/>;
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Deskera Contact Code",
        accessor: "deskeraDocumentCode",
      },
    ],
    [accounts]
  );

  useEffect(() => {
    dispatch(loadCustomers());
    dispatch(loadConfig());
    if (accounts?.data?.length === 0) {
      dispatch(loadAccounts());
    }
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  useEffect(() => {
    setLoading(false);
    if(data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);


  const config = useSelector((state) => state.shopify.config);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search, query }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setStatus(status);
    setLoading(true);
    setSortBy(sortBy)

    const queryParams = getQueryParams(query);

    dispatch(loadCustomers(pageSize, pageIndex, status, sortBy, search ?? "", queryParams));
  }; 


  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateShopifyData();
    } catch (err) {
      dispatch(addToast({
        type: "danger",
        title: "Failure",
        message: err.message
      }))
    }
  }


  return (
    <div className="p-4">
      <h3 className="mb-3">Customers</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced customers will appear here. Please complete{" "}
          <Link to="/app/shopify" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ServerPaginationTable data={data.content ? data.content : []} columns={columns} migrate={migrateShopifyDataForCustomers} cart='shopify'
      refresh={refreshData} tab='Customers' pageCount={pageCount} loading={loading} rowId="cartCustomerId"/>

    </div>
  );
}

export default Customers;
