import { useEffect } from "react";
import { useState } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import CreditCardIcon from "src/assets/CreditCard";
import WrenchIcon from "src/assets/Wrench";
import Logs from "src/assets/Logs";
import Config from "src/pages/ShipStation/Config";
import Orders from "src/pages/ShipStation/Orders";
import Invoices from "src/pages/ShipStation/Invoices";
import SyncReports from "./SyncReports";

function ShipStation() {
  const [activeVisible, setActiveVisible] = useState(false);
  const [activeTop, setActiveTop] = useState(0);

  useEffect(() => {
    sessionStorage.setItem("cartType", "shipStation");
  });

  const menuItems = [
    {
      name: "Setup",
      href: "/app/shipStation",
      icon: <WrenchIcon />,
      component: <Config />,
      exact: true,
    },
    {
      name: "Deskera Orders",
      href: "/app/shipStation/orders",
      icon: <CreditCardIcon />,
      component: <Orders />,
      exact: false,
    },
    {
      name: "Deskera Invoices",
      href: "/app/shipStation/invoices",
      icon: <CreditCardIcon />,
      component: <Invoices />,
      exact: false,
    },
    {
      name: "ShipStation to Deskera Sync Report",
      href: "/app/shipStation/sync-to-deskera-report",
      icon: <Logs />,
      component: <SyncReports syncTo="DESKERA" />,
      exact: false,
    },
    {
      name: "Deskera to ShipStation Sync Report",
      href: "/app/shipStation/sync-to-bigcommerce-report",
      icon: <Logs />,
      component: <SyncReports syncTo="ECOM" />,
      exact: false,
    },
  ];

  return (
    <>
      <div
        className="bg-white py-3 dk-shadow-sm"
        style={{
          minWidth: 240,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          zIndex: 9,
          position: "relative",
        }}
      >
        {activeVisible && (
          <div
            className="ease-in-out-back"
            style={{
              width: 8,
              height: "3.25rem",
              position: "absolute",
              right: 0,
              top: activeTop,
              backgroundColor: "#4962df",
            }}
          ></div>
        )}
        {menuItems.map((item, index) => (
          <NavLink
            exact
            to={item.href}
            className="text-decoration-none px-4 py-3 d-block dk-navlink dk-navlink-hover mb-2 ease-in d-flex"
            isActive={(match, location) => {
              if (!match) return false;
              setActiveVisible(true);
              setActiveTop(1 + (3.375 + 0.5) * index + "rem");
              return true;
            }}
            style={{
              fontWeight: 500,
              color: "black",
            }}
          >
            <span className="svg-icon-lg svg-baseline mr-3">{item.icon}</span>
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="w-100 h-100 overflow-auto" style={{ background: "#f6f6f6" }}>
        <Switch>
          {menuItems.map((item) => (
            <Route exact={item.exact} path={item.href}>
              {item.component}
            </Route>
          ))}
        </Switch>
      </div>
    </>
  );
}

export default ShipStation;
