import { useState } from "react";
import AsyncSelect from "react-select/async";
import { Utility } from "src/utils/Utility";

export function MatchingProductSelect({
  row,
  loadDeskeraOptions,
  deskeraHandleChange,
  productData,
  setProductData,
}) {
  const [product, setProduct] = useState(
    row.original.deskeraId
      ? { pid: row.original.deskeraId, name: row.original.deskeraProductName }
      : null
  );

  const loadProductsOptions = Utility.debounce((inputValue, callback) => {
    loadDeskeraOptions(inputValue).then((response) => {
      callback(response);
    });
  }, 250);
  return (
    <AsyncSelect
      placeholder={"Select Product"}
      className="filter-selector ml-3"
      cacheOptions
      defaultOptions={productData}
      value={product}
      getOptionLabel={(e) => e.name}
      getOptionValue={(e) => e.pid}
      loadOptions={loadProductsOptions}
      onChange={(e) => {
        // setProduct(e);
        // setProductData((prev) => {
        //   const doesOptionsExsits = prev.find((obj) => obj.pid === e.pid);
        //   if (!doesOptionsExsits) {
        //     prev.push(e);
        //   }
        //   return prev;
        // });
        deskeraHandleChange(e, row.index);
      }}
    />
  );
}
