import {
  TALLY_SET_CONFIG,
  TALLY_SET_ACCOUNTS,
  TALLY_SET_CREDIT_NOTES,
  TALLY_SET_DEBIT_NOTES,
  TALLY_SET_JOURNAL_VOUCHERS,
  TALLY_SET_PRODUCTS,
  TALLY_SET_CUSTOMERS,
  TALLY_SET_INVOICES,
  TALLY_SET_BILLS,
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  accounts: {
    loading: true,
    data: [],
  },
  creditNotes: {
    loading: true,
    data: {},
  },
  debitNotes: {
    loading: true,
    data: {},
  },
  journalVouchers: {
    loading: true,
    data: {},
  },
  tallyCustomers: {
    loading: false,
    data: {},
  },
  tallyProducts: {
    loading: false,
    data: {},
  },
  invoices: {
    loading: true,
    data: {},
  },
  bills: {
    loading: true,
    data: {},
  },
};

function tallyReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TALLY_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case TALLY_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case TALLY_SET_CREDIT_NOTES:
      return {
        ...state,
        creditNotes: {
          loading: false,
          data: action.creditNotes,
        },
      };
    case TALLY_SET_DEBIT_NOTES:
      return {
        ...state,
        debitNotes: {
          loading: false,
          data: action.debitNotes,
        },
      };
    case TALLY_SET_JOURNAL_VOUCHERS:
      return {
        ...state,
        journalVouchers: {
          loading: false,
          data: action.journalVouchers,
        },
      };
    case TALLY_SET_PRODUCTS:
      return {
        ...state,
        tallyProducts: {
          loading: false,
          data: action.tallyProducts,
        },
      };
    case TALLY_SET_CUSTOMERS:
      return {
        ...state,
        tallyCustomers: {
          loading: false,
          data: action.tallyCustomers,
        },
      };
    case TALLY_SET_INVOICES:
      return {
        ...state,
        invoices: {
          loading: false,
          data: action.invoices,
        },
      };
    case TALLY_SET_BILLS:
      return {
        ...state,
        invoices: {
          loading: false,
          data: action.bills,
        },
      };
    default:
      return state;
  }
}

export default tallyReducer;
