import ReactTooltip from "react-tooltip";

function SyncStatus({ status, reason, id, cart}) {
  switch (status) {
    case "PARTIAL":
      return (
        <>
          <span
            className="badge badge-pill badge-warning"
            data-tip="React-tooltip"
            data-for={id}
          >
            Partial
          </span>
          <ReactTooltip
            className="bg-warning"
            place="top"
            type="error"
            id={id}
            effect="solid"
          >
            <span>{cart === "shipStation" ? "Order is not completely fulfilled." : "Payment/Fulfillments are not captured."}</span>
          </ReactTooltip>
        </>
      );
    case "SUCCESSFUL":
      return <span className="badge badge-pill badge-success">Success</span>;
    case "FAILED":
      return (
        <div>
          <span
            data-tip="React-tooltip"
            data-for={id}
            className="badge badge-pill badge-danger"
          >
            Failed
          </span>
          <ReactTooltip
            className="bg-danger"
            place="top"
            type="error"
            id={id}
            effect="solid"
          >
            <span>{reason ? reason : "An error occurred."}</span>
          </ReactTooltip>
        </div>
      );
      case "CANCELLED":
        return (
          <div>
            <span
              data-tip="React-tooltip"
              data-for={id}
              className="badge badge-pill badge-danger"
            >
              Cancelled
            </span>
            <ReactTooltip
              className="bg-danger"
              place="top"
              type="error"
              id={id}
              effect="solid"
            >
              <span>{reason ? reason : "An error occurred."}</span>
            </ReactTooltip>
          </div>
        );
        case "RESYNC":
          return (
            <div>
              <span
                data-tip="React-tooltip"
                data-for={id}
                className="badge badge-pill badge-warning"
              >
                Resync
              </span>
              <ReactTooltip
                className="bg-warning"
                place="top"
                type="error"
                id={id}
                effect="solid"
              >
                <span>{reason ? reason : "The order was modified. It needs to sync again with Deskera. "}</span>
              </ReactTooltip>
            </div>
          );
    case "FULFILLED":
        return <span className="badge badge-pill badge-success">Fulfilled</span>;
    default:
      return <span className="badge badge-pill badge-info">Pending</span>;
  }
}

export default SyncStatus;