import React, { useEffect, useMemo, useState } from "react";
import { DKLabel, DKButton, showAlert,DKIcons } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import MatchingSummaryTable from "src/pages/Magento/MatchingSummaryTable";
import customSelectTheme from "src/utils/selectTheme";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getDeskeraDimensions,saveMagentoMatchingCustomFields } from "src/api";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import { DKDataGrid, INPUT_TYPE } from "deskera-ui-library";
import { loadMagentoCustomfields ,loadMagentoCustomfieldMapping,loadDeskeraCustomfields } from "src/redux/actions/magento";
import AsyncSelect from "react-select/async";
import { addToast } from "src/redux/actions/toasts";
import { template } from "underscore";
function CustomeFieldMapping(props) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [hover, setHover] = useState(false);
    const [triggered, setTriggered] = useState(false);
    const [data, setData] = useState([
        { id: 0, deskeraDocumentCode: "", magentoDocumentCode: "" },
    ]
    )

    const[disable ,setDisable] =useState(false);
    const customFieldMappingData = useSelector((state) => state.magento.matchingCustomFieldMapping);
    const deskeraCustomFieldData =useSelector((state)=>state.magento.deskeraCustomFields)
    const [deskeraCustomFieldData1, setDeskeraCustomFieldData] = useState([]);
    const magentoCustomFieldData = useSelector((state) => state.magento.matchingCustomField);
    
    const [magentoCustomFieldList, setMagentoCustomFieldList] = useState([]);
    const [deskeraCustomFieldList, setDeskeraCustomFieldList] = useState([]);

    const [currentRow, setCurrentRow] = useState(data.length);
    const [deskeraIndexList, setDeskeraIndexList] = useState([]);
    const [magentoIndexList, setMagentoIndexList] = useState([])
    const [err ,setError]=useState();
    const [columns, setColumns] = useState([

            {
                Header: "Deskera Custom Field",
                accessor: "deskeraDocumentCode",
                Cell: ({ cell: { row } }) => {
                    return (
                        <AsyncSelectWrapper
                            row={row}
                            loadAllOptions={loadDeskeraOptions}
                            handleChange={deskeraHandleChange}
                            customFieldData={deskeraCustomFieldList}
                            customFieldIndexList={deskeraIndexList}
                            type='deskeraDocumentCode'

                        />
                    );
                },
            },
            {
                Header: "  Magento Custom Field",
                accessor: "magentoDocumentCode",
                Cell: ({ cell: { row } }) => {
                    return (
                        <AsyncSelectWrapper
                            row={row}
                            loadAllOptions={loadMagentoOptions}
                            handleChange={magentoHandleChange}
                            customFieldData={magentoCustomFieldList}
                            //  productData={productData}
                            customFieldIndexList={magentoIndexList}
                            type='magentoDocumentCode'
                        />
                    );
                },
            },
            {
                Header: "Actions",
                accessor: "delete",
                Cell: ({ cell: { row } }) => {
                    return (
                        <ButtonWrapper
                            row={row}
                            deleteRow={deleteRow}
                           
                        />
                    );
                },
            }
        ]
    )
    useEffect(()=>{
        if(data.length>0){
           setDisable(false);
        }else{
          setDisable(true);
        }
    },[data])
    useEffect(()=>{
      var tempColumns = columns
      setColumns([

        {
            Header: "Deskera Custom Field",
            accessor: "deskeraDocumentCode",
            Cell: ({ cell: { row } }) => {
                return (
                    <AsyncSelectWrapper
                        row={row}
                        loadAllOptions={loadDeskeraOptions}
                        handleChange={deskeraHandleChange}
                        customFieldData={deskeraCustomFieldList}
                        customFieldIndexList={deskeraIndexList}
                        type='deskeraDocumentCode'

                    />
                );
            },
        },
        {
            Header: "  Magento Custom Field",
            accessor: "magentoDocumentCode",
            Cell: ({ cell: { row } }) => {
                return (
                    <AsyncSelectWrapper
                        row={row}
                        loadAllOptions={loadMagentoOptions}
                        handleChange={magentoHandleChange}
                        customFieldData={magentoCustomFieldList}
                        //  productData={productData}
                        customFieldIndexList={magentoIndexList}
                        type='magentoDocumentCode'
                    />
                );
            },
        },
        {
            Header: "Action",
            accessor: "delete",
            Cell: ({ cell: { row } }) => {
                return (
                    <ButtonWrapper
                        row={row}
                        deleteRow={deleteRow}
                       
                    />
                );
            },
        }
    ]);
    },[magentoCustomFieldList,deskeraCustomFieldList,data])
   useEffect(()=>{
    var tmpData = []
    var tempMagentoIndexList = magentoIndexList;
    var tempDeskeraIndexList =deskeraIndexList;
    if (customFieldMappingData?.data && customFieldMappingData?.data.length>0) {
       
        customFieldMappingData?.data.forEach((field,index) => {
            tempMagentoIndexList.push(field.deskeraId)
            tempDeskeraIndexList.push(field.magentoCustomFieldDto?.attribute_code);
            tmpData.push({
                id: index,
                deskeraDocumentCode: field.deskeraId,
                magentoDocumentCode: field.magentoCustomFieldDto?.attribute_code,
              });
        });
        setDeskeraIndexList(tempDeskeraIndexList);
        setMagentoIndexList(tempMagentoIndexList)
        setCurrentRow(tmpData.length);
        setData(tmpData);
    }
   },[customFieldMappingData , magentoCustomFieldData , deskeraCustomFieldData])
    useState(() => {
     
        var tmpMagentoCustomFieldList = []

        if (
          magentoCustomFieldData?.data &&
          magentoCustomFieldData?.data.length > 0
        ) {
          
          magentoCustomFieldData?.data.forEach((field) => {
            if(field.module === props.module){
            const isDisabledList =
              customFieldMappingData?.data.filter((data, index) => {
               return data.magentoCustomFieldDto?.attribute_code === field.attribute_code;
              })
              const isDisabled = isDisabledList && isDisabledList.length ? true: false;
            if(field.attribute_code && field.default_frontend_label){
                tmpMagentoCustomFieldList.push({
                    code: field.attribute_code,
                    label: field.default_frontend_label,
                    isDisabled: isDisabled,
                  });
            }
           }
          });
          setMagentoCustomFieldList(tmpMagentoCustomFieldList);
        }
    }, [magentoCustomFieldData, customFieldMappingData])

    useState(() => {
        var tmpDeskeraCustomFieldList = []
        if (
            deskeraCustomFieldData?.data &&
            deskeraCustomFieldData?.data.length > 0
          ) {
            
            deskeraCustomFieldData?.data.forEach((field) => {
            if(field.modules.includes(props.module)){
                const isDisabledList =
                customFieldMappingData?.data.filter((data, index) => {
                  return  data.deskeraId === field.code;
                })
                const isDisabled =isDisabledList && isDisabledList.length ? true: false;
                  tmpDeskeraCustomFieldList.push({
                code: field.code,
                label: field.label,
                isDisabled: isDisabled,
              });

            }
              
            });
            setDeskeraCustomFieldList(tmpDeskeraCustomFieldList);
          }
       
    }, [deskeraCustomFieldData,customFieldMappingData])

   
    const deskeraHandleChange = (e, index) => {

        var newData = data;
        var tempDeskeraIndexList = deskeraIndexList;
        var tmpDeskeraCustomFieldList = deskeraCustomFieldList;
        tempDeskeraIndexList.push(e.code);

        if (data) {
            if (newData[index].deskeraDocumentCode !== '') {
                tempDeskeraIndexList = tempDeskeraIndexList.filter((list) => list !== newData[index].deskeraDocumentCode);
                const previousDeskeraIndex = deskeraCustomFieldList.findIndex((deskera) => {
                    return deskera.code === newData[index].deskeraDocumentCode;
                });
                if(previousDeskeraIndex  !== -1){
                    tmpDeskeraCustomFieldList[previousDeskeraIndex].isDisabled = false;
                }
                }
               

            newData[index].deskeraDocumentCode = e.code;
            const deskeraIndex = deskeraCustomFieldList.findIndex((deskera) => { return deskera.code === e.code })
            tmpDeskeraCustomFieldList[deskeraIndex].isDisabled = true;
            setData(newData);
            setDeskeraIndexList(tempDeskeraIndexList);
            setDeskeraCustomFieldList(tmpDeskeraCustomFieldList)
        }

    };
    
    const magentoHandleChange = (e, index) => {

        var newData = data;
        var tmpMagentoCustomFieldList = magentoCustomFieldList;
        var tempMagentoIndexList = magentoIndexList;
        tempMagentoIndexList.push(e.code);

        if (data) {
            if (newData[index].magentoDocumentCode !== '') {
                tempMagentoIndexList = tempMagentoIndexList.filter((list) => list !== newData[index].magentoDocumentCode);
                const previousMagentoIndex = magentoCustomFieldList.findIndex((magento) => {
                    return magento.code === newData[index].magentoDocumentCode;
                });
                if(previousMagentoIndex !== -1){
                    tmpMagentoCustomFieldList[previousMagentoIndex].isDisabled = false;
                }
                
            }
            newData[index].magentoDocumentCode = e.code;
            const magentoIndex = magentoCustomFieldList.findIndex((magento) => { return magento.code === e.code })
            tmpMagentoCustomFieldList[magentoIndex].isDisabled = true;
            setMagentoCustomFieldList(tmpMagentoCustomFieldList);
            setData(newData);
            setMagentoIndexList(tempMagentoIndexList);
        }

    };
    const loadDeskeraOptions = async (inputValue) => {
        const response = deskeraCustomFieldList
        // return response
        if (inputValue === undefined || inputValue == "") {
            return response;
        } else {
            return response.filter((field) => {
                if (String(field.label)?.toLowerCase().includes(String(inputValue)?.toLowerCase())) {
                    return true;
                }
            })
        }
    }
    const loadMagentoOptions = async (inputValue) => {
        const response = magentoCustomFieldList;
        // return response
        if (inputValue === undefined || inputValue == "") {
            return response;
        } else {
           return  response.filter((field) => {
                if (String(field.label)?.toLowerCase().includes(String(inputValue)?.toLowerCase())) {
                    return true;
                }
            })
        }
    }
    const refreshData = () => {
        dispatch(loadMagentoCustomfieldMapping([props.module]));
        dispatch(loadMagentoCustomfields(['PRODUCT','CONTACT']));
     };

    const validation = () => {
        const isFieldEmptyDeskerafield= data.filter((data) => {
            return data.deskeraDocumentCode === ""  ;
        });
        const isFieldEmptyMagentoField= data.filter((data) => {
            return data.magentoDocumentCode === ""  ;
        });


    
        if(data.length<=0){
            showAlert("Alert", 'Please Add atleast one row');
            return false;
        }
        if (isFieldEmptyDeskerafield.length > 0) {
            showAlert("Alert", 'Please select the Deskera field , Custom field cannot be empty');
            return false;
        }
        if (isFieldEmptyMagentoField.length > 0) {
            showAlert("Alert", 'Please select the Magento field , Custom field cannot be empty');
            return false;
        }


        return true;
    }
    const addRow = () => {
        var tempData = data
        tempData.push({ id: currentRow, deskeraDocumentCode: "", magentoDocumentCode: "" });
        setData(tempData)
        setCurrentRow((prevState) => (
            prevState + 1
        ));

    }

    const deleteRow = (index) => {
        var tempData = data
        const removedRow = tempData[index];
        if (removedRow.deskeraDocumentCode !== '') {
            var tmpDeskeraCustomFieldList = deskeraCustomFieldList;
            var tempDeskeraIndexList = deskeraIndexList;
            const deskeraIndex = deskeraCustomFieldList.findIndex((deskera) => { return deskera.code === removedRow.deskeraDocumentCode })
            tmpDeskeraCustomFieldList[deskeraIndex].isDisabled = false;
            tempDeskeraIndexList = tempDeskeraIndexList.filter((list) => list !==removedRow.deskeraDocumentCode);
            setDeskeraCustomFieldList(tmpDeskeraCustomFieldList);
            setDeskeraIndexList(tempDeskeraIndexList);

        }
        if (removedRow.magentoDocumentCode !== '') {
            var tmpMagentoCustomFieldList = magentoCustomFieldList;
            var tempMagentoIndexList = magentoIndexList;
            const magentoIndex = magentoCustomFieldList.findIndex((magento) => { return magento.code === removedRow.magentoDocumentCode })
            tmpMagentoCustomFieldList[magentoIndex].isDisabled = false;
            tempMagentoIndexList = tempMagentoIndexList.filter((list) => list !== removedRow.magentoDocumentCode);
            setMagentoCustomFieldList(tmpMagentoCustomFieldList);
            setMagentoIndexList(tempMagentoIndexList);
        }

        setCurrentRow((prevState) => (
            prevState - 1
        ));
        tempData =tempData.filter((list,indexNumber)=> index !==indexNumber)
       tempData =  tempData.map((field,index)=>{
           return{
            ...field,
            id :index
           }
        })
        setData(tempData);

    }

    const handleSyncSubmitAndClosePopup = () => {
         handleSyncSubmit()
         if(validation()){
            props.setCustomFieldState(false)
         }
        
    }
    const handleSyncSubmit = async () => {
        if (validation()) {
            
            var tempcustomFieldList = []
            data.forEach((field) => {
                tempcustomFieldList.push({ deskeraId: field.deskeraDocumentCode, magentoCustomFieldCode: field.magentoDocumentCode });
            });

            const payload = {
                customFieldList:tempcustomFieldList,
                module: props.module,
            };

            try {
                const resp = await saveMagentoMatchingCustomFields(payload);
          
                dispatch(
                  addToast({
                    type: "success",
                    title: "Success",
                    message: "Custom Field Mapping Saved successfully ",
                  })
                );
                refreshData();
                return;
              } catch (err) {
                dispatch(
                  addToast({
                    type: "danger",
                    title: "Failure",
                    message: err.response.data.message,
                  })
                );
              }
            
          
           
        }
    }
    const handleCancel = () => { props.setCustomFieldState(false) }

    const matchingDocScreen = () => {

        return (
            <>
                {" "}
                <div
                    className="w-100 h-100  d-flex justify-content-center  overflow-hidden"
                    style={{
                        zIndex: 99,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.4)",
                    }}
                >
                    <div
                        className=" mt-s card dk-card dk-card-shadow d-flex border border-radius-l"
                        style={{ minWidth: "100%", minHeight: "70%" }}
                    >
                        <h3 className="pt-4 ml-4">Map Custom Fields</h3>
                        <div className=" overflow-hidden card-body d-flex flex-column" style={{ overflowX: "auto", height:'100%' }}>
                            <ServerPaginationTable
                                data={data ? data : []}
                                columns={columns}
                                migrate={""}
                                cart="magento"
                                refresh={refreshData}
                                tab="customFields"
                                pageCount={1}
                                loading={loading}
                                rowId="id"
                            />
                        </div>
                        <div className="ml-xl mb-l d-flex flex-row">
                           
                            <button
                                className="btn border-radius-m p-v-s text-white bg-success"
                                onClick={() => { addRow() }}
                                disabled={triggered}
                                onMouseOver={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >
                                <span className="svg-icon svg-baseline mr-2 svg-white">
                                    {triggered ? (
                                        <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                {
                                    <span>
                                      + Add Custom field
                                    </span>
                                }
                            </button>        
                            {false && ( <button
                                className="btn ml-s border-radius-m p-v-s text-white bg-success"
                                onClick={() => { deleteRow() }}
                                disabled={triggered}
                                onMouseOver={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >
                                <span className="svg-icon svg-baseline mr-2 svg-white">
                                    {triggered ? (
                                        <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                                    ) : (
                                        <></>
                                    )}
                                </span>
                                {
                                    <span>
                                        Delete Row
                                    </span>
                                }
                            </button>
                            )}
                        </div>
                        <div className=" mb-l d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
                            <DKButton
                                className="bg-success px-4 text-white"
                                onClick={handleSyncSubmitAndClosePopup}
                                title={"SAVE AND CLOSE"}
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                            ></DKButton>
                            <DKButton
                                className="bg-success px-4 text-white"
                                onClick={handleSyncSubmit}
                                title={"SAVE"}
                                style={{ marginLeft: "10px", marginRight: "10px" }}
                            ></DKButton>
                            <button
                                className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
                                onClick={handleCancel}
                            >
                                <span className="svg-icon svg-baseline mr-2 svg-disabled">
                                    <CloseIcon />
                                </span>
                                <span>Cancel</span>
                            </button>
                        </div>

                    </div>
                </div>{" "}
            </>
        );
    }


    return (
        <>
            <div className="d-flex">
                <div
                    className="vw-100 vh-100 d-flex justify-content-center align-items-center"
                    style={{
                        zIndex: 99,
                        position: "absolute",
                        top: 0,
                        left: 0,
                        background: "rgba(0, 0, 0, 0.4)",
                        width: 600,
                    }}
                >
                    <div
                        className="card dk-card dk-card-shadow d-flex border border-radius-l"
                        style={{ minWidth: "60%", minHeight: "85%" }}
                    >
                        <div className="card-body d-flex flex-column" style={{ align: "center" }}>
                            <div className=" card-body d-flex flex-column">
                                <div class="overflow-hidden flex-row">{matchingDocScreen()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CustomeFieldMapping;

function AsyncSelectWrapper({ row, loadAllOptions, handleChange, customFieldData, customFieldIndexList, type }) {
    const [async, setAsync] = useState(
        type === 'deskeraDocumentCode'
            ? customFieldData.find((field) => field.code === row.original.deskeraDocumentCode)
            : customFieldData.find((field) => field.code === row.original.magentoDocumentCode)
    );

    return (
        <div className="  justify-content-start align-items-start mr-xxl">
            <AsyncSelect
                placeholder={'Select  Custom field'}
                className="filter-selector ml-3"
                cacheOptions
                defaultOptions
                maxMenuHeight={200}
                maxMenuWidth={100}
                value={async}
                getOptionLabel={(e) => {
                    return e.label
                }}
                getOptionValue={(e) => {
                    return e.code
                }}
                loadOptions={loadAllOptions}
                onChange={(e) => {
                    setAsync(e);
                    handleChange(e, row.index);
                }}
            />
        </div>
    );
}

function ButtonWrapper({row, deleteRow}){

    return <>
      <div className="ml-l">
        <DKButton
            className=" border-l text-white"
            icon ={ DKIcons.ic_delete}
            onClick={()=>{deleteRow(row.index)}}
            style={{
                border: '1px'
            }}
            title=""
        ></DKButton>
        </div></>

}