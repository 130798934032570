import {
  getShopifyAccounts,
  getShopifyConfig,
  getShopifyCustomers,
  getShopifyInventoryTransactions,
  getShopifyProducts,
  getShopifyOrders,
  getShopifyJobStatus,
  getShopifyLocationMappings,
  getDeskeraSyncedProductsForShopify,
  getPaymentMethodConfig,
  getDiscountConfig,
  getShopifySyncContactCustomer,
  saveShopifySyncContactCustomer,
  getShopifyRefunds,
  saveShopifyRefunds,
} from "src/api";
import {
  SHOPIFY_FAILED_CONFIG_LOAD,
  SHOPIFY_SET_CONFIG,
  SHOPIFY_FAILED_ACCOUNT_LOAD,
  SHOPIFY_SET_ACCOUNTS,
  SHOPIFY_FAILED_CUSTOMERS_LOAD,
  SHOPIFY_SET_CUSTOMERS,
  SHOPIFY_FAILED_INVENTORY_TRANSACTIONS_LOAD,
  SHOPIFY_SET_INVENTORY_TRANSACTIONS,
  SHOPIFY_FAILED_PRODUCTS_LOAD,
  SHOPIFY_SET_PRODUCTS,
  SHOPIFY_FAILED_ORDERS_LOAD,
  SHOPIFY_SET_ORDERS,
  SHOPIFY_SET_JOB,
  SHOPIFY_FAILED_JOB_LOAD,
  SHOPIFY_FAILED_LOCATIONS_LOAD,
  SHOPIFY_SET_LOCATIONS,
  SHOPIFY_SET_DESKERA_PRODUCTS,
  SHOPIFY_FAILED_PAYMENT_LOAD,
  SHOPIFY_SET_PAYMENT_CONFIG,
  SHOPIFY_SET_DISCOUNT_CONFIG,
  SHOPIFY_FAILED_DISCOUNT_LOAD,
  SHOPIFY_CONTACT_CUSTOMER_TYPE,
  SHOPIFY_FAILED_CONTACT_CUSTOMER_LOAD,
  SHOPIFY_SET_REFUNDS,
  SHOPIFY_FAILED_REFUNDS_LOAD
} from "../types";

export function loadConfig() {
  return async function (dispatch, getState) {
    try {
      const config = await getShopifyConfig();
      dispatch(setConfig(config));
    } catch (err) {
      dispatch(failedConfigLoad());
    }
  };
}

export function setConfig(config) {
  return {
    type: SHOPIFY_SET_CONFIG,
    config: config,
  };
}

export function failedConfigLoad() {
  return {
    type: SHOPIFY_FAILED_CONFIG_LOAD,
  };
}

export function loadAccounts() {
  return async function (dispatch, getState) {
    try {
      const accounts = await getShopifyAccounts();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(failedAccountLoad());
    }
  };
}

export function setAccounts(accounts) {
  return {
    type: SHOPIFY_SET_ACCOUNTS,
    accounts: accounts,
  };
}

export function failedAccountLoad() {
  return {
    type: SHOPIFY_FAILED_ACCOUNT_LOAD,
  };
}

export function loadCustomers(limit, page, status, sortBy, search, queryParams = "") {
  return async function (dispatch, getState) {
    try {
      const customers = await getShopifyCustomers(limit, page, status, sortBy, search ?? "",queryParams);
      dispatch(setCustomers(customers));
    } catch (err) {
      dispatch(failedCustomersLoad());
    }
  };
}

export function loadInventoryTransactions(limit, page, status, queryParams = '') {
  return async function (dispatch, getState) {
    try {
      const inventoryTransactions = await getShopifyInventoryTransactions(limit, page, status, queryParams);
      dispatch(setInventoryTransactions(inventoryTransactions));
    } catch (err) {
      dispatch(failedInventoryTransactionsLoad());
    }
  };
}

export function setCustomers(customers) {
  return {
    type: SHOPIFY_SET_CUSTOMERS,
    customers: customers,
  };
}

export function setInventoryTransactions(inventoryTransactions) {
  return {
    type: SHOPIFY_SET_INVENTORY_TRANSACTIONS,
    inventoryTransactions: inventoryTransactions,
  };
}

export function failedCustomersLoad() {
  return {
    type: SHOPIFY_FAILED_CUSTOMERS_LOAD,
  };
}

export function failedInventoryTransactionsLoad() {
  return {
    type: SHOPIFY_FAILED_INVENTORY_TRANSACTIONS_LOAD,
  };
}

export function loadOrders(limit, page, status, sortBy, startDate = "", endDate = "", search, queryParams = '') {
  return async function (dispatch, getState) {
    const ordersLoading = getState().shopify.orders.loading;

    try {
      const orders = await getShopifyOrders(
        limit,
        page,
        status,
        sortBy,
        startDate,
        endDate,
        search ?? "",
        queryParams
      );
      dispatch(setOrders(orders));
    } catch (err) {
      dispatch(failedOrdersLoad());
    }
  };
}

export function setOrders(orders) {
  return {
    type: SHOPIFY_SET_ORDERS,
    orders: orders,
  };
}

export function failedOrdersLoad() {
  return {
    type: SHOPIFY_FAILED_ORDERS_LOAD,
  };
}

export function setRefunds(refunds) {
  return {
    type: SHOPIFY_SET_REFUNDS,
    refunds: refunds,
  };
}


export function failedRefundsLoad() {
  return {
    type: SHOPIFY_FAILED_REFUNDS_LOAD,
  };
}

export function loadRefunds() {
  return async function (dispatch, getState) {
    try {
      const refunds = await getShopifyRefunds();
      dispatch(setRefunds(refunds));
    } catch (err) {
      dispatch(failedRefundsLoad());
    }
  };
}

export function loadProducts(limit, page, status, sortBy, search, queryParams = '') {
  return async function (dispatch, getState) {
    try {
      const products = await getShopifyProducts(limit, page, status, sortBy, search ?? "", queryParams);
      dispatch(setProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}



export function loadShopifyContactCustomerType() {
  return async function (dispatch, getState) {
    try {
      const contactCustomerType = await getShopifySyncContactCustomer();
      dispatch(setShopifyContactCustomerType(contactCustomerType));
    } catch (err) {
      dispatch(failedContactCustomerLoad());
    }
  };
}


export function updateShopfiyContactCompanyType(newList) {
  return async function (dispath, getState) {
    try {
      let updatedContactCompanyType = [];
      try {
        updatedContactCompanyType = await saveShopifySyncContactCustomer(newList);
      } catch (err) {
        updatedContactCompanyType = [];
        dispath(failedContactCustomerLoad());
      }
      dispath(setShopifyContactCustomerType(updatedContactCompanyType));
    } catch (err) {
      dispath(failedContactCustomerLoad());
    }
  };
}

export function updateShopfiyRefunds(newList) {
  return async function (dispath, getState) {
    try {
      let updatedRefunds = [];
      try {
        updatedRefunds = await saveShopifyRefunds(newList);
      } catch (err) {
        updatedRefunds = [];
        dispath(failedRefundsLoad());
      }
      dispath(setRefunds(updatedRefunds));
    } catch (err) {
      dispath(failedRefundsLoad());
    }
  };
}

export function loadDeskeraSyncedProducts(limit, page, queryParams = '') {
  return async function (dispatch, getState) {
    try {
      const products = await getDeskeraSyncedProductsForShopify(limit, page, queryParams);
      dispatch(setDeskeraProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setProducts(products) {
  return {
    type: SHOPIFY_SET_PRODUCTS,
    products: products,
  };
}


export function setShopifyContactCustomerType(list){
  
  return {
    type: SHOPIFY_CONTACT_CUSTOMER_TYPE,
    contactCustomerType: list
  }
}
export function setDeskeraProducts(products) {
  return {
    type: SHOPIFY_SET_DESKERA_PRODUCTS,
    products: products,
  };
}

export function failedProductsLoad() {
  return {
    type: SHOPIFY_FAILED_PRODUCTS_LOAD,
  };
}

export function failedContactCustomerLoad() {
  return {
    type: SHOPIFY_FAILED_CONTACT_CUSTOMER_LOAD,
    contactCustomerType: []
  };
}

export function setJob(job) {
  return {
    type: SHOPIFY_SET_JOB,
    job: job,
  };
}

export function loadJob() {
  return async function (dispatch) {
    try {
      const job = await getShopifyJobStatus();
      dispatch(setJob(job));
    } catch (err) {
      dispatch(failedJobLoad());
    }
  };
}

export function failedJobLoad() {
  return {
    type: SHOPIFY_FAILED_JOB_LOAD,
  };
}

export function setLocations(locations) {
  return {
    type: SHOPIFY_SET_LOCATIONS,
    locations: locations,
  };
}

export function loadLocations() {
  return async function (dispatch) {
    try {
      const locations = await getShopifyLocationMappings();
      dispatch(setLocations(locations));
    } catch (err) {
      dispatch(failedLocationsLoad());
    }
  };
}

export function failedLocationsLoad() {
  return {
    type: SHOPIFY_FAILED_LOCATIONS_LOAD,
  };
}

export function setPaymentMethodConfig(paymentConfig) {
  return {
    type: SHOPIFY_SET_PAYMENT_CONFIG,
    paymentConfig: paymentConfig
  };
}
export function loadPaymentAccountConfig() {
  return async function(dispatch) {
    try {
      const resp = await getPaymentMethodConfig();
      if (resp) {
        dispatch(setPaymentMethodConfig(resp));
      } else {
        dispatch(setPaymentMethodConfig({}))
      }
    } catch (err) {
      dispatch(failedPaymentAccountConfigLoad());
    }
  }
}

export function failedPaymentAccountConfigLoad() {
  return {
    type: SHOPIFY_FAILED_PAYMENT_LOAD
  };
}

export function setDiscountConfig(discountCodes) {
  return {
    type: SHOPIFY_SET_DISCOUNT_CONFIG,
    discountCodes: discountCodes
  };
}
export function loadDiscountConfig() {
  return async function(dispatch) {
    try {
      const resp = await getDiscountConfig();
      if (resp) {
        dispatch(setDiscountConfig(resp));
      } else {
        dispatch(setDiscountConfig({}))
      }
    } catch (err) {
      dispatch(failedDiscountConfigLoad());
    }
  }
}

export function failedDiscountConfigLoad() {
  return {
    type: SHOPIFY_FAILED_DISCOUNT_LOAD
  };
}
