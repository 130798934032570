import axios from "axios";
import querystring from "querystring";
import { Utility } from "src/utils/Utility";

const API = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // check whether request was a network failure, or unauthenticated or failed to login status
    if (
      !originalRequest._retry &&
      (!error.response ||
        error.response.status === 401 ||
        originalRequest.url.includes("/login/status"))
    ) {
      // if its refreshtoken request that failed, do not retry
      if (originalRequest.url.includes("getrefreshtoken")) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      try {
        await refreshAccessToken();
      } catch {
        return Promise.reject(error);
      }

      return API(originalRequest);
    }
    throw error;
  }
);

export function setAuthToken(token) {
  API.defaults.headers["x-access-token"] = token;
}

export async function getLoginStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_LOGIN_STATUS_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getLogout() {
  try {
    const resp = await API.get(process.env.REACT_APP_BASE_URL + "v1/iam/auth/logout2");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraLoginUserInfo(userId) {
  try {
    const resp = await API.get(process.env.REACT_APP_BASE_URL + "v1/users/" + userId);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function refreshAccessToken() {
  const requestBody = {
    refreshToken: localStorage.getItem("deskera-refresh-token"),
  };
  try {
    const resp = await API.post(process.env.REACT_APP_TOKEN_REFRESH_URL, requestBody);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setAccountConfig(config) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/deskera/accounts", config);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraRedirectUrl() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/redirectUrl");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraProductUom() {
  try {
    const resp = await API.get(process.env.REACT_APP_UOM_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraTenantDetails() {
  try {
    const resp = await API.get(process.env.REACT_APP_TENANT_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedDeskeraAccount() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/deskera/disconnect");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getCartConfig(cartType) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/" + cartType + "/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedAmazonAccount(sellingPartnerId) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/amazon/disconnect?accountId=" + sellingPartnerId
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getAmazonMappingProducts(payload) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/amazon/get-map-deskera-products",
      payload
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveAmazonMatchingProducts(payload) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/amazon/save-map-deskera-products",
      payload
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getAmazonOrders(
  limit = 10,
  page = 0,
  sortBy = [{ id: "", desc: "" }],
  query
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/amazon/orders?" +
        query +
        (!Utility.isEmpty(query) ? "&" : "") +
        "limit=" +
        limit +
        "&page=" +
        page +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    return [];
  }
}

export async function getAmazonProducts(
  limit = 10,
  page = 0,
  sortBy = [{ id: "", desc: "" }],
  query = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/amazon/products?" +
        query +
        (!Utility.isEmpty(query) ? "&" : "") +
        "limit=" +
        limit +
        "&page=" +
        page +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getAmazonCustomers(limit = 10, page = 0, sortBy = [{ id: "", desc: "" }]) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/amazon/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getAmazonAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/amazon/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getAmazonRedirectUrl() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/amazon/redirectUrl");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraAccount() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getAmazonConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/amazon/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setAmazonConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/amazon/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateAmazonData(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/amazon/migrate", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/books/accounts");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksWarehouses() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/books/warehouses");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTenantInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_TENANT_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksTaxes() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/books/taxes");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setAmazonConnection(connectionInfo) {
  let amazonConnectionUrl = new URL(process.env.REACT_APP_SERVER_URL + "/cart/amazon/oauth");

  amazonConnectionUrl.searchParams.append("code", connectionInfo.code);
  amazonConnectionUrl.searchParams.append("state", connectionInfo.state);
  amazonConnectionUrl.searchParams.append("sellingPartnerId", connectionInfo.sellingPartnerId);
  amazonConnectionUrl.searchParams.append("accountName", connectionInfo.accountName);

  try {
    const resp = await API.get(amazonConnectionUrl.toString());
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDeskeraConnection(connectionInfo) {
  let amazonConnectionUrl = new URL(process.env.REACT_APP_SERVER_URL + "/deskera/oauth");

  amazonConnectionUrl.searchParams.append("code", connectionInfo.code);
  amazonConnectionUrl.searchParams.append("state", connectionInfo.state);

  try {
    const resp = await API.get(amazonConnectionUrl.toString());
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraUserInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

/* shopify store apis */
let shopifyConfigDateParams = {
  startDate: "",
  endDate: "",
};
export async function getShopifyOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  startDate = "",
  endDate = "",
  search = "",
  queryParams = ''
) {
  if (Utility.isNotEmpty(startDate)) {
    shopifyConfigDateParams.startDate = startDate;
  }
  if (Utility.isNotEmpty(endDate)) {
    shopifyConfigDateParams.endDate = endDate;
  }
  const config = shopifyConfigDateParams;
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  const dateRange =
    config.startDate && config.endDate
      ? "&fromDate=" + config.startDate + "&toDate=" + config.endDate
      : "";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/shopify/orders?limit=" +
        limit +
        "&search=" +
        search +
        "&query=" +
        queryParams +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir +
        dateRange
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getShopifySyncContactCustomer() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shopify/sync-name-type");
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function saveShopifySyncContactCustomer(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shopify/sync-name-type",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function populateShopifyOrders(payload) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/populate/ORDERS",
      payload
    );
    return resp.data;
  } catch (err) {
    console.error(err);
  }
}

export async function populateShopifyProducts() {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/populate/PRODUCTS"
    );
    return resp.data;
  } catch (err) {
    console.error(err);
  }
}

export async function getShopifyRefunds(){
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shopify/refunds");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveShopifyRefunds(payload){
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/shopify/refunds", payload);
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getShopifyProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search = "",
  queryParams = ''
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/shopify/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&query=" +
        queryParams +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraSyncedProductsForShopify(limit = 10, page = 0, queryParams = '') {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/shopify/products/deskeraproducts?limit=" +
        limit +
        "&page=" +
        page +
        "&query=" +
        queryParams
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShopifyCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search = "",
  queryParams = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/shopify/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&search=" +
        search +
        "&query=" +
        queryParams +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShopifyInventoryTransactions(limit = 10, page = 0, status = "", queryParams= '') {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/shopify/transactions?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&query=" +
        queryParams
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function updateShopifyInventoryTransactionsValue(id, body) {
  try {
    const resp = await API.put(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/transactions/" + id,
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShopifyAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/shopify/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShopifyConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/shopify/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setShopifyConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setShopifyKeys(apiKeys) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/shopify/apikey", apiKeys);
    return resp.data;
  } catch (err) {
    console.log("Error occurred");
    throw err;
  }
}

export async function enableShopifySync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/shopify/sync/enable");
  } catch (err) {
    throw err;
  }
}

export async function disableShopifySync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/shopify/sync/disable");
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedShopifyAccount(shopUrl) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/disconnect?accountId=" + shopUrl
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShopifyData(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/shopify/migrate", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShopifyDataForCustomers(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/migrate/customers",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShopifyDataForInventoryTransactions(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/migrate/deskera-inventory",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShopifyInventoryTransactionsToDeskera(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/migrate/shopify-inventory",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShopifyDataForProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/migrate/products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function syncDeskeraProductsToShopify(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/sync-deskera-product-to-shopify",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShopifyDataForOrders(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/migrate/orders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShopifyJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/shopify/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShopifyLocationMappings() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shopify/locations");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getPaymentMethodConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shopify/payment-method-config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function savePaymentMethodNewConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shopify/payment-method-config",
      newConfig
    );
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShopifyTransactionSync() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shopify/transaction-sync");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveShopifyLocationMappings(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/shopify/locations", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveShopifyTransactionSync(body = {}) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/products/save-shopify-two-way-config",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDiscountConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shopify/discount-config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveShopifyDiscountConfigs(body = {}) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shopify/discount-config",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function disableShopifyDiscountConfigs(body = {}) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shopify/disable-discount-config",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksDiscounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_PRODUCT_URL + "/additional-charge");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function enabledNegativeInventory() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/shopify/negative-inventory");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getDeskeraProductBrif() {
  try {
    const resp = await API.get(process.env.REACT_APP_PRODUCT_URL + "/brief");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getDeskeraProductBreifSearch(config) {
  const params = new URLSearchParams();
  params.set("search", Utility.defaultIfEmpty(config?.search));
  params.set("limit", Utility.defaultIfEmpty(config?.limit));
  params.set("sortDir", Utility.defaultIfEmpty(config?.sortDir, "name"));
  params.set("sort", Utility.defaultIfEmpty(config?.sort), "ASC");
  const url = process.env.REACT_APP_PRODUCT_URL + "/brief?" + params.toString();
  try {
    const resp = await API.get(url);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateMatchingProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/get-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function saveMatchingProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shopify/save-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getSquareMappingProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SQUARE_URL + "/get-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function saveSquareMappedProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SQUARE_URL + "/save-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

/* magento store apis */

export async function getMagentoOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  startDate = "",
  endDate = "",
  search = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  const dateRange = startDate && endDate ? "&fromDate=" + startDate + "&toDate=" + endDate : "";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/magento/orders?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir +
        dateRange
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/magento/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraSyncedProductsForMagento(limit = 10, page = 0) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/magento/products/deskeraproducts?limit=" +
        limit +
        "&page=" +
        page
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateMagentoMatchingProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/get-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoCustomFields(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/magento-custom-fields",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoCustomFieldMapping(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/magento/get-cf-mapping", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoMatchingCustomFields(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/save-cf-mapping",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveDiscountCodeMapping(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/discount-config",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoDiscountCode(limit = 300, page = 0) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/magento/discount-config?sortDir=ASC&limit=" +
        limit +
        "&page=" +
        page
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getMagentoCutomerFilter() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/magento/customer-filter-config"
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getMagentoDiscountCodeSearch(search) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/magento/discount-config?search=" +
        search +
        "&sortBy=coupon_id&sortDir=ASC"
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoMatchingProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/save-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoMatchingProductsToECom(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/save-product-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoMatchingCustomersToECom(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/save-contact-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoMatchingOrdersToECom(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/save-order-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }]
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/magento/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoInventoryTransactions(limit = 10, page = 0, status = "") {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/magento/transactions?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function updateMagentoInventoryTransactionsValue(id, body) {
  try {
    const resp = await API.put(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/transactions/" + id,
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/magento/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/magento/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoStoreConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/magento/magento-stores");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setMagentoConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setMagentoKeys(apiKeys) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/magento/apikey", apiKeys);
    return resp.data;
  } catch (err) {
    console.log("Error occurred");
    throw err;
  }
}

export async function enableMagentoSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/magento/sync/enable");
  } catch (err) {
    throw err;
  }
}

export async function disableMagentoSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/magento/sync/disable");
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedMagentoAccount(shopUrl) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/disconnect?accountId=" + shopUrl
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateMagentoData(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/magento/migrate", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateMagentoDataForCustomers(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/migrate/customers",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateMagentoDataForProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/migrate/products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function syncDeskeraProductsToMagento(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/sync-deskera-product-to-shopify",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateMagentoDataForOrders(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/migrate/orders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/magento/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoLocationMappings() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/magento/locations");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoPaymentMethodConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/magento/payment-method-config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoPaymentMethodNewConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/payment-method-config",
      newConfig
    );
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoCusomterFilterConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/customer-filter-config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function disableMagentDiscountConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/magento/disable-discount-config",
      newConfig
    );
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getMagentoTransactionSync() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/magento/transaction-sync");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoLocationMappings(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/magento/locations", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveMagentoTransactionSync(body = {}) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/magento/products/save-magento-two-way-config",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function enabledNegativeInventoryForMagento() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/magento/negative-inventory");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

// Call to attachment service to save file in S3 bucket and returns relative path
export async function uploadInventoryFile(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_BASE_URL + "v1/attachment/file-upload", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

// Calls ecom backend to save relative path
export async function importRelativePath(relativePath) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL +
        "/shopify/save-product-opening?relativePath=" +
        relativePath
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function downloadSampleInventoryFile() {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shopify/get-product-opening-sample-file",
      null,
      { responseType: "blob" }
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

/* woocommerce store apis */
export async function getWoocommerceOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/woocommerce/orders?limit=" +
        limit +
        "&search=" +
        search +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getWoocommerceProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/woocommerce/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getWoocommerceCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }]
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/woocommerce/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getWoocommerceAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getWoocommerceConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setWoocommerceConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getSquareAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SQUARE_URL + "/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getSquareConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SQUARE_URL + "/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setSquareConfig(newConfig) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/config", newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getSquareCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }]
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc == "" ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SQUARE_URL +
        "/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getSquareOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc == "" ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SQUARE_URL +
        "/orders?limit=" +
        limit +
        "&search=" +
        search +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );

    return resp.data;
  } catch (err) {
    return [];
  }
}
export async function migrateSquareDataForOrders(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/migrate/orders", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getWoocommerceRedirectUrl(site, name, syncStartDate) {
  let requestUrl = new URL(process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/redirectUrl");
  requestUrl.searchParams.append("site", site);
  requestUrl.searchParams.append("name", name);
  requestUrl.searchParams.append("syncStartDate", syncStartDate);

  try {
    const resp = await API.get(requestUrl.toString());
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getSquareRedirectUrl(name, id, secret) {
  let requestUrl = new URL(process.env.REACT_APP_SQUARE_URL + "/get-redirect-url");
  requestUrl.searchParams.append("applicationId", id);
  requestUrl.searchParams.append("applicationName", name);
  requestUrl.searchParams.append("applicationSecreteKey", secret);
  try {
    const resp = await API.get(requestUrl.toString());
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateSquareDataForCustomers(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/migrate/customers", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedSquareAccount(site) {
  let requestUrl = new URL(process.env.REACT_APP_SQUARE_URL + "/disconnect");
  requestUrl.searchParams.append("accountId", site);
  try {
    const resp = await API.post(requestUrl.toString(), site);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getSquareLocations() {
  try {
    const resp = await API.get(process.env.REACT_APP_SQUARE_URL + "/location");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setSquareLocations(newConfig) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/location", newConfig);
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getDeskeraCustomField() {
  try {
    const resp = await API.get(process.env.REACT_APP_SQUARE_URL + "/get-catalog-cf-mapping");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function setSquareCustomField(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SQUARE_URL + "/save-catalog-cf-mapping",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getSquareMeasures() {
  try {
    const resp = await API.get(process.env.REACT_APP_SQUARE_URL + "/measure");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setSquareMeasures(newConfig) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/measure", newConfig);
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getSquareProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc == "" ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SQUARE_URL +
        "/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    return [];
  }
}

export async function migrateSquareProducts(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/migrate/products", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveSquareTransactionSync(body = {}) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SQUARE_URL + "/save-product-two-way-sync",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraProductsForSquare(limit = 10, page = 0, status = "") {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SQUARE_URL +
        "/deskeraproducts?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateSquareData(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/cart/shopify/migrate", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function syncDeskeraProductsToSquare(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SQUARE_URL + "/sync-deskera-product-to-square",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getSquareInventoryTransactions(limit = 10, page = 0, status = "") {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SQUARE_URL +
        "/transactions?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status
    );
    return resp.data;
  } catch (err) {
    return [];
  }
}
export async function updateSquareInventoryTransactionsValue(id, body) {
  try {
    const resp = await API.put(process.env.REACT_APP_SQUARE_URL + "/transactions/" + id, body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function migrateSquareDataForInventoryTransactions(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/square-inventory", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function migrateSquareInventoryTransactionsToDeskera(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SQUARE_URL + "/deskera-inventory", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedWoocommerceAccount(site) {
  let requestUrl = new URL(process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/disconnect");
  requestUrl.searchParams.append("accountId", site);
  try {
    const resp = await API.post(requestUrl.toString(), site);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateWoocommerceData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/migrate",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateWoocommerceDataForOrders(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/migrate/orders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateWoocommerceDataForProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/migrate/products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateWoocommerceDataForCustomers(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/migrate/customers",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getWoocommerceJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function connectFoodicsStore(path, body) {
  try {
    const resp = await API.post(path, body);
  } catch (err) {
    throw err;
  }
}

export async function getWoocomPaymentMethodConfig() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/woocommerce/payment-method-config"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveWoocomPaymentMethodNewConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/woocommerce/payment-method-config",
      newConfig
    );
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraDimensions() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/books/dimensions");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getWoocomProductAttributes() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/woocommerce/product-attributes"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveWoocomProductAttributes(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/woocommerce/product-attributes",
      newConfig
    );
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getWoocomTaxMappings() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/woocommerce/tax-mappings");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveWoocomTaxMappings(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/woocommerce/tax-mappings",
      newConfig
    );
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

// --------------------------------------------------------
//
//            /*Foodics API calls*/
//
// --------------------------------------------------------

export async function getFoodicsOrders() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/orders");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsInventory() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/inventory");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsPurchases() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/purchases");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsProducts() {
  try {
    const resp = await API.get(process.env.REACT_APP_PRODUCT_URL + "?limit=2147483647");
    return resp.data.content;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/account-config");
    if (resp.data) {
      return resp.data[0];
    } else return {};
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/foodics/account-config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsProdConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/product-config");
    if (resp.data) {
      return resp.data[0];
    } else return {};
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsOrdersBranches() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/branches");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsProdConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/foodics/product-config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsConnection(connectionInfo) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/foodics/apikey",
      connectionInfo
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function enableFoodicsSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/foodics/sync/enable");
  } catch (err) {
    throw err;
  }
}

export async function disableFoodicsSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/foodics/sync/disable");
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedFoodicsAccount(shopUrl) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/foodics/disconnect?accountId=" + shopUrl
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateFoodicsData(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/foodics/migrate", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsRedirectUrl() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/redirectUrl");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function activateFoodicsSetup() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/foodics/setup");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraSyncedProductsForWoocommerce(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }]
) {
  try {
    const id = sortBy.length > 0 ? sortBy[0].id : "";
    const desc = sortBy.length > 0 ? sortBy[0].desc : "";
    const sortDir = desc == "" ? "DESC" : "ASC";
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/woocommerce/products/get-synced-deskera-products?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function saveWoocommerceTwoWaySync(body = {}) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/woocommerce/save-product-two-way-sync",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function syncDeskeraProductsToWooCommerce(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/woocommerce/sync-deskera-product-to-woocom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function syncDeskeraInventoryTransactionToWooCommerce(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/woocommerce/migrate/deskera-inventory",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraDeskeraInventoryTransactionForWoocommerce(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }]
) {
  try {
    const id = sortBy.length > 0 ? sortBy[0].id : "";
    const desc = sortBy.length > 0 ? sortBy[0].desc : "";
    const sortDir = desc == "" ? "DESC" : "ASC";
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/woocommerce/transactions?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );

    return resp.data;
  } catch (err) {
    throw err;
  }
}
/* Ship Station store apis */

export async function getShipStationOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "orderDate", desc: "DESC" }],
  startDate = "",
  endDate = "",
  search = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  const dateRange = startDate && endDate ? "&fromDate=" + startDate + "&toDate=" + endDate : "";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/shipstation/orders?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir +
        dateRange
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationInvoices(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "invoiceDate", desc: "DESC" }],
  startDate = "",
  endDate = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  const dateRange = startDate && endDate ? "&fromDate=" + startDate + "&toDate=" + endDate : "";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/shipstation/invoices?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir +
        dateRange
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/shipstation/connections");
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/shipstation/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationConnectedStores() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/shipstation/stores?isConnected=true"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setShipStationKeys(apiKeys) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shipstation/apikey",
      apiKeys
    );
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    console.log("Error occurred");
    throw err;
  }
}

export async function enableShipStationSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/shipstation/sync/enable");
  } catch (err) {
    throw err;
  }
}

export async function disableShipStationSync() {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shipstation/sync/disable"
    );
  } catch (err) {
    throw err;
  }
}

export async function syncShipStationCustomFields() {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shipstation/sync-custom-fields"
    );
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedShipStationAccount(shopUrl) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shipstation/disconnect?accountId=" + shopUrl
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShipStationData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shipstation/migrate",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateShipStationDataForOrders(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shipstation/migrate/orders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/shipstation/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationStores() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shipstation/stores");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveShipStationStore(storeId, defaultStore) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL +
        "/shipstation/stores?storeId=" +
        storeId +
        "&defaultStore=" +
        defaultStore
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function disconnectShipStationStore(storeId) {
  try {
    const resp = await API.delete(
      process.env.REACT_APP_SERVER_URL + "/shipstation/stores?storeId=" + storeId
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function syncDocumentsToShipStation(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/sync-deskera-documents-to-shipstation",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function syncFulfillmentShipStation(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shipstation/fulfillment",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setShipStationConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/shipstation/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationStoreMapping() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shipstation/store-mapping");
    return resp.data;
  } catch (err) {
    console.error("Failed to get ShipStation store mapping");
    return null;
  }
}

export async function getShipStationStoreMappingAttributes(fieldName) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/shipstation/store-attribute-mapping?fieldName=" +
        fieldName
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to get ShipStation store mapping attributes");
    return null;
  }
}

export async function saveShipStationStoreMapping(fieldName) {
  try {
    if (!fieldName) {
      return;
    }
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shipstation/store-mapping?fieldName=" + fieldName
    );
    return resp.data;
  } catch (err) {
    throw err?.response?.data || err;
  }
}

export async function saveShipStationStoreMappingAttributes(fieldName, mappedData) {
  try {
    if (!fieldName) {
      return;
    }
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL +
        "/shipstation/store-attribute-mapping?fieldName=" +
        fieldName,
      mappedData
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getShipStationAlertSettings() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/shipstation/alert-settings");
    return resp.data;
  } catch (err) {
    console.error("Failed to get ShipStation alert settings");
    return null;
  }
}

export async function saveShipStationAlertSettings(data) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shipstation/alert-settings",
      data
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to save ShipStation alert settings");
    throw err;
  }
}

export async function getShipStationSyncReportExportSettings() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/shipstation/sync-report-export-settings"
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to get ShipStation sync report export settings");
    return null;
  }
}

export async function saveShipStationSyncReportExportSettings(data) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/shipstation/sync-report-export-settings",
      data
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to save ShipStation sync report export settings");
    throw err;
  }
}

export async function getShipStationSyncReports(
  fromDate,
  toDate,
  syncTo,
  limit,
  page,
  syncType = "",
  syncStatus = "",
  remarks = "",
  sortBy = "",
  dir = ""
) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/shipstation/sync-report?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&syncTo=" +
        syncTo +
        "&syncType=" +
        syncType +
        "&syncStatus=" +
        syncStatus +
        "&remarks=" +
        remarks +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&sortBy=" +
        sortBy +
        "&sortDir=" +
        dir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

/* bigcommerce store apis */
export async function setBigcommerceKeys(apiKeys) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/apikey",
      apiKeys
    );
    return resp.data;
  } catch (err) {
    console.log("Error occurred");
    throw err;
  }
}

export async function getBigcommerceAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigcommerceStoreConfig() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/bigcommerce-stores"
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedBigcommerceAccount(shopUrl) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/disconnect?accountId=" + shopUrl
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setBigcommerceConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setBigcommerceRefundConfig(newConfig) {
  try {
    const resp = await API.patch(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/update-refund-methods",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err?.response?.data;
  }
}

export async function setBigcommerceDataSyncConfig(newConfig) {
  try {
    const resp = await API.patch(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/update-data-sync-settings",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err?.response?.data;
  }
}

export async function getBigcommerceConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function enableBigcommerceSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/sync/enable");
  } catch (err) {
    throw err;
  }
}

export async function disableBigcommerceSync() {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/sync/disable"
    );
  } catch (err) {
    throw err;
  }
}

export async function migrateBigcommerceData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/migrate",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateBigcommerceDataForProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/migrate/products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigcommerceJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigcommerceProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "id", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "id";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/bigcommerce/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    console.log("getBigcommerceProducts(): ", resp);
    return resp.data;
  } catch (err) {
    console.log("error in getBigcommerceProducts():", err);
    throw err;
  }
}

export async function migrateBigcommerceDataForOrders(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/migrate/orders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigcommerceOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "orderDate", desc: "DESC" }],
  search = ""
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/bigcommerce/orders?limit=" +
        limit +
        "&search=" +
        search +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateBigcommerceDataForCustomers(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/migrate/customers",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigcommerceCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }]
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/bigcommerce/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveBigCommerceMatchingProductsToECom(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/save-product-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveBigCommerceMatchingCustomersToECom(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/save-contact-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveBigCommerceMatchingOrdersToECom(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/save-order-ecom",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}



export async function saveBigCommerceMatchingProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/save-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateBigCommerceMatchingProducts(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/bigcommerce/get-map-deskera-products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigCommerceStoreMappingAttributes(fieldName) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/bigcommerce/store-attribute-mapping?fieldName=" +
        fieldName
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to get Bigcommerce store mapping attributes");
    return null;
  }
}

export async function saveBigCommerceStoreMappingAttributes(fieldName, mappedData) {
  try {
    if (!fieldName) {
      return;
    }
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL +
        "/bigcommerce/store-attribute-mapping?fieldName=" +
        fieldName,
      mappedData
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigCommerceStoreWarehouseMapping() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/store-warehouse-mapping"
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to get Bigcommerce store warehouse mapping");
    return null;
  }
}

export async function saveBigCommerceStoreWarehouseMapping(mappedData) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/store-warehouse-mapping",
      mappedData
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigCommerceStoreOrderSuffixMapping() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/order-suffix-mapping"
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to get Bigcommerce store warehouse mapping");
    return null;
  }
}

export async function saveBigCommerceStoreOrderSuffixMapping(mappedData) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/order-suffix-mapping",
      mappedData
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBigCommerceAlertSettings() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/bigcommerce/alert-settings");
    return resp.data;
  } catch (err) {
    console.error("Failed to get Bigcommerce alert settings");
    return null;
  }
}

export async function saveBigCommerceAlertSettings(data) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/alert-settings",
      data
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to save BigCommerce alert settings");
    throw err;
  }
}

export async function getBigCommerceSyncReportExportSettings() {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/sync-report-export-settings"
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to get Bigcommerce sync report export settings");
    return null;
  }
}

export async function saveBigCommerceSyncReportExportSettings(data) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/bigcommerce/sync-report-export-settings",
      data
    );
    return resp.data;
  } catch (err) {
    console.error("Failed to save BigCommerce sync report export settings");
    throw err;
  }
}

export async function getBigCommerceSyncReports(
  fromDate,
  toDate,
  syncTo,
  limit,
  page,
  syncType = "",
  syncStatus = "",
  remarks = "",
  sortBy = "",
  dir = ""
) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/bigcommerce/sync-report?fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&syncTo=" +
        syncTo +
        "&syncType=" +
        syncType +
        "&syncStatus=" +
        syncStatus +
        "&remarks=" +
        remarks +
        "&limit=" +
        limit +
        "&page=" +
        page +
        "&sortBy=" +
        sortBy +
        "&sortDir=" +
        dir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function exportSyncReports(payload, format) {
  try {
    const resp = await API.get(
      process.env.REACT_APP_BASE_URL + "v1/exim/export?format=" + format + "&" + payload,
      {
        responseType: "blob",
      }
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

/* Tally apis */
export async function getTallyConnection() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/tally/connection");
    return resp.data;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export async function saveTallyConnection(payload) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/tally/connection", payload);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function setTallyConfig(newConfig) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/tally/config", newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/tally/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyCreditNotes(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/credit-note?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyCreditNotesData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/credit-note",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyDebitNotes(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/debit-note?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyDebitNotesData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/debit-note",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyJournalVoucher(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/journal?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyJournalData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/journal-entry",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyProductsData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyCustomersData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/customers",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getTallyInvoices(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/invoices?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyInvoicesData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/invoices",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTallyBills(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/tally/bills?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateTallyBillsData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/tally/migrate/bills",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

// BRIGHTPEARL

export async function connectToBrightPearl(name, accountCode) {
  try {
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/cart/brightpearl/redirectUrl?name=" +
      name +
      "&accountCode=" +
      accountCode;
    const resp = await API.get(url);
    const redirectUrl = resp.data.url;
    
    console.log('REDIRECT URL', redirectUrl);
    window.location.href = redirectUrl;

  } catch (err) {
    console.log(err);
  }
}

export async function getBrightPearlConnections() {
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/cart/brightpearl/connections"
    const resp = await API.get(url);
    return resp.data;  
}

export async function disconnectBrightPearl(accountId) {
  try {
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/cart/brightpearl/disconnect?accountId=" +
      accountId;
    const resp = await API.post(url);
    return resp.data;
  } catch (err) {
    console.log(err);
  }
}


export async function getBrightPearlConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setBrightPearlConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getBrightPearlProducts(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/brightpearl/products?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBrightPearlInventoryTransactions(
  limit = 10,
  page = 0,
  sortBy,
  search = "",
  status = "",
  queryParams = ""
) {
  try {
    let url =
      process.env.REACT_APP_SERVER_URL +
      "/cart/brightpearl/transactions?limit=" +
      limit +
      "&page=" +
      page +
      "&status=" +
      status +
      "&query=" +
      queryParams;
    if (Utility.isNotEmpty(sortBy)) {
      const sort = sortBy[0];
      url += "&sortBy=" + sort.id + "&sortDir=" + (sort.desc ? "DESC" : "ASC");
    }
    if (Utility.isNotEmpty(search)) {
      url += "&search=" + search;
    }
    const resp = await API.get(url);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function updateBrightPearlInventoryTransactionsValue(id, body) {
  try {
    const resp = await API.put(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/transactions/" + id,
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateBrightPearlData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/migrate",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function migrateBrightPearlProductsData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/migrate/products",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function migrateBrightPearlDataForInventoryTransactions(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/migrate/deskera-inventory",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateBrightPearlInventoryTransactionsToDeskera(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/migrate/inventory",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBrightPearlCustomers(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  search
) {
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/brightpearl/customers?limit=" +
        limit +
        "&page=" +
        page +
        "&search=" +
        search +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function migrateBrightPearlCustomersData(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/migrate/customers",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getBrightPearlJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getBrightPearlTransactionSync() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/brightpearl/transaction-sync");
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getBrightPearlWarehouseMappings(fetchNewWarehouses) {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/brightpearl/get-wh-mappings?fetchNewWarehouses=" + fetchNewWarehouses);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function saveBrightPearlWarehouseMappings(accountCode, body) {
  try {
    const resp = await API.put(
      process.env.REACT_APP_SERVER_URL + "/brightpearl/save-wh-mappings?accountCode=" + accountCode,
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}



let brightPearlConfigDateParams = {
  startDate: "",
  endDate: "",
};
export async function getBrightPearlOrders(
  limit = 10,
  page = 0,
  status = "",
  sortBy = [{ id: "", desc: "" }],
  startDate = "",
  endDate = "",
  search = "",
  queryParams = ''
) {
  if (Utility.isNotEmpty(startDate)) {
    brightPearlConfigDateParams.startDate = startDate;
  }
  if (Utility.isNotEmpty(endDate)) {
    brightPearlConfigDateParams.endDate = endDate;
  }
  const config = brightPearlConfigDateParams;
  const id = sortBy.length > 0 ? sortBy[0].id : "";
  const desc = sortBy.length > 0 ? sortBy[0].desc : "";
  const sortDir = desc ? "DESC" : "ASC";
  const dateRange =
    config.startDate && config.endDate
      ? "&fromDate=" + config.startDate + "&toDate=" + config.endDate
      : "";
  try {
    const resp = await API.get(
      process.env.REACT_APP_SERVER_URL +
        "/cart/brightpearl/orders?limit=" +
        limit +
        "&search=" +
        search +
        "&query=" +
        queryParams +
        "&page=" +
        page +
        "&status=" +
        status +
        "&sortBy=" +
        id +
        "&sortDir=" +
        sortDir +
        dateRange
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}




export async function migrateBrightPearlForOrders(body) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/brightpearl/migrate/orders",
      body
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}