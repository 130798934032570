import { DKButton, DKCheckMark, DKIcon, DKIcons, DKLabel, DKListPicker2 } from "deskera-ui-library";
import React, { useMemo } from "react";
import CloseIcon from "src/assets/Close";

const SELECT_ALL_STORES_ID = "Select All Stores";

const SyncByStores = (props) => {
  const [filteredStores, setFilteredStores] = React.useState(props?.storeConfig || []);
  const [storeIdIndexMap, selectedIndexes] = useMemo(() => {
    const prevSelectedStores = props?.selectedStores || []; // ['url', 'url', 'url']
    const storeIdIndexMap = {};
    const selectedIndexes = [];
    filteredStores.forEach((store, index) => {
      storeIdIndexMap[store.id] = index;
      if (prevSelectedStores.includes(store.id)) {
        selectedIndexes.push(index);
      }
    });
    return [storeIdIndexMap, selectedIndexes];
  }, [filteredStores, props?.selectedStores]);

  React.useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        props?.onClose();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [props?.onClose]);

  console.log("filtered-storeConfig", filteredStores);
  console.log("selectedIndex", selectedIndexes);
  console.log("storeIdIndexMap", storeIdIndexMap);

  return (
    <div className="DKLiskPicker2 relative position-relative bg-white border-m rounded-md shadow-md">
      <>
        <DKListPicker2
          data={filteredStores}
          className={"position-absolute z-index-3 bg-white border-m px-1"}
          style={{
            minWidth: 280,
          }}
          searchableKey="name"
          renderer={(index, obj) => {
            const value = obj;
            return (
              <>
                <div className="flex">
                  {value.name}
                  {}
                  <br />
                  <span style={{ fontSize: "10px", color: "gray", marginTop: "3px" }}>
                    {value?.id}
                  </span>
                </div>
              </>
            );
          }}
          checkMarkColor={"bg-blue"}
          selectedIndexes={selectedIndexes}
          onSelect={(index, obj) => {
            const allStoreIds = props?.storeConfig.map((store) => store.id);
            const selectedStores = [...(props?.selectedStores || [])];
            const storeId = obj.id;

            if (storeId === SELECT_ALL_STORES_ID) {
              if (selectedStores.includes(SELECT_ALL_STORES_ID)) {
                props?.onSelectStore([]);
              } else {
                props?.onSelectStore(allStoreIds);
              }
            } else {
              let newSelectedStores;
              if (selectedStores.includes(storeId)) {
                newSelectedStores = selectedStores.filter((id) => id !== storeId);
              } else {
                newSelectedStores = [...selectedStores, storeId];
              }
              const filteredStoreIds = allStoreIds.filter((id) => id !== SELECT_ALL_STORES_ID);
              const filteredSelectedStores = newSelectedStores.filter((id) => id !== SELECT_ALL_STORES_ID);
              if (filteredSelectedStores.length === filteredStoreIds.length) {
                if (!newSelectedStores.includes(SELECT_ALL_STORES_ID)) {
                  newSelectedStores.push(SELECT_ALL_STORES_ID);
                }
              } else {
                newSelectedStores = newSelectedStores.filter((id) => id !== SELECT_ALL_STORES_ID);
              }
              props?.onSelectStore(newSelectedStores);
            }
          }}
          onClear={() => {
            props?.onSelectStore([]);
          }}
          multiSelect={true}
          onClose={() =>
            setTimeout(() => {
              props?.onClose();
            }, 100)
          }
          allowSearch={true}
          onSearchTextChange={(text) => {
            const fs = props?.storeConfig.filter((store) =>
              store.name.toLowerCase().includes(text.toLowerCase())
            );
            setFilteredStores(fs);
          }}
          searchApiConfig={{}}
          button={{
            icon: DKIcon.ic_reload,
            title: "Sync",
            onClick: () => {
              props?.onSync();
              props?.onClose();
            },
            style : {
              marginRight: "50px",
              marginLeft: "50px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            className: "bg-success text-white",
          }}
        />
      </>
    </div>
  );
};

export default SyncByStores;
