
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import {
  disableMagentoSync,
  enableMagentoSync,
  getDeskeraBooksAccounts,
  getDeskeraBooksTaxes,
  getDeskeraBooksWarehouses,
  getDeskeraDimensions,
  getDeskeraProductUom,
  getDeskeraRedirectUrl,
  getDeskeraUserInfo,
  saveMagentoPaymentMethodNewConfig,
  setDisconnectedDeskeraAccount,
  setDisconnectedMagentoAccount,
  setMagentoConfig,
  setMagentoKeys,
  getMagentoStoreConfig,
  getDeskeraBooksDiscounts,
  disableMagentDiscountConfig,
  saveMagentoCusomterFilterConfig
} from "src/api";
import CheckIcon from "src/assets/Check";
import CloseIcon from "src/assets/Close";
import DeleteLogo from "src/assets/Delete";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import PlusLogo from "src/assets/Plus";
import SyncIcon from "src/assets/Sync";
import SyncDisableIcon from "src/assets/SyncDisable";
import Alert from "src/components/Alert";
import { setDeskeraInfo } from "src/redux/actions/auth";
import {
  loadAccounts,
  loadConfig,
  loadPaymentAccountConfig,
  setConfig,
  setPaymentMethodConfig,
} from "src/redux/actions/magento";
import { addToast } from "src/redux/actions/toasts";
import customSelectTheme from "src/utils/selectTheme";
import { showAlert } from "deskera-ui-library";
import CustomeFieldMapping from "./CustomFieldMapping";
import DiscountMapping from "./DiscountMapping";
import {
  loadCustomerFilterConfig,
  loadMagentoCustomfields,
  loadMagentoCustomfieldMapping,
  loadDeskeraCustomfields,
  loadMagentoDiscountCode,
} from "src/redux/actions/magento";
import CustomerFilterMapping from "src/pages/Magento/CustomerFilterMapping";
function Config() {
  const [disconnectWarningMagento, setDisconnectWarningMagento] = useState(false);
  const [disconnectButtonMagento, setDisconnectButtonMagento] = useState(true);
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const accounts = useSelector((state) => state.magento.accounts);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [deskeraDimensions, setDeskeraDimensions] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const config = useSelector((state) => state.magento.config);
  const [formErrors, setFormErrors] = useState({});
  const [configOpen, setConfigOpen] = useState(false);
  const [site, setSite] = useState("");
  const [syncStartDate, setSyncStartDate] = useState(null);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [name, setName] = useState("");
  const [magentoErrors, setMagentoErrors] = useState({});
  const [accountsConfigured, setAccountsConfigured] = useState(false);
  const [saveShopLoading, setSaveShopLoading] = useState(false);
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const [saveTransactionSyncLoading, setSaveTransactionSyncLoading] = useState(false);
  const [mapDiscountEnabled, setMapDiscountEnabled] = useState(config.data.mapDiscountEnabled);
  const [isClicked, setIsClicked] = useState(false);
  const [saveDiscountConfigLoading, setSaveDiscountConfigLoading] = useState(false);
  const [discountMapping,setDiscountMappingOptions] = useState([]);
  const [configDiscountMappings, setConfigDiscountMappings] = useState([]);
  const [syncInOutTransactions, setSyncInOutTransactions] = useState(
    config.data.syncInOutTransactions
  );
  const [autoSyncInOutTransactions, setAutoSyncInOutTransactions] = useState(
    config.data.autoSyncInOutTransactions
  );
  const [syncFromAllWarehouses, setSyncFromAllWarehouses] = useState(
    config.data.syncFromAllWarehouses
  );
  const useDocumentCodeFromMagentoForOrders = [
    { value: true, label: "YES" },
    { value: false, label: "NO" },
  ];
  const YesOrNo = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ];
  
  const useDocumentCodeFromMagentoForProducts = [
    { value: "PRODUCT_ID", label: "Use Product ID from Magento" },
    { value: "SKU", label: "Use SKU from Magento" },
    { value: "FALSE", label: "Use Default Deskera ID" },
  ];
  const productType = [
    { value: "TRACKED", label: "Tracked (Goods)" },
    { value: "NON_TRACKED", label: "Non-Tracked (Services)" },
    { value: "MAGENTO_TYPE", label: "Magento Type" },
  ];
  const [deskeraTaxes, setDeskeraTaxes] = useState([]);
  const [mConnectLoading, setMConnectLoading] = useState(false);
  const [configPaymentAccountMappings, setConfigPaymentAccountMappings] = useState([]);
  const [configTaxMappings, setConfigTaxMappings] = useState([]);
  const paymentConfig = useSelector((state) => state.magento.paymentConfig);
  const taxMapping = useSelector((state) => state.magento.taxMapping);
  const productAttributes = useSelector((state) => state.magento.productAttributes);
  const [configProductAttributeMappings, setConfigProductAttributeMappings] = useState([]);
  const [saveProductAttributeConfigLoading, setsaveProductAttributeConfigLoading] = useState(false);
  const [paymentAccountMappingConfigured, setpaymentAccountMappingConfigured] = useState(false);
  const [taxMappingConfigured, setTaxMappingConfigured] = useState(false);
  const [savePaymentAccountConfigLoading, setSavePaymentAccountConfigLoading] = useState(false);
  const [saveTaxMappingConfigLoading, setSaveTaxMappingConfigLoading] = useState(false);
  const userInfo = useSelector((state) => state.auth.user);
  const [configStoreValue , setConfigStoreValue] = useState();
  const [configStoreOptions, setConfigStoreOptions] = useState([])
  const [configStoreMappings, setConfigStoreMappings] = useState([]);
  const [magentoStoreState ,setMagentoStoreState ] = useState(false);
  const useBooksTaxRatesOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const [customField ,setCustomField] =useState('PRODUCT')
  const[customFieldState, setCustomFieldState] =useState(false);
  const[discountCodeState, setDiscountCodeState] =useState(false);
  const [deskeraDiscountMapping,setDeskeraDiscountMapping] = useState([]);
  const customFieldOption =[{label:'Product' ,value :'PRODUCT'},{label : 'Customer', value :'CONTACT'}]
  const [customerFilterOption, setCustomerFilterOption] = useState([{label:'All' ,value :'ALL'},{label : 'Custom', value :'CUSTOM'}]);
  const [customerFilter, setCustomerFilter] = useState();
  const[filterButton , setFilterButton] =useState(false);
  const [customerFilterValue, setCustomerFilterValue] = useState(null);
  const [customerFilterPayload, setCustomerFilterPayload] = useState(null);
  const [isArray, setIsArray] = useState(false);
  const customerFieldData = useSelector((state) => state.magento.magentoCusomterFilter);
  const[customerFiterState, setCustomerFilterState] =useState(false);
  const configAccounts = [
    {
      name: "Contact Payable Account Code",
      accessor: "contactPayableAccountCode",
      options: deskeraAccounts,
    },
    {
      accessor: "contactReceivableAccountCode",
      name: "Contact Receivable Account Code",
      options: deskeraAccounts,
    },
    {
      accessor: "paymentDepositAccountCode",
      name: "Payment Deposit Account Code",
      options: deskeraAccounts,
    },  
    {
      accessor: "productSalesAccountCode",
      name: "Product Sales Account Code",
      options: deskeraAccounts,
    },
    {
      accessor: "productPurchaseAccountCode",
      name: "Product Purchase Account Code",
      options: deskeraAccounts,
    },
    {
      accessor: "productSalesReturnAccountCode",
      name: "Product Sales Return Account Code",
      options: deskeraAccounts,
    },
    {
      accessor: "productPurchaseReturnAccountCode",
      name: "Product Purchase Return Account Code",
      options: deskeraAccounts,
    },
    {
      accessor: "productSalesTaxCode",
      name: "Product Sales Tax Code",
      options: deskeraTaxes,
    },
    {
      accessor: "zeroRatedTaxCode",
      name: "Zero Rated Tax Code",
      options: deskeraTaxes,
    },
    {
      accessor: "productCostOfGoodsSoldAccountCode",
      name: "Product Cost of Goods Sold Account Code",
      options: deskeraAccounts,
    },
    {
      accessor: "productInventoryAccountCode",
      name: "Product Inventory Account Code",
      options: deskeraAccounts,
    },
    ,
    {
      accessor: "productStockAdjustmentAccountCode",
      name: "Product Stock Adjustment Account Code",
      options: deskeraAccounts,
    },
    {
      accessor: "productWarehouseCode",
      name: "Product Warehouse Code",
      options: deskeraWarehouses,
    },
    {
      accessor: "productUom",
      name: "Product UOM",
      options: deskeraProductUom,
    },
    {
      name: "Books Document Type For Magento Orders",
      accessor: "booksDocumentTypeForMagentoOrders",
      options:userInfo?.additionalSettings?.ENABLE_SO  ? [
        {
          value: "SALES_ORDER",
          label: "Sales Order",
        },
        {
          value: "SALES_INVOICE",
          label: "Sales Invoice",
        },
      ] :[
       
        {
          value: "SALES_INVOICE",
          label: "Sales Invoice",
        },
      ],
    },
    {
      name: "Magento Store Configuration",
      accessor: "magentoId",
      options :configStoreOptions
    },
  ];
  
  async function getBooksTaxes() {
    try {
      const taxes = await getDeskeraBooksTaxes();
      const taxesOptions = [];
      taxes.forEach((tax) => {
        taxesOptions.push({ value: tax.code, label: tax.name });
      });
      setDeskeraTaxes(taxesOptions);
    } catch {
      setError("Failed to load Deskera Books Taxes");
    }
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      accounts.forEach((account) => {
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }
  async function getMagentoStore() {
    try {
    const stores = await getMagentoStoreConfig();
      
      const storeOptions = [];
      stores.forEach((store) => {
        if (store.id === 0){
          storeOptions.push({ value: store.id, label: "All Stores" });
        }
        else{
          storeOptions.push({ value: store.id, label: store.name });
        }
      });
      setConfigStoreOptions(storeOptions);
      setConfigStoreMappings(stores)
      
      
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function getBooksDimensions() {
    try {
      const accounts = await getDeskeraDimensions();
      const dimensionOptions = [];
      accounts.forEach((account) => {
        dimensionOptions.push({ value: account.code, label: account.label });
      });
      setDeskeraDimensions(dimensionOptions);
    } catch {
      setError("Failed to load Deskera Dimensions");
    }
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadConfig());
    getBooksAccounts();
    getBooksDimensions();
    getBooksWarehouses();
    getBooksProductUom();
    getBooksTaxes();
    getMagentoStore();
    dispatch(loadDeskeraCustomfields());
    dispatch(loadMagentoCustomfields(['PRODUCT','CONTACT']));
    dispatch(loadMagentoDiscountCode());
    getDeskeraDiscounts();
    dispatch(loadCustomerFilterConfig());
    // dispatch(loadTaxMapping());
    // dispatch(loadProuductAttributes());
  }, []);

  useEffect(() => {
    handleConfigLoad();
   
  }, [config]);

  useEffect(() => setSyncStartDate(userInfo?.bookBeginningStartDate), [userInfo]);

  function handleConfigLoad() {
       
    if(config.data.discountCodes !==undefined){
      setConfigDiscountMappings(config.data.discountCodes)
    }
    setAccountsConfigured(true);
          if(config.data.magentoStore ===undefined)
          {
                setMagentoStoreState(true)
          }
          else{
            setMagentoStoreState(false)
          }

    if (config?.data?.mapDiscountEnabled === undefined) {
      setMapDiscountEnabled(false)
    } else {
      setMapDiscountEnabled(config.data.mapDiscountEnabled)
    }

    configAccounts.forEach((configAccount) => {
      if (!config.data[configAccount.accessor]) {
        setAccountsConfigured(false);
        return;
      }
    });

    if(config?.data?.magentoCustomerFilter !== undefined){
      
    const currentFilter =  config?.data?.magentoCustomerFilter.filter((item)=>item.field ==='ALL');

    if(currentFilter.length>0){
      setCustomerFilter({label:'All' ,value :'ALL'});
    }
    else{
      setCustomerFilter({label:'Custom' ,value :'CUSTOM'});
    }

    }
    else{
      setCustomerFilter({label:'All' ,value :'ALL'});
    }
  }
// useEffect(()=>{
//   let  magentoStoreValue = 'default'

//   setMagentoStoreState(config?.data?.magentoStore)
//   if(config?.data?.magentoStore){
//     magentoStoreValue = config?.data?.magentoStore[0].id
//   }
//   dispatch(setConfig({ ...config.data, magentoStore: magentoStoreValue }))
// },[])
  useEffect(() => {
    initialiseConfigPaymentAccountMappings();
  }, [paymentConfig, deskeraAccounts]);
  function initialiseConfigPaymentAccountMappings() {
    const configPaymentAccountMapping = [];

    for (let i = 0; i < paymentConfig.data.length; i++) {
      let payment = paymentConfig.data[i];
      let Name = payment.gateway_name;
      let options = deskeraAccounts;
      let configPayment = {
        gateway_name: Name,
        account_code: payment.account_code,
        options: options,
      };
      configPaymentAccountMapping.push(configPayment);
    }
    setConfigPaymentAccountMappings(configPaymentAccountMapping);
  }
  useEffect(() => {
    initialiseConfigTaxMappings();
  }, [taxMapping, deskeraTaxes]);
  function initialiseConfigTaxMappings() {
    const configTaxMapping = [];

    for (let i = 0; i < taxMapping?.data.length; i++) {
      let taxMap = taxMapping.data[i];
      let options = deskeraTaxes;
      let configTax = {
        class: taxMap.class,
        display:
          taxMap.state +
          "-" +
          taxMap.name +
          "(Rate:" +
          taxMap.rate +
          ")(Class:" +
          taxMap.class +
          ")",
        country: taxMap.country,
        id: taxMap.id,
        name: taxMap.name,
        rate: taxMap.rate,
        state: taxMap.state,
        options: options,
        deskeraTaxCode: taxMap.deskeraTaxCode,
        deskeraTaxName: taxMap.deskeraTaxName,
      };
      configTaxMapping.push(configTax);
    }
    setConfigTaxMappings(configTaxMapping);
  }

  async function getDeskeraDiscounts() {
    try {
      const discounts = await getDeskeraBooksDiscounts();
      const discountMapping = [];
      discounts.forEach((additionalCharge) => {
        if (additionalCharge.isDiscount !== undefined && additionalCharge.isDiscount){
          discountMapping.push({ id: additionalCharge.id, code: additionalCharge.expenseAccountCode, label: additionalCharge.name });
        }
      });
      setDeskeraDiscountMapping(discountMapping);
    } catch {
      setError("Failed to load Deskera Books Discounts");
    }
  }

  function setPaymentAccount(e, i) {
    const payments = paymentConfig.data;
    const conf = payments.splice(i, 1)[0];

    conf.account_code = e.value;
    conf.account_name = e.label;
    payments.splice(i, 0, conf);

    dispatch(setPaymentMethodConfig(payments));
  }

  function setTaxMappingOnSelect(e, i) {
    const mapping = taxMapping.data;
    const conf = mapping.splice(i, 1)[0];

    conf.deskeraTaxCode = e.value;
    conf.deskeraTaxName = e.label;
    mapping.splice(i, 0, conf);

    // dispatch(setTaxMapping(mapping));
  }
  async function savePaymentAccountConfig(e) {
    e.preventDefault();
    setSavePaymentAccountConfigLoading(true);
    try {
      const configResp = await saveMagentoPaymentMethodNewConfig(paymentConfig.data);
      dispatch(setPaymentMethodConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Payment Account updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSavePaymentAccountConfigLoading(false);
  }
  async function saveTaxMappingConfig(e) {
    e.preventDefault();
    setSaveTaxMappingConfigLoading(true);
    try {
      // const configResp = await saveWoocomTaxMappings(taxMapping.data);
      // dispatch(setTaxMapping(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Tax mappings updated successfully.",
        })
      );
    } catch {
      setError("Failed to save tax mappings");
    }
    setSaveTaxMappingConfigLoading(false);
    // dispatch(loadTaxMapping());
  }

  useEffect(() => {
    initialiseConfigProductAttributeMappings();
  }, [productAttributes, deskeraDimensions]);
  function initialiseConfigProductAttributeMappings() {
    const configProductAttributeMapping = [];

    for (let i = 0; i < productAttributes?.data.length; i++) {
      let attribute = productAttributes.data[i];
      let options = deskeraDimensions;
      let configAttribute = {
        id: attribute.id,
        name: attribute.name,
        deskeraCode: attribute.deskeraCode,
        deskeraLabel: attribute.deskeraLabel,
        options: options,
      };
      configProductAttributeMapping.push(configAttribute);
    }
    setConfigProductAttributeMappings(configProductAttributeMapping);
  }

  function setProductAttribute(e, i) {
    const attributes = productAttributes.data;
    const conf = attributes.splice(i, 1)[0];

    conf.deskeraCode = e.value;
    conf.deskeraLabel = e.label;
    attributes.splice(i, 0, conf);

    // dispatch(setProductAttributes(attributes));
  }
  async function saveProductAttribute(e) {
    e.preventDefault();
    setsaveProductAttributeConfigLoading(true);
    try {
      // const configResp = await saveWoocomProductAttributes(productAttributes.data);
      // dispatch(setProductAttributes(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Product attribute mapping updated successfully.",
        })
      );
    } catch {
      setError("Failed to set product attributes");
    }
    setsaveProductAttributeConfigLoading(false);
    // dispatch(loadProuductAttributes());
  }

  async function handleAddMagentoAccount(e) {
    e.preventDefault();

    if (!name || name === "") {
      setMagentoErrors({ name: "Enter a Magento Store name" });
      return;
    }
    if (!site || site === "") {
      setMagentoErrors({ site: "Enter a Magento Store URL" });
      return;
    }

    if (!userName) {
      setMagentoErrors({ userName: "Enter a Magento User Name" });
      return;
    }
    if (!userPassword) {
      setMagentoErrors({ userPassword: "Enter a Magento User Password" });
      return;
    }

    setMConnectLoading(true);
    try {
      const value = {
        password: userPassword,
        storeName: name,
        storeUrl: site,
        username: userName,
      };

      const magentoConnectUrl = await setMagentoKeys(value);

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Magento account connected successfully.",
        })
      );
      dispatch(loadAccounts());
      getMagentoStore();
      setConfigOpen(false);
      dispatch(loadMagentoCustomfields(['PRODUCT','CONTACT']));
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: err.response.data,
          })
        );
        setMConnectLoading(false);
      } else {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: "Failed to add Magento Store",
          })
        );
        setMConnectLoading(false);
      }
    }
    setMConnectLoading(false);
  }

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectMagentoAccount(e, accountId) {
    e.preventDefault();
    try {
      await setDisconnectedMagentoAccount(accountId);
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Magento account");
    }
  }

  console.log("config", config)
  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    setSaveSyncLoading(true);
    try {
      // const configResp = await setMagentoConfig(newConfig);
      if (syncStatus) {
        if(magentoStoreState){
          showAlert("Alert", "Please Configure Magneto Store , before enabling the data sync");
          setSyncButton(true);
          setSyncWarning(false);
            setSaveSyncLoading(false);
          return
        }
        if (
          config?.data.magentoCustomerFilter === undefined ||
          config?.data?.magentoCustomerFilter === null
        ) {
          showAlert("Alert", "Please Configure Customer Filter , before enabling the data sync");
          setSyncButton(true);
          setSyncWarning(false);
          setSaveSyncLoading(false);
          return;
        }
        await enableMagentoSync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been Enabled.",
          })
        );
        dispatch(setConfig({ ...config.data, syncEnabled: syncStatus, disableSyncReason : "" }))
      } else {
        await disableMagentoSync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been disabled.",
          })
        );
       dispatch(setConfig({ ...config.data, syncEnabled: syncStatus }))
      }

      setSyncButton(true);
      setSyncWarning(false);
    } catch (err) {
      setError("Failed to set config");
    }
    setSaveSyncLoading(false);
  }

  function validateAndSaveAccountConfigs(e) {
    let magnetoPayload = configStoreMappings.filter((store) => {
      return store.id === config?.data?.magentoId;
    });

    if (magnetoPayload.length <= 0) {
      showAlert(
        "Alert",
        "Please select the Magento Store that you want to configure "
      );
      return;
    } else if (
      (magnetoPayload.length > 0 &&
        magnetoPayload[0].id === 0 &&
        config?.data?.magentoStore === undefined) ||
      (config?.data?.magentoStore !== undefined &&
        magnetoPayload[0].id === 0 &&
        config?.data?.magentoStore[0].id !== 0)
    ) {
      showAlert(
        "Alert",
        "Please note, once you have selected All Stores, this setting cannot be changed. Do you wish to continue?",
        [
          {
            title: "Continue",
            className: "bg-blue mr-r text-white",
            onClick: () => {
              saveAccountConfigs(e);
            },
          },
          {
            title: "Cancel",
            className: "bg-gray text-white",
            onClick: () => {},
          },
        ]
      );
      return;
    } else if (
      magnetoPayload.length > 0 &&
      magnetoPayload[0].id !== 0 &&
      config?.data?.magentoStore !== undefined &&
      magnetoPayload[0].id !== config?.data?.magentoStore[0]?.id
    ) {
      showAlert("Alert", "You cannot change the  already Configured Store !! ");
      return;
    } else {
      saveAccountConfigs(e);
      return;
    }
  }

  async function saveAccountConfigs(e) {
    let magnetoPayload = configStoreMappings.filter((store)=>{return store.id===config.data.magentoId}); 
    e.preventDefault();
    setSaveConfigLoading(true);
    const payload = {...config.data,'useBooksTaxRates' : false,'magentoStore':magnetoPayload};
    try {
      let  configResp = await setMagentoConfig(payload);
      let magentoStoreId = 0
      if (configResp && configResp.magentoStore && configResp.magentoStore.length > 0) {

        magentoStoreId = configResp.magentoStore[0].id
        configResp = { ...configResp, magentoId: magentoStoreId }
      }
      else if (configResp) {
        configResp = { ...configResp, magentoId: magentoStoreId }
      }
      dispatch(setConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Accounts config updated successfully.",
        })
      );
    } catch(err) {
      setError("Failed to set config",err);
    }
    setSaveConfigLoading(false);
  }

  async function saveTransactionSync(e) {
    e.preventDefault();
    setSaveTransactionSyncLoading(true);
    const body = {
      twoWaySync: config.data.productTwoWaySyncEnabled
        ? config.data.productTwoWaySyncEnabled
        : false,
      syncInOutTransactions: config.data.syncInOutTransactions
        ? config.data.syncInOutTransactions
        : false,
      autoSyncInOutTransactions: config.data.autoSyncInOutTransactions
        ? config.data.autoSyncInOutTransactions
        : false,
      syncFromAllWarehouses: config.data.syncFromAllWarehouses
        ? config.data.syncFromAllWarehouses
        : false,
    };
    try {
      // const configResp = await saveWoocommerceTwoWaySync(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync setting updated successfully.",
        })
      );
    } catch {
      setError("Failed to save Transaction sync setting.");
    }

    const newConfig = { ...config.data };

    try {
      const resp = await setMagentoConfig(newConfig);
      const configRes = resp.config;
      configRes.syncEnabled = resp.syncEnabled;
      configRes.productTwoWaySyncEnabled = resp.productTwoWaySyncEnabled;
      configRes.syncInOutTransactions = resp.syncInOutTransactions;
      configRes.autoSyncInOutTransactions = resp.autoSyncInOutTransactions;
      configRes.syncFromAllWarehouses = resp.syncFromAllWarehouses;
      dispatch(setConfig(configRes));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveTransactionSyncLoading(false);
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger px-3 text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title={"Disconnect"}
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }
useEffect(()=>{
  dispatch(loadMagentoCustomfieldMapping([customField]));
  
},[customField])
  function customFieldMappingModal() {
      return (
        <div className="card-body">
          <p className="text-muted">
          You can configure Custom field mapping for your account by selecting following options
          </p>
          <div className="mb-l">
          <Select
                  placeholder="Magento Custom Field Mapping"
                  className="ml-s text-align-left"
                  options={customFieldOption}
                  defaultValue={customFieldOption[0]}
                  menuPlacement="auto"
                  onChange={(e) => {
                    setCustomField(e.value);
                  }}
                  theme={customSelectTheme}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                />
            </div>
          <div className="d-flex flex-row-reverse">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setCustomFieldState(true)}
              disabled={saveSyncLoading}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
              <FloppyDiskIcon />
              </span>
              <span>Custom Field Mapping</span>
            </button>
          </div>
        </div>
      );
    }

    

    

   
    function customerFilterConfig() {
      return (
        <div className="card-body">
          <p className="text-muted">
            You can configure Customer Filter for your account by selecting following options
          </p>   
          <div className="mb-l">
          <Select
                  placeholder="Magento Customer Filter Option"
                  className="ml-s text-align-left"
                  options={customerFilterOption}
                  menuPlacement="auto"
                  onChange={(e) => {
                    setCustomerFilter(e);
                  }}
                  theme={customSelectTheme}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  value ={customerFilter}
                  isSearchable={false}
                />
            </div>       
          <div className="d-flex flex-row-reverse">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={onFilterClick}
              disabled={filterButton}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <FloppyDiskIcon />
              </span>
              <span>Customer Filter Configuration</span>
            </button>
          </div>
        </div>
      );
    }

    const validation = () => {
      if (customerFilterPayload === null) {
        showAlert("Alert", "Please select Customer filter configuration option");
        return false;
      }
      if (customerFilterValue === null || customerFilterValue === "") {
        showAlert(
          `Alert`,
          `Please select the value for ${customerFilterPayload.label}  in Customer filter config`
        );
        return false;
      }

      return true;
    };
    
    async function onFilterClick() {
      setFilterButton(true);
      if(customerFilter.value ==='ALL'){
       const payload = [{ field: "ALL", custom: false, input: "TEXT", value: "ALL" }];

       try {
        const configResp = saveMagentoCusomterFilterConfig(payload).then(()=>{
          dispatch(loadConfig());
        })
        .catch(()=>{
          console.log("Unable to save Magento Customer filter Config");
          dispatch(loadConfig());
        });

        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Customer filter config updated successfully.",
          })
        );
        setFilterButton(false);
      } catch {
        setError("Failed to set config");
        setFilterButton(false);
      }
      }
      else{
        dispatch(loadCustomerFilterConfig());
        dispatch(loadConfig());
        setCustomerFilterState(true);
        setFilterButton(false);
        return;
      }
     
    }
  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Products and Customers from your Magento store
          will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => {
                setSyncWarning(true);
              }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is disabled for your account. Orders, Products and Customers from your Magento store
          are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  function magentoConnect() {
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store Name</b>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. Nunes Greens"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {magentoErrors.name && <span className="text-danger">{magentoErrors.name}</span>}
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store URL</b>
              </div>
              <input
                id="site"
                type="text"
                className="form-control"
                placeholder="Ex. https://nunes-greens.com"
                value={site}
                onChange={(e) => setSite(e.target.value)}
              />
              {magentoErrors.site && <span className="text-danger">{magentoErrors.site}</span>}
            </div>
            {/* <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Sync Start Date</b>
              </div>
              <input
                id="syncStartDate"
                type="date"
                className="form-control"
                value={syncStartDate}
                min={userInfo.bookBeginningStartDate}
                onChange={(e) => setSyncStartDate(e.target.value)}
              />
              {magentoErrors.syncStartDate && (
                <span className="text-danger">{magentoErrors.syncStartDate}</span>
              )}
            </div> */}
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>User Name</b>
              </div>
              <input
                id="userName"
                placeholder="Ex. admin"
                type="text"
                className="form-control"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              {magentoErrors.userName && (
                <span className="text-danger">{magentoErrors.userName}</span>
              )}
            </div>
            {/* user password */}

            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Password</b>
              </div>
              <input
                placeholder="Ex. 1234"
                id="userPassword"
                type="password"
                className="form-control"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
              {magentoErrors.userPassword && (
                <span className="text-danger">{magentoErrors.userPassword}</span>
              )}
            </div>

            <div className="d-flex flex-row-reverse">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={handleAddMagentoAccount}
                disabled={mConnectLoading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {mConnectLoading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <PlusLogo />
                  )}
                </span>
                <span>Connect</span>
              </button>
              <button
                className="btn border-radius-m p-v-s mr-2 text-white bg-danger"
                onClick={(e) => setConfigOpen(false)}
                // style={{marginRight: "10px"}}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function magentoConnectModal() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <p className="text-muted">Link your Magento accounts.</p>
          <div className="d-flex flex-row-reverse mb-2">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setConfigOpen(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <PlusLogo />
              </span>
              <span>{accounts?.data.length ? "Add Another Store" : "Connect Magento Store"}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  async function disconnectMagentoStore(e, accountId) {
    await handleDisconnectMagentoAccount(e, accountId);
    setDisconnectWarningMagento(false);
    
  }
  
  async function saveDiscountConfigs(e) {
    setDiscountCodeState(true);
  }

  async function disableDiscountConfigs(e) {
    try {
      const configResp = await disableMagentDiscountConfig(paymentConfig.data);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Discount config disabled.",
        })
      );
      dispatch(loadConfig());
    } catch {
      setError("Failed to disable discount config");
    }
  }


  const updateConfig= async()=> {
    setSaveConfigLoading(true);
    const payload = {...config.data,mapDiscountEnabled:!mapDiscountEnabled};
    try {
      let  configResp = await setMagentoConfig(payload);;
     
      dispatch(loadConfig());
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Accounts config updated successfully.",
        })
      );
    } catch(err) {
      setError("Failed to set config",err);
    }
    setSaveConfigLoading(false);

  }
  

  function magentoConnectedModal() {
    return (
      <>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            <p className="text-muted">Your Magento account is linked.</p>
            {accounts.data.length > 0 && (
              <table className="table m-0 table-no-padding">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.data.map((account, index) => (
                    <div>
                      {!disconnectWarningMagento && (
                        <tr style={{ width: "560px" }}>
                          <td className="align-middle" style={{ width: "10%" }}>
                            <p className="m-0">
                              <b>{account.name}</b>
                            </p>
                            <span class="d-inline-block text-truncate" style={{ width: "auto" }}>
                              <small>{account.id}</small>
                            </span>
                          </td>
                          <td className="align-middle text-right">
                            <button
                              // style={{ marginLeft: "10px" }}
                              onClick={(e) => setDisconnectWarningMagento(true)}
                              className="btn border-radius-m p-v-s text-white bg-danger"
                            >
                              <span className="svg-icon svg-baseline mr-2 svg-white">
                                <DeleteLogo />
                              </span>
                              <span>Disconnect</span>
                            </button>
                          </td>
                        </tr>
                      )}

                      <CSSTransition
                        in={disconnectWarningMagento}
                        timeout={100}
                        classNames="scale-opacity"
                        unmountOnExit
                        onEnter={() => setDisconnectButtonMagento(false)}
                        onExited={() => setDisconnectButtonMagento(true)}
                      >
                        <Alert className="m-0 flex-fill" type="warning">
                          <p className="m-0">Disconnect Magento Store?</p>
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn dk-btn font-weight-bold text-muted px-3"
                              onClick={(e) => {
                                disconnectMagentoStore(e, account.id);
                              }}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CheckIcon />
                              </span>
                            </button>
                            <button
                              className="btn dk-btn mr-2 px-3"
                              onClick={(e) => setDisconnectWarningMagento(false)}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CloseIcon />
                              </span>
                            </button>
                          </div>
                        </Alert>
                      </CSSTransition>
                    </div>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="p-4 w-auto">
      {customFieldState && <CustomeFieldMapping module = {customField} setCustomFieldState = {setCustomFieldState} />}
      {customerFiterState && <CustomerFilterMapping module = {customField} setCustomerFilterState = {setCustomerFilterState} />}
      {discountCodeState && (
        <DiscountMapping
          configDiscountMappings={configDiscountMappings}
          deskeraDiscountMapping={deskeraDiscountMapping}
          setDiscountCodeState={setDiscountCodeState}
        />
      )}
      {configOpen && magentoConnect()}
      <h3 className="mb-4">Account Setup</h3>
      
      {config.data?.disableSyncReason && (
        <div className="mb-4">
          <Alert type="warning">{config.data?.disableSyncReason}</Alert>
        </div>
      )}

      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Magento Stores</h5>
          </div>
          {accounts.data.length ? magentoConnectedModal() : magentoConnectModal()}
        </>
      )}
      {/* <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <p className="text-muted">Link your Magento accounts.</p>
              <div className="d-flex flex-row-reverse mb-2">
                <button
                  className="btn dk-btn font-weight-bold text-success px-3 border border-success"
                  onClick={(e) => setConfigOpen(true)}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    <PlusLogo />
                  </span>
                  <span>Add Store</span>
                </button>
              </div>
              {accounts.data.length > 0 && (
                <table className="table m-0 table-no-padding">
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts.data.map((account, index) => (
                      <tr>
                        <td className="align-middle">
                          <p className="m-0">
                            <b>{account.name}</b>
                          </p>
                          <span>
                            <small>{account.id}</small>
                          </span>
                        </td>
                        <td className="align-middle text-right" style={{ width: 180 }}>
                          <button
                            className="btn text-danger px-4 dk-btn border border-danger"
                            onClick={(e) => handleDisconnectMagentoAccount(e, account.id)}
                          >
                            <span className="svg-icon svg-baseline mr-2 svg-danger">
                              <DeleteLogo />
                            </span>
                            <span>Disconnect</span>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div> */}

      {/* 

*/}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Books Accounts</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Magento products can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configAccounts.map((configAccount) => (
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>{configAccount.name}</b>
                  </div>
                  <Select
                    placeholder="Select an Account"
                    theme={customSelectTheme}
                    options={configAccount.options}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch(setConfig({ ...config.data, [configAccount.accessor]: e.value }))
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={configAccount.options.find(
                      ({ value }) => value == config.data[configAccount.accessor]
                    )}
                  />
                  {formErrors[configAccount.accessor] && (
                    <div>{formErrors[configAccount.accessor]}</div>
                  )}
                </div>
              ))}
              <div className="d-flex flex-row-reverse">
                <button
                  onClick={validateAndSaveAccountConfigs}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {
      deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Payment Gateway Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!paymentAccountMappingConfigured && !paymentConfig && (
                <Alert type="primary">
                  Accounts must be configured before Payment Accounts can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configPaymentAccountMappings.length === 0 && (
                <span className="mt-2 text-muted">No payment method accounts found.</span>
              )}
              {configPaymentAccountMappings.length > 0 &&
                configPaymentAccountMappings.map((configPaymentAccountMapping, idx) => (
                  <div className="form-group">
                    <div className="text-muted mb-2" aria="label">
                      <b>{configPaymentAccountMapping.gateway_name}</b>
                    </div>
                    <Select
                      placeholder="Select an Account"
                      theme={customSelectTheme}
                      options={configPaymentAccountMapping.options}
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(e) => {
                        setPaymentAccount(e, idx);
                      }}
                      value={configPaymentAccountMapping.options.filter(
                        (obj) => obj.value === configPaymentAccountMapping.account_code
                      )}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {formErrors[configPaymentAccountMapping.account_code] && (
                      <div>{formErrors[configPaymentAccountMapping.account_code]}</div>
                    )}
                  </div>
                ))}
              {configPaymentAccountMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={savePaymentAccountConfig}
                    disabled={savePaymentAccountConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {savePaymentAccountConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )} */}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        // THIS COMMENT MAY BE NEEDED.
        /*   <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Product Attribute Configuration</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                {!productAttributes && (
                  <Alert type="primary">
                    Product attributes should be configured before Products can be synced with Deskera
                    Books.
                  </Alert>
                )}
                {configProductAttributeMappings.length === 0 && (
                  <span className="mt-2 text-muted">No product attributes found.</span>
                )}
                {configProductAttributeMappings.length > 0 &&
                  configProductAttributeMappings?.map((configProductAttributeMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configProductAttributeMapping.name}</b>
                      </div>
                      <Select
                        placeholder="Select a Dimension"
                        theme={customSelectTheme}
                        options={configProductAttributeMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setProductAttribute(e, idx);
                        }}
                        value={configProductAttributeMapping.options.filter(
                          (obj) => obj.value === configProductAttributeMapping.deskeraCode
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[configProductAttributeMapping.deskeraCode] && (
                        <div>{formErrors[configProductAttributeMapping.deskeraCode]}</div>
                      )}
                    </div>
                  ))}
                {configProductAttributeMappings.length > 0 && (
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn dk-btn font-weight-bold text-success border border-success px-4"
                      onClick={saveProductAttribute}
                      disabled={saveProductAttributeConfigLoading}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-success">
                        {saveProductAttributeConfigLoading ? (
                          <div
                            class="spinner-border text-success spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </> */
        <> </>
      )}

      {
        // Tax mapping is hidden
      }
      {deskeraInfo.accountConnected &&
        deskeraInfo.taxResidency === "IN" &&
        deskeraTaxes.length > 0 &&
        false && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Tax Mappings</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                {/*  {!taxMappingConfigured && !taxMapping && (
                  <Alert type="primary">
                    Tax must be configured before Invoices can be synced with Deskera Books.
                  </Alert>
                )} */}
                {configTaxMappings.length === 0 && (
                  <span className="mt-2 text-muted">No Taxes found at Magento.</span>
                )}
                {configTaxMappings.length > 0 &&
                  configTaxMappings?.map((configTaxMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configTaxMapping.display}</b>
                      </div>
                      <Select
                        placeholder="Select a Tax"
                        theme={customSelectTheme}
                        options={configTaxMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setTaxMappingOnSelect(e, idx);
                        }}
                        value={configTaxMapping.options.filter(
                          (obj) => obj.value === configTaxMapping.deskeraTaxCode
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[configTaxMapping.deskeraTaxCode] && (
                        <div>{formErrors[configTaxMapping.deskeraTaxCode]}</div>
                      )}
                    </div>
                  ))}
                {configTaxMappings.length > 0 && (
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn dk-btn font-weight-bold text-success border border-success px-4"
                      onClick={saveTaxMappingConfig}
                      disabled={saveTaxMappingConfigLoading}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-success">
                        {saveTaxMappingConfigLoading ? (
                          <div
                            class="spinner-border text-success spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
    {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Customer Filter Configuration</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">{customerFilterConfig()}</div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {config.data.syncEnabled===true ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Custom Field Mapping</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">{customFieldMappingModal()}</div>
        </>
      )}
      {config?.data?.mapDiscountEnabled != undefined && userInfo?.additionalSettings?.MULTIPLE_GLOBAL_DISCOUNT === true &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0  && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Discount Mappings</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                {config.data && (
                  <>
                    <div className="form-group">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox2"
                          defaultChecked={config?.data?.mapDiscountEnabled || mapDiscountEnabled}
                          onChange={(e) => {
                            setMapDiscountEnabled(!mapDiscountEnabled);
                          }}
                        />
                        <label class="form-check-label text-muted" for="inlineCheckbox2">
                          Enable Discount Mappings
                        </label>
                      </div>
                    </div>
                  </>)
                }
                {mapDiscountEnabled === false && (
                <div className="d-flex flex-row-reverse">
                  <button
                    onClick={(e) => disableDiscountConfigs(e)}
                    disabled={saveDiscountConfigLoading}
                    className="btn border-radius-m p-v-s text-white bg-success"
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-white">
                      {saveDiscountConfigLoading ? (
                        <div
                          class="spinner-border text-white spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
                )}
                {mapDiscountEnabled ===true && (
                  <div className="d-flex flex-row-reverse">
                    <button
                      onClick={(e) => saveDiscountConfigs(e)}
                      disabled={saveDiscountConfigLoading}
                      className="btn border-radius-m p-v-s text-white bg-success"
                      // style={{ marginRight: "20px" }}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-white">
                        {saveDiscountConfigLoading ? (
                          <div
                            class="spinner-border text-white spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save Discount Mapping</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default Config;
