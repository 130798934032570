import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchLogo from "src/assets/Search";
import { useTable, useSortBy, useGlobalFilter, useAsyncDebounce, usePagination } from "react-table";
import clsx from "clsx";
import UpArrowIcon from "src/assets/UpArrow";
import ForwardRight from "src/assets/ForwardRight";
import ArrowRight from "src/assets/ArrowRight";
import Select from "react-select";
import { loadConfig, loadJob, loadInvoices } from "src/redux/actions/shipStation";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SyncStatus from "src/components/SyncStatus";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  migrateShipStationData,
  syncDocumentsToShipStation,
  syncFulfillmentShipStation,
} from "src/api";
import { addToast } from "src/redux/actions/toasts";

function Invoices() {
  const [syncStatus, setSyncStatus] = useState("enabled");
  const invoices = useSelector((state) => state.shipStation.invoices);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.shipStation.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    dispatch(loadConfig());
    dispatch(loadInvoices());
  }, []);

  const data = useMemo(() => invoices.data, [invoices]);

  const columns = useMemo(
    () => [
      {
        Header: "ShipStation ID",
        accessor: "deskeraId",
      },
      {
        Header: "Deskera Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Store Name",
        accessor: "storeName",
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate",
        disableSortBy: true,
      },
      {
        Header: "Customer Name",
        accessor: "customerName",
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.totalAmount && getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.totalAmount}
          </span>
        ),
        className: "text-right",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartInvoiceId}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.reason}</>;
        },
      },
    ],
    []
  );

  const config = useSelector((state) => state.shipStation.config);

  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy, startDate, endDate }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setStatus(status);
    setSortBy(sortBy);
    setLoading(true);
    setStartDate(startDate);
    setEndDate(endDate);
    dispatch(loadInvoices(pageSize, pageIndex, status, sortBy, startDate, endDate));
  };

  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateShipStationData();
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.message,
        })
      );
    }
  }

  function jobStatus(job) {
    if (!job.loading && !Object.keys(job.data).length === 0) {
      return true;
    }
    return false;
  }

  async function syncInvoices(body) {
    try {
      if (!body?.shipStationIds?.length) {
        return;
      }
      let response;
      var cartInvoices = data?.content?.filter((cartInvoice) =>
        body?.shipStationIds?.includes(cartInvoice?.id?.toString())
      );
      if (body.syncType === "fulfillShipStation") {
        response = await syncFulfillmentShipStation(cartInvoices);
      } else {
        response = await syncDocumentsToShipStation(cartInvoices);
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Invoices</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced invoices will appear here. Please complete{" "}
          <Link to="/app/shipStation" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ServerPaginationTable
        data={data.content ? data.content : []}
        columns={columns}
        migrate={syncInvoices}
        cart="shipStation"
        refresh={refreshData}
        tab="Invoices"
        pageCount={pageCount}
        loading={loading}
        rowId="id"
      />
    </div>
  );
}

export default Invoices;
