import React from "react";

function BigCommerceLogo() {
  return (
    <svg viewBox="0 0 256 292" xmlns="http://www.w3.org/2000/svg">
      {/* Right-Angle Triangle */}
      <polygon points="16,260 240,260 240,26" fill="#000" />

      {/* 'B' text */}
      <text
        x="55%"
        y="70%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="150"
        fontWeight="bold"
        fill="#FFF"
      >
        B
      </text>
    </svg>
  );
}

export default BigCommerceLogo;