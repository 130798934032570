function FoodicsLogo() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="225.000000pt"
      height="225.000000pt"
      viewBox="0 0 225.000000 225.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        fillRule="evenodd"
      >
        <path
          d="M0 1125 l0 -1125 1125 0 1125 0 0 1125 0 1125 -1125 0 -1125 0 0
   -1125z m1640 440 l0 -124 -366 -3 -366 -3 -19 -24 c-28 -34 -28 -188 0 -222
   19 -24 23 -24 220 -27 l201 -3 0 -125 0 -126 -222 4 c-221 3 -224 3 -280 31
   -73 36 -147 116 -173 188 -28 77 -28 261 0 338 22 60 86 138 142 173 71 43
   108 47 496 47 l367 1 0 -125z m-800 -782 c58 -53 47 -176 -20 -211 -15 -8 -49
   -12 -88 -9 -51 2 -67 8 -88 29 -50 49 -41 173 14 203 15 8 55 14 89 15 55 0
   67 -4 93 -27z"
        />
      </g>
    </svg>
  );
}

export default FoodicsLogo;
