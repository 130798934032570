import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { setDeskeraConnection } from "src/api";
import Alert from "src/components/Alert";

function DeskeraOAuth() {
  const [status, setStatus] = useState("connecting");
  const history = useHistory();

  useEffect(() => {
    let windowUrl = new URL(window.location);
    let oauthParams = windowUrl.searchParams;

    const connectionInfo = {
      code: oauthParams.get("code"),
      state: oauthParams.get("state"),
    };

    async function updateConnection() {
      const data = sessionStorage.getItem('cartType');
      const cart = "/app/" + data;
      try {
        await setDeskeraConnection(connectionInfo);
        setStatus("connected");
      } catch {
        setStatus("failed");
      } finally {
        setTimeout(() => history.push(cart), 1000);
      }
    }

    updateConnection();
  }, []);

  return status === "connecting" ? (
    <>
      <p>Connecting your Deskera account.</p>
      <div class="spinner-border text-muted" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </>
  ) : status === "connected" ? (
    <Alert type="success">
      Deskera account successfully connected. Redirecting you to Deskera ECom.
    </Alert>
  ) : (
    <Alert type="danger">
      Failed to connect your Deskera account. Please try authorizing again.
    </Alert>
  );
}

export default DeskeraOAuth;
