function WrenchIcon() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
    >
      <path
        d="M501.818,90.998c-1.876-4.733-6.008-8.203-10.993-9.234c-4.991-1.033-10.156,0.516-13.756,4.116l-77.994,77.994
			l-41.932-9.016l-9.015-41.932l77.994-77.995c3.6-3.6,5.146-8.77,4.116-13.755c-1.031-4.986-4.502-9.117-9.234-10.995
			C367.457-11.053,306.542,1.538,265.818,42.26c-19.604,19.604-33.066,44.227-38.93,71.206c-5.017,23.082-4.312,46.931,1.991,69.544
			L20.735,391.154C7.363,404.524,0,422.301,0,441.21c0,18.909,7.363,36.686,20.734,50.056
			c13.371,13.371,31.147,20.734,50.056,20.734c18.908,0,36.686-7.363,50.056-20.734l208.144-208.144
			c22.614,6.302,46.462,7.006,69.543,1.99c26.98-5.864,51.603-19.326,71.207-38.93C510.463,205.46,523.053,144.545,501.818,90.998z"
      />
    </svg>
  );
}

export default WrenchIcon;
