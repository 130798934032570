import { useEffect, useRef } from "react";
import Chart from "chart.js";
import EmptyLineGraph from "src/assets/EmptyLineGraph.svg";

function CustomersChart({ customers }) {
  const chartRef = useRef(null);
  Chart.defaults.global.defaultFontFamily = "'Inter', sans-serif";
  Chart.defaults.global.legend.position = "bottom";
  Chart.defaults.global.responsive = true;
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];


  useEffect(() => {
    const nowDate = new Date();
    const chartMonths = [];
    const zeroDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
    chartMonths.push(months[zeroDate.getMonth()]);
    const oneDate = new Date(zeroDate.getFullYear(), zeroDate.getMonth() - 1, 1);
    chartMonths.push(months[oneDate.getMonth()]);
    const twoDate = new Date(oneDate.getFullYear(), oneDate.getMonth() - 1, 1);
    chartMonths.push(months[twoDate.getMonth()]);
    const threeDate = new Date(twoDate.getFullYear(), twoDate.getMonth() - 1, 1);
    chartMonths.push(months[threeDate.getMonth()]);
    const monthsLabel = chartMonths.reverse();

    let customersLabel = [0, 0, 0, 0];
    if (customers.data.content) {
      for (let i = 0; i < customers.data.content.length; i++) {
        var customer = customers.data.content[i];
        console.log(customer);
        if (customer.syncStatus !== "SUCCESSFUL") {
          continue;
        }
        const customerDate = new Date(customer.createdAt);
        if (customerDate >= zeroDate && customerDate <= nowDate) customersLabel[3] += 1;
        if (customerDate >= oneDate && customerDate < zeroDate) customersLabel[2] += 1;
        if (customerDate >= twoDate && customerDate < oneDate) customersLabel[1] += 1;
        if (customerDate >= threeDate && customerDate < twoDate) customersLabel[0] += 1;
      }
    }
    if (chartRef && chartRef.current) {
      const myChartRef = chartRef.current.getContext("2d");
      new Chart(myChartRef, {
        type: "line",
        data: {
          labels: monthsLabel,
          datasets: [
            {
              label: "Customers Synced",
              data: customersLabel,
              fill: false,
              borderColor: "#f4a261",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          animation: {
            duration: 0,
          },
          tooltips: {
            backgroundColor: "rgba(29, 53, 87, 0.9)",
            titleFontSize: 12,
            titleFontStyle: "bold",
            bodyFontSize: 14,
            xPadding: 12,
            yPadding: 12,
            caretSize: 0,
            cornerRadius: 2,
            displayColors: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  stepSize: 1,
                  maxTicksLimit: 3,
                  padding: 10,
                },
                gridLines: {
                  drawBorder: false,
                  drawTicks: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  padding: 10,
                },
                gridLines: {
                  drawOnChartArea: false,
                  drawTicks: false,
                },
              },
            ],
          },
        },
      });
    }
  }, [chartRef, customers]);

  return customers.loading ? (
    <div className="text-center chartjs-render-monitor">
      <div class="spinner-border text-secondary spinner-border-sm" role="status">
        <span class="sr-only text-muted">Loading...</span>
      </div>
    </div>
  ) : customers.data.content.length ? (
    <canvas ref={chartRef} />
  ) : (
    <div
      className="bg-image d-flex align-items-center justify-content-center container-fluid"
      style={{
        height: "100%",
        width: "100%",
        backgroundImage: `url(${EmptyLineGraph})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className=" p-1 text-muted">
        <span>Looks like there's no data here.</span>
      </div>
    </div>
  );
}

export default CustomersChart;
