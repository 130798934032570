import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadConfig, loadProducts, loadJob } from "src/redux/actions/woocommerce";
import Alert from "src/components/Alert";
import SyncStatus from "src/components/SyncStatus";
import getSymbolFromCurrency from "currency-symbol-map";
import { migrateWoocommerceData, migrateWoocommerceDataForProducts } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import ServerPaginationTable from "src/components/ServerPaginationTable";

function Products() {
  const products = useSelector((state) => state.woocommerce.products);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.woocommerce.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(loadProducts());
    dispatch(loadConfig());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const data = useMemo(() => products.data, [products]);

  const columns = useMemo(
    () => [
      {
        Header: "Deskera ID",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Name",
        accessor: "productName",
      },
      {
        Header: "Unit Price",
        accessor: "productUnitPrice",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productUnitPrice && getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.productUnitPrice}
          </span>
        ),
        className: "text-right",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return <SyncStatus status={row.original.syncStatus} reason={row.original.reason} id={row.original.cartProductId}/>;
        },
      },
    ],
    []
  );

  const config = useSelector((state) => state.woocommerce.config);

  useEffect(() => {
    setLoading(false);
    if(data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]) ;

  const refreshData = ({ pageSize, pageIndex, status, sortBy, search }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setStatus(status);
    setSortBy(sortBy);
    setLoading(true);
    dispatch(loadProducts(pageSize, pageIndex, status, sortBy, search ?? ""));
  }; 


  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateWoocommerceData();
    } catch (err) {
      dispatch(addToast({
        type: "danger",
        title: "Failure",
        message: err.message
      }))
    }
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Products</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced products will appear here. Please complete{" "}
          <Link to="/app/woocommerce" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ServerPaginationTable data={data.content ? data.content : []} columns={columns} migrate={migrateWoocommerceDataForProducts} cart='woocommerce'
      refresh={refreshData} tab="Products" pageCount={pageCount} loading={loading} rowId="cartProductId"/>
    </div>
  );
}

export default Products;
