import { combineReducers } from "redux";
import toasts from "src/redux/reducers/toasts";
import auth from "src/redux/reducers/auth";
import amazon from "src/redux/reducers/amazon";
import shopify from "src/redux/reducers/shopify";
import woocommerce from "src/redux/reducers/woocommerce";
import foodics from "src/redux/reducers/foodics";
import square from "src/redux/reducers/square";
import shipStation from "src/redux/reducers/shipStation";
import magento from "./magento";
import bigcommerce from "./bigcommerce";
import tally from "./tally";
import brightpearl from "./brightpearl";

export default combineReducers({
  toasts,
  auth,
  amazon,
  shopify,
  woocommerce,
  foodics,
  square,
  shipStation,
  magento,
  bigcommerce,
  tally,
  brightpearl
});
