import React, { useEffect, useMemo, useState } from "react";
import SyncStatus from "src/components/SyncStatus";
import SearchLogo from "src/assets/Search";
import { useAsyncDebounce } from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { DKLabel, DKButton, Toggle } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import { addToast } from "src/redux/actions/toasts";
import {
  getDeskeraProductBreifSearch,
  getDeskeraProductBrif,
  saveAmazonMatchingProducts,
} from "src/api";
import { loadMatchingProducts } from "src/redux/actions/amazon";
import { MatchingProductSelect } from "src/components/MatchingProductSelect";
import AmazowwnTable from "src/components/AmazonTable";
import AmazonTable from "src/components/AmazonTable";
import { Utility } from "src/utils/Utility";
const initailAmazonData = {
  content: [],
  totalPages: 0,
  totalElements: 0,
  last: true,
  size: 0,
  number: 0,
};
const MatchingSummaryTable = (props) => {
  const [toggleStatus, setToggleStatus] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const state = useSelector((state) => state);
  const amazonData = useSelector((state) => state.amazon?.matchingProducts);
  const [data, setData] = useState(amazonData?.data ? amazonData.data : initailAmazonData);
  const [input, setInput] = useState("");
  const [productData, setProductData] = useState([]);
  const [error, setError] = useState("");

  useMemo(() => setData(amazonData?.data ?? initailAmazonData), [amazonData]);
  useMemo(() => setPageCount(data.totalPages), [data]);
  // useEffects---:
  useEffect(() => {
    getBooksProducts();
  }, []);

  useEffect(() => {
    refreshData({ pageSize, pageIndex, sortBy });
    if (toggleStatus) {
      setShowAll(false);
    }
  }, [toggleStatus, input, showAll]);

  const handleToggle = () => {
    if (!loading) {
      setToggleStatus((toggleStatus) => !toggleStatus);
    }
  };
  // Methods ---:

  useEffect(() => {
    setLoading(false);
  }, [data]);

  function refreshData({ pageSize, pageIndex, sortBy }) {
    if (!loading) {
      setLoading(true);
      let payload = {
        deskeraMappingField: props.deskeraMappingField,
        amazonMappingField: props.amazonMappingField,
        showSucceedProducts: toggleStatus,
        limit: !toggleStatus && showAll ? 1000 : pageSize,
        page: !toggleStatus && showAll ? 0 : pageIndex,
        sortDir: "ASC",
        search: input,
      };
      setPageSize(pageSize);
      setPageIndex(pageIndex);
      setStatus(status);
      setSortBy(sortBy);
      dispatch(loadMatchingProducts(payload));
    }
  }

  async function getBooksProducts() {
    try {
      const products = await getDeskeraProductBrif();
      setProductData(products);
    } catch {
      setError("Failed to load Deskera Books Products");
    }
  }

  function handleCancel() {
    props.setShowPopup(false);
    props.setSyncConfigOpen(false);
    props.refreshData();
  }

  async function loadDeskeraOptions(inputValue) {
    const config = {
      search: inputValue,
      limit: 25,
    };
    const response = await getDeskeraProductBreifSearch(config);
    console.log(response);
    if (inputValue === undefined || inputValue == "") {
      return response;
    } else {
      return response;
    }
  }

  const deskeraHandleChange = (e, index) => {
    let newData = Utility.deepClone(data);
    if (Utility.isNotEmpty(newData.content)) {
      newData.content[index] = {
        ...newData.content[index],
        deskeraDocumentCode: e.documentSequenceCode,
        deskeraId: e.pid,
        deskeraProductName: e.name,
      };
      setData(newData);
    }
  };

  async function handleSyncSubmitAndClosePopup() {
    await handleSyncSubmit();
    props.setShowPopup(false);
    props.setSyncConfigOpen(false);
  }
  async function handleSyncSubmit() {
    try {
      const resp = await saveAmazonMatchingProducts(data.content);

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Product Mapping Saved successfully ",
        })
      );
      return;
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productName}{" "}
            {row.original.productVariantName &&
              row.original.productVariantName !== "Default Title" &&
              "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "Mapped Deskera Product",
        accessor: "deskeraDocumentCode",
        width:'350px',
        Cell: ({ cell: { row } }) => {
          return (
            <MatchingProductSelect
              row={row}
              loadDeskeraOptions={loadDeskeraOptions}
              deskeraHandleChange={deskeraHandleChange}
              productData={productData}
              setProductData={setProductData}
            />
          );
        },
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
        disableGlobalFilter: true,
      },
    ],
    [deskeraHandleChange, loadDeskeraOptions, productData]
  );

  const onTextChange = useAsyncDebounce((value) => {
    setInput(value);
  }, 200);

  const handleSearchChange = (value) => {
    onTextChange(value || "");
  };

  return (
    <div
      className="w-100 h-100  d-flex justify-content-center  overflow-auto"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card dk-card-shadow d-flex border border-radius-l"
        style={{ minWidth: "100%", minHeight: "70%" }}
      >
        <h3 className="pt-4 ml-4">Map Products</h3>
        <div className="card-body d-flex flex-column">
          <form className="form-inline" style={{ color: "gray", fontSize: 12 }}>
            {
              <>
                <div className="form-group mr-5">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox2"
                      defaultChecked={showAll}
                      checked={showAll}
                      disabled={loading || toggleStatus}
                      onChange={() => {
                        setShowAll(!showAll);
                      }}
                    />
                    <label class="form-check-label text-muted" for="inlineCheckbox2">
                      Show all
                    </label>
                  </div>
                </div>
              </>
            }

            <div className=" mb-2 ml-xxl input-group" style={{ width: "30%", marginLeft: "450px" }}>
              <div class="input-group-prepend">
                <div class="input-group-text bg-light">
                  <span className="svg-icon svg-disabled">
                    <SearchLogo />
                  </span>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handleSearchChange(e.target.value)}
                value={input}
              />
            </div>
            <DKLabel
              text="Show Succeed Products"
              style={{ color: "gray", fontSize: 12, marginLeft: "300px" }}
            />
            <Toggle
              disabled={loading}
              className="ml-s"
              isOn={toggleStatus}
              onChange={handleToggle}
            />
          </form>

          <AmazonTable
            data={data.content ? data.content : []}
            columns={columns}
            minHeight={Utility.isEmpty(data.content) ? 'auto' : 450}
            migrate={""}
            cart="amazon"
            refresh={refreshData}
            tab="MatchingSummaryTable"
            pageCount={pageCount}
            loading={loading}
            rowId="id"
          />
          <div className="d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmitAndClosePopup}
              title={"SAVE AND CLOSE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmit}
              title={"SAVE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
              onClick={handleCancel}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchingSummaryTable;
