import {
  SQUARE_SET_CONFIG,
  SQUARE_SET_CUSTOMERS,
  SQUARE_SET_ORDERS,
  SQUARE_SET_PRODUCTS,
  SQUARE_SET_ACCOUNTS,
  SQUARE_SET_JOB,
  SQUARE_SET_PAYMENT_CONFIG,
  SQUARE_SET_LOCATION_CONFIG,
  SQUARE_SET_MEASURE_CONFIG,
  SQUARE_SET_DESKERA_PRODUCTS,
  SQUARE_SET_CUSTOM_FIELD_CONFIG,
  SQUARE_MATCHING_PRODUCT,
  SQUARE_SET_TWOWAYCONFIG,
  SQUARE_SET_INVENTORY_TRANSACTIONS
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  twoWaySyncConfig: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  products: {
    loading: true,
    data: [],
  },
  customers: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  paymentConfig:{
    loading: true,
    data: {}
  },
  locationConfig:{
    loading: true,
    data: {}
  },
  customfieldConfig:{
    loading: true,
    data: {}
  },
  measureConfig:{
    loading: true,
    data: {}
  },
  deskeraproducts: {
    loading: true,
    data: [],
  },
  matchingProduct:{
    loading: true,
    data: {}
  },
  inventoryTransactions: {
    loading: true,
    data: [],
  },
};

function SquareReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SQUARE_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
      case SQUARE_SET_TWOWAYCONFIG:
        return {
          ...state,
          twoWaySyncConfig: {
            loading: false,
            data: action.twoWaySyncConfig,
          },
        }
    case SQUARE_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case SQUARE_SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          loading: false,
          data: action.customers,
        },
      };
    case SQUARE_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case SQUARE_SET_PRODUCTS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.products,
        },
      };
    case SQUARE_SET_JOB: {
      return {
        ...state,
        job: {
          loading: false,
          job: action.job,
        },
      };
    }
    case SQUARE_SET_PAYMENT_CONFIG:
      return {
        ...state,
        paymentConfig: {
          loading: false,
          data: action.paymentConfig,
        },
      };
    case SQUARE_SET_LOCATION_CONFIG:
      return {
        ...state,
        locationConfig: {
          loading: false,
          data: action.locationConfig,
        },
      };
    case SQUARE_SET_CUSTOM_FIELD_CONFIG:
      return {
        ...state,
        customfieldConfig: {
          loading: false,
          data: action.customfieldConfig,
        },
      };
    case SQUARE_SET_MEASURE_CONFIG:
      return {
        ...state,
        measureConfig: {
          loading: false,
          data: action.measureConfig,
        },
      };
      case SQUARE_SET_DESKERA_PRODUCTS:
        return {
          ...state,
          deskeraproducts: {
            loading: false,
            data: action.deskeraproducts,
          },
        };
    case SQUARE_MATCHING_PRODUCT:
      return {
        ...state,
        matchingProduct: {
          loading: false,
          data: action.matchingProduct,
        },
      };
      case SQUARE_SET_INVENTORY_TRANSACTIONS:
        return {
          ...state,
          inventoryTransactions: {
            loading: false,
            data: action.inventoryTransactions,
          },
        };
    default:
      return state;
  }
}

export default SquareReducer;
