import React from "react";

const BrightPearlLightSVG = () => {
  return (
    <>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="200.000000pt"
        height="200.000000pt"
        viewBox="0 0 200.000000 200.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
-1000z m1136 532 c108 -49 178 -156 179 -273 0 -54 -5 -76 -31 -126 l-31 -60
46 -40 c71 -61 104 -131 109 -228 5 -101 -20 -172 -84 -244 -92 -102 -185
-124 -524 -119 l-215 3 -3 558 -2 558 252 -3 c239 -3 256 -4 304 -26z m404
-994 c13 -25 13 -31 0 -55 -30 -55 -120 -35 -120 25 0 64 88 86 120 30z"
          />
          <path
            d="M800 1249 l0 -121 113 4 c72 2 120 9 133 18 52 33 69 107 37 161 -25
42 -78 59 -188 59 l-95 0 0 -121z"
          />
          <path
            d="M800 791 l0 -151 140 0 c160 0 194 9 231 61 32 45 33 125 3 166 -43
58 -75 68 -231 71 l-143 4 0 -151z"
          />
        </g>
      </svg>
    </>
  );
};

export default BrightPearlLightSVG;
