import React, { useEffect, useMemo, useState } from "react";
import SyncStatus from "src/components/SyncStatus";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SearchLogo from "src/assets/Search";
import AsyncSelect from "react-select/async";
import {
  getDeskeraProductBrif,
  saveSquareMappedProducts,
} from "src/api";
import {
  useAsyncDebounce
} from "react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  DKLabel,
  DKButton,
  Toggle,
} from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import { addToast } from "src/redux/actions/toasts";
import { loadSquareMatchingProducts } from "src/redux/actions/square";
import { Utility } from "src/utils/Utility";

function MatchingSummaryTable(props) {
  const [toggleStatus, setToggleStatus] = useState(false);
  const [limit, setLimit] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const data1 = useSelector((state) => state.square.matchingProduct);
  const [data, setData] = useState(data1.data);
  const [input, setInput] = useState("");
  const [productData, setProductData] = useState([]);
  const [error, setError] = useState("");

  const refreshData = ({ pageSize, pageIndex, sortBy }) => {
    if (!loading) {
      setLoading(true);
      let payload = {
        deskeraMappingField: props.deskeraMappingField,
        squareMappingField: props.squareMappingField,
        showSucceedProducts: toggleStatus,
        limit: !toggleStatus && showAll ? 1000 : pageSize,
        page: !toggleStatus && showAll ? 0 : pageIndex,
        sortDir: "ASC",
        search: input,
      };
      setPageSize(pageSize);
      setPageIndex(pageIndex);
      setStatus(status);
      setSortBy(sortBy);
      dispatch(loadSquareMatchingProducts(payload));
    }
  };

  async function getBooksProducts() {
    try {
      const products = await getDeskeraProductBrif();
      products.forEach((product) => {
        productData.push(product);
      });
    } catch {
      setError("Failed to load Deskera Books Products");
    }
  }

  useEffect(() => {
    getBooksProducts();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [data]);

  useMemo(() => setData(data1.data), [data1]);
  useMemo(() => setPageCount(data.totalPages), [data]);

  const handleCancel = () => {
    props.setShowPopup(false);
    props.setSyncConfigOpen(false);
    props.refreshData();
  };

  useEffect(() => {
    refreshData({ pageSize, pageIndex, sortBy });
    if (toggleStatus) {
      setShowAll(false);
    }
  }, [toggleStatus, input, showAll]);

  const handleToggle = () => {
    if (!loading) {
      setToggleStatus((toggleStatus) => !toggleStatus);
    }
  };

  const loadDeskeraOptions = async (inputValue) => {
    const response = productData;
    // return response
    if (inputValue === undefined || inputValue == "") {
      return response;
    } else {
      return response.filter(data => String(data.name)?.toLowerCase().includes(String(inputValue)?.toLowerCase()) ||
        String(data.documentSequenceCode)?.toLowerCase().includes(String(inputValue)?.toLowerCase()))
    }

  };

  const deskeraHandleChange = (e, index) => {
    let newData = { ...data };

    if (data.content) {
      newData.content[index].deskeraDocumentCode = e.documentSequenceCode;
      newData.content[index].deskeraId = e.pid;
      newData.content[index].deskeraPrimaryId = e.id;
      setData({ ...newData });
    }
  };
  const getName = (row) => {
    var name = row.original.productName
    if (row.original.productVariantName != undefined && row.original.productVariantName !== "Default Title") {
      name = name + "(" + row.original.productVariantName + ")";
    }
    return name;
  }

  async function handleSyncSubmitAndClosePopup() {
    await handleSyncSubmit();
    props.setShowPopup(false);
    props.setSyncConfigOpen(false);
  }
  async function handleSyncSubmit() {
    try {
      const resp = await saveSquareMappedProducts(data.content);

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Product Mapping Saved successfully ",
        })
      );
      return;
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data.message,
        })
      );
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Variant ID",
        accessor: "productVariantId",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.productName}{" "}
            {row.original.productVariantName &&
              row.original.productVariantName !== "Default Title" &&
              "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: "Mapped Deskera Product",
        accessor: "deskeraDocumentCode",
        width:'350px',
        Cell: ({ cell: { row } }) => {
          return (
            <AsyncSelectWrapper
              row={row}
              loadDeskeraOptions={loadDeskeraOptions}
              deskeraHandleChange={deskeraHandleChange}
              productData={productData}
            />
          );
        },
      },

      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
        disableGlobalFilter: true,
      },
    ],
    [deskeraHandleChange]
  );

  const onTextChange = useAsyncDebounce((value) => {
    setInput(value);
  }, 200);

  const handleSearchChange = (value) => {
    onTextChange(value || "");
  };
  return (
    <div
      className="w-100 h-100  d-flex justify-content-center  overflow-auto"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card dk-card-shadow d-flex border border-radius-l"
        style={{ minWidth: "100%", minHeight: "70%" }}
      >
        <h3 className="pt-4 ml-4">Map Products</h3>
        <div className="card-body d-flex flex-column" >
          <form className="form-inline flex justify-around" style={{ color: "gray", fontSize: 12, display: 'flex', justifyContent: 'space-between' }}>
            {<>
              <div className="form-group mr-5">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox2"
                    defaultChecked={showAll}
                    checked={showAll}
                    disabled={loading || toggleStatus}
                    onChange={() => {
                      setShowAll(!showAll);
                    }}
                  />
                  <label class="form-check-label text-muted" for="inlineCheckbox2">
                    Show all
                  </label>
                </div>
              </div>
            </>
            }
            <div className="mb-2 ml-xxl input-group">
              <div class="input-group-prepend">
                <div class="input-group-text bg-light">
                  <span className="svg-icon svg-disabled">
                    <SearchLogo />
                  </span>
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handleSearchChange(e.target.value)}
                value={input}
              />
            </div>
            <div className=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem'
              }}
            >
              <DKLabel text="Show Succeed Products" />
              <Toggle disabled={loading} className="ml-s" isOn={toggleStatus} onChange={handleToggle} />
            </div>
          </form>

          <ServerPaginationTable
            data={data.content ? data.content : []}
            columns={columns}
            migrate={""}
            minHeight={Utility.isEmpty(data.content) ? 'auto' : 450}
            cart="shopify"
            refresh={refreshData}
            tab="MatchingSummaryTable"
            pageCount={pageCount}
            loading={loading}
            rowId="id"
          />
          <div className="d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmitAndClosePopup}
              title={"SAVE AND CLOSE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <DKButton
              className="bg-success px-4 text-white"
              onClick={handleSyncSubmit}
              title={"SAVE"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
              onClick={handleCancel}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MatchingSummaryTable;


function AsyncSelectWrapper({ row, loadDeskeraOptions, deskeraHandleChange, productData }) {
  const [async, setAsync] = useState(
    productData.filter((obj) => obj.pid === row.original.deskeraId)
  );
  return (
    <AsyncSelect
      placeholder={'Select Product'}
      className="filter-selector ml-3"
      cacheOptions
      defaultOptions
      value={async}
      getOptionLabel={(e) => e.documentSequenceCode + " - "+ e.name}
      getOptionValue={(e) => e.pid}
      loadOptions={loadDeskeraOptions}
      onChange={(e) => {
        setAsync(e);
        deskeraHandleChange(e, row.index);
      }}
    />
  );
}