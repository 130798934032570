import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadProducts } from "src/redux/actions/amazon";
import Alert from "src/components/Alert";
import AmazonTable from "src/components/AmazonTable";
import SyncStatus from "src/components/SyncStatus";
import { isEmpty } from "underscore";

function Products() {
  const amazonConfig = useSelector((state) => state.amazon.config);
  const products = useSelector((state) => state.amazon.products);
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  const refreshData = ({ pageSize, pageIndex, sortBy }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setLoading(true);
    setSortBy(sortBy);

    let updatedSortBy = sortBy?.length ? sortBy : [{ id: "syncStatus", desc: false }];
    dispatch(loadProducts(pageSize, pageIndex, updatedSortBy, query));
  };

  useEffect(() => {
    if (!isEmpty(query)) {
      refreshData({ pageSize, pageIndex, sortBy});
    }
  }, [query]);

  const data = useMemo(() => products.data, [products]);

  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Name",
        accessor: "productName",
      },
      {
        Header: "Deskera Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.reason}</>;
        },
      },
    ],
    []
  );

  const onFilter = (filter) => {
    setQuery(filter);
  };

  return (
    <div className="p-4">
      <h3 className="mb-3">Products</h3>
      {!amazonConfig.data.syncEnabled && (
        <Alert type="warning">
          Your synced products will appear here. Please complete{" "}
          <Link to="/app/amazon" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <AmazonTable
        data={data.content ? data.content : []}
        columns={columns}
        cart="amazon"
        syncButton={false}
        refresh={refreshData}
        onFilter={onFilter}
        pageCount={pageCount}
        loading={loading}
        rowId="id"
        tab={"products"}
      />
    </div>
  );
}

export default Products;
