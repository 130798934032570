import { DKButton, DKDateRangePicker } from "deskera-ui-library";
import moment from "moment";
import React from "react";
import CloseIcon from "src/assets/Close";

export default function SyncByDateRange({ selectedDate, onSelect, syncSubmit, onClose }) {
  
  const areBothDatesValid = React.useMemo(() => {
    return moment(selectedDate.startDate).isValid() && moment(selectedDate.endDate).isValid();
  }, [selectedDate]);

  React.useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onClose]);
  return (
    <div className="d-flex">
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div
          className="card dk-card dk-card-shadow d-flex border border-radius-m"
          style={{ minWidth: "30%" }}
        >
          <div className="card-body d-flex flex-column" style={{ align: "center" }}>
            <div className="card-body d-flex flex-column">
              <div className="text-muted mb-3 d-flex flex-column">
                <span className="font-weight-bold">Sync for dates:</span>
                <span>Click once to select a from-date, then select the to-date</span>
              </div>
              <div class="bg-white z-10 top-12 right-20 deskera-datepicker">
                <DKDateRangePicker
                  onClose={() => {}}
                  color={"rgb(33, 107, 165)"}
                  startDate={selectedDate.startDate}
                  selectedStartDate={selectedDate.startDate}
                  selectedEndDate={selectedDate.endDate}
                  onSelectDateRange={(startDate, endDate) => {
                    onSelect({ startDate, endDate });
                  }}
                />
              </div>
            </div>
            <div className="d-flex flex-row-reverse">
              <DKButton
                className="bg-success px-3 text-white"
                onClick={syncSubmit}
                disabled={!areBothDatesValid}
                title={"Sync"}
                style={{ marginLeft: "10px", marginRight: "10px" }}
              ></DKButton>
              <button
                className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
                onClick={onClose}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
