import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadProducts, loadConfig, loadJob, loadDeskeraSyncedProducts } from "src/redux/actions/woocommerce";
import Alert from "src/components/Alert";
import SyncStatus from "src/components/SyncStatus";
import ActiveInactive from "src/components/ActiveInactive";
import getSymbolFromCurrency from "currency-symbol-map";
import { migrateShopifyData, migrateShopifyDataForProducts, migrateWoocommerceDataForOrders, syncDeskeraProductsToShopify, syncDeskeraProductsToWooCommerce } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import RowSelectionTable from "src/components/RowSelectionTable";


function DeskeraProducts() {

    const [syncStatus, setSyncStatus] = useState("enabled");
    const products = useSelector((state) => state.woocommerce.deskeraProducts);
    const dispatch = useDispatch();
    const job = useSelector((state) => state.woocommerce.job);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState([]);
    const [sortBy, setSortBy] = useState();


    useEffect(() => {
        dispatch(loadDeskeraSyncedProducts());
        dispatch(loadConfig());
    }, []);

    useEffect(() => {
        dispatch(loadJob());
    }, job);

    const getProductType = (type) => {
        switch (type) {
            case "TRACKED":
                return "Tracked";
            case "NONTRACKED":
                return "Non-Tracked";
            case "BILL_OF_MATERIALS":
                return "Bill of Materials";
            default:
                return type;
        }
    }


    const data = useMemo(() => products.data, [products]);

    const columns = useMemo(
        () => [
            {
                Header: "Product Code",
                accessor: "deskeraDocumentCode",
            },
            {
                Header: "Name",
                accessor: "productName",
                Cell: ({ cell: { row } }) => (
                    <span>
                        {row.original.productName} {row.original.productVariantName && row.original.productVariantName !== "Default Title" && "(" + row.original.productVariantName + ")"}
                    </span>
                ),
            },
            {
                Header: "Description",
                accessor: "description",
            },
            {
                Header: "Type",
                accessor: "type",
                Cell: ({ cell: { row } }) => (
                    <span>
                        {getProductType(row.original.type)}
                    </span>
                )
            },
            {
                Header: "Opening Quantity",
                accessor: "openingQuantity",
                className: "text-right",

            },
            {
                Header: "Unit Price",
                accessor: "productUnitPrice",
                Cell: ({ cell: { row } }) => (
                    <span>
                        {getSymbolFromCurrency(row.original.currency)}{" "}
                        {row.original.productUnitPrice}
                    </span>
                ),
                className: "text-right",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ cell: { row } }) => {
                    return <ActiveInactive status={row.original.status} />;
                }
            },
            {
                Header: "Sync Status",
                accessor: "syncStatus",
                Cell: ({ cell: { row } }) => {
                    return <SyncStatus status={row.original.syncStatus} reason={row.original.reason} />;
                },
                disableGlobalFilter: true,
            },
            {
                Header: "Product ID",
                accessor: "cartProductId",
            }
        ],
        []
    );

    const config = useSelector((state) => state.woocommerce.config);

    useEffect(() => {
        setLoading(false);
        if (data && data.totalPages) {
            setPageCount(data.totalPages);
        }
    }, [data]);

    const refreshData = ({  pageSize, pageIndex, status, sortBy }) => {
        setPageSize(pageSize);
        setPageIndex(pageIndex);
        setStatus(status);
        setSortBy(sortBy);
        setLoading(true);
        dispatch(loadDeskeraSyncedProducts(pageSize, pageIndex, status, sortBy));
    };


    async function handleSyncDataSubmit() {
        try {
            const resp = await migrateShopifyData();
        } catch (err) {
            dispatch(addToast({
                type: "danger",
                title: "Failure",
                message: err.message
            }))
        }
    }

    return (
        <div className="p-4">
            <h3 className="mb-3">Deskera Products</h3>
            {(!config.data || ! config.data.productTwoWaySyncEnabled) && (
                <Alert type="warning">
                    Your synced Deskera Products will appear here. Please complete{" "}
                    <Link to="/app/woocommerce" className="text-alert-warning">
                        <u>Two Way Sync Setup</u>
                    </Link>{" "}
                    before continuing.
                </Alert>
            )}
            <RowSelectionTable
                data={data.content ? data.content : []}
                columns={columns}
                migrate={syncDeskeraProductsToWooCommerce}
                cart="woocommerce"
                refresh={refreshData}
                tab="Deskera Product"
                pageCount={pageCount}
                loading={loading}
                rowId="id"
            />
        </div>
    );
}
export default DeskeraProducts;


