import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadAccounts,
  loadConfig,
  loadJob,
  loadDeskeraSyncedProducts,
} from "src/redux/actions/shopify";
import Alert from "src/components/Alert";
import SyncStatus from "src/components/SyncStatus";
import ActiveInactive from "src/components/ActiveInactive";
import getSymbolFromCurrency from "currency-symbol-map";
import { migrateShopifyData, migrateShopifyDataForProducts, syncDeskeraProductsToShopify } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import RowSelectionTable from "src/components/RowSelectionTable";
import { getQueryParams } from "src/utils/Utility";


function DeskeraProducts() {
  const [syncStatus, setSyncStatus] = useState("enabled");
  const products = useSelector((state) => state.shopify.deskeraproducts);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.shopify.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const accounts = useSelector((state) => state.shopify.accounts);

  useEffect(() => {
    dispatch(loadConfig());
    if (accounts?.data?.length === 0) {
      dispatch(loadAccounts());
    }
    dispatch(loadDeskeraSyncedProducts());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const getProductType = (type) => {
    switch (type) {
      case "TRACKED":
        return "Tracked";
      case "NONTRACKED":
        return "Non-Tracked";
      case "BILL_OF_MATERIALS":
        return "Bill of Materials";
      default:
        return type;
    }
  }


  const data = useMemo(() => products.data, [products]);

  const columns = useMemo(
    () => [
      {
        Header: "Product Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Name",
        accessor: "productName",
        Cell: ({ cell: { row } }) => (
          <span>
          {row.original.productName} {row.original.productVariantName && row.original.productVariantName !== "Default Title" && "(" + row.original.productVariantName + ")"}
          </span>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ cell: { row } }) => (
          <span>
          {getProductType(row.original.type)}
          </span>
        )
      },
      {
        Header: "Opening Quantity",
        accessor: "inventorySales",
        className: "text-right",

      },
      {
        Header: "Unit Price",
        accessor: "productUnitPrice",
        Cell: ({ cell: {row} }) => (
          <span>
            {getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.productUnitPrice}
          </span>
        ),
        className: "text-right",
      },
      {
        Header: "Shopify Store",
        accessor: "shopifyStore",
        Cell: ({ cell: { row } }) => {
          const account =
            row.original.cartAccountId &&
            accounts?.data?.find((item) => item.id === row.original.cartAccountId);
          return account?.name || "";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { row } }) => {
          return <ActiveInactive status={row.original.status} />;
        }
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return <SyncStatus status={row.original.syncStatus} reason={row.original.reason} />;
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Product ID",
        accessor: "cartProductId",
      },
      {
        Header: "Variant ID",
        accessor: "productVariantId",
      }
    ],
    [accounts]
  );

  const config = useSelector((state) => state.shopify.config);

  useEffect(() => {
    setLoading(false);
    if(data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const refreshData = ({ pageSize, pageIndex, query}) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setLoading(true);

    const queryParams = getQueryParams(query);

    dispatch(loadDeskeraSyncedProducts(pageSize, pageIndex, queryParams));
  }; 


  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateShopifyData();
    } catch (err) {
      dispatch(addToast({
        type: "danger",
        title: "Failure",
        message: err.message
      }))
    }
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Deskera Products</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced orders will appear here. Please complete{" "}
          <Link to="/app/shopify" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <RowSelectionTable data={data.content ? data.content : []} columns={columns} migrate={syncDeskeraProductsToShopify} cart='shopify'
      refresh={refreshData} pageCount={pageCount} loading={loading}/>
    </div>
  );
}

export default DeskeraProducts;
