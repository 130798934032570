import clsx from "clsx";
import {
  DKDateRangePicker,
  getDateAsString,
  showLoader,
  removeLoader,
  DKListPicker,
  DKIcons,
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { exportSyncReports } from "src/api";
import ArrowClockwise from "src/assets/ArrowClockwise";
import Download from "src/assets/Download";
import ArrowRight from "src/assets/ArrowRight";
import EmptyTray from "src/assets/EmptyTray";
import ForwardRight from "src/assets/ForwardRight";
import UpArrowIcon from "src/assets/UpArrow";
import ic_calendar from "src/assets/ic_calendar.png";
import customSelectTheme from "src/utils/selectTheme";
import SearchBar from "./Filters/SearchBar";
import SearchFilter from "./Filters/SearchFilter";
import { addToast } from "src/redux/actions/toasts";
import { useDispatch } from "react-redux";

function ReportTable({
  columns,
  data,
  refresh,
  minHeight,
  pageCount: controlledPageCount,
  loading,
  rowId,
  filter,
  setFilter,
  exportPayload,
}) {
  const [downloadTriggered, setDownloadTriggered] = useState(false);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const [showHideExport, setShowHideExport] = useState(false);

  const dispatch = useDispatch();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      manualSortBy: true,
      autoResetSelectedRows: false,
      getRowId: (row) => row[rowId],
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const colummsLength = columns.length;
  const dataLength = data.length;

  const pageSizeOptions = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 30,
      label: "30",
    },
  ];

  useEffect(() => {
    if (
      filter.page !== pageIndex ||
      filter.limit !== pageSize ||
      (sortBy.length > 0 &&
        (filter.sortBy?.[0]?.id !== sortBy?.[0]?.id ||
          filter.sortBy?.[0]?.desc !== sortBy?.[0]?.desc))
    ) {
      onFilterUpdate({ page: pageIndex, limit: pageSize, sortBy });
    }
  }, [pageIndex, pageSize, sortBy]);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      minWidth: "12rem",
    }),
  };

  async function triggerDownload(format) {
    setDownloadTriggered(true);
    showLoader();
    try {
      const payload = exportPayload();
      const exportFile = await exportSyncReports(payload.query, format);

      let blobContent = new Blob([exportFile], {
        type:
          format == "XLS"
            ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            : "text/csv;charset=utf-8;",
      });
      const filename = `${payload.filename}.${String(format).toLowerCase()}`;
      const downloadAnchor = document.createElement("a");
      downloadAnchor.setAttribute("download", filename);
      /* Supporting IE */
      if (blobContent && navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blobContent, filename);
      } else if (downloadAnchor.download !== undefined) {
        /* Checking If download feature supported in browser */
        downloadAnchor.href = blobContent ? URL.createObjectURL(blobContent) : exportFile;
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
      }
    } catch (err) {
      console.log(err);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Failed to export report data",
        })
      );
    } finally {
      setDownloadTriggered(false);
      removeLoader();
    }
  }

  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-index-1 top-12 right-20 deskera-datepicker">
        <DKDateRangePicker
          className="w-500"
          onClose={() => {
            setShowHideCalendar(false);
          }}
          color={"rgb(33, 107, 165)"}
          startDate={filter.fromDate || new Date()}
          selectedStartDate={filter.fromDate || new Date()}
          selectedEndDate={filter.toDate || new Date()}
          onSelectDateRange={(fromDate, toDate) => {
            if (fromDate && toDate) {
              onFilterUpdate({
                fromDate,
                toDate,
              });
              setShowHideCalendar(false);
            }
          }}
        />
      </div>
    );
  };

  const getDateRangeString = () => {
    if (filter && filter.fromDate) {
      return (
        getDateAsString(filter.fromDate, "dd-MM-yyyy") +
        " to " +
        getDateAsString(filter.toDate, "dd-MM-yyyy")
      );
    } else {
      return "";
    }
  };

  const onFilterUpdate = (updatedFilter) => {
    setFilter((prevState) => ({ ...prevState, ...updatedFilter, page: updatedFilter.page || 0 }));
    if (updatedFilter.page === undefined) {
      gotoPage(0);
    }
  };

  return (
    <>
      <div className="d-flex flex-row mb-3">
        <button
          className="btn border-radius-m p-v-s text-white bg-primary mr-l font-weight-bold"
          onClick={() => refresh()}
          disabled={downloadTriggered}
        >
          <ArrowClockwise />
        </button>
        <div className="position-relative">
          <button
            className="border-radius-m text-wrap-none p-v-s dk-button-hover border-m bg-white px-2"
            onClick={() => setShowHideCalendar(true)}
            style={{ height: "38px" }}
          >
            <img
              src={ic_calendar}
              alt=""
              className="ic-r ic-s"
              style={{
                objectFit: "scale-down",
                marginBottom: "2px",
              }}
            />
            <span style={{ fontSize: "13px", color: "rgb(45, 43, 37)", marginLeft: "8px" }}>
              {getDateRangeString()}
            </span>
          </button>
          {showHideCalendar && getDateRangePicker()}
        </div>
        <div className="row justify-content-end mr-xs">
          <form className="form-inline">
            <SearchFilter className="mr-r" onFilterMap={(searchKey) => onFilterUpdate(searchKey)}>
              <SearchBar
                placeholder={`Search in remarks`}
                inputStyle={{ width: "12rem" }}
                queryKey={"search"}
              />
            </SearchFilter>
            {columns
              .filter((col) => col.filter === true)
              .map((col) => (
                <Select
                  key={col.accessor}
                  placeholder={col.Header}
                  className="mr-2"
                  styles={customStyles}
                  options={[
                    { value: "", label: "All" },
                    ...(col.options?.length
                      ? col.options.filter((item) => item.hidden !== true)
                      : []),
                  ]}
                  value={col.options?.filter((option) => option.value === filter[col.accessor])}
                  menuPlacement="auto"
                  onChange={(e) => onFilterUpdate({ [col.accessor]: e.value })}
                  theme={customSelectTheme}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              ))}
          </form>
          <div>
            <button
              className="btn dk-btn mr-2 px-3 text-white border bg-success border-radius-m"
              onClick={() => setShowHideExport(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <Download />
              </span>
              <span>Export</span>
            </button>
            {showHideExport && (
              <DKListPicker
                data={[`XLS`, `CSV`]}
                className="shadow-m position-absolute z-index-1"
                style={{ width: 100 }}
                icons={[DKIcons.doc_type.ic_xls, DKIcons.doc_type.ic_csv]}
                onSelect={(index, value) => {
                  if (index === 0) {
                    triggerDownload("XLS");
                  } else if (index === 1) {
                    triggerDownload("CSV");
                  }
                  setShowHideExport(false);
                }}
                onClose={() => setShowHideExport(false)}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className="card"
        style={{
          overflowX: "auto",
          height: "100%",
        }}
      >
        <table
          style={{ minHeight: minHeight ?? "auto" }}
          className="table m-0 dk-table-hover dk-table"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      { className: column.className },
                      column.getSortByToggleProps(),
                    ])}
                    style={{ whiteSpace: "nowrap", width: `${column.width ?? "auto"}` }}
                  >
                    <span className=" fs-r text-align-left fw-m fs-m text-gray cursor-hand ">
                      {column.render("Header")}
                    </span>
                    <span
                      className={clsx(
                        "svg-icon svg-disabled svg-baseline",
                        !column.isSorted && "invisible",
                        column.isSorted && column.isSortedDesc && "svg-flipped"
                      )}
                    >
                      <UpArrowIcon />
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading && dataLength === 0 && (
              <tr>
                <td colSpan={colummsLength}>
                  <div className="d-flex flex-column align-items-center">
                    <span className="mt-3 svg-disabled" style={{ width: "50px", height: "50px" }}>
                      <EmptyTray />
                    </span>
                    <span className="mt-2 text-muted">No records were found.</span>
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([{ className: cell.column.className }])}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
          {loading && (
            <tr>
              <td colSpan={colummsLength}>
                <div className="d-flex flex-column align-items-center">
                  <span className="mt-2 text-muted">Loading...</span>
                </div>
              </td>
            </tr>
          )}
        </table>
      </div>
      <div className="d-flex flex-row-reverse align-items-center mt-3">
        <div className="d-flex flex-row align-items-center">
          <span className="mr-2">Max rows per page:</span>
          <Select
            placeholder="Page"
            className="page-selector"
            options={pageSizeOptions}
            value={pageSizeOptions.filter((option) => option.value === pageSize)}
            menuPlacement="auto"
            onChange={(e) => {
              setPageSize(Number(e.value));
            }}
            theme={customSelectTheme}
            components={{
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
          />
        </div>
        <div className="d-flex flex-row align-items-center mr-2">
          <button className="btn mr-2" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ForwardRight />
            </span>
          </button>
          <button className="btn mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <span
              className={clsx(
                "svg-icon svg-baseline svg-flipped",
                canPreviousPage ? "svg-black" : "svg-disabled"
              )}
            >
              <ArrowRight />
            </span>
          </button>
          <span className="mr-2">
            {pageIndex + 1} / {Math.max(pageOptions.length, 1)}
          </span>
          <button className="btn mr-2" onClick={() => nextPage()} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ArrowRight />
            </span>
          </button>
          <button className="btn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            <span
              className={clsx("svg-icon svg-baseline", canNextPage ? "svg-black" : "svg-disabled")}
            >
              <ForwardRight />
            </span>
          </button>
        </div>
      </div>
    </>
  );
}

export default ReportTable;
