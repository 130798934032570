import { Link } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { migrateBrightPearlCustomersData } from "src/api";
import Alert from "src/components/Alert";
import ServerPaginationTable from "src/components/ServerPaginationTable";
import SyncStatus from "src/components/SyncStatus";
import { loadConfig, loadCustomers, loadJob } from "src/redux/actions/brightpearl";

const Customers = () => {
  const customers = useSelector((state) => state.brightpearl.customers);
  const accounts = useSelector((state) => state.brightpearl.accounts);
  const data = useMemo(() => customers.data, [customers]);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.brightpearl.job);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [status, setStatus] = useState([]);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "customerName",
      },
      {
        Header: "Email",
        accessor: "customerEmail",
      },
      {
        Header: "Deskera Contact Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartCustomerId}
            />
          );
        },
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.reason}</>;
        },
      },
    ],
    []
  );

  useEffect(() => {
    dispatch(loadCustomers());
    dispatch(loadConfig());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  const config = useSelector((state) => state.brightpearl.config);

  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const refreshData = ({ pageSize, pageIndex, status, sortBy }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setStatus(status);
    setSortBy(sortBy);
    setLoading(true);
    dispatch(loadCustomers(pageSize, pageIndex, status, sortBy));
  };

  return (
    <div className="p-4">
      <h3 className="mb-3">Customers</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced customers will appear here. Please complete{" "}
          <Link to="/app/brightpearl" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ServerPaginationTable
        data={data.content ? data.content : []}
        columns={columns}
        migrate={migrateBrightPearlCustomersData}
        cart="brightpearl"
        refresh={refreshData}
        tab="Customers"
        pageCount={pageCount}
        loading={loading}
        rowId="cartCustomerId"
      />
    </div>
  );
};

export default Customers;
