import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getBigCommerceSyncReports } from "src/api";
import Alert from "src/components/Alert";
import ReportTable from "src/components/ReportTable";
import SyncStatus from "src/components/SyncStatus";
import SyncType from "src/components/SyncType";
import { addToast } from "src/redux/actions/toasts";
import { getDateAsString } from "deskera-ui-library";
import moment from "moment";
import { loadConfig } from "src/redux/actions/bigcommerce";

const initialFilterState = {
  fromDate: new Date(),
  toDate: new Date(),
  syncTo: "",
  page: 0,
  limit: 10,
  sortBy: [],
  search: "",
};

export default function SyncReports({ syncTo }) {
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ ...initialFilterState });
  const [payload, setPayload] = useState({});
  const [reportData, setReportData] = useState([]);

  const dispatch = useDispatch();
  const config = useSelector((state) => state.bigcommerce.config);

  const columns = [
    {
      Header: "Date",
      accessor: "syncDate",
      Cell: ({ cell: { row } }) => moment(row.original.syncDate).format("YYYY-MM-DD hh:MM a z"),
    },
    {
      Header: "Order ID",
      accessor: "orderNumber",
    },
    {
      Header: "Deskera Invoice Number ",
      accessor: "deskeraDocumentCode",
    },
    {
      Header: "Type of Sync",
      accessor: "syncType",
      Cell: ({ cell: { row } }) => {
        return <SyncType type={row.original.syncType} />;
      },
      filter: syncTo === "DESKERA",
      options: [
        {
          label: "Order",
          value: "ORDER",
        },
        {
          label: "Payment",
          value: "PAYMENT",
        },
        {
          label: "Refund",
          value: "REFUND",
        },
      ],
    },
    {
      Header: "Sync Status",
      accessor: "syncStatus",
      Cell: ({ cell: { row } }) => {
        return (
          <SyncStatus
            status={row.original.syncStatus}
            reason={row.original.remarks}
            id={row.original.orderNumber}
          />
        );
      },
      filter: true,
      options: [
        {
          label: "Successful",
          value: "SUCCESSFUL",
        },
        {
          label: "Failed",
          value: "FAILED",
        },
        {
          label: "Pending",
          value: "PENDING",
        },
      ],
    },
    {
      Header: "Remarks",
      accessor: "remarks",
      disableSortBy: true,
    },
  ];

  useEffect(() => {
    setFilter({ ...initialFilterState, syncTo });
  }, [syncTo]);

  useEffect(() => {
    if (!config?.data || !config?.data?.syncEnabled) {
      dispatch(loadConfig());
    }
    if (!isEqual(payload, filter)) {
      loadReportData();
    }
  }, [filter, config]);

  const loadReportData = async () => {
    if (!config?.data || !config?.data?.syncEnabled || !filter.syncTo) {
      return;
    }
    setPayload({ ...filter });
    setLoading(true);
    try {
      const response = await getBigCommerceSyncReports(
        getDateAsString(filter.fromDate, "dd-MM-yyyy"),
        getDateAsString(filter.toDate, "dd-MM-yyyy"),
        filter.syncTo,
        filter.limit,
        filter.page,
        filter.syncType || "",
        filter.syncStatus || "",
        filter.search || "",
        filter.sortBy?.[0]?.id || "",
        filter.sortBy?.[0]?.desc == true ? "desc" : "asc" || ""
      );
      const data = response.content?.map((rowData) => ({
        ...rowData,
        id: `${rowData.orderNumber}_${rowData.syncType}`,
      }));
      setReportData(data);
      setPageCount(response.totalPages);
    } catch (err) {
      console.error("Error in fetching report data", err);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Failed to fetch report data",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getExportPayload = () => {
    if (!config?.data || !config?.data?.syncEnabled || !filter.syncTo) {
      return null;
    }

    const query =
      "module=BIGCOMMERCE_SYNC_REPORT" +
      "&from_date=" +
      getDateAsString(payload.fromDate, "dd-MM-yyyy") +
      "&to_date=" +
      getDateAsString(payload.toDate, "dd-MM-yyyy") +
      "&query=syncTo=" +
      payload.syncTo +
      ",syncType=" +
      (payload.syncType || "") +
      ",syncStatus=" +
      (payload.syncStatus || "") +
      ",remarks=" +
      (payload.search || "") +
      "&sort=" +
      (payload.sortBy?.[0]?.id || "") +
      "&sortDir=" +
      (payload.sortBy?.[0]?.desc == true ? "desc" : "asc" || "");

    return {
      filename:
        syncTo === "DESKERA"
          ? "BigCommerce to Deskera Sync Report"
          : "Deskera to BigCommerce Sync Report",
      query,
    };
  };

  const isEqual = (obj1, obj2) => {
    if (obj1 === null && obj2 === null) return true;
    if (obj1 === null || obj2 === null) return false;

    try {
      const arr1 = Object.entries(obj1).sort();
      const arr2 = Object.entries(obj2).sort();

      return JSON.stringify(arr1) === JSON.stringify(arr2);
    } catch (err) {
      console.error("Failed to parse objects", err);
      return false;
    }
  };

  return (
    <div className="p-4">
      <h3 className="mb-3">
        {syncTo === "DESKERA" ? "BigCommerce to Deskera" : "Deskera to BigCommerce"} Sync Report
      </h3>
      {(!config?.data || !config?.data?.syncEnabled) && (
        <Alert type="warning">
          Your synced logs will appear here. Please complete{" "}
          <Link to="/app/bigcommerce" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <ReportTable
        data={reportData || []}
        columns={columns}
        refresh={loadReportData}
        filter={filter}
        setFilter={setFilter}
        pageCount={pageCount}
        loading={loading}
        exportPayload={getExportPayload}
        rowId="id"
      />
    </div>
  );
}
