import { DKButton } from 'deskera-ui-library';
import React from 'react'
import DatePicker from "react-datepicker";
import CloseIcon from 'src/assets/Close';

export default function SyncByDateFilter({
    selected,
    onChange,
    startDate,
    endDate,
    selectsRange,
    isInline,
    syncSubmit,
    onClose
}) {
  return (
    <div className="d-flex">
    <div
      className="vw-100 vh-100 d-flex justify-content-center align-items-center"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card dk-card-shadow d-flex border border-radius-l"
        style={{ minWidth: "30%" }}
      >
        <div className="card-body d-flex flex-column" style={{ align: "center" }}>
          <div className="card-body d-flex flex-column">
            <div className="text-muted mb-3 d-flex flex-column">
              <span className="font-weight-bold">Sync for dates:</span>
              <span>Click once to select a from-date, then select the to-date</span>
            </div>
            <div class="overflow-hidden">
              <DatePicker
                selected={selected}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange={selectsRange}
                inline={isInline}
              />
            </div>
          </div>
          <div className="d-flex flex-row-reverse">
            <DKButton
              className="bg-success px-3 text-white"
              onClick={syncSubmit}
              title={"Sync"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
              onClick={onClose}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
