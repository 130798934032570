import { DKButton, DKInput, showLoader, removeLoader } from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBigCommerceAlertSettings, saveBigCommerceAlertSettings } from "src/api";
import CloseIcon from "src/assets/Close";
import { Checkbox } from "src/components/Checkbox";
import { addToast } from "src/redux/actions/toasts";
import { Utility } from "src/utils/Utility";

function AlertsSettings(props) {
  const dispatch = useDispatch();

  const [alertSyncSettings, setAlertSyncSettings] = useState([
    {
      syncType: "ORDER",
      syncStatus: "FAILED",
      enabled: false,
      email: null,
    },
    {
      syncType: "FULFILLMENT",
      syncStatus: "FAILED",
      enabled: false,
      email: null,
    },
    {
      syncType: "PAYMENT",
      syncStatus: "FAILED",
      enabled: false,
      email: null,
    },
    {
      syncType: "REFUND",
      syncStatus: "FAILED",
      enabled: false,
      email: null,
    },
  ]);

  useEffect(() => {
    getDefaultAlertSettings();
  }, []);

  const getDefaultAlertSettings = async () => {
    try {
      showLoader();
      let response = await getBigCommerceAlertSettings();
      updateAlertSettings(response);
    } catch (error) {
      console.error(error);
    } finally {
      removeLoader();
    }
  };

  const updateAlertSettings = (data) => {
    if (data.length) {
      const alertSettingsMap = {};
      data.forEach((item) => {
        alertSettingsMap[`${item.syncType}_${item.syncStatus}`] = item;
      });

      const updatedSetting = alertSyncSettings.map((prevData) => {
        let updatedData = { ...prevData };
        if (alertSettingsMap[`${updatedData.syncType}_${updatedData.syncStatus}`]) {
          updatedData = {
            ...updatedData,
            ...alertSettingsMap[`${updatedData.syncType}_${updatedData.syncStatus}`],
          };
        }

        return updatedData;
      });
      setAlertSyncSettings(updatedSetting);
    }
  };

  const validate = () => {
    let isValid = true;
    alertSyncSettings.forEach((item) => {
      if (item.enabled && !validateEmail(item.email)) {
        isValid = false;
      }
    });
    return isValid;
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const updateRow = (index, key, value) => {
    setAlertSyncSettings((oldData) => {
      let updatedData = oldData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });
      return [...updatedData];
    });
  };

  const handleSubmit = async (closePopup = false) => {
    try {
      if (validate()) {
        showLoader();
        const response = await saveBigCommerceAlertSettings(alertSyncSettings);
        updateAlertSettings(response);
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Successfully updated alert settings.",
          })
        );
      } else {
        dispatch(
          addToast({
            type: "danger",
            title: "Error",
            message: "Invalid alert settings.",
          })
        );
      }
      if (closePopup === true) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      dispatch(
        addToast({
          type: "danger",
          title: "Error",
          message: "Failed to update alert settings.",
        })
      );
    } finally {
      removeLoader();
    }
  };

  const handleClose = () => {
    props.closePopup();
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div className="card dk-card dk-card-shadow d-flex border border-radius-l">
        <div className="card-header bg-transparent border-none">
          <h3 className="mt-2 ml-2">Sync Alerts Settings</h3>
        </div>
        <div className="card-body d-flex flex-column" style={{ align: "center" }}>
          <div
            class="d-block border-m rounded mb-3"
            style={{ height: "40vh", overflowY: "auto", overflowX: "hidden" }}
          >
            <table className="table border-none rounded">
              <thead className="sticky-top bg-white">
                <tr>
                  <th scope="col" style={{ minWidth: 150 }}>
                    <h5 className="text-body mb-0">Sync Type</h5>
                  </th>
                  <th scope="col" style={{ minWidth: 100 }}>
                    <h5 className="text-body mb-0">Enable</h5>
                  </th>
                  <th scope="col" style={{ minWidth: 300 }}>
                    <h5 className="text-body mb-0">Email</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {alertSyncSettings?.map((item, index) => (
                  <tr key={index}>
                    <td>{Utility.convertInTitleCase(item.syncType)}</td>
                    <td>
                      <Checkbox
                        onChange={(e) => updateRow(index, "enabled", !item.enabled)}
                        checked={item.enabled}
                      />
                    </td>
                    <td>
                      <DKInput
                        type="text"
                        value={item?.email}
                        className="mb-m"
                        direction="VERTICAL"
                        titleStyle={{
                          display: "none",
                        }}
                        onBlur={() => {}}
                        onChange={(e) => {
                          updateRow(index, "email", e);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer bg-transparent border-none">
          <div className="d-flex flex-row-reverse">
            <DKButton
              className="bg-success mx-2 text-white"
              onClick={() => handleSubmit(true)}
              title={"Save & Close"}
              style={{ marginLeft: "10px", marginRight: "5px" }}
            ></DKButton>
            <DKButton
              className="bg-success mx-2 text-white"
              onClick={() => handleSubmit()}
              title={"Save"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mx-2 font-weight-bold text-muted border border-secondary"
              onClick={handleClose}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertsSettings;
