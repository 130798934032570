const { Link } = require("react-router-dom");

function NotFound() {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vw-100 vh-100">
      <h1 className="text-danger font-weight-bold display-1">404</h1>
      <p>Oops! Something went wrong.</p>
      <Link to="/app">Go back to Dashboard</Link>
    </div>
  );
}

export default NotFound;

