import {useSelector} from "react-redux";

function getInitials(name) {
  return name
    .split(" ")
    .map(n => n[0])
    .join("")
    .substring(0, 2);
}

function Avatar({name}) {
  return (
    <div
    className="font-weight-bold d-flex justify-content-center align-items-center rounded-circle"
      style={{
        backgroundColor: "#304072",
        color: "rgba(256, 256, 256, 0.9)",
        width: 40,
        height: 40,
        boxShadow: "inner 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)",
      }}>
      <span>{getInitials(name)}</span>
    </div>
  );
}

export default Avatar;
