import {
  DKButton,
  DKIcons
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getBigCommerceStoreWarehouseMapping,
  saveBigCommerceStoreWarehouseMapping
} from "src/api";
import CloseIcon from "src/assets/Close";
import { addToast } from "src/redux/actions/toasts";
import customSelectTheme from "src/utils/selectTheme";

function StoreWarehouseMapping(props) {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const storeList = useSelector((state) => state.bigcommerce.accounts);
  const [storeOptions, setStoreOptions] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.storeMapping) {
      getStoreMappingAttributes();
    }

    getStoreOptions();
  }, [props.storeMapping, storeList]);

  const getStoreMappingAttributes = async () => {
    try {
      let response = await getBigCommerceStoreWarehouseMapping();
      if (response?.deskeraWarehouses?.length) {
        let attributes = response.deskeraWarehouses.map((value) => ({
          label: value.name,
          value: value.code,
        }));
        setAttributeOptions(attributes);
      }      

      let warehouseNameMap = {};
      response?.deskeraWarehouses?.forEach(warehouse => {
        warehouseNameMap[warehouse.code] = warehouse.name
      });
      let storeNameMap = {};
      storeList?.data?.forEach((store) => {
        storeNameMap[store.id] = store.name;
      })
      if (response?.warehouseStoreMapping?.length) {
        let defaultData = [];
        response?.warehouseStoreMapping.forEach((item) => {
          if(warehouseNameMap[item.deskeraWarehouseCode] && storeNameMap[item.storeHash]) {
            defaultData.push({
              attribute: {
                label: warehouseNameMap[item.deskeraWarehouseCode],
                value: item.deskeraWarehouseCode,
              },
              store: {
                label: storeNameMap[item.storeHash],
                value: item.storeHash,
              }
            })
          }
        });
        setData(defaultData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStoreOptions = () => {
    if (storeList?.data?.length) {
      console.log(storeList);
      let storeOptions = storeList.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setStoreOptions(storeOptions);
    }
  };

  const getAttributeOptions = (selectedOption) => {
    let filteredOptions = [...attributeOptions];
    if (data?.length) {
      let selectedAttributes = data.map((item) => item?.attribute?.label);
      filteredOptions = filteredOptions.filter(
        (item) => !selectedAttributes.includes(item.label) || selectedOption?.label === item.label
      );
    }
    return filteredOptions;
  };

  const validate = () => {
    let validFlag = true;

    if (data?.length) {
      for (let mappedValue of data) {
        if (!(mappedValue?.attribute?.label && mappedValue?.store?.label)) {
          validFlag = false;
          break;
        }
      }
    } else {
      validFlag = false;
    }
    return validFlag;
  };

  const updateRow = (index, key, value) => {
    setData((oldData) => {
      let updatedData = [...oldData];
      updatedData[index][key] = value;
      return [...updatedData];
    });
  };

  const addRow = () => {
    let newRow = {
      attribute: null,
      store: null,
    };
    setData((updatedData) => [...updatedData, newRow]);
  };

  const deleteRow = (index) => {
    setData((oldData) => {
      let updatedData = [...oldData];
      updatedData.splice(index, 1);
      return [...updatedData];
    });
  };

  const optionMappingTable = () => {
    return (
      <table className="table border-none rounded">
        <thead className="sticky-top bg-white">
          <tr>
            <th scope="col">
              <h5 className="text-body mb-0">Deskera Warehouse</h5>
            </th>
            <th scope="col">
              <h5 className="text-body mb-0">Bigcommerce Store</h5>
            </th>
            <th scope="col">
              <h5 className="text-body mb-0 float-right">Action</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Select
                    placeholder="Deskera Field Value"
                    className="ml-s text-align-left"
                    options={getAttributeOptions(item.attribute)}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(e) => {
                      updateRow(index, "attribute", e);
                    }}
                    theme={customSelectTheme}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={true}
                    value={item.attribute}
                  />
                </td>
                <td>
                  <Select
                    placeholder="Bigcommerce Store"
                    className="ml-s text-align-left"
                    options={storeOptions}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(e) => {
                      updateRow(index, "store", e);
                    }}
                    theme={customSelectTheme}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={true}
                    value={item.store}
                  />
                </td>
                <td>
                  <DKButton
                    className="border-l text-white float-right mr-2"
                    icon={DKIcons.ic_delete}
                    onClick={() => {
                      deleteRow(index);
                    }}
                    style={{
                      border: "1px",
                    }}
                    title=""
                  ></DKButton>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={3}>
                <p className="mt-2">No Mapping Data</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const handleSubmit = async (closePopup = false) => {
    try {
      if (validate()) {
        let payload = data.map((item) => ({
          deskeraWarehouseCode: item.attribute.value,
          storeHash: item.store.value,
        }));
        await saveBigCommerceStoreWarehouseMapping(payload);
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Successfully updated warehouse mapping fields.",
          })
        );
      } else {
        dispatch(
          addToast({
            type: "danger",
            title: "Error",
            message: "Invalid mapping data.",
          })
        );
      }
      if (closePopup === true) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      dispatch(
        addToast({
          type: "danger",
          title: "Error",
          message: "Failed to update warehouse mapping fields.",
        })
      );
    }
  };

  const handleClose = () => {
    props.setCustomerFilterState(false);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card dk-card-shadow d-flex border border-radius-l"
        style={{ width: "50vw" }}
      >
        <div className="card-header bg-transparent border-none">
          <h3 className="mt-2 ml-2">Warehouse Mapping Configuration</h3>
        </div>
        <div className="card-body d-flex flex-column" style={{ align: "center" }}>
          <div
            class="d-block border-m rounded mb-3"
            style={{ height: "40vh", overflowY: "auto", overflowX: "hidden" }}
          >
            {optionMappingTable()}
          </div>
          <div>
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={() => {
                addRow();
              }}
              style={{ marginLeft: "" }}
              disabled={data?.length >= attributeOptions?.length}
            >
              + Add Mapping
            </button>
          </div>
        </div>
        <div className="card-footer bg-transparent border-none">
          <div className="d-flex flex-row-reverse">
            <DKButton
              className="bg-success mx-2 text-white"
              onClick={() => handleSubmit(true)}
              title={"Save & Close"}
              style={{ marginLeft: "10px", marginRight: "5px" }}
            ></DKButton>
            <DKButton
              className="bg-success mx-2 text-white"
              onClick={() => handleSubmit()}
              title={"Save"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mx-2 font-weight-bold text-muted border border-secondary"
              onClick={handleClose}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreWarehouseMapping;
