function ShipStation() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 402 405.96">
      <path class="cls-1"
        d="M37.58,391.35l11-52.54H59.88l-4.35,20.64.17.08a15.83,15.83,0,0,1,12-5.44c5.9,0,9.26,3.5,9.26,9.31a35.25,35.25,0,0,1-.82,6.71l-4.43,21.24H60.37l4.26-20.57a21.34,21.34,0,0,0,.49-4.55c0-2.23-1-3.8-3.52-3.8-3.61,0-7.46,4.17-8.94,11l-3.77,18Z"
      />
      <path class="cls-1"
        d="M114.65,382.86a7.48,7.48,0,0,0,4.67,1.48c6.32,0,10.66-9.46,10.66-16.1,0-2.75-1.07-5.66-4.51-5.66-3.93,0-7.62,4.25-8.93,10.66ZM98.5,406l7.87-37.64c.9-4.25,1.81-9.91,2.3-13.64h10L118,360.2h.17a15.34,15.34,0,0,1,12-6.11c8.28,0,11.64,6.18,11.64,13.11,0,12.52-8.69,24.89-22,24.89a14.75,14.75,0,0,1-6.63-1.34h-.25L109.82,406Z"
        />
      <path class="cls-1"
        d="M202.21,345.14l-2.05,9.76h8.28l-1.64,7.68h-8.28l-3,13.64a20.69,20.69,0,0,0-.57,4.17c0,1.94,1,3.06,3.36,3.06a21,21,0,0,0,3-.15l-1.31,8a25,25,0,0,1-6.72.75c-6.72,0-10-3.21-10-7.9a28.93,28.93,0,0,1,.82-6.41l3.2-15.13h-5L184,354.9h5l1.4-6.7Z" />
        <path d="M234.28,362.73a12.21,12.21,0,0,0-2.87-.29c-8.43,0-12.26,8.07-12.26,15.42,0,3.51,1.23,5.89,4.27,5.89,3.36,0,7.21-3.8,8.93-12.08Zm-3.4,28.62c.08-2.09.32-4.4.49-6.86h-.25c-3.52,5.67-8.28,7.6-12.46,7.6-6.8,0-11.15-4.91-11.15-12.14,0-13.79,6.52-25.34,27-25.34a48,48,0,0,1,12.38,1.72l-4.14,18.92a84.75,84.75,0,0,0-1.64,16.1Z"
        />
      <path class="cls-1" d="M270.7,345.14l-2.06,9.76h8.28l-1.63,7.68H267l-2.94,13.64a20.77,20.77,0,0,0-.58,4.17c0,1.94,1,3.06,3.37,3.06a21.19,21.19,0,0,0,3-.15l-1.32,8a25,25,0,0,1-6.72.75c-6.72,0-10-3.21-10-7.9a28.93,28.93,0,0,1,.82-6.41l3.2-15.13h-5l1.64-7.68h5l1.39-6.7Z"/>
      <path class="cls-1" d="M345.57,391.35l5.16-24.6c1-4.47,1.56-8.49,2.13-11.85h9.92l-.66,5.59h.17a16.23,16.23,0,0,1,13-6.4c6.31,0,9.59,3.65,9.59,9.39a37.28,37.28,0,0,1-.73,6.4indl-4.51,21.47H368.36l4.34-20.94a21.43,21.43,0,0,0,.41-4c0-2.31-.9-3.95-3.53-3.95-3.43,0-7.54,4-9.09,11.78L357,391.35Z" />
      <path class="cls-1" d="M92.26,350.22h.16c3.48,0,6.49-2.14,6.57-5.9,0-2.95-2.19-5.08-5.6-5.08a6.11,6.11,0,0,0-6.41,5.9,4.91,4.91,0,0,0,5.28,5.08" />
      <path class="cls-1" d="M96.45,354.65h-11l-4.88,23.21a28.3,28.3,0,0,0-.82,6.34c0,4.65,3.25,7.82,9.9,7.82a25,25,0,0,0,6.65-.74l1.3-7.89a20.71,20.71,0,0,1-3,.15c-2.36,0-3.33-1.1-3.33-3a20.15,20.15,0,0,1,.57-4.13Z" />
      <path class="cls-1" d="M290.17,350.22h.17c3.48,0,6.48-2.14,6.56-5.9,0-2.95-2.19-5.08-5.6-5.08a6.11,6.11,0,0,0-6.4,5.9,4.91,4.91,0,0,0,5.27,5.08" />
      <path class="cls-1" d="M293.73,354.65h-11l-4.93,23.24a28.22,28.22,0,0,0-.81,6.34c0,4.64,3.24,7.82,9.89,7.82a25.45,25.45,0,0,0,6.66-.74l1.29-7.89a20.64,20.64,0,0,1-3,.15c-2.35,0-3.32-1.11-3.32-3a21,21,0,0,1,.56-4.13Z" />
      <path class="cls-1" d="M24.85,362.79c-4.59-2.75-7.3-5.06-7.3-7.81,0-3.06,2.95-5.59,8.12-5.59,3.75-.07,7.6,1.56,10.35,3l2.16-8.92,0-.22a24.61,24.61,0,0,0-11.81-2.85c-13,0-20.58,5.94-20.58,16.18,0,5.89,4.59,10.35,9.92,13.48,4.59,2.61,6.88,4,6.88,6.94,0,3.95-3.93,6.11-8.52,6-4.39,0-9-2.46-11.85-4L0,388.44A26.92,26.92,0,0,0,13.29,392c12.4,0,21.23-4.66,21.23-16.77,0-5.44-3.61-9.1-9.67-12.46" />
      <path class="cls-1" d="M167.15,362.79c-4.59-2.75-7.29-5.06-7.29-7.81,0-3.06,2.95-5.59,8.11-5.59,3.76-.07,7.6,1.56,10.36,3l2.15-8.92,0-.22a24.61,24.61,0,0,0-11.81-2.85c-13,0-20.58,5.94-20.58,16.18,0,5.89,4.59,10.35,9.92,13.48,4.59,2.61,6.89,4,6.89,6.94,0,3.95-3.94,6.11-8.53,6-4.38,0-9-2.46-11.84-4l-2.27,9.39A26.92,26.92,0,0,0,155.59,392c12.4,0,21.23-4.66,21.23-16.77,0-5.44-3.6-9.1-9.67-12.46" />
      <path class="cls-1" d="M394,351.82H396a1.32,1.32,0,0,0,.93-.41,1.29,1.29,0,0,0,.42-1,1.35,1.35,0,0,0-.42-1,1.31,1.31,0,0,0-.93-.4H394Zm-1,3.85v-7.49h3a2.39,2.39,0,0,1,1.67.63,2.12,2.12,0,0,1,.69,1.64,2.18,2.18,0,0,1-1.91,2.23l2,3h-1.24l-1.94-3H394v3Zm-3.18-3.76a5.63,5.63,0,1,0,1.66-4,5.46,5.46,0,0,0-1.66,4m-.85,0a6.52,6.52,0,0,1,6.52-6.53,6.49,6.49,0,0,1,3.3.87,6.27,6.27,0,0,1,2.37,2.37,6.54,6.54,0,0,1,.86,3.29,6.53,6.53,0,1,1-13.05,0" />
      <path class="cls-1" d="M344.08,375.73v-7.16l-6.24-.94a18.29,18.29,0,0,0-1.86-4.48l3.75-5.08L334.67,353l-5.08,3.74a17.73,17.73,0,0,0-4.48-1.85l-.94-6.24H317l-.94,6.24a17.58,17.58,0,0,0-4.48,1.85L306.52,353l-5.06,5.05,3.75,5.08a18,18,0,0,0-1.86,4.48l-6.24.94v7.16l6.24.94a17.6,17.6,0,0,0,1.86,4.48l-3.75,5.08,5.06,5,5.08-3.75a17.4,17.4,0,0,0,4.48,1.86l.94,6.25h7.15l.94-6.25a17.88,17.88,0,0,0,4.48-1.85l5.08,3.75,5.06-5.06L336,381.15a18.29,18.29,0,0,0,1.86-4.48ZM320.59,380a7.85,7.85,0,1,1,7.85-7.85A7.86,7.86,0,0,1,320.59,380Z" />
      <path id="Gear" class="cls-1" d="M222.76,292.48H178.23l-5.89-38.87A110.53,110.53,0,0,1,144.46,242l-31.64,23.35-31.5-31.47,23.35-31.66a109.68,109.68,0,0,1-11.55-27.9l-38.88-5.85V124l38.87-5.88a110.51,110.51,0,0,1,11.56-27.88L81.32,58.57l31.5-31.49,31.64,23.35a110,110,0,0,1,27.88-11.56L178.23,0h44.53l5.85,38.87a109.88,109.88,0,0,1,27.9,11.56l31.63-23.35,31.51,31.49L296.3,90.21a110.29,110.29,0,0,1,11.55,27.88L346.72,124v44.54l-38.87,5.85a110.06,110.06,0,0,1-11.55,27.89l23.35,31.65-31.5,31.5-31.64-23.35a110.57,110.57,0,0,1-27.9,11.56l-5.85,38.87ZM200.48,97.34a48.9,48.9,0,1,0,48.89,48.89,48.94,48.94,0,0,0-48.89-48.89Z" />
    </svg>
  );
}

export default ShipStation;
