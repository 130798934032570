import React, { useEffect, useRef, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DKLabel, DKInput, DKButton, showAlert, DKIcons, showLoader, removeLoader } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import customSelectTheme from "src/utils/selectTheme";
import Select from "react-select";
import MultiSelect from "react-multi-select-component";
import MultiTextInput from "src/components/MultiTextBox";
import { loadCustomerFilterConfig } from "src/redux/actions/magento";
import validate from "namor/dist/validate";
import Moment, { suppressDeprecationWarnings } from "moment";
import { startDateFormat, yearFirstDateFormat } from "src/utils/Constants";
import { addToast } from "src/redux/actions/toasts";
import { Input } from "@material-ui/core";
import { loadConfig } from "src/redux/actions/magento";
import { saveMagentoCusomterFilterConfig } from "src/api";
import { setConfig } from "src/redux/actions/foodics";
function CustomerFilterMapping(props) {
  const [customerFilterOption, setCustomerFilterOption] = useState([]);
  const [customerFilter, setCustomerFilter] = useState("ALL");
  const [customerFilterPayload, setCustomerFilterPayload] = useState(null);
  const [optionText, setOptionText] = useState([]);
  const [hover, setHover] = useState(false);
  const [triggered, setTriggered] = useState(false);
  const [data, setData] = useState([]);
  const [isAllSeletected, setIsAllSelected] = useState(false);
  const [currentRow, setCurrentRow] = useState(0);
  const [selected, setSelected] = useState([]);
  const customerFieldData = useSelector((state) => state.magento.magentoCusomterFilter);
  const dispatch = useDispatch();
  const [filterOptionArray, setFilterOptionArray] = useState([]);
  const [sinceDate, setSinceDate] = useState("");
  const [error, setError] = useState("");
  const config = useSelector((state) => state.magento.config);


  useEffect(() => {
    if (customerFieldData && customerFieldData?.data.length !== 0) {
      let tempCustomerFilterOption = [];
      customerFieldData?.data.map((item) => {
        const doExist =  config?.data?.magentoCustomerFilter ? config?.data?.magentoCustomerFilter.filter((filter)=>filter.field === item.field) : [];
        item.displayLabel !== null && item.displayLabel !=='ALL'&&
          tempCustomerFilterOption.push({
            label: item.displayLabel,
            value: item.value,
            input: item.input,
            list:
              item.input === "DROPDOWN"
                ? item?.options.filter((op) => op?.label?.trim() !== "")
                : [],
            custom: item.custom,
            field: item.field,
            isDisabled :doExist.length !==0
          });
      });

      if (
        config?.data?.magentoCustomerFilter !== undefined &&
        config?.data?.magentoCustomerFilter !== undefined &&
        config?.data?.magentoCustomerFilter.find((item)=>item.field !=="ALL")
      ) {
        let tempData = [];
        config?.data?.magentoCustomerFilter.map((filterData, index) => {
          filterData.field === "ALL" && setIsAllSelected(true);
          let currentCustomerFilterOptionData = tempCustomerFilterOption.find((item) => {
            return item.field === filterData.field;
          }).list;
          const dateValue =
            filterData.input === "DATE"
              ? Moment(filterData.value, startDateFormat).format(yearFirstDateFormat)
              : "";
          tempData.push({
            id: 0,
            customerFilterData: [...tempCustomerFilterOption],
            customerFilterOptionData: currentCustomerFilterOptionData,
            inputType: filterData.input,
            customerFilterValue: tempCustomerFilterOption.find((item) => {
              return item.field === filterData.field;
            }),
            customerFilterOptionValue:
              filterData.input === "DATE"
                ? dateValue
                : filterData.input === "TEXT"
                ? filterData.value.split(",")
                : currentCustomerFilterOptionData.filter((item) =>
                    filterData.value.split(",").includes(item.value)
                  ),
          });
        });
        setCurrentRow(tempData.length-1);
        setData(tempData);
      } else {
        let tempData = [
          {
            id: 0,
            customerFilterData: [...tempCustomerFilterOption],
            customerFilterOptionData: [],
            inputType: "DROPDOWN",
            customerFilterValue: null,
            customerFilterOptionValue: null,
          },
        ];
        setData(tempData);
      }

      setCustomerFilterOption(tempCustomerFilterOption);
    }
  }, [customerFieldData]);


  const selectedDateConfig = (date, index) => {
    let tempData = [...data];
    tempData[index].customerFilterOptionValue = date.target.value;
    setData(tempData);
  };

  const optionFilterConfig = (e, index) => {
    setIsAllSelected(false);
    setFilterOptionArray(e.list);
    let tempData = [...data];
    
    let tempCustomerFilterOption = [...customerFilterOption];
    const optionIndex = tempCustomerFilterOption.findIndex(
      (customerOpt) => customerOpt.field === e.field
    );
    tempCustomerFilterOption[optionIndex].isDisabled = true;
    
    if (tempData[index].customerFilterValue !== null) {
      const previousIndex = tempCustomerFilterOption.findIndex(
        (customerOpt) => customerOpt.field === tempData[index].customerFilterValue.field
      );
      tempCustomerFilterOption[previousIndex].isDisabled = false;
    }
    setCustomerFilterOption(tempCustomerFilterOption);

   
    tempData[index].customerFilterValue = e;
    tempData[index].customerFilterOptionValue = null;
    tempData[index].inputType = e.input;
    e.input === "DROPDOWN"
      ? e.input === "DATE"
        ? (tempData[index].customerFilterOptionData = "")
        : (tempData[index].customerFilterOptionData = e.list)
      : (tempData[index].customerFilterOptionData = []);

    if (e.label === "ALL") {
      tempData = [
        {
          id: 0,
          customerFilterData: [...customerFilterOption],
          customerFilterOptionData: [],
          inputType: "TEXT",
          customerFilterValue: e,
          customerFilterOptionValue: ["ALL"],
        },
      ];

      setIsAllSelected(true);
      setCurrentRow(0);
    }
    setData(tempData);
  };

  const selectedOptionConfig = (e, index) => {
    let tempData = [...data];
    tempData[index].customerFilterOptionValue = e;
    setData(tempData);
  };

  const onMultiTextInputConfig = (value, index) => {
    let tempData = [...data];

    if (value.length === 0) {
      tempData[index].customerFilterOptionValue = [];
    } else if (tempData[index].customerFilterOptionValue?.includes(value[-1])) {
      tempData[index].customerFilterOptionValue = tempData[index].customerFilterOptionValue.filter(
        (item) => item !== value.toString()
      );
    } else {
      if (tempData[index].customerFilterOptionValue == null) {
        tempData[index].customerFilterOptionValue = [];
      }
      tempData[index].customerFilterOptionValue = value;
    }
    setData(tempData);
  };

  const validate = () => {
    let filterEmptyValue = data.filter((currentData) => currentData.customerFilterValue === null);
    let filterOptionEmptyValue = data.filter(
      (currentData) => currentData.customerFilterOptionValue === null
    );
    if (filterEmptyValue.length > 0) {
      showAlert("Alert", "Please Select the Contact Filter first");
      return false;
    }
    if (filterOptionEmptyValue?.length > 0) {
      showAlert("Alert", "Please Enter Contact Filter Option first ");
      return false;
    }
    return true;
  };

  const addRow = () => {
    if (isAllSeletected === true) {
      showAlert("Alert", "All Option is Selected");
      return;
    }
    if (validate()) {
      var tempData = [...data];
      tempData.push({
        id: currentRow + 1,
        customerFilterData: [...customerFilterOption],
        customerFilterOptionData: [],
        isDropDown: true,
        customerFilterValue: null,
        customerFilterOption: null,
      });
      setData(tempData);
      setCurrentRow((prevState) => prevState + 1);
    }
  };

  const deleteRow = (index) => {
    if (data.length > 1) {
      var tempData = [...data];
      setCurrentRow((prevState) => prevState - 1);

      if(tempData[index].customerFilterValue){
        let tempCustomerFilterOption = [...customerFilterOption];
        const optionIndex = tempCustomerFilterOption.findIndex(
          (customerOpt) => customerOpt.field === tempData[index].customerFilterValue.field
        );
        tempCustomerFilterOption[optionIndex].isDisabled = false;
        setCustomerFilterOption(tempCustomerFilterOption);
      } 

      tempData = tempData.filter((list, indexNumber) => index !== indexNumber);
      tempData = tempData.map((field, index) => {
        return {
          ...field,
          id: index,
        };
      });
      setData(tempData);
    }
  };

  const matchingDocScreen = () => {
    return (
      <div>
        <div
          style={{
            top: 0,
            height: "480px",
            marginTop: "2%",
            border: "1px solid rgba(0,0,0,.125)",
            borderRadius: "0.25rem"
          }}
        >
          <div
            style={{
              top: 0,
              borderBlockEnd: "1px solid rgba(0,0,0,.125)",
              marginTop: "2%",
              display: "flex",
              marginBottom: "2%",
            }}
          >
            <div style={{ width: "35%", marginLeft: "120px" }}>
              <h5>Customer Filter</h5>
            </div>
            <div style={{ width: "50%", marginLeft: "230px" }}>
              <h5>Options</h5>
            </div>
            <div style={{ width: "15%", marginRight: "100px", marginLeft: "10px" }}>
              <h5>Action</h5>
            </div>
          </div>
          <div style={{
            height: "400px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}>
          {data &&
            data.map((item, index) => {
              return (
                <div style={{ borderBlockEnd: "1px solid rgba(0,0,0,.125)" }}>
                  <div
                    className="scrollable-div"
                    style={{
                      top: 0,
                      marginLeft: "40px",
                      marginTop: "2%",
                      display: "flex",
                      marginBottom: "2%",
                    }}
                  >
                    <div style={{ width: "300px" }}>
                      <Select
                        placeholder="Magento Customer Filter"
                        className="ml-s text-align-left"
                        options={customerFilterOption}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        onChange={(e) => {
                          setCustomerFilter(e.value);
                          optionFilterConfig(e, index);
                        }}
                        theme={customSelectTheme}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        getOptionValue={(e) => e.label}
                        isSearchable={true}
                        value={
                          item.customerFilterValue == null || item.customerFilterValue === undefined
                            ? {}
                            : item.customerFilterValue
                        }
                      />
                    </div>

                    <div style={{  width: "300px", marginLeft: "200px" }}>
                      {isAllSeletected == false &&
                        (item.inputType === "DROPDOWN" ? (
                          <MultiSelect
                            options={item.customerFilterOptionData}
                            value={
                              item.customerFilterOptionValue === null ||
                              item.customerFilterOptionValue === undefined
                                ? []
                                : item.customerFilterOptionValue
                            }
                            onChange={(list) => {
                              selectedOptionConfig(list, index);
                            }}
                            labelledBy="Select"
                          />
                        ) : item.inputType === "DATE" ? (
                          <input
                            style={{
                              backgroundColor: "white",
                              width: "300px",
                              height: "40px",
                              borderRadius: "6px",
                            }}
                            type="date"
                            onChange={(date) => {
                              selectedDateConfig(date, index);
                            }}
                            value={
                              item.customerFilterOptionValue === null ||
                              item.customerFilterOptionValue === undefined
                                ? []
                                : item.customerFilterOptionValue
                            }
                          />
                        ) : (
                          <MultiTextInput
                            onValueChange={(value, hasError) => {
                              onMultiTextInputConfig(value, index);
                            }}
                            value={
                              item.customerFilterOptionValue === null ||
                              item.customerFilterOptionValue === undefined
                                ? []
                                : item.customerFilterOptionValue
                            }
                            placeHolder=""
                            isMandatory={false}
                          />
                        ))}
                    </div>
                    <div className="" style={{ width: "200px", marginLeft: "200px" }}>
                      {isAllSeletected == false && (
                        <DKButton
                          className=" border-l text-white"
                          icon={DKIcons.ic_delete}
                          onClick={() => {
                            deleteRow(index);
                          }}
                          style={{
                            border: "1px",
                          }}
                          title=""
                        ></DKButton>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
           </div> 
        </div>
        <div className="mt-l d-flex flex-row">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={() => {
              addRow();
            }}
            style={{ marginLeft: "" }}
            disabled={triggered}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              {triggered ? (
                <div class="spinner-border text-white spinner-border-sm" role="status"></div>
              ) : (
                <></>
              )}
            </span>
            {<span>+ Add Customer Filter</span>}
          </button>

          {false && (
            <button
              className="btn ml-s border-radius-m p-v-s text-white bg-success"
              onClick={() => {
                deleteRow();
              }}
              disabled={triggered}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                {triggered ? (
                  <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                ) : (
                  <></>
                )}
              </span>
              {<span>Delete Row</span>}
            </button>
          )}
        </div>
        <div className="d-flex flex-row-reverse" style={{ marginTop: "30px" }}>
          <DKButton
            className="bg-success px-4 text-white"
            onClick={() => {
              handleSyncSubmitAndClosePopup();
            }}
            title={"SAVE AND CLOSE"}
            style={{ marginLeft: "10px", marginRight: "5px" }}
          ></DKButton>
          <DKButton
            className="bg-success px-4 text-white"
            onClick={() => {
              handleSyncSubmit();
            }}
            title={"SAVE"}
            style={{ marginLeft: "10px", marginRight: "10px" }}
          ></DKButton>
          <button
            className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
            onClick={handleCancel}
          >
            <span className="svg-icon svg-baseline mr-2 svg-disabled">
              <CloseIcon />
            </span>
            <span>Cancel</span>
          </button>
        </div>
      </div>
    );
  };

  const handleSyncSubmitAndClosePopup = () => {
    props.setCustomerFilterState(false);
    handleSyncSubmit();
    dispatch(loadConfig());
  };

  async function handleSyncSubmit() {
    if (validate()) {
      let payload = [];

      data.map((item) => {
        let value = null;

        if (item.inputType === "DROPDOWN") {
          let valueList = [];
          item.customerFilterOptionValue.map((list) => {
            valueList.push(list?.value?.toString());
          });
          value = valueList.toString();
        } else if (item.inputType === "TEXT") {
          value = item.customerFilterOptionValue.toString();
        } else {
          value = Moment(item.customerFilterOptionValue).format(startDateFormat);
        }

        payload.push({
          field: item.customerFilterValue.field,
          custom: item.customerFilterValue.custom,
          input: item.inputType,
          value: value,
        });

      });

      try {
        const configResp = saveMagentoCusomterFilterConfig(payload).then(()=>{
          dispatch(loadConfig());
        })
        .catch(()=>{
          console.log("Unable to save Magento Customer filter Config");
          dispatch(loadConfig());
        });

        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Customer filter config updated successfully.",
          })
        );
      } catch {
        setError("Failed to set config");
      }
    }
  }

  const handleCancel = () => {
    props.setCustomerFilterState(false);
    dispatch(loadConfig());
  };
  
  return (
    <>
      <div className="d-flex">
        <div
          className="vw-100 vh-100 d-flex justify-content-center align-items-center"
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
            width: 600,
          }}
        >
          <div
            className="card dk-card dk-card-shadow d-flex border border-radius-l"
            style={{ minWidth: "60%", minHeight: "85%" }}
          >
            <div className="card-body d-flex flex-column" style={{ align: "center" }}>
              <div className=" card-body d-flex flex-column">
                <h3 className="pt-4 ml-2">Customer Filter Configuration</h3>
                <div class="overflow-hidden flex-row">{matchingDocScreen()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default CustomerFilterMapping;
