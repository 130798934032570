import {
  getSquareAccounts,
  getSquareConfig,
  getSquareCustomers,
  getSquareProducts,
  getSquareOrders,
  getWoocommerceJobStatus,
  getWoocomPaymentMethodConfig,
  getSquareLocations,
  getSquareMeasures,
  getDeskeraProductsForSquare,
  getDeskeraCustomField,
  getSquareMappingProducts,
  getSquareInventoryTransactions
} from "src/api";
import {
  SQUARE_FAILED_CONFIG_LOAD,
  SQUARE_SET_CONFIG,
  SQUARE_SET_TWOWAYCONFIG,
  SQUARE_FAILED_ACCOUNT_LOAD,
  SQUARE_SET_ACCOUNTS,
  SQUARE_FAILED_CUSTOMERS_LOAD,
  SQUARE_SET_CUSTOMERS,
  SQUARE_FAILED_PRODUCTS_LOAD,
  SQUARE_SET_PRODUCTS,
  SQUARE_FAILED_ORDERS_LOAD,
  SQUARE_SET_ORDERS,
  SQUARE_SET_JOB,
  SQUARE_FAILED_JOB_LOAD,
  SQUARE_SET_PAYMENT_CONFIG,
  SQUARE_FAILED_PAYMENT_LOAD,
  SQUARE_FAILED_LOCATION_LOAD,
  SQUARE_SET_LOCATION_CONFIG,
  SQUARE_FAILED_MEASURE_LOAD,
  SQUARE_SET_MEASURE_CONFIG,
  SQUARE_SET_DESKERA_PRODUCTS,
  SQUARE_SET_CUSTOM_FIELD_CONFIG,
  SQUARE_FAILED_CUSTOM_LOAD,
  SQUARE_MATCHING_PRODUCT,
  SQUARE_SET_INVENTORY_TRANSACTIONS,
  SQUARE_FAILED_INVENTORY_TRANSACTIONS_LOAD
} from "../types";

export function loadConfig() {
  return async function (dispatch, getState) {
    try {
      const configResp = await getSquareConfig();
      const config =configResp.config
      const twoWaySyncConfig = configResp.twoWaySyncConfig
      config.syncEnabled = configResp.syncEnabled
      config.productTwoWaySyncEnabled = configResp.productTwoWaySyncEnabled
      dispatch(setConfig(config));
      dispatch(setTwoWaySyncConfig(twoWaySyncConfig));
    } catch (err) {
      dispatch(failedConfigLoad());
    }
  };
}

export function setTwoWaySyncConfig(twoWaySyncConfig){
  return {
    type: SQUARE_SET_TWOWAYCONFIG,
    twoWaySyncConfig: twoWaySyncConfig,
  };
}

export function setConfig(config) {
  return {
    type: SQUARE_SET_CONFIG,
    config: config,
  };
}

export function failedConfigLoad() {
  return {
    type: SQUARE_FAILED_CONFIG_LOAD,
  };
}

export function loadAccounts() {
  return async function (dispatch, getState) {
    const accountLoading = getState().square.accounts.loading;

    try {
      const accounts = await getSquareAccounts();
      dispatch(setAccounts(accounts));
    } catch (err) {
      dispatch(failedAccountLoad());
    }
  };
}

export function setAccounts(accounts) {
  return {
    type: SQUARE_SET_ACCOUNTS,
    accounts: accounts,
  };
}

export function failedAccountLoad() {
  return {
    type: SQUARE_FAILED_ACCOUNT_LOAD,
  };
}

export function loadCustomers(limit, page, status, sortBy) {
  return async function (dispatch, getState) {
    try {
      const customers = await getSquareCustomers(limit, page, status, sortBy);
      dispatch(setCustomers(customers));
    } catch (err) {
      console.log(err);
      dispatch(failedCustomersLoad());
    }
  };
}

export function setCustomers(customers) {
  return {
    type: SQUARE_SET_CUSTOMERS,
    customers: customers,
  };
}

export function failedCustomersLoad() {
  return {
    type: SQUARE_FAILED_CUSTOMERS_LOAD,
  };
}

export function loadOrders(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    const ordersLoading = getState().square.orders.loading;

    try {
      const orders = await getSquareOrders(limit, page, status, sortBy, search ?? "");
      dispatch(setOrders(orders));
    } catch (err) {
      dispatch(failedOrdersLoad());
    }
  };
}

export function setOrders(orders) {
  return {
    type: SQUARE_SET_ORDERS,
    orders: orders,
  };
}

export function failedOrdersLoad() {
  return {
    type: SQUARE_FAILED_ORDERS_LOAD,
  };
}

export function loadProducts(limit, page, status, sortBy, search) {
  return async function (dispatch, getState) {
    try {
      const products = await getSquareProducts(limit, page, status, sortBy, search ?? "");
      dispatch(setProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setProducts(products) {
  return {
    type: SQUARE_SET_PRODUCTS,
    products: products,
  };
}

export function failedProductsLoad() {
  return {
    type: SQUARE_FAILED_PRODUCTS_LOAD,
  };
}

export function setJob(job) {
  return {
    type: SQUARE_SET_JOB,
    job: job,
  };
}

export function loadJob() {
  return async function (dispatch) {
    try {
      const job = await getWoocommerceJobStatus();
      dispatch(setJob(job));
    } catch (err) {
      dispatch(failedJobLoad());
    }
  };
}

export function failedJobLoad() {
  return {
    type: SQUARE_FAILED_JOB_LOAD,
  };
}

export function setPaymentMethodConfig(paymentConfig) {
  return {
    type: SQUARE_SET_PAYMENT_CONFIG,
    paymentConfig: paymentConfig
  };
}
export function loadPaymentAccountConfig() {
  return async function(dispatch) {
    try {
      const resp = await getWoocomPaymentMethodConfig();
      if (resp) {
        dispatch(setPaymentMethodConfig(resp));
      } else {
        dispatch(setPaymentMethodConfig({}))
      }
    } catch (err) {
      dispatch(failedPaymentAccountConfigLoad());
    }
  }
}

export function failedPaymentAccountConfigLoad() {
  return {
    type: SQUARE_FAILED_PAYMENT_LOAD
  };
}
export function failedLocationConfigLoad() {
  return {
    type: SQUARE_FAILED_LOCATION_LOAD
  };
}

export function setLocationConfig(locationConfig) {
  return {
    type: SQUARE_SET_LOCATION_CONFIG,
    locationConfig: locationConfig
  };
}
export function loadLocationMapping() {
  return async function(dispatch) {
    try {
      const resp = await getSquareLocations();
      if (resp) {
        dispatch(setLocationConfig(resp));
      } else {
        dispatch(setLocationConfig({}))
      }
    } catch (err) {
      dispatch(failedLocationConfigLoad());
    }
  }
}
export function failedCustomFieldConfigLoad() {
  return {
    type: SQUARE_FAILED_CUSTOM_LOAD
  };
}
export function setCustomFieldConfig(customfieldConfig) {
  return {
    type: SQUARE_SET_CUSTOM_FIELD_CONFIG,
    customfieldConfig: customfieldConfig
  };
}
export function loadCustomField(){
  return async function(dispatch){
    try{
      const resp = await getDeskeraCustomField();
      if(resp){
        dispatch(setCustomFieldConfig(resp));
      }else{
        dispatch(setCustomFieldConfig({}));
      }
    }catch(err){
      dispatch(failedCustomFieldConfigLoad());
    }
  }
}
export function failedMeasureConfigLoad() {
  return {
    type: SQUARE_FAILED_MEASURE_LOAD
  };
}

export function setMeasureConfig(measureConfig) {
  return {
    type: SQUARE_SET_MEASURE_CONFIG,
    measureConfig: measureConfig
  };
}
export function loadMeasureMapping() {
  return async function(dispatch) {
    try {
      const resp = await getSquareMeasures();
      if (resp) {
        dispatch(setMeasureConfig(resp));
      } else {
        dispatch(setMeasureConfig({}))
      }
    } catch (err) {
      dispatch(failedMeasureConfigLoad());
    }
  }
}

export function loadDeskeraSyncedProducts(limit, page, status) {
  return async function (dispatch, getState) {
    try {
      const products = await getDeskeraProductsForSquare(limit, page, status);
      dispatch(setDeskeraProducts(products));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}

export function setDeskeraProducts(products) {
  return {
    type: SQUARE_SET_DESKERA_PRODUCTS,
    deskeraproducts: products,
  };
}


export function setSquareMachingProduct(matchingProduct) {
  return { type: SQUARE_MATCHING_PRODUCT, matchingProduct: matchingProduct };
}

export function loadSquareMatchingProducts(body) {
  return async function (dispatch, getState) {
    try {
      const matchingProduct = await getSquareMappingProducts(body);
      dispatch(setSquareMachingProduct(matchingProduct));
    } catch (err) {
      dispatch(failedProductsLoad());
    }
  };
}
export function loadInventoryTransactions(limit, page, status) {
  return async function (dispatch, getState) {
    try {
      const inventoryTransactions = await getSquareInventoryTransactions(limit, page, status);
      dispatch(setInventoryTransactions(inventoryTransactions));
    } catch (err) {
      dispatch(failedInventoryTransactionsLoad());
    }
  };
}

export function setInventoryTransactions(inventoryTransactions) {
  return {
    type: SQUARE_SET_INVENTORY_TRANSACTIONS,
    inventoryTransactions: inventoryTransactions,
  };
}

export function failedInventoryTransactionsLoad() {
  return {
    type: SQUARE_FAILED_INVENTORY_TRANSACTIONS_LOAD,
  };
}
