import { useEffect, useRef } from "react";
import Chart from "chart.js";
import EmptyBarGraph from "src/assets/EmptyBarGraph.svg";

function SyncChart({ customers, orders, products }) {
  const chartRef = useRef(null);
  Chart.defaults.global.defaultFontFamily = "'Inter', sans-serif";

  useEffect(() => {
    const successOrders = [];
    const pendingOrders = [];
    const failedOrders = [];

    if (orders.data.content) {
      for (let i = 0; i < orders.data.content.length; i++) {
        var order = orders.data.content[i];
        if (order.syncStatus !== "SUCCESSFUL") {
          successOrders.push(product);
        }
        if (order.syncStatus !== "PENDING") {
          pendingOrders.push(product);
        }
        if (order.syncStatus !== "FAILED") {
          failedOrders.push(product);
        }
      }
    }
    
    
    const successProducts = [];
    const pendingProducts = [];
    const failedProducts = [];
    if (products?.data.content) {
      for (let i = 0; i < products.data.content.length; i++) {
        var product = products.data.content[i];
        if (product.syncStatus !== "SUCCESSFUL") {
          successProducts.push(product);
        }
        if (product.syncStatus !== "PENDING") {
          pendingProducts.push(product);
        }
        if (product.syncStatus !== "FAILED") {
          failedProducts.push(product);
        }
      }
    }

    const successCustomers = [];
    const pendingCustomers = [];
    const failedCustomers = [];

    if (customers?.data.content) {
      for (let i = 0; i < customers.data.content.length; i++) {
        var customer = customers.data.content[i];
        if (customer.syncStatus !== "SUCCESSFUL") {
          successCustomers.push(customer);
        }
        if (customer.syncStatus !== "PENDING") {
          pendingCustomers.push(customer);
        }
        if (customer.syncStatus !== "FAILED") {
          failedCustomers.push(customer);
        }
      }
    }

    if (chartRef && chartRef.current) {
      const myChartRef = chartRef.current.getContext("2d");
      new Chart(myChartRef, {
        type: "horizontalBar",
        data: {
          labels: ["Orders", "Products", "Contacts"],
          datasets: [
            {
              label: "Successful",
              data: [successOrders.length, successProducts.length, successCustomers.length],
              backgroundColor: "#1d3557",
            },
            {
              label: "Pending",
              data: [pendingOrders.length, pendingProducts.length, pendingCustomers.length],
              backgroundColor: "#e9c46a",
            },
            {
              label: "Failed",
              data: [failedOrders.length, failedProducts.length, failedCustomers.length],
              backgroundColor: "#e63946",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          animation: {
            duration: 0,
          },
          tooltips: {
            backgroundColor: "rgba(29, 53, 87, 0.95)",
            titleFontSize: 12,
            titleFontStyle: "bold",
            bodyFontSize: 14,
            xPadding: 12,
            yPadding: 12,
            caretSize: 0,
            cornerRadius: 2,
            displayColors: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  padding: 10,
                },
                gridLines: {
                  drawOnChartArea: false,
                  drawTicks: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  maxTicksLimit: 4,
                },
                gridLines: {
                  drawBorder: false,
                },
              },
            ],
          },
        },
      });
    }
  }, [chartRef, orders, products, customers]);

  return orders?.loading || products?.loading || customers?.loading ? (
    <div className="text-center chartjs-render-monitor">
      <div class="spinner-border text-secondary spinner-border-sm" role="status">
        <span class="sr-only text-muted">Loading...</span>
      </div>
    </div>
  ) : orders?.data?.content?.length || products?.data.content?.length || customers?.data.content?.length ? (
    <canvas ref={chartRef} />
  ) : (
    <div
      className="bg-image d-flex align-items-center justify-content-center"
      style={{
        height: "100%",
        width: "100%",
        backgroundImage: `url(${EmptyBarGraph})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className=" p-1 text-muted">
        <span>Looks like there's no data here.</span>
      </div>
    </div>
  );
}

export default SyncChart;
