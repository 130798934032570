import { useEffect } from "react";
import { useState } from "react";
import { NavLink, Route, Switch } from "react-router-dom";
import UserIcon from "src/assets/User";
import WrenchIcon from "src/assets/Wrench";
import Config from "src/pages/Square/Config";
import Customers from "src/pages/Square/Customers";
import Products from "src/pages/Square/Products";
import Orders from "src/pages/Square/Orders";
import DeskeraProducts from "./DeskeraProducts";
import InventoryTransactions from "./InventoryTransactions";
import CartonBoxIcon from "src/assets/CartonBox";
import Bag from "src/assets/Bag";
import CardCredit from "src/assets/CardCredit";
import { useDispatch, useSelector } from "react-redux";
import {loadConfig} from "src/redux/actions/square";

function Square() {
  const [activeVisible, setActiveVisible] = useState(false);
  const [activeTop, setActiveTop] = useState(0);
  const dispatch = useDispatch();
  const config = useSelector((state) => state.square.twoWaySyncConfig);

  useEffect(() => {
    sessionStorage.setItem("cartType", "square");
  });
  useEffect(() => {
    dispatch(loadConfig());
  },[]);

  var menuItems = [
    {
      name: "Setup",
      href: "/app/square",
      icon: <WrenchIcon />,
      component: <Config />,
      exact: true,
    },
    {
      name: "Customers",
      href: "/app/square/customers",
      icon: <UserIcon />,
      component: <Customers />,
      exact: false,
    },
    {
      name: "Products",
      href: "/app/square/products",
      icon: <CartonBoxIcon />,
      component: <Products />,
    },
    {
      name: "Orders",
      href: "/app/square/orders",
      icon: <CartonBoxIcon />,
      component: <Orders />,
    },
    {
      name: "Inventory Transactions",
      href: "/app/square/inventory-transactions",
      icon: <CardCredit />,
      component: <InventoryTransactions />,
    },
  ];

  if (config?.data?.showDeskeraProducts != undefined && config?.data?.showDeskeraProducts) {
    menuItems.splice(3, 0, {
      name: "Deskera Products",
      href: "/app/square/deskeraproducts",
      icon: <Bag />,
      component: <DeskeraProducts />
    })
  }

  return (
    <>
      <div
        className="bg-white py-3 dk-shadow-sm"
        style={{
          minWidth: 240,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
          zIndex: 9,
          position: "relative",
        }}
      >
        {activeVisible && (
          <div
            className="ease-in-out-back"
            style={{
              width: 8,
              height: "3.25rem",
              position: "absolute",
              right: 0,
              top: activeTop,
              backgroundColor: "#4962df",

            }}
          ></div>
        )}
        {menuItems.map((item, index) => (
          <NavLink
            exact
            to={item.href}
            className="text-decoration-none px-4 py-3 d-block dk-navlink dk-navlink-hover mb-2 ease-in"
            isActive={(match, location) => {
              if (!match) return false;
              setActiveVisible(true);
              setActiveTop(1 + (3.375 + 0.5) * index + "rem");
              return true;
            }}
            style={{
              fontWeight: 500,
              color: "black"
            }}
          >
            <span className="svg-icon-lg svg-baseline mr-3">{item.icon}</span>
            {item.name}
          </NavLink>
        ))}
      </div>
      <div className="w-100 h-100 overflow-auto" style={{ background: "#f6f6f6" }}>
        <Switch>
          {menuItems.map((item) => (
            <Route exact={item.exact} path={item.href}>
              {item.component}
            </Route>
          ))}
        </Switch>
      </div>
    </>
  );
}

export default Square;
