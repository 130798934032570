import { useEffect, useState } from "react";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import SyncDisableIcon from "src/assets/SyncDisable";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "src/assets/Close";
import CheckIcon from "src/assets/Check";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import {
  getDeskeraRedirectUrl,
  setDisconnectedDeskeraAccount,
  getDeskeraUserInfo,
  setDisconnectedSquareAccount,
  getDeskeraBooksAccounts,
  setSquareConfig,
  getDeskeraBooksWarehouses,
  getDeskeraProductUom,
  getDeskeraBooksTaxes,
  getSquareRedirectUrl,
  setSquareLocations,
  setSquareMeasures,
  saveSquareTransactionSync,
  getDeskeraCustomField,
  setSquareCustomField,
  getDeskeraDimensions
} from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import { setDeskeraInfo } from "src/redux/actions/auth";
import {
  loadConfig, setConfig, loadAccounts, loadLocationMapping, setLocationConfig, setMeasureConfig,
  loadMeasureMapping,setCustomFieldConfig,loadCustomField,setTwoWaySyncConfig
} from "src/redux/actions/square";
import SyncIcon from "src/assets/Sync";
import { addToast } from "src/redux/actions/toasts";

const YesOrNo = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];


function Config() {
  const [disconnectWarningSquare, setDisconnectWarningSquare] = useState(false);
  const [disconnectButtonSquare, setDisconnectButtonSquare] = useState(true);
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [validAccounts, setValidAccounts] = useState(false);
  const accounts = useSelector((state) => state.square.accounts);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const config = useSelector((state) => state.square.config);
  const [formErrors, setFormErrors] = useState({});
  const [configOpen, setConfigOpen] = useState(false);
  const [secret, setSecret] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [squareErrors, setSquareErrors] = useState({});
  const [accountsConfigured, setAccountsConfigured] = useState(false);
  const [saveShopLoading, setSaveShopLoading] = useState(false);
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const [squareConnectLoading, setSquareConnectLoading] = useState(false);
  const [deskeraTaxes, setDeskeraTaxes] = useState([]);
  const [configLocationMappings, setConfigLocationMappings] =
  useState([]);
  const locationConfig = useSelector((state) => state.square.locationConfig);
  const [locationMappingConfigured, setLocationMappingConfigured] =
  useState(false);
  const [saveLocationAccountConfigLoading, setSaveLocationAccountConfigLoading] =
  useState(false);
  const [configMeasureMappings, setConfigMeasureMappings] =
  useState([]);
  const measureConfig = useSelector((state) => state.square.measureConfig);
  const [measureMappingConfigured, setMeasureMappingConfigured] =
  useState(false);
  const [saveMeasureAccountConfigLoading, setSaveMeasureAccountConfigLoading] =
  useState(false);
  const [customFieldConfigured, setCustomFieldConfigured] =
  useState(false);
  const [configCustomFieldAttributeMappings, setConfigCustomFieldAttributeMappings] =
  useState([]);
  const [saveCustomFieldAttributeConfigLoading,setSaveCustomFieldAttributeConfigLoading ] =
  useState(false);
  const customfieldConfig = useSelector((state) => state.square.customfieldConfig);
  const [deskeraCustomField, setDeskeraCustomField] = useState([]);
  const twoWaySyncConfig = useSelector((state) => state.square?.twoWaySyncConfig);
  const [enableTwoSync, setEnableTwoSync] = useState(twoWaySyncConfig?.data?.enableTwoSync);
  const [enableTwoSyncForProductDeletion, setEnableTwoSyncForProductDeletion] = useState(
    twoWaySyncConfig.data?.enableTwoSyncForProductDeletion
  );
  const [syncPaymentFromDeskeraToSquare, setSyncPaymentFromDeskeraToSquare] = useState(
    twoWaySyncConfig.data?.syncPaymentFromDeskeraToSquare
  );
  const [showDeskeraProducts, setShowDeskeraProducts] = useState(
    twoWaySyncConfig.data?.showDeskeraProducts
  );
  const [checked, setChecked] = useState(twoWaySyncConfig.data?.warehouseCode);
  const [defaultDeskeraWarehouses, setDefaultDeskeraWarehouses] = useState([]);
  
  useEffect(() => {
    setEnableTwoSync(twoWaySyncConfig.data?.enableTwoSync);
    setSyncPaymentFromDeskeraToSquare(twoWaySyncConfig?.data?.syncPaymentFromDeskeraToSquare);
    setEnableTwoSyncForProductDeletion(twoWaySyncConfig?.data?.enableTwoSyncForProductDeletion);
    setShowDeskeraProducts(twoWaySyncConfig?.data?.showDeskeraProducts);
  }, [twoWaySyncConfig]);

  const useBooksTaxRateOptions = [
    { value: "CREATE_NEW", label: "Create New Product" },
    { value: "MAP_MANUALLY", label: "I Will Map Manually" },
  ];


  const configAccounts = [
    {
      name: "Bank Account",
      accessor: "bankAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Account",
      accessor: "productPurchaseAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Return Account",
      accessor: "productPurchaseReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Account",
      accessor: "productSalesAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Return Account",
      accessor: "productSalesReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Payable Account",
      accessor: "contactPayableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Receivable Account",
      accessor: "contactReceivableAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Cost of Goods Sold Account",
      accessor: "productCostOfGoodsSoldAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Inventory Account",
      accessor: "productInventoryAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Stock Adjustment Account",
      accessor: "productStockAdjustmentAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Product Unit of Measure",
      accessor: "productUom",
      options: deskeraProductUom,
    },
    {
      name: "Product Purchase Tax",
      accessor: "productPurchaseTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Product Sales Tax",
      accessor: "productSalesTaxCode",
      options: deskeraTaxes,
    },
    {
      name: "Product Warehouse",
      accessor: "productWarehouseCode",
      options: defaultDeskeraWarehouses.filter((wh) => wh.value !== "NO_SPECIFIC_WH"),
    },
    {
      name: "Product Action at Deskera",
      accessor: "productAction",
      options: useBooksTaxRateOptions,
    },
    {
      name: "Hide Sync Products",
      accessor: "hideProducts",
      options: YesOrNo,
    },
  ];

  async function getBooksTaxes() {
    try {
      const taxes = await getDeskeraBooksTaxes();
      const taxesOptions = [];
      taxes.forEach((tax) => {
        taxesOptions.push({ value: tax.code, label: tax.name });
      });
      setDeskeraTaxes(taxesOptions);
    } catch {
      setError("Failed to load Deskera Books Taxes");
    }
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      accounts.forEach((account) => {
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
      const tempwh = warehouseOptions
      tempwh.push({ value: "NO_SPECIFIC_WH",label: "No Specific WareHouse"})
      setDefaultDeskeraWarehouses(tempwh);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }
  async function getBooksCustomField () {
    try {
      const accounts = await getDeskeraDimensions();
      const dimensionOptions = [];
      accounts.forEach((account) => {
          dimensionOptions.push({ value: account.code, label: account.label, 
            type:account.fieldType, module:account.modules.join(',')});
      });
      
      setDeskeraCustomField(dimensionOptions);
    } catch {
      setError("Failed to load Deskera Custom Field");
    }
  }

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadConfig());
    getBooksAccounts();
    getBooksWarehouses();
    getBooksProductUom();
    getBooksTaxes();
    dispatch(loadLocationMapping());
    dispatch(loadCustomField())
    dispatch(loadMeasureMapping());
    getBooksCustomField();
  }, []);

  useEffect(() => { initialiseConfigCustomFieldAttributeMappings() }, [customfieldConfig, deskeraCustomField]);
  function initialiseConfigCustomFieldAttributeMappings() {
    const configCustomFieldAttributeMapping = [];

    for (let i = 0; i < customfieldConfig.data.length; i++) {
      let attribute = customfieldConfig.data[i];
      let options = deskeraCustomField;
      let configAttribute = {
        id: attribute.id,
        name: attribute.name,
        deskeraCF:attribute.deskeraCF,
        options: options,
      };
      configCustomFieldAttributeMapping.push(configAttribute);
    }
    setConfigCustomFieldAttributeMappings(configCustomFieldAttributeMapping);
  }

  function setCustomFieldAttribute(e, i) {
   
    const attributes = customfieldConfig.data;
    const conf = attributes.splice(i, 1)[0];
     conf.deskeraCF.code = e.value
     conf.deskeraCF.label = e.label
     conf.deskeraCF.fieldType = e.type
     conf.deskeraCF.module = e.module 
    attributes.splice(i, 0, conf);
   
    dispatch(setCustomFieldConfig(attributes));
  } 

  async function saveCustomFieldAttribute(e) {
    e.preventDefault();
    setSaveCustomFieldAttributeConfigLoading(true);
    try {
      const configResp = await setSquareCustomField(
        customfieldConfig.data
      );
      dispatch(setCustomFieldConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Custom Field attribute mapping updated successfully.",
        })
      );
    } catch {
      setError("Failed to set Custom Field attributes");
    }
    setSaveCustomFieldAttributeConfigLoading(false);
    dispatch(loadCustomField());
  }

  useEffect(() => { initialiseConfigLocationMappings() }, [locationConfig, deskeraAccounts]);
  function initialiseConfigLocationMappings() {
    const locationAccountMapping = [];

    for (let i = 0; i < locationConfig.data.length; i++) {
      let location = locationConfig.data[i];
      let id = location.id;
      let options = deskeraAccounts;
      let configLocation = {
        id: id,
        name: location.name,
        address: location.address,
        deskeraAccCode: location.deskeraAccCode,
        deskeraAccName: location.deskeraAccName,
        options: options,
      };
      locationAccountMapping.push(configLocation);
    }
    setConfigLocationMappings(locationAccountMapping);
  }


  function setLocationAccount(e, i) {
    const locations = locationConfig.data;
    const conf = locations.splice(i, 1)[0];

    conf.deskeraAccCode = e.value;
    conf.deskeraAccName = e.label;
    locations.splice(i, 0, conf);

    dispatch(setLocationConfig(locations));
  }
  async function saveLocationAccountConfig(e) {
    e.preventDefault();
    setSaveLocationAccountConfigLoading(true);
    try {
      const configResp = await setSquareLocations(
        locationConfig.data
      );
      dispatch(setLocationConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Location mappings updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveLocationAccountConfigLoading(false);
    dispatch(loadLocationMapping());
  }




  useEffect(() => { initialiseConfigMeasureMappings() }, [measureConfig, deskeraProductUom]);
  function initialiseConfigMeasureMappings() {
    const measureAccountMapping = [];

    for (let i = 0; i < measureConfig.data.length; i++) {
      let measure = measureConfig.data[i];
      let id = measure.id;
      let options = deskeraProductUom;
      let configMeasure = {
        id: id,
        name: measure.name,
        precision: measure.precision,
        abbreviation: measure.abbreviation,
        deskeraUomId: measure.deskeraUomId,
        presentAtAllLocations: measure.presentAtAllLocations,
        options: options,
      };
      measureAccountMapping.push(configMeasure);
    }
    setConfigMeasureMappings(measureAccountMapping);
  }


  function setMeasureAccount(e, i) {
    const measures = measureConfig.data;
    const conf = measures.splice(i, 1)[0];

    conf.deskeraUomId = e.value;
    measures.splice(i, 0, conf);

    dispatch(setMeasureConfig(measures));
  }
  async function saveMeasureAccountConfig(e) {
    e.preventDefault();
    setSaveMeasureAccountConfigLoading(true);
    try {
      const configResp = await setSquareMeasures(
        measureConfig.data
      );
      dispatch(setMeasureConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Measure Unit mapping updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveMeasureAccountConfigLoading(false);
    dispatch(loadMeasureMapping());
  }

  
  useEffect(() => {
    handleConfigLoad();
  }, [config]);

  function handleConfigLoad() {
    setAccountsConfigured(true);
    configAccounts.forEach((configAccount) => {
      if (!config.data[configAccount.accessor]) {
        setAccountsConfigured(false);
        return;
      }
    });
  }

  async function handleAddSquareAccount(e) {
    e.preventDefault();

    if (!name || name === "") {
      setSquareErrors({ site: "Enter a Square application name" });
      return;
    }
    if (!id || id === "") {
      setSquareErrors({ site: "Enter a Square application ID" });
      return;
    }
    
    if (!secret || secret === "") {
      setSquareErrors({ site: "Enter a Square application secret key" });
      return;
    }

    setSquareConnectLoading(true);
    try {
      const squareConnectUrl = await getSquareRedirectUrl(name, id, secret);
      window.open(squareConnectUrl.url, "_self");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setSquareErrors({ site: err.response.data.message });
        setSquareConnectLoading(false);
      } else {
        setSquareErrors({ site: "An error occured. Please try again." });
        setSquareConnectLoading(false);
      }
    }
  }

  async function saveTransactionSync(e) {
    e.preventDefault();
    setSaveSyncLoading(true);
    const body = {
      enableTwoSync: twoWaySyncConfig.data.enableTwoSync ? twoWaySyncConfig.data.enableTwoSync : false,
      enableTwoSyncForProductDeletion: twoWaySyncConfig.data.enableTwoSyncForProductDeletion
        ? twoWaySyncConfig.data.enableTwoSyncForProductDeletion
        : false,
        syncPaymentFromDeskeraToSquare: twoWaySyncConfig.data.syncPaymentFromDeskeraToSquare
        ? twoWaySyncConfig.data.syncPaymentFromDeskeraToSquare
        : false,
        warehouseCode: twoWaySyncConfig.data.warehouseCode? twoWaySyncConfig.data.warehouseCode: null,
        showDeskeraProducts: twoWaySyncConfig.data.showDeskeraProducts? twoWaySyncConfig.data.showDeskeraProducts: false
    };
    try {
      const configResp = await saveSquareTransactionSync(body);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync setting updated successfully.",
        })
      );
    } catch {
      setError("Failed to save Transaction sync setting.");
    }

    const newConfig = { config:config.data, syncEnabled: config.data.syncEnabled,  productTwoWaySyncEnabled: config.data.productTwoWaySyncEnabled,
      twoWaySyncConfig:twoWaySyncConfig.data};
    try {
      const resp = await setSquareConfig(newConfig);
      const configRes =resp.config
      const twoWaySyncRes =resp.twoWaySyncConfigs
      configRes.syncEnabled = resp.syncEnabled
      configRes.productTwoWaySyncEnabled = resp.productTwoWaySyncEnabled
      dispatch(setConfig(configRes));
      dispatch(setTwoWaySyncConfig(twoWaySyncRes));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync config updated successfully.",
        })
      );
      setFormErrors([]);
    } catch {
      setError("Failed to set config");
    }
    setSaveSyncLoading(false);
  }

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectSquareAccount(e, accountId) {
    e.preventDefault();
    try {
      await setDisconnectedSquareAccount(accountId);
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Square account");
    }
  }

  async function handleUpdateSync(e, syncStatus) {
    await checkAccountsAreValid();
    if(!validAccounts && syncStatus){
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Please map correct accounts before enabling sync.",
        })
      );
      return;
    }
    e.preventDefault();
    const newConfig = { config:config.data, syncEnabled: syncStatus,  productTwoWaySyncEnabled: config.data.productTwoWaySyncEnabled,
      twoWaySyncConfig:twoWaySyncConfig.data};
    setSaveSyncLoading(true);
    try {
      const configResp = await setSquareConfig(newConfig);
      const configRes =configResp.config
      const twoWaySyncRes =configResp.twoWaySyncConfigs
      configRes.syncEnabled = configResp.syncEnabled
      configRes.productTwoWaySyncEnabled = configResp.productTwoWaySyncEnabled
      dispatch(setConfig(configRes));
      dispatch(setTwoWaySyncConfig(twoWaySyncRes));
      setSyncButton(true);
      setSyncWarning(false);
    } catch {
      setError("Failed to set config");
    }
    setSaveSyncLoading(false);
  }

  async function saveAccountConfigs(e) {
    e.preventDefault();
    await checkAccountsAreValid();
    if(!validAccounts){
      return;
    }
    setSaveConfigLoading(true);
    try {
      const configResp = await setSquareConfig({ config:config.data, syncEnabled: config.data.syncEnabled});
      const configRes =configResp.config
      const twoWaySyncRes =configResp.twoWaySyncConfigs
      configRes.syncEnabled = configResp.syncEnabled
      configRes.productTwoWaySyncEnabled = configResp.productTwoWaySyncEnabled
      dispatch(setConfig(configRes));
      dispatch(setTwoWaySyncConfig(twoWaySyncRes));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Accounts config updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveConfigLoading(false);
  }

  function checkAccountsAreValid(){
    const configErrors = {}; 
    if (!config.data.bankAccountCode) {
      configErrors.bankAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, bankAccountCode: "Select a valid account" });
    } //1

    if (!config.data.productPurchaseAccountCode) {
      configErrors.productPurchaseAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productPurchaseAccountCode: "Select a valid account" });
    } //2

    if (!config.data.productSalesAccountCode) {
      configErrors.productSalesAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productSalesAccountCode: "Select a valid account" });
    } //3

    if (!config.data.productSalesReturnAccountCode) {
      configErrors.productSalesReturnAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productSalesReturnAccountCode: "Select a valid account" });
    } //4


    if (!config.data.productPurchaseReturnAccountCode) {
      configErrors.productPurchaseReturnAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productPurchaseReturnAccountCode: "Select a valid account" });
    } //5

    if (!config.data.contactPayableAccountCode) {
      configErrors.contactPayableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactPayableAccountCode: "Select a valid account" });
    } //6

    if (!config.data.contactPayableAccountCode) {
      configErrors.contactPayableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactPayableAccountCode: "Select a valid account" });
    } //7

    if (!config.data.contactReceivableAccountCode) {
      configErrors.contactReceivableAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, contactReceivableAccountCode: "Select a valid account" });
    } //8

    if (!config.data.productUom) {
      configErrors.productUom = "Select a valid account";
      setFormErrors({ ...formErrors, productUom: "Select a valid account" });
    } //9
    if (!config.data.productWarehouseCode) {
      configErrors.productWarehouseCode = "Select a valid account";
      setFormErrors({ ...formErrors, productWarehouseCode: "Select a valid account" });
    } //10
    if (!config.data.productCostOfGoodsSoldAccountCode) {
      configErrors.productCostOfGoodsSoldAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productCostOfGoodsSoldAccountCode: "Select a valid account" });
    } //11
    if (!config.data.productInventoryAccountCode) {
      configErrors.productInventoryAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productInventoryAccountCode: "Select a valid account" });
    } //12
    if (!config.data.productStockAdjustmentAccountCode) {
      configErrors.productStockAdjustmentAccountCode = "Select a valid account";
      setFormErrors({ ...formErrors, productStockAdjustmentAccountCode: "Select a valid account" });
    } //13
    if (!config.data.productAction) {
      configErrors.productAction = "Select a valid action for products to create at Deskera";
      setFormErrors({ ...formErrors, productAction: "Select a valid action for products to create at Deskera" });
    } //14

    if ((Object.keys(configErrors)).length > 0) {
      setFormErrors(configErrors);
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: "Some account mappings are missing.",
        })
      );
      return; 
    } else {
      setFormErrors({});
      setValidAccounts(true);
    }
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger px-3 text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title={"Disconnect"}
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Products and Customers from your Square
          account will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => setSyncWarning(true)}
              // style={{ marginRight: "20px" }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is disabled for your account. Orders, Products and Customers from your Square
          account are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  function squareConnect() {
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Application Name</b>
              </div>
              <input
                
                type="text"
                className="form-control"
                placeholder="Ex. Nunes Greens"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Application ID</b>
              </div>
              <input
                
                type="text"
                className="form-control"
                placeholder="Ex. sq0idp-eFikAOxKcR7VFOWAWeeodA"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Secret Key</b>
              </div>
              <input
                
                type="text"
                className="form-control"
                placeholder="Ex. sq0csp-nSKej3FkslzUkxs9dlNBzDiJz69qOtW08hcudcpIqI8"
                value={secret}
                onChange={(e) => setSecret(e.target.value)}
              />
              {squareErrors.site && (
                <span className="text-danger">{squareErrors.site}</span>
              )}
            </div>
            <div className="d-flex flex-row-reverse">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={handleAddSquareAccount}
                disabled={squareConnectLoading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {squareConnectLoading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <PlusLogo />
                  )}
                </span>
                <span>Connect</span>
              </button>
              <button
                className="btn border-radius-m p-v-s mr-2 text-white bg-danger"
                onClick={(e) => setConfigOpen(false)}
                // style={{marginRight: "10px"}}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function squareConnectModal() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <p className="text-muted">Link your Square applications.</p>
          <div className="d-flex flex-row-reverse mb-2">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setConfigOpen(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <PlusLogo />
              </span>
              <span>
                {accounts.data.length ? "Add Another Application" : "Connect Square Application"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  async function disconnectSquareStore(e, accountId) {
    await handleDisconnectSquareAccount(e, accountId);
    setDisconnectWarningSquare(false);
  }

  function squareConnectedModal() {
    return (
      <>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            {/* <p className="text-muted">Link your Square applications.</p>
             <div className="d-flex flex-row-reverse mb-2">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={(e) => setSyncWarning(true)}
                // style={{ marginRight: "20px" }}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  <PlusLogo />
                </span>
                <span>Add Application</span>
              </button>
            </div> */}
            {accounts.data.length > 0 && (
              <table className="table m-0 table-no-padding">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.data.map((account, index) => (
                    <div>
                      {!disconnectWarningSquare && (
                        <div>
                        <tr style={{ width: "560px" }}>
                          <td className="align-middle" style={{ width: "10%" }}>
                            <p className="m-0">
                              <b>{account.name}</b>
                            </p>
                            <span class="d-inline-block text-truncate" style={{ width: "auto" }}>
                              <small>{account.id}</small>
                            </span>
                          </td>
                          <td className="align-middle text-right">
                            <button
                              // style={{ marginLeft: "10px" }}
                              onClick={(e) => setDisconnectWarningSquare(true)}
                              className="btn border-radius-m p-v-s text-white bg-danger"
                            >
                              <span className="svg-icon svg-baseline mr-2 svg-white">
                                <DeleteLogo />
                              </span>
                              <span>Disconnect</span>
                            </button>
                          </td>
                        </tr>
                        <tr style={{ width: "560px" }}>
                        <td className="align-middle" colSpan={2}>
                          {!accounts.data[0]?.squareTokenPresent &&
                            <Alert type="warning">
                              Square authentication failed, please reconnect account!
                            </Alert>}</td></tr>
                        </div> 
                      )}

                      <CSSTransition
                        in={disconnectWarningSquare}
                        timeout={100}
                        classNames="scale-opacity"
                        unmountOnExit
                        onEnter={() => setDisconnectButtonSquare(false)}
                        onExited={() => setDisconnectButtonSquare(true)}
                      >
                        <Alert className="m-0 flex-fill" type="warning">
                          <p className="m-0">Disconnect Square Application?</p>
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn dk-btn font-weight-bold text-muted px-3"
                              onClick={(e) => {
                                disconnectSquareStore(e, account.id);
                              }}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CheckIcon />
                              </span>
                            </button>
                            <button
                              className="btn dk-btn mr-2 px-3"
                              onClick={(e) => setDisconnectWarningSquare(false)}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CloseIcon />
                              </span>
                            </button>
                          </div>
                        </Alert>
                      </CSSTransition>
                    </div>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="p-4 w-auto">
      {configOpen && squareConnect()}
      <h3 className="mb-4">Account Setup</h3>
      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Square Connections</h5>
          </div>
          {accounts.data.length ? squareConnectedModal() : squareConnectModal()}
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Books Accounts</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Square products can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configAccounts.map((configAccount) => (
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>{configAccount.name}</b>
                  </div>
                  <Select
                    placeholder="Select an Account"
                    theme={customSelectTheme}
                    options={configAccount.options}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch(setConfig({ ...config.data, [configAccount.accessor]: e.value }))
                    }
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={configAccount.options.filter(
                      (obj) => obj.value === config.data[configAccount.accessor]
                    )}
                  />
                  {formErrors[configAccount.accessor] && (
                    <div>{formErrors[configAccount.accessor]}</div>
                  )}
                </div>
              ))}
              <div className="d-flex flex-row-reverse">
                <button
                  onClick={saveAccountConfigs}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Location Account Mapping</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!locationMappingConfigured && !locationConfig && (
                <Alert type="primary">
                  Locations must be configured before Orders can be
                  synced with Deskera Books.
                </Alert>
              )}
              {configLocationMappings.length === 0 && (
                <span className="mt-2 text-muted">
                  No Square Locations found.
                </span>
              )}
              {configLocationMappings.length > 0 &&
                configLocationMappings.map(
                  (locationAccountMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{locationAccountMapping.name}</b>
                      </div>
                      <Select
                        placeholder="Select an Account"
                        theme={customSelectTheme}
                        options={locationAccountMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setLocationAccount(e, idx);
                        }}
                        value={locationAccountMapping.options.filter(
                          (obj) =>
                            obj.value ===
                            locationAccountMapping.deskeraAccCode
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[
                        locationAccountMapping.deskeraAccCode
                      ] && (
                        <div>
                          {
                            formErrors[
                              locationAccountMapping.deskeraAccCode
                            ]
                          }
                        </div>
                      )}
                    </div>
                  )
                )}
              {configLocationMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={saveLocationAccountConfig}
                    disabled={saveLocationAccountConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveLocationAccountConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}



      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Meausure Unit Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!measureMappingConfigured && !measureConfig && (
                <Alert type="primary">
                  Measure Units must be configured before Products can be
                  synced with Deskera Books.
                </Alert>
              )}
              {configMeasureMappings.length === 0 && (
                <span className="mt-2 text-muted">
                  No Square Measure Units found.
                </span>
              )}
              {configMeasureMappings.length > 0 &&
                configMeasureMappings.map(
                  (measureAccountMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{measureAccountMapping.name}</b>
                      </div>
                      <Select
                        placeholder="Select an UOM"
                        theme={customSelectTheme}
                        options={measureAccountMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setMeasureAccount(e, idx);
                        }}
                        value={measureAccountMapping.options.filter(
                          (obj) =>
                            obj.value ===
                            measureAccountMapping.deskeraUomId
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[
                        measureAccountMapping.deskeraUomId
                      ] && (
                        <div>
                          {
                            formErrors[
                              measureAccountMapping.deskeraUomId
                            ]
                          }
                        </div>
                      )}
                    </div>
                  )
                )}
              {configMeasureMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={saveMeasureAccountConfig}
                    disabled={saveMeasureAccountConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveMeasureAccountConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
     {deskeraInfo.accountConnected && accounts.data.length > 0 && (
      <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Custom Field Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!customFieldConfigured && !customfieldConfig && (
                <Alert type="primary">
                  Custom Field must be configured before Square transactions can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configCustomFieldAttributeMappings.length === 0 && (
                <span className="mt-2 text-muted">
                  No Square Custom Field found.
                </span>
              )}
              {configCustomFieldAttributeMappings.length > 0 &&
                configCustomFieldAttributeMappings.map(
                  (configCustomFieldAttributeMappings, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configCustomFieldAttributeMappings.name}</b>
                      </div>
                      <Select
                        placeholder="Select Custom Field"
                        theme={customSelectTheme}
                        options={configCustomFieldAttributeMappings.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setCustomFieldAttribute(e, idx);
                        }}
                        value={configCustomFieldAttributeMappings.options.filter(
                          (obj) =>
                            obj.label ===
                            configCustomFieldAttributeMappings.deskeraCF.label
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[
                        configCustomFieldAttributeMappings.deskeraCF.label
                      ] && (
                        <div>
                          {
                            formErrors[
                              configCustomFieldAttributeMappings.deskeraCF.label
                            ]
                          }
                        </div>
                      )}
                    </div>
                  )
                )}
              {configCustomFieldAttributeMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={saveCustomFieldAttribute}
                    disabled={saveCustomFieldAttributeConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveCustomFieldAttributeConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
        )} 

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {config.data.syncEnabled ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}

      {/* Transaction Sync Modal*/}
      {config.data.syncEnabled && deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Transaction Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Square transactions can be synced with Deskera
                  Books.
                </Alert>
              )}
              {
                <>
                  <div className="form-group">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        defaultChecked={enableTwoSync}
                        onChange={() => {
                          if (enableTwoSync) {
                            dispatch(
                              setTwoWaySyncConfig({
                                ...twoWaySyncConfig.data,
                                enableTwoSync: !enableTwoSync,
                              })
                            );
                            setEnableTwoSync(!enableTwoSync);
                          } else {
                            dispatch(
                              setTwoWaySyncConfig({
                                ...twoWaySyncConfig.data,
                                enableTwoSync: !enableTwoSync,
                                enableTwoSyncForProductDeletion: false,
                                showDeskeraProducts: false,
                                syncPaymentFromDeskeraToSquare: false,
                              })
                            );
                            setEnableTwoSync(!enableTwoSync);
                            setEnableTwoSyncForProductDeletion(false);
                            setShowDeskeraProducts(false);
                            setSyncPaymentFromDeskeraToSquare(false);
                          }
                        }}
                      />
                      <label class="form-check-label text-muted" for="inlineCheckbox2">
                        Enable two-way sync
                      </label>
                    </div>
                  </div>
                </>
              }
               {enableTwoSync && (
                <>
                  <div className="form-group">
                    <div className="text-muted mb-2" aria="label">
                      <b>Two-Way Sync Settings</b>
                    </div>
                    <div class="ml-m form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox3"
                        defaultChecked={enableTwoSyncForProductDeletion}
                        onChange={() => {
                          dispatch(
                            setTwoWaySyncConfig({
                              ...twoWaySyncConfig.data,
                              enableTwoSyncForProductDeletion: !enableTwoSyncForProductDeletion,
                            })
                          );
                          setEnableTwoSyncForProductDeletion(!enableTwoSyncForProductDeletion);
                        }}
                      />
                      <label class="form-check-label text-muted" for="inlineCheckbox3">
                        Enable 2-way sync for deletion of products
                      </label>
                    </div>
                    <div class="ml-m form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox4"
                        defaultChecked={syncPaymentFromDeskeraToSquare}
                        onChange={() => {
                          dispatch(
                            setTwoWaySyncConfig({
                              ...twoWaySyncConfig.data,
                              syncPaymentFromDeskeraToSquare: !syncPaymentFromDeskeraToSquare,
                            })
                          );
                          setSyncPaymentFromDeskeraToSquare(!syncPaymentFromDeskeraToSquare);
                        }}
                      />
                      <label class="form-check-label text-muted" for="inlineCheckbox4">
                        Sync payment status update from Deskera to Square
                      </label>
                    </div>
                    <div class="ml-m form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox5"
                        defaultChecked={showDeskeraProducts}
                        onChange={() => {
                          dispatch(
                            setTwoWaySyncConfig({
                              ...twoWaySyncConfig.data,
                              showDeskeraProducts: !showDeskeraProducts,
                            })
                          );
                          setShowDeskeraProducts(!showDeskeraProducts);
                        }}
                      />
                      <label class="form-check-label text-muted" for="inlineCheckbox5">
                        Show Deskera Products
                      </label>
                    </div>
                  </div>
                </>
              )}
              {enableTwoSync && (
                 <>
                    {enableTwoSync && (
                      <div className="form-group">
                        <div className="text-muted mb-2" aria="label">
                          <b>Select Warehouse To Use From Deskera</b>
                        </div>
                        <Select
                          placeholder="Select a Warehouse"
                          theme={customSelectTheme}
                          options={defaultDeskeraWarehouses}
                          defaultValue={"NO_SPECIFIC_WH"}
                          isSearchable={true}
                          menuPlacement="auto"
                          onChange={(e) =>
                            dispatch(
                              setTwoWaySyncConfig({
                                  ...twoWaySyncConfig.data,
                                  warehouseCode: e.value
                              })
                            )
                          }
                          value={deskeraWarehouses.filter(
                            (obj) => obj.value == twoWaySyncConfig.data.warehouseCode
                          )}
                        />
                        {formErrors.warehouseCode && (
                          <div>{formErrors.warehouseCode}</div>
                        )}
                      </div>
                    )}
                  </>
             )} 
              {
                <div className="d-flex flex-row-reverse">
                  <button
                    onClick={(e) => saveTransactionSync(e)}
                    disabled={saveSyncLoading}
                    className="btn border-radius-m p-v-s text-white bg-success"
                    // style={{ marginRight: "20px" }}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-white">
                      {saveSyncLoading ? (
                        <div
                          class="spinner-border text-white spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              }
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Config;
