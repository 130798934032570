import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAsyncDebounce } from "react-table";
import { isEmpty } from "src/utils/Utility";

export default function SelectBox(props) {
  const [value,setValue] = useState(null);
  const onChange = (e) => {
    const selectedValues = new Set((props?.isMulti ? e : [e]).map(obj => obj?.value).filter(val => !isEmpty(val)));
    const selectedOptions = props?.options?.filter(option => selectedValues.has(option?.value));
    setValue(selectedOptions?.map(option => option?.value)?.filter(val => !isEmpty(val))?.join(","))
  };
  useEffect(()=>{
    if(value !== null){
      onPatchQuery(props?.queryKey, value);
    }
  },[value])
  const onPatchQuery = useAsyncDebounce((key, value)=>{
    props?.patchQuery(key, value)
  },props?.debounceAfter || 200);

  return (
    <div style={{ ...props?.style }} className={props?.className || "input-group position-relative"}>
      <Select
        placeholder={props?.placeholder || "Select..."}
        className={props?.inputClassName || "mr-2"}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...props?.style
          }),
        }}
        
        options={props?.options || []}
        menuPlacement="auto"
        onChange={onChange}
        theme={props?.customSelectTheme}
        isMulti={props?.isMulti}
      />
    </div>
  );
}
