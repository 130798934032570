import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadOrders } from "src/redux/actions/amazon";
import Alert from "src/components/Alert";
import AmazonTable from "src/components/AmazonTable";
import { migrateAmazonData } from "src/api";
import SyncStatus from "src/components/SyncStatus";
import getSymbolFromCurrency from "currency-symbol-map";

function Orders() {
  const amazonConfig = useSelector((state) => state.amazon.config);
  const orders = useSelector((state) => state.amazon.orders);
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortBy, setSortBy] = useState();
  const [loading, setLoading] = useState(false);

  const data = useMemo(() => orders.data, [orders]);

  useEffect(() => {
    setLoading(false);
    if (data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const refreshData = ({ pageSize, pageIndex, sortBy, query }) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setLoading(true);
    setSortBy(sortBy);

    let updatedSortBy = sortBy?.length ? sortBy : [{ id: "orderDate", desc: true }];
    dispatch(loadOrders(pageSize, pageIndex, updatedSortBy, query ?? ""));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "cartInvoiceId",
      },
      {
        Header: "Purchase Date  ",
        accessor: "orderDate",
        Cell: ({ cell: { value } }) => <span> {value.substring(0, 10)}</span>,
      },
      {
        Header: "Customer Email",
        accessor: "customerEmail",
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        Cell: ({ cell: { row } }) => (
          <span>
            {row.original.totalAmount && getSymbolFromCurrency(row.original.currency)}{" "}
            {row.original.totalAmount}
          </span>
        ),
      },
      {
        Header: "Deskera Code",
        accessor: "deskeraDocumentCode",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartInvoiceId}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Sync Remarks",
        accessor: "reason",
        disableSortBy: true,
        Cell: ({ cell: { row } }) => {
          return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.reason}</>;
        },
      },
    ],
    []
  );

  return (
    <div className="p-4">
      <h3 className="mb-3">Orders</h3>
      {!amazonConfig.data.syncEnabled && (
        <Alert type="warning">
          Your synced orders will appear here. Please complete{" "}
          <Link to="/app/amazon" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
      <AmazonTable
        data={data.content ? data.content : []}
        columns={columns}
        cart="amazon"
        migrate={migrateAmazonData}
        refresh={refreshData}
        syncButton={true}
        onFilter={refreshData}
        tab="orders"
        pageCount={pageCount}
        loading={loading}
        rowId="cartInvoiceId"
      />
    </div>
  );
}

export default Orders;
