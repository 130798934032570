import { SET_AMAZON_CONFIG, SET_ACCOUNTS, SET_CUSTOMERS, SET_ORDERS, SET_PRODUCTS, SET_MATCHING_PRODUCTS } from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  products: {
    loading: true,
    data: [],
  },
  customers: {
    loading: true,
    data: [],
  },
  job: {
    loading: false,
    data: [],
  }
};

function amazonReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_AMAZON_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          loading: false,
          data: action.customers,
        },
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: {
          loading: false,
          data: action.products,
        },
      };
    case SET_MATCHING_PRODUCTS:
      return {
        ...state,
        matchingProducts: {
          loading: false,
          data: action.matchingProducts,
        },
      }
    default:
      return state;
  }
}

export default amazonReducer;
