import Alert from "src/components/Alert";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SyncStatus from "src/components/SyncStatus";
import { loadConfig, loadJob, loadDeskeraInventoryTransaction } from "src/redux/actions/woocommerce";
import { syncDeskeraInventoryTransactionToWooCommerce } from "src/api";
import ServerPaginationTable from "src/components/ServerPaginationTable";

function DeskeraInventoryTransaction() {

    const [syncStatus, setSyncStatus] = useState("enabled");
    const inventoryTransaction = useSelector((state) => state.woocommerce.deskeraInventoryTransaction);
    const dispatch = useDispatch();
    const job = useSelector((state) => state.woocommerce.job);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState([]);
    const [sortBy, setSortBy] = useState();


    useEffect(() => {
        dispatch(loadDeskeraInventoryTransaction());
        dispatch(loadConfig());
    }, []);

    useEffect(() => {
        dispatch(loadJob());
    }, job);

    const config = useSelector((state) => state.woocommerce.config);

    const columns = useMemo(
        () => [
            {
                Header: "Movement Type",
                accessor: "movementType",
            },
            {
                Header: "Product Id",
                accessor: "productId",
            },
            {
                Header: "Product Name",
                accessor: "productName",
            },
            {
                Header: "Transaction Type",
                accessor: "transactionType",
            },
            {
                Header: "Quantity",
                accessor: "quantity",
            },
            {
                Header: "Transaction Number",
                accessor: "transactionNo",
            },
            {
                Header: "Contact Name",
                accessor: "contactName",
            },
            {
                Header: "Rate/Value",
                accessor: "value",
            },
            {
                Header: "Source",
                accessor: "source",
            },
            {
                Header: "Sync Status",
                accessor: "syncStatus",
                Cell: ({ cell: { row } }) => {
                    return (
                        <SyncStatus
                            status={row.original.syncStatus}
                            reason={row.original.reason}
                            id={row.original.cartCustomerId}
                        />
                    );
                },
                disableGlobalFilter: true,
            },
            {
                Header: "Sync Remarks",
                accessor: "syncRemark",
                disableSortBy: true,
                Cell: ({ cell: { row } }) => {
                  return <>{!(row.original.syncStatus === "SUCCESSFUL") && row.original.syncRemark}</>;
                },
              }
        ],
        []
    );

    const data = useMemo(() => inventoryTransaction.data, [inventoryTransaction]);

    useEffect(() => {
        setLoading(false);
        if (data && data.totalPages) {
            setPageCount(data.totalPages);
        }
    }, [data]);

    const refreshData = ({ pageSize, pageIndex, status, sortBy }) => {
        setPageSize(pageSize);
        setPageIndex(pageIndex);
        setStatus(status);
        setSortBy(sortBy);
        setLoading(true);
        dispatch(loadDeskeraInventoryTransaction(pageSize, pageIndex, status, sortBy));
    };



    return (
        <div className="p-4">
            <h3 className="mb-3">Deskera Inventory Transaction</h3>
            {(!config.data || ! config.data.syncInOutTransactions) && (
                <Alert type="warning">
                    Your synced Deskera Inventory Transaction will appear here. Please complete{" "}
                    <Link to="/app/woocommerce" className="text-alert-warning">
                        <u>Two Way Sync Setup</u>
                    </Link>{" "}
                    before continuing.
                </Alert>
            )}
            <ServerPaginationTable
                data={data.content ? data.content : []}
                columns={columns}
                migrate={syncDeskeraInventoryTransactionToWooCommerce}
                cart="woocommerce"
                refresh={refreshData}
                tab="Deskera Inventory Transaction To Woocommerce"
                pageCount={pageCount}
                loading={loading}
                rowId="id"
            />
        </div>
    );
}
export default DeskeraInventoryTransaction;