import { DKButton, DKIcons, DKInput, DKLabel } from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getBigCommerceStoreOrderSuffixMapping,
  saveBigCommerceStoreOrderSuffixMapping,
} from "src/api";
import CloseIcon from "src/assets/Close";
import { addToast } from "src/redux/actions/toasts";
import customSelectTheme from "src/utils/selectTheme";

function OrderSuffixMapping(props) {
  const [attributeOptions, setAttributeOptions] = useState([]);
  const storeList = useSelector((state) => state.bigcommerce.accounts);
  const [storeOptions, setStoreOptions] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  console.log(data);
  useEffect(() => {
    getStoreMappingAttributes();
    getStoreOptions();
  }, [props.storeMapping, storeList]);

  const getStoreMappingAttributes = async () => {
    try {
      let response = await getBigCommerceStoreOrderSuffixMapping();
  
      let orderSuffixMap = {};
      response?.forEach((store) => {
        orderSuffixMap[store.storeHash] = store.orderSuffix;
      });
      let storeNameMap = {};
      storeList?.data?.forEach((store) => {
        storeNameMap[store.id] = store.name;
      });
      const storeAttributes = storeList?.data?.map((store) => ({
        label: store.name,
        value: store.id,
      }));
      setAttributeOptions(storeAttributes);
      let initailData = [];
      if (response?.length) {
        response.forEach((store) => {
          initailData.push({
            storeHash: {
              label: storeNameMap[store.storeHash],
              value: store.storeHash,
            },
            orderSuffix: store.orderSuffix,
          });
        });
        setData(initailData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStoreOptions = () => {
    if (storeList?.data?.length) {
      console.log(storeList);
      let storeOptions = storeList.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setStoreOptions(storeOptions);
    }
  };

  const validate = () => {
    // make sure the all data are having proper, orderSuffix and storeHash
    let isValid = true;
    data.forEach((item) => {
      if (!item.orderSuffix) {
        isValid = false;
      }
    });
    return isValid;
  };

  const updateRow = (index, key, value) => {
    setData((oldData) => {
      let updatedData = oldData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });
      return [...updatedData];
    });
  };

  const addRow = () => {
    let newRow = {
      storeHash: null,
      orderSuffix: null,
    };
    setData((updatedData) => [...updatedData, newRow]);
  };

  const deleteRow = (index) => {
    setData((oldData) => {
      let updatedData = [...oldData];
      updatedData.splice(index, 1);
      return [...updatedData];
    });
  };

  const optionMappingTable = () => {
    console.log(data);
    return (
      <table className="table border-none rounded">
        <thead className="sticky-top bg-white">
          <tr>
            <th scope="col">
              <h5 className="text-body mb-0">Bigcommerce Store</h5>
            </th>
            <th scope="col">
              <h5 className="text-body mb-0">Order Suffix</h5>
            </th>
            <th scope="col">
              <h5 className="text-body mb-0 float-right">Action</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Select
                    placeholder="Bigcommerce Store"
                    className="ml-s text-align-left"
                    options={getAttributeOptions(item?.storeHash)}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    onChange={(e) => {
                      updateRow(index, "storeHash", e);
                    }}
                    theme={customSelectTheme}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={true}
                    value={item.storeHash}
                  />
                </td>
                <td>
                  <DKInput
                    type="text"
                    value={item?.orderSuffix}
                    className="mb-m"
                    direction="VERTICAL"
                    titleStyle={{
                      display: "none",
                    }}
                    onBlur={() => {}}
                    onChange={(e) => {
                      console.log(e);
                      updateRow(index, "orderSuffix", e);
                    }}
                  />
                </td>

                <td>
                  <DKButton
                    className="border-l text-white float-right mr-2"
                    icon={DKIcons.ic_delete}
                    onClick={() => {
                      deleteRow(index);
                    }}
                    style={{
                      border: "1px",
                    }}
                    title=""
                  ></DKButton>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan={3}>
                <p className="mt-2">No Mapping Data</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const getAttributeOptions = (selectedOption) => {
    let filteredOptions = [...attributeOptions];
    if (data?.length) {
      let selectedAttributes = data.map((item) => item?.storeHash?.label);
      filteredOptions = filteredOptions.filter(
        (item) => !selectedAttributes.includes(item.label) || selectedOption?.label === item.label
      );
    }
    return filteredOptions;
  };

  const handleSubmit = async (closePopup = false) => {
    try {
      if (validate()) {
        let payload = data.map((item) => ({
          orderSuffix: item.orderSuffix,
          storeHash: item.storeHash.value,
        }));
        await saveBigCommerceStoreOrderSuffixMapping(payload);
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Successfully updated order suffix mapping fields.",
          })
        );
      } else {
        dispatch(
          addToast({
            type: "danger",
            title: "Error",
            message: "Invalid order suffix mapping data.",
          })
        );
      }
      if (closePopup === true) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      dispatch(
        addToast({
          type: "danger",
          title: "Error",
          message: "Failed to update order suffix mapping fields.",
        })
      );
    }
  };

  const handleClose = () => {
    props.setCustomerFilterState(false);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100"
      style={{
        zIndex: 99,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <div
        className="card dk-card dk-card-shadow d-flex border border-radius-l"
        style={{ width: "50vw" }}
      >
        <div className="card-header bg-transparent border-none">
          <h3 className="mt-2 ml-2">Order Suffix Mapping Configuration</h3>
        </div>
        <div className="card-body d-flex flex-column" style={{ align: "center" }}>
          <div
            class="d-block border-m rounded mb-3"
            style={{ height: "40vh", overflowY: "auto", overflowX: "hidden" }}
          >
            {optionMappingTable()}
          </div>
          <div>
            <button
              disabled={data?.length === storeOptions?.length}
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={() => {
                addRow();
              }}
              style={{ marginLeft: "" }}
            >
              + Add Mapping
            </button>
          </div>
        </div>
        <div className="card-footer bg-transparent border-none">
          <div className="d-flex flex-row-reverse">
            <DKButton
              className="bg-success mx-2 text-white"
              onClick={() => handleSubmit(true)}
              title={"Save & Close"}
              style={{ marginLeft: "10px", marginRight: "5px" }}
            ></DKButton>
            <DKButton
              className="bg-success mx-2 text-white"
              onClick={() => handleSubmit()}
              title={"Save"}
              style={{ marginLeft: "10px", marginRight: "10px" }}
            ></DKButton>
            <button
              className="btn dk-btn mx-2 font-weight-bold text-muted border border-secondary"
              onClick={handleClose}
            >
              <span className="svg-icon svg-baseline mr-2 svg-disabled">
                <CloseIcon />
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSuffixMapping;
