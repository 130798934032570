function ForwardRight() {
  return (
    <svg
      width="328"
      height="338"
      viewBox="0 0 328 338"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M191.515 151.805L96.2541 56.5438C87.529 47.8187 73.2289 47.8187 64.5038 56.5438L56.5538 64.4938C47.8287 73.209 47.8287 87.5091 56.5538 96.2342L129.237 168.937L56.5438 241.63C47.8187 250.355 47.8187 264.655 56.5438 273.37L64.4938 281.32C73.219 290.055 87.519 290.055 96.2442 281.32L191.505 186.049C196.196 181.369 198.282 175.088 197.935 168.927C198.292 162.776 196.205 156.495 191.515 151.805Z"
      />
      <rect x="222.974" y="50" width="55" height="237.87" rx="20" />
    </svg>
  );
}

export default ForwardRight;
