function Magento() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
      <polygon
        fill="#f4511e"
        points="26,35.164 24.004,36.293 22,35.159 22,17.476 17,20.311 17,38.07 24,42.039 31,38.075 31,20.264 26,17.428"
      />
      <polygon
        fill="#f4511e"
        points="24,5.995 8,14.979 8,32.981 13,35.808 13,17.964 24.004,11.742 35,17.959 35,35.813 40,32.986 40,15.042"
      />
    </svg>
  );
}

export default Magento;
