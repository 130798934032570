import {
  SHIP_STATION_SET_CONFIG,
  SHIP_STATION_SET_STORES,
  SHIP_STATION_SET_ORDERS,
  SHIP_STATION_SET_ACCOUNTS,
  SHIP_STATION_SET_JOB,
  SHIP_STATION_SET_INVOICES,
} from "../types";

const initialState = {
  config: {
    loading: true,
    data: {},
  },
  stores: {
    loading: true,
    data: [],
  },
  orders: {
    loading: true,
    data: [],
  },
  accounts: {
    loading: true,
    data: [],
  },
  job: {
    loading: true,
    data: {},
  },
  invoices: {
    loading: true,
    data: [],
  },
};

function shipStationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHIP_STATION_SET_CONFIG:
      return {
        ...state,
        config: {
          loading: false,
          data: action.config,
        },
      };
    case SHIP_STATION_SET_STORES:
      return {
        ...state,
        stores: {
          loading: false,
          data: action.stores,
        },
      };
    case SHIP_STATION_SET_ACCOUNTS:
      return {
        ...state,
        accounts: {
          loading: false,
          data: action.accounts,
        },
      };
    case SHIP_STATION_SET_ORDERS:
      return {
        ...state,
        orders: {
          loading: false,
          data: action.orders,
        },
      };
    case SHIP_STATION_SET_INVOICES:
      return {
        ...state,
        invoices: {
          loading: false,
          data: action.invoices,
        },
      };
    case SHIP_STATION_SET_JOB:
      return {
        ...state,
        job: {
          loading: false,
          data: action.job,
        },
      };
    default:
      return state;
  }
}

export default shipStationReducer;
