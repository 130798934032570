import React, { useState, useEffect } from "react";
import { DKLabel, DKButton } from "deskera-ui-library";
import CloseIcon from "src/assets/Close";
import MatchingSummaryTable from "src/pages/Magento/MatchingSummaryTable";
import customSelectTheme from "src/utils/selectTheme";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {getDeskeraDimensions ,getDeskeraProductBrif} from "src/api";
function ProductSyncConfig(props) {
    
    const [showPopUp, setShowPopup] = useState(false);
    const [magentoMappingField, setMagentoMappingField] = useState("PRODUCT_NAME");
    const [deskeraMappingField, setDeskeraMappingField] = useState({
      value: "PRODUCT_NAME",
      label: "Product Name",
      isCustomField: false
    });
    const [customFieldData , setCustomFieldData] = useState();
    const [productData, setProductData] = useState([]);
    const [error, setError] = useState("");
    const [deskeraStatusOptions, setDeskeraStatusOptions] = useState([
      {
        value: "PRODUCT_NAME",
        label: "Product Name",
        isCustomField: false
      },
      {
        value: "BARCODE",
        label: "Barcode",
        isCustomField: false
      },
    ]);
    async function getBooksProducts() {
      try {
        const products = await getDeskeraProductBrif();
        products.forEach((product) => {
          productData.push(product);
        });
      } catch {
        setError("Failed to load Deskera Books Products");
      }
    }
    const matchingDocScreen = () => {

        return<>
          {showPopUp && (
          <MatchingSummaryTable
            setSyncConfigOpen={props.setSyncConfigOpen}
            setShowPopup={setShowPopup}
            magentoMappingField={magentoMappingField}
            deskeraMappingField={deskeraMappingField}
            refreshData={props.refreshData}
            productData = {productData}
          />
        )}
        <div className=" column-width row-height mt-l">
          <div
            className="mr-l ml-l border-radius-m shadow-s border-m p-xl bg-white border-box"
            style={{ position: "sticky", top: "25%" }}
          >
            <div className=" row flex flex-row justify-content-center">
              <div
                className="mr-m ml-l border-radius-m shadow-s border-m p-xl bg-white border-box"
                style={{ width: "30%", height: "160px", position: "relative" }}
              >
                <DKLabel text="Magento Products" className="fw-m fs-l" />
                <div className="flex">
                  <DKLabel className="fs-m text-blue" style={{ paddingTop: "1%" }} />
                </div>
              </div>
              <hr
                style={{
                  borderTop: "2px dashed grey",
                  marginTop: "3%",
                  width: "12%",
                }}
                className="mr-m"
              />
              <div className="parent-height mr-m" style={{ paddingTop: "2%" }}>
                <DKButton
                  title="Start Matching"
                  className="bg-blue text-white border-m"
                  onClick={() => {
                    setShowPopup(true);
                  }}
                />
              </div>
              <hr
                style={{
                  borderTop: "2px dashed grey",
                  marginTop: "3%",
                  width: "15%",
                }}
                className="mr-m"
              />
              <div
                className="mr-s border-radius-m shadow-s border-m p-xl bg-white border-box"
                style={{ width: "30%", height: "160px", position: "relative" }}
              >
                <DKLabel text="Deskera Products" className="fw-m fs-l" />
                <div className="flex">
                  <DKLabel className="fs-m text-blue" style={{ paddingTop: "1%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    }
    const customStyles = {
        container: (provided, state) => ({
          ...provided,
          minWidth: "13rem",
        }),
      };

      const magentoStatusOption = [
        {
          value: "PRODUCT_NAME",
          label: "Product Name",
        },
        {
          value: "SKU",
          label: "SKU",
        },
      ];

      

      const updateMagentoSelectedStatus = (options) => {
        setMagentoMappingField(options.value);
      };
      const updateDeskeraSelectedStatus = (options) => {
        setDeskeraMappingField(options);
      };

      useEffect(() => {
        getDeskeraDimensionsData();
        getBooksProducts()
      },[]);

     async function getDeskeraDimensionsData() {
      try {
        const accounts = await getDeskeraDimensions();
        const dimensionOptions = [];
        accounts.forEach((account) => {
          if(account.modules.includes('PRODUCT')){
            dimensionOptions.push({ value: account.code, label: account.label,isCustomField: true });
          }   
        });
        setCustomFieldData(dimensionOptions);
        let tempDeskeraStatusOptions  =deskeraStatusOptions
        tempDeskeraStatusOptions = dimensionOptions !== null ? tempDeskeraStatusOptions.concat(dimensionOptions):dimensionOptions
        setDeskeraStatusOptions(tempDeskeraStatusOptions);
      } catch {
        console.log("Failed to load Deskera Dimensions");
      }
      }
  return (
    <div className="d-flex">
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div
          className="card dk-card dk-card-shadow d-flex border border-radius-l"
          style={{ minWidth: "90%", minHeight: "90%" }}
        >
          <div className="card-body d-flex flex-column" style={{ align: "center" }}>
            <div className=" card-body d-flex flex-column">
              <div className="text-muted mb-3 d-flex flex-row">
              <span className=" ml-xl text-align-left font-weight-bold" style={{ marginTop: "10px"}}>
                  Magento Mapping Field
                </span>
                <Select
                  placeholder="Magento Mapping Field"
                  className="ml-s text-align-left"
                  styles={customStyles}
                  options={magentoStatusOption}
                  defaultValue={magentoStatusOption[0]}
                  menuPlacement="auto"
                  onChange={(e) => {
                    updateMagentoSelectedStatus(e);
                  }}
                  theme={customSelectTheme}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isSearchable={false}
                />
                 <div className="text-muted mb-3 d-flex flex-row">
                  <span
                    className=" ml-xxl text-align-right font-weight-bold"
                    style={{ marginLeft: "700px", marginTop: "10px" }}
                  >
                    Deskera Mapping Field
                  </span>
                  <Select
                    placeholder="Deskera Mapping Field"
                    className="ml-s text-align-left"
                    styles={customStyles}
                    options={deskeraStatusOptions}
                    defaultValue={deskeraStatusOptions[0]}
                    onChange={(e) => {
                      updateDeskeraSelectedStatus(e);
                    }}
                    theme={customSelectTheme}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isSearchable={false}
                  />
                </div>
            
              </div>
              <div class="overflow-hidden flex-row">{matchingDocScreen()}</div>
            </div>
            <div className="d-flex flex-row-reverse">
              <button
                className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
                onClick={() => {
                  props.setSyncConfigOpen(false);
                }}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductSyncConfig;
