import { NavLink, Route, Switch, useLocation } from "react-router-dom";
import Header from "src/components/Header";
import ToastList from "src/components/ToastList";
import AmazonLightLogo from "src/assets/Amazon";
import AmazonLogo from "src/assets/AmazonDark";
import Amazon from "src/pages/Amazon";
import ShopifyLogo from "src/assets/Shopify";
import FoodicsLogo from "src/assets/FoodicsLogo";
import Shopify from "src/pages/Shopify";
import Foodics from "src/pages/Foodics";
import "src/index.css";
import "src/styles/index.scss";
import DashboardHome from "./Home";
import Woocommerce from "src/pages/Woocommerce";
import WoocommerceLogo from "src/assets/Woocommerce";
import ShipStationLogo from "src/assets/ShipStation";
import SquareLogo from "src/assets/square";
import Square from "src/pages/Square";
import ShipStation from "../ShipStation";
import Magento from "../Magento";
import MagentoLogo from "../../assets/Magento";
import BigCommerceLogo from "src/assets/Bigcommerce";
import BigCommerce from "src/pages/Bigcommerce";
import TallyLogo from "src/assets/Tally";
import Tally from "../Tally";
import BrightPearl from "../BrightPearl";
import BrightPearlLightSVG from "src/assets/BrightPearlLightSVG";

function Dashboard() {
  const location = useLocation();

  let sidebarItems = [
    {
      logo: <AmazonLogo />,
      lightLogo: <AmazonLightLogo />,
      href: "/app/amazon",
      component: <Amazon />,
      name: "amazon",
    },
    {
      logo: <ShopifyLogo />,
      lightLogo: <ShopifyLogo />,
      href: "/app/shopify",
      component: <Shopify />,
      name: "shopify",
    },
    {
      logo: <WoocommerceLogo />,
      lightLogo: <WoocommerceLogo />,
      href: "/app/woocommerce",
      component: <Woocommerce />,
      name: "woocommerce",
    },
    {
      logo: <MagentoLogo />,
      lightLogo: <MagentoLogo />,
      href: "/app/magento",
      component: <Magento />,
      name: "magento",
    },
    {
      logo: <SquareLogo />,
      lightLogo: <SquareLogo />,
      href: "/app/square",
      component: <Square />,
      name: "square",
    },
    {
      logo: <BigCommerceLogo />,
      lightLogo: <BigCommerceLogo />,
      href: "/app/bigcommerce",
      component: <BigCommerce />,
      name: "bigcommerce",
    },
    {
      logo: <ShipStationLogo />,
      lightLogo: <ShipStationLogo />,
      href: "/app/shipStation",
      component: <ShipStation />,
      name: "ShipStation",
    },
    // {
    //   logo: <TallyLogo />,
    //   lightLogo: <TallyLogo />,
    //   href: "/app/tally",
    //   component: <Tally />,
    //   name: "Tally",
    // },
    {
      logo: <BrightPearlLightSVG />,
      lightLogo: <BrightPearlLightSVG />,
      href: "/app/brightpearl",
      component: <BrightPearl />,
      name: "BrightPearl",
    },
  ];

  return (
    <div className="d-flex flex-column wh-100 min-h-screen">
      <Header />
      <ToastList />
      <div className="d-flex flex-row overflow-y-scroll vh-100 wh-100">
        <div
          className="dk-shadow-sm overflow-y-scroll"
          style={{
            minWidth: 60,
            paddingLeft: 5,
            background: "#384fbe",
          }}
        >
          {sidebarItems.map((item, index) => (
            <NavLink
              onClick={() => sessionStorage.setItem("cartType", item.name)}
              className="d-flex py-2 px-1 align-items-center justify-content-center mt-3"
              to={item.href}
              style={{
                borderRadius: "20px 0px 0px 20px",
                zIndex: 10,
              }}
              activeClassName="bg-white"
            >
              <h2 className="m-0">
                <span className="svg-icon">
                  {location.pathname.includes(item.href) ? item.logo : item.lightLogo}
                </span>
              </h2>
            </NavLink>
          ))}
        </div>
        <Switch>
          <Route exact path="/app">
            <DashboardHome />
          </Route>
          {sidebarItems.map((item) => (
            <Route path={item.href}>{item.component}</Route>
          ))}
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
